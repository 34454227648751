import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { Stores } from '../../models/stores'
import icAdd from '@iconify/icons-ic/twotone-add'
import icDelete from '@iconify/icons-ic/twotone-delete'
import icSearch from '@iconify/icons-ic/twotone-search'
import { GroupService } from 'src/app/services/group/group.service'
import { DefaultReturnDto } from '../../models/DefaultReturnDto'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'vex-filter-store',
  templateUrl: './filter-store.component.html',
  styleUrls: ['./filter-store.component.scss']
})
export class FilterStoreComponent implements OnInit {
  loading: boolean
  form: FormGroup
  stores: MatTableDataSource<Stores>
  storesToAdd: Stores[] = []
  icAdd = icAdd
  icSearch = icSearch
  icDelete = icDelete
  displayedColumns: string[] = [
    'actions',
    'name',
    'cnpj'
  ];

  constructor (
    private readonly snackbar: MatSnackBar,
    private readonly groupService: GroupService,
    private readonly fb: FormBuilder,
    public readonly dialogRef: MatDialogRef<FilterStoreComponent>
  ) { }

  ngOnInit (): void {
    this.stores = new MatTableDataSource()
    this.form = this.fb.group({
      nameOrCnpj: ['', Validators.required]
    })
  }

  filterStore (): void {
    this.loading = true
    if (!this.form.valid) {
      this.snackbar.open('Selecione o nome ou cnpj para filtrar as lojas!', '', {
        duration: 4000
      })
      return
    }
    this.groupService.getStores(this.form.get('nameOrCnpj').value).subscribe(
      (res: DefaultReturnDto<Stores>) => {
        this.loading = false
        this.stores.data = res.data
      },
      () => {
        this.loading = false
        this.snackbar.open('Ocorreu um erro ao filtrar as lojas!', '', {
          duration: 4000
        })
      }
    )

  }

  addStore (event: any, element: Stores): void {
    if (event.checked) {
      this.storesToAdd.push(element)
      return
    }

    const index = this.storesToAdd.findIndex(e => e.id === element.id)
    this.storesToAdd.splice(index, 1)
  }

  addStores (): void {
    if (!this.storesToAdd.length) {
      this.snackbar.open('Você deve selecionar ao menos uma loja!', '', {
        duration: 4000
      })
      return
    }

    this.dialogRef.close({
      data: this.storesToAdd
    })
  }

}
