import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import icSearch from '@iconify/icons-ic/twotone-search'
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators'
import { CrudService } from 'src/app/services/crud/crud.service'
import { StoresUserAccessDto } from '../../models/StoresUserAccessDto'
import dateFormat from 'dateformat'
import { MatSnackBar } from '@angular/material/snack-bar'
import { COMMA } from '@angular/cdk/keycodes'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { StoreDto } from '../../models/StoreDto'
import { DefaultReturnDto } from '../../models/DefaultReturnDto'
@Component({
  selector: 'vex-filter-cnpjs-data',
  templateUrl: './filter-cnpjs-data.component.html',
  styleUrls: ['./filter-cnpjs-data.component.scss']
})
export class FilterCnpjsDataComponent implements OnInit {
  @Output() onSearch: EventEmitter<any> = new EventEmitter()
  @Input() NotSearchWhenIncludingCnpj: boolean;
  today = new Date()
  icSearch = icSearch
  form: FormGroup
  storeFilter: FormControl
  cnpjListOptions: any
  loading = false

  @ViewChild('cnpjInput') cnpjInput: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [COMMA];
  chipListCnpjs: any[] = [];
  allCnpjs: any[] = [];

  constructor (
    private readonly matSnackBar: MatSnackBar,
    private readonly crudService: CrudService,
    private readonly accreditationService: AccreditationService,
    private readonly fb: FormBuilder
  ) {
    this.storeFilter = new FormControl()
    this.getAllCnpjs()
  }

  ngOnInit (): void {
    const finalDate = new Date().setDate(new Date().getDate() - 7)

    this.form = this.fb.group({
      cnpj: ['', Validators.required],
      initialDate: [new Date(finalDate), Validators.required],
      finalDate: [new Date(), Validators.required]
    })

    this.initAutoCompleteCnpj()
  }

  getAllCnpjs (): void {
    this.loading = true
    this.accreditationService.getAllStores()
      .subscribe((res: DefaultReturnDto<StoreDto>) => {
        this.loading = false
        if (res.data.length < 500) {
          this.chipListCnpjs = [
            {
              label: 'Todos Cnpjs',
              value: 'all'
            }
          ]
        }
        
        res.data.map((data) => data.cnpj).forEach(cnpj => {
          if (cnpj.length > 11) {
            this.allCnpjs.push(cnpj)
          }
        })
        this.form.get('cnpj').setValue('all')
        this.search()
      }, () => {
        this.loading = false
      }
      )
  }

  getToday (): Date {
    return this.today
  }

  setCnpjValue (value: StoresUserAccessDto): void {
    const exists = this.chipListCnpjs.some((objCnpj) => (objCnpj.value == value.cnpj && objCnpj.label == value.nameWithCnpj))
    if (exists) {
      let message = `O cnpj ${value.nameWithCnpj} já foi selecionado!`
      if (value.cnpj == 'all') {
        message = 'Todos cnpjs já foram selecionados!'
      }
      this.matSnackBar.open(message, '', {
        duration: 5000
      })
      this.cnpjInput.nativeElement.value = ''
      return
    }
    this.chipListCnpjs.push({ value: value.cnpj, label: value.nameWithCnpj })
    this.cnpjInput.nativeElement.value = ''

    this.form.get('cnpj').setValue(value.cnpj)

    if (this.NotSearchWhenIncludingCnpj) {
      return
    }
    this.search()
  }

  getOptionText (option: StoresUserAccessDto): string {
    if (option) {
      return option?.nameWithCnpj
    }
  }

  private _filterStore (value: string): any {
    let newValue = value.replace(/[^\d]+/g, '')
    if (!newValue) {
      newValue = value
    }

    this.loading = true
    return this.accreditationService.getStoresFromUserFiltered(newValue)
      .pipe(
        map((response: any) => {
          this.loading = false

          if (!response.data.length) {
            if (newValue?.toLowerCase().startsWith('todo') || newValue == 'all') {
              return response.data = [{
                cnpj: 'all',
                trading_name: 'Todos Cnpjs',
                nameWithCnpj: 'Todos Cnpjs'
              }]
            }
            this.matSnackBar.open('Nenhum estabelecimento encontrado!', '', {
              duration: 5000
            })
            return
          }
          response.data.forEach(option => {
            option.nameWithCnpj = option.trading_name + ' - ' + option.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
          })

          response.data.push({
            cnpj: 'all',
            trading_name: 'Todos Cnpjs',
            nameWithCnpj: 'Todos Cnpjs'
          })

          return response.data
        }, () => {
          this.loading = false
          this.matSnackBar.open('Nenhum estabelecimento encontrado!', '', {
            duration: 5000
          })
        })
      )
  }

  initAutoCompleteCnpj (): void {
    this.cnpjListOptions = this.storeFilter.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        distinctUntilChanged(),
        switchMap(val => {
          if (val && typeof val === 'string') {
            if (val.length >= 3)
              return this._filterStore(val)
          } else {
            if (val.cnpj)
              return this._filterStore(val.cnpj)
          }
          return new Subject()
        })
      )
  }

  search (): void {
    if (this.form.valid) {
      const diffTime = Math.abs(this.form.get('finalDate').value - this.form.get('initialDate').value)
      if (Math.ceil(diffTime / (1000 * 60 * 60 * 24)) > 7) {
        this.matSnackBar.open('Período selecionado excede 7 dias!', '', {
          duration: 5000
        })
        return
      }
      let chips = this.chipListCnpjs.map((cnpj) => cnpj.value)

      if (chips.length == 0) {
        return
      }

      if (chips.includes('all')) {
        const index = chips.indexOf('all')

        if (index >= 0) {
          chips.splice(index, 1)
        }
        chips = [...chips, ...this.allCnpjs]
      }

      this.onSearch.emit({
        initialDate: dateFormat(this.form.get('initialDate').value, 'yyyy-mm-dd'),
        finalDate: dateFormat(this.form.get('finalDate').value, 'yyyy-mm-dd'),
        cnpjs: chips
      })
    }
  }

  remove (cnpjObj: string): void {
    const index = this.chipListCnpjs.indexOf(cnpjObj)

    if (index >= 0) {
      this.chipListCnpjs.splice(index, 1)
    }
    this.search()
  }
}
