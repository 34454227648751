import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { Stores } from '../../models/stores'
import icAdd from '@iconify/icons-ic/twotone-add'
import icDelete from '@iconify/icons-ic/twotone-delete'
import { FilterStoreComponent } from '../filter-store/filter-store.component'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { GroupService } from 'src/app/services/group/group.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { Group } from '../../models/group'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import icSearch from '@iconify/icons-ic/twotone-search'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'


@Component({
  selector: 'vex-update-group',
  templateUrl: './update-group.component.html',
  styleUrls: ['./update-group.component.scss']
})
export class UpdateGroupComponent implements OnInit {
  loading: boolean
  form: FormGroup
  stores: MatTableDataSource<Stores>
  selectedStores: Stores[] = [];
  group : Group
  searchCtrl: FormControl
  icAdd = icAdd
  icDelete = icDelete
  icSearch = icSearch
  displayedColumns: string[] = [
    'name',
    'cnpj',
    'actions'
  ];
  constructor (
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<UpdateGroupComponent>,
    private readonly snackbar: MatSnackBar,
    private readonly utilService: UtilsService,
    private readonly groupService: GroupService,
    private readonly accreditationService: AccreditationService,
    private readonly fb: FormBuilder,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: { group: Group }   
  ) { 
    this.group = data.group
    this.form = new FormGroup({
      name: new FormControl(this.group.name),
      id : new FormControl(this.group.id)  
    })
    this.searchCtrl = new FormControl()
  }

  ngOnInit (): void {
    this.initializeForm()
    this.setupSearch()
  }

  initializeForm (): void {
    this.stores = new MatTableDataSource()
    this.form = this.fb.group({
      name: [this.group.name ?? '', Validators.required]
    })
  }

  setupSearch (): void {
    this.searchCtrl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        distinctUntilChanged()
      )
      .subscribe((val: string) => {
        if (val.length === 0 || val.length >= 3) {
          this.searchStores(val)
        }
      })

    this.stores = new MatTableDataSource()
    this.form = this.fb.group({
      name: [this.group.name ?? '', Validators.required]
    })
  }

  searchStores (storeCnpj: string): void {
    this.loading = true
    const groupId = this.data.group.id
    this.accreditationService.getStoresByGroup(groupId, storeCnpj).subscribe(
      (res: DefaultReturnObjDto<Array<Stores>>) => {
        this.loading = false
        if (!res.data.length) {
          this.snackbar.open('Nenhuma loja encontrada!', '', {
            duration: 5000
          })
          this.stores.data = []
          return
        }
        this.stores.data = res.data
      },
      () => {
        this.loading = false
        this.snackbar.open('Nenhuma loja encontrada!', '', {
          duration: 5000
        })
      }
    )
  }

  add (): void {
    this.dialog.open(FilterStoreComponent, {
      disableClose: true,
      minWidth: '40%'
    }).afterClosed().subscribe(res => {
      res.data.forEach(item => {
        if (!this.stores.data.some(existingItem => existingItem.id === item.id)) {
          this.stores.data = this.stores.data.concat(item)
          console.log('Item adicionado')
        } else {
          console.log('Item não adicionado')
        }
      })
    })
  }
  
  removeStore (store: Stores): void {
    const data = this.stores.data
    data.splice(data.findIndex(e => e.id === store.id), 1)
    this.stores.data = data
  }
  
  saveGroup (): void {
    if (this.loading || !this.form.valid) return

    if (!this.stores.data || !this.stores.data.length) {
      this.loading = false
      this.snackbar.open('Por favor, adicione pelo menos uma loja para prosseguir com a atualização!', '', {
        duration: 4000}
      ); return } 
      
    this.loading = true
  
    const data = this.form.getRawValue()
    data.id = this.group.id
    data.stores = this.stores.data.map(e => {
      return { store_id: e.id }
    })
  
    this.loading = true
    this.groupService.editGroup(data).subscribe(
      () => {
        this.loading = false
        this.snackbar.open('Grupo atualizado com sucesso!', '', {
          duration: 4000
        })
        return this.dialogRef.close()
      },
      (err) => {
        this.loading = false
        this.snackbar.open(this.utilService.getErrorMessage(err, 'Não foi possível atualizar o grupo. Tente novamente!'), '', {
          duration: 4000
        })
      }
    )
  }
}
