import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { RefundDto } from 'src/app/layouts/client-layout/models/RefundDto'
import icClose from '@iconify/icons-ic/twotone-close'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'

@Component({
  selector: 'vex-refund-detail',
  templateUrl: './pending-refund-detail.component.html',
  styleUrls: ['./pending-refund-detail.component.scss'],
  animations: [stagger40ms]
})

export class PendingRefundDetailComponent  {
  icClose = icClose
  refundData: any = {}
  
  constructor (
  public dialogRef: MatDialogRef<PendingRefundDetailComponent>,
  @Inject(MAT_DIALOG_DATA) public data:RefundDto
  ) 
  {
   
    this.refundData = data
  }


  toPspName (integrationTypePix: string): string {
    switch (integrationTypePix) { 
    case 'MundiPagg': { 
      return 'Pagar.me'
    } 
    default: { 
      return integrationTypePix
    } 
    } 
  }

  closeDialog (): void {
    this.dialogRef.close()
  }
}
