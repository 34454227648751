import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { validate } from 'gerador-validador-cpf'

@Component({
  selector: 'vex-payer-document',
  templateUrl: './payer-document.component.html',
  styleUrls: ['./payer-document.component.scss']
})
export class PayerDocumentComponent implements OnInit {
  form: FormGroup

  constructor (
    private readonly fb: FormBuilder,
    public matSnackBar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<PayerDocumentComponent>
  ) { }

  ngOnInit (): void {
    this.form = this.fb.group({
      document: ['', Validators.required]
    })
  }

  save (): void {
    if (!this.form.valid) return

    const data = this.form.getRawValue()

    if (!validate(data.document)) {
      this.matSnackBar.open('CPF inválido!', '',
        {
          duration: 4000
        }
      )
      return
    }

    this.dialogRef.close(this.form.getRawValue())
  }
}
