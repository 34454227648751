<vex-page-layout>
  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="mt-0 mb-1"><b>Provedores</b></h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">
    <div class="card overflow-auto -mt-16">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
          <span>Provedores</span>
        </h2>

        <div class="bg-card rounded-full border px-4" fxFlex="460px" fxFlex.lt-md="auto" fxHide.xs fxLayout="row"
          fxLayoutAlign="start center">
          <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
          <input [formControl]="searchCtrl" class="px-4 py-3 border-0 outline-none w-full bg-transparent"
            placeholder="Procurar por nome" type="text" />
        </div>

        <span fxFlex></span>

        <button class="ml-4" fxFlex="none" fxHide.gt-xs mat-icon-button type="button">
          <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>
        <button *ngIf="canList" (click)="fetchData()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
          matTooltip="Procurar PSP" type="button">
          <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>
      </div>

      <table @stagger [dataSource]="dataSource" *ngIf="canList" class="w-full" mat-table matSort>
        <ng-container matColumnDef="name" class="w-1/3">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element.isActive ? 'Ativo' : 'Inativo' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="warning">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let element">
            {{ element.warning ? 'Em alerta' : element.isActive ? 'Operacional' : '---' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="minTimeToAlert">
          <th mat-header-cell *matHeaderCellDef>Tempo mínimo para alertar</th>
          <td mat-cell *matCellDef="let element">
            {{ element.minTimeToAlert + 's' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="minPercentageToAlert">
          <th mat-header-cell *matHeaderCellDef>
            Porcentagem mínima para alertar
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.minPercentageToAlert + '%' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="resolvePspTimeWindow">
          <th mat-header-cell *matHeaderCellDef>
            Tempo mínimo para resolver alerta
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.resolvePspTimeWindow + 'm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="w-1/12">Editar</th>
          <td mat-cell *matCellDef="let element">
            <button (click)="editPsp(element.pspId)">
              <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <div fxLayoutGap="25px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout="column"
        fxLayoutAlign="space-around center">
        <div class="center" *ngIf="!canList">
          USUÁRIO NÃO TEM PERMISSÃO DE VISUALIZAÇÃO!
        </div>

        <div class="center" *ngIf="(!dataSource.data || dataSource.data.length === 0) && !loading">
          NENHUM PSP ENCONTRADO!
        </div>

        <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
          <mat-spinner diameter="36"></mat-spinner>
        </div>
      </div>
      <mat-paginator [pageSize]="paging.pageSize" (page)="changePage($event)" [length]="paging.length"
        [pageSizeOptions]="[10, 50, 100]" aria-label="Select page">
      </mat-paginator>
    </div>
  </vex-page-layout-content>
</vex-page-layout>