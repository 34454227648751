import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'vex-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  dialogObj: {
    dialogTitle: string,
    dialogText: string,
    dialogConfirmText: string
    dialogCloseText?: string
  }
  constructor (
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      dialogTitle: string,
      dialogText: string,
      dialogConfirmText: string
      dialogCloseText?: string
    }
  ) {
    this.dialogObj = data
  }

  clickConfirm (): void {
    this.dialogRef.close({ event: 'CONFIRM' })
  }

  clickClose (): void {
    this.dialogRef.close({ event: 'CLOSE' })
  }

}
