<h2 fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-title>
    Detalhes do estorno

    <button (click)="closeDialog()" class="close_custom" color="primary" mat-icon-button type="button">
        <mat-icon [icIcon]="icClose"></mat-icon>
    </button>
</h2>

<div mat-dialog-content>
    <div class="column">
        <div class="flex" fxLayoutGap="25px">
            <mat-form-field class="flex-auto" style="width: 350px;">
                <mat-label>Identificador estorno</mat-label>
                <input matInput readonly value="{{refundData?.refundId ?? '---'}}">
            </mat-form-field>
            <mat-form-field class="flex-auto" style="width: 350px;">
                <mat-label>Identificador pagamento</mat-label>
                <input matInput readonly value="{{refundData?.paymentId ?? '---'}}">
            </mat-form-field>
        </div>

        <div class="flex" fxLayoutGap="25px">

            <mat-form-field class="flex-auto">
                <mat-label>Identificador cobrança</mat-label>
                <input matInput readonly value="{{refundData?.billingId ?? '---'}}">
            </mat-form-field>
            <mat-form-field class="flex-auto">
                <mat-label>Identificador transação</mat-label>
                <input matInput readonly value="{{refundData?.transactionId ?? '---'}}">
            </mat-form-field>
        </div>
        <div class="flex" fxLayoutGap="25px">
            <mat-form-field class="flex-auto">
                <mat-label>Identificador transferencia</mat-label>
                <input matInput readonly value="{{refundData?.transferId ?? '---'}}">
            </mat-form-field>
            <mat-form-field class="flex-auto">
                <mat-label>Integração Pix</mat-label>
                <input matInput readonly value="{{toPspName(refundData?.integrationTypePix ?? '---')}}">
            </mat-form-field>
        </div>
    </div>
</div>