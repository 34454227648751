<h1 mat-dialog-title>Cadastro de Grupos</h1>
<div mat-dialog-content>
  <form autocomplete="off" [formGroup]="form" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Nome</mat-label>

        <input type="text" formControlName="name" matInput />
        <mat-error *ngIf="form.get('name').hasError('required')"
          >Nome do Grupo é obrigatório
        </mat-error>
      </mat-form-field>

      <button
        (click)="filterGroups()"
        class="ml-4"
        color="primary"
        fxFlex="none"
        mat-mini-fab
        matTooltip="Filtrar grupos"
        type="button"
      >
        <mat-icon [icIcon]="icSearch"></mat-icon>
      </button>
    </div>

    <table
      *ngIf="groups.data && groups.data.length"
      mat-table
      [dataSource]="groups"
      class="w-full"
    >
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            [checked]="element.checked"
            color="primary"
            (change)="addGroups($event, element)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div *ngIf="!groups.data || !groups.data.length" class="w-full center">
      NENHUM GRUPO ENCONTRADO!
    </div>

    <mat-paginator
      [pageSize]="paging.pageSize"
      (page)="changePage($event)"
      [length]="paging.length"
      [pageSizeOptions]="[10, 50, 100]"
      aria-label="Select page"
    >
    </mat-paginator>
  </form>
</div>

<div
  *ngIf="!loading"
  class="mt-3 mb-1"
  mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="space-around center"
>
  <button mat-button mat-dialog-close>FECHAR</button>
  <button (click)="addGroup()" mat-raised-button type="submit" color="primary">
    VINCULAR GRUPO{{ groupsToAdd.length > 1 ? 'S' : '' }}
    <span *ngIf="groupsToAdd.length >= 1">({{ groupsToAdd.length }})</span>
  </button>
</div>

<div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
  <mat-spinner diameter="36"></mat-spinner>
</div>
