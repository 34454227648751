import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { BrandDto } from '../../models/BrandDto'
import { Crumb } from '../../models/crumb'
import icAdd from '@iconify/icons-ic/twotone-add'
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz'
import icSearch from '@iconify/icons-ic/twotone-search'
import icDownload from '@iconify/icons-ic/file-download'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { PagedListDto } from '../../models/PagedListDto'
import { MatDialog } from '@angular/material/dialog'
import { CreateBrandComponent } from './create-brand/create-brand.component'
import { DefaultFilter } from '../../models/DefaultFilter'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'

@Component({
  selector: 'vex-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss'],
  animations: [stagger40ms]
})
export class BrandsComponent implements OnInit {
  loadingBrand = false
  loadingBrandWithFilter = false
  dataSource: MatTableDataSource<BrandDto>
  breadcrumbs: Crumb[] = [{ name: 'Marcas', path: ['brand'] }]

  @ViewChild(MatPaginator) paginator: MatPaginator

  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }

  form: FormGroup
  icSearch = icSearch
  icAdd = icAdd
  icMoreHoriz = icMoreHoriz
  icDownload = icDownload
  searchCtrl: FormControl = new FormControl('')
  displayedColumns: string[] = ['brandName', 'actions']
  canCreate: boolean
  canList: boolean

  constructor (
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private readonly utilsService: UtilsService
  ) {
    this.dataSource = new MatTableDataSource()
    this.form = this.formBuilder.group({})
  }

  ngOnInit (): void {
    this.loadingBrand = true
    this.fetchData()

    this.searchCtrl.valueChanges
      .pipe(debounceTime(500), startWith(''), distinctUntilChanged())
      .subscribe((val) => {
        if (!val.length || val.length >= 3) {
          this.paging.actualPage = 1
          this.fetchDataWithFilter()
        }
      })
  }

  fetchData (): void {
    this._getBrandPaged({
      pageNumber: this.paging.actualPage,
      pageSize: this.paging.pageSize,
      searchBy: this.searchCtrl.value
    })
  }

  fetchDataWithFilter (): void {
    this.loadingBrandWithFilter = true
    this._getBrandPaged({
      pageNumber: this.paging.actualPage,
      pageSize: this.paging.pageSize,
      searchBy: this.searchCtrl.value
    })
  }

  openCreateBrandDialog (): void {
    this.dialog.open(CreateBrandComponent, {})
  }

  openUpdateBrandDialog (brand: BrandDto): void {
    this.dialog
      .open(CreateBrandComponent, {
        data: {
          brand
        }
      })
      .afterClosed()
      .subscribe((res) => {
        this.fetchDataWithFilter()
      })
  }

  public ExportTOExcel (): void {
    this.matSnackBar.open(
      'O download do arquivo de exportação irá iniciar em breve.',
      '',
      {
        duration: 5000
      }
    )
    const filter = this.searchCtrl.value
    this.accreditationService
      .getBrandsByFilter(filter)
      .subscribe((response: Blob) => {
        this.utilsService.downloadFile(
          response,
          `RelatorioMarcas_${new Date().toLocaleDateString()}${new Date().getHours()}${new Date().getMinutes()}`,
          'csv',
          'text/csv'
        )
      },
      (response) => {
        this.matSnackBar.open(response.error, '', {
          duration: 5000
        })
      })
  }

  private _getBrandPaged (filter: DefaultFilter): void {
    this.accreditationService.getBrandsPaged(filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<BrandDto>>) => {
        this.loadingBrand = false
        this.loadingBrandWithFilter = false

        if (!res.data.pageItems.length) {
          this.matSnackBar.open('Nenhuma marca encontrada!', '', {
            duration: 5000
          })
          this.dataSource.data = res.data.pageItems
          return
        }
        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
      },
      () => {
        this.loadingBrandWithFilter = false
        this.loadingBrand = false
        this.matSnackBar.open('Nenhuma marca encontrada!', '', {
          duration: 5000
        })
      }
    )
  }

  changePage (event: PageEvent): void {
    this.loadingBrand = true
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.fetchData()
  }
}
