export class DateHelper {
  public static getToday (): Date {
    return new Date()
  }

  public static getTodayAddDays (days: number): Date {
    days++
    return new Date(new Date().setDate(this.getToday().getDate() + days))
  }

  public static getDateAddDays (date: Date, days: number): Date {
    return new Date(new Date().setDate(date.getDate() + days))
  }

  public static daysBetweenDates (date1: Date, date2: Date): number {
    date1.setHours(0, 0, 0, 0)
    date2.setHours(0, 0, 0, 0)
    const diff = date2.getTime() - date1.getTime()
    return Math.ceil(diff / (1000 * 3600 * 24)) + 1
  }
}
