import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'vex-warning-multiple-psp',
  templateUrl: './warning-multiple-psp.component.html',
  styleUrls: ['./warning-multiple-psp.component.scss']
})
export class WarningMultiplePspComponent {
  pspName: string
  accounts: Array<{ accountId: string, pixKey: string }> = []
  accountSelected: { accountId: string, pixKey: string } = null

  constructor (
    public dialogRef: MatDialogRef<WarningMultiplePspComponent>,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {
      pspName: string,
      accounts: Array<{ accountId: string, pixKey: string }>
    }
  ) {
    this.accounts = []
    this.pspName = data.pspName
    this.accounts = data.accounts
  }

  close (): void {
    this.dialogRef.close()
  }

  accept (): void {
    if (this.accountSelected) {
      return this.dialogRef.close(this.accountSelected)
    }

    this.matSnackBar.open('Você deve selecionar uma conta a ser utilizada!', '', {
      duration: 5000
    })
  }

}
