import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import dateFormat from 'dateformat'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import icSearch from '@iconify/icons-ic/twotone-search'
import icAdd from '@iconify/icons-ic/twotone-add'
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz'
import icPerms from '@iconify/icons-ic/twotone-perm-identity'
import icEdit from '@iconify/icons-ic/twotone-edit'
import icDelete from '@iconify/icons-ic/twotone-delete'
import icFinish from '@iconify/icons-ic/round-check-circle-outline'
import icFileUpload from '@iconify/icons-ic/file-upload'
import icUpload from '@iconify/icons-ic/round-upload'
import icSync from '@iconify/icons-ic/sync'
import icFilterAlt from '@iconify/icons-ic/round-filter-alt'
import icFilterAltOff from '@iconify/icons-ic/round-filter-alt-off'
import icDownload from '@iconify/icons-ic/cloud-download'
import checkSmall from '@iconify/icons-ic/check'
import icTrash from '@iconify/icons-ic/delete'
import { Crumb } from '../../models/crumb'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { PagedListDto } from '../../models/PagedListDto'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { debounceTime, distinctUntilChanged, finalize, startWith, take } from 'rxjs/operators'
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component'
import { MassiveHistoric } from '../../models/MassiveHistoric'

@Component({
  selector: 'vex-store-status-processing',
  templateUrl: './store-status-processing.component.html',
  styleUrls: ['./store-status-processing.component.scss'],
  animations: [stagger40ms]
})
export class StoreStatusProcessingComponent implements OnInit {
  icMoreHoriz = icMoreHoriz
  icSearch = icSearch
  icDelete = icDelete
  icPerms = icPerms
  icEdit = icEdit
  icAdd = icAdd
  icFinish = icFinish
  icFileUpload = icFileUpload
  icSync = icSync
  icFilterAlt = icFilterAlt
  icFilterAltOff = icFilterAltOff
  icDownload = icDownload
  icUpload = icUpload
  checkSmall = checkSmall
  icTrash = icTrash

  canList: boolean
  canCreate: boolean

  loading: boolean
  dataSource: MatTableDataSource<any>
  displayedColumns: string[] = [
    'name',
    'createdAt',
    'batchProcessingHistoryStatus',
    'quantitySuccess',
    'quantityError',
    'quantityRows',
    'inputUrl',
    'outputUrl'
  ]

  form: FormGroup
  maxPeriodDate = new Date()
  uploadedFiles: any[] = []
  showFilters: boolean
  fileList: FileList[]
  disablePostFile: boolean
  disableProcess: boolean
  fileName: string

  breadcrumbs: Crumb[] = [{ name: 'Atualizar Lojas', path: [''] }]

  @ViewChild('inputFile') inputFile: ElementRef
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 0
  }

  constructor (
    private readonly formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly matSnackBar: MatSnackBar,
    private readonly accreditationService: AccreditationService,
    private readonly utilsService: UtilsService
  ) {
    this.canList = utilsService.canShowElementByFunctionality(
      'store.status.processing'
    )
    this.canCreate = true
    this.dataSource = new MatTableDataSource()
  }

  ngOnInit (): void {
    this.fileName = 'Carregar'
    this.showFilters = false
    this.disablePostFile = true
    this.disableProcess = true
    this.configureForm()
  }

  configureForm (): void {
    const finalDate = new Date().setDate(new Date().getDate() - 1)
    this.form = this.formBuilder.group({
      requestNumber: [null],
      operatorUserName: [''],
      initialDate: [new Date(finalDate), Validators.required],
      finalDate: [new Date(), Validators.required],
      selectedStatus: ['']
    })

    this.form
      .get('requestNumber')
      .valueChanges.pipe(
        debounceTime(500),
        startWith(''),
        distinctUntilChanged()
      )
      .subscribe((dataValue) => {
        this.fetchData()
      })

    this.form
      .get('operatorUserName')
      .valueChanges.pipe(
        debounceTime(500),
        startWith(''),
        distinctUntilChanged()
      )
      .subscribe((dataValue) => {
        this.fetchData()
      })

    this.form
      .get('finalDate')
      .valueChanges.pipe(
        debounceTime(500),
        startWith(''),
        distinctUntilChanged()
      )
      .subscribe((dataValue) => {
        if (dataValue) this.fetchData()
      })
  }

  fetchData (): void {
    if (this.loading) return

    this.loading = true

    const form = this.form.getRawValue()

    const filter = {
      requestNumber: form.requestNumber ?? 0,
      dateTimeEnd: dateFormat(form.finalDate, 'yyyy-mm-dd 23:59:59'),
      selectedStatus: form.selectedStatus,
      operatorUserName: form.operatorUserName,
      pageNumber: this.paging.actualPage,
      pageSize: this.paging.pageSize
    }

    this.dataSource.data = []
    this.accreditationService
      .getStoreStatusProcessing(dateFormat(form.initialDate, 'yyyy-mm-dd'), filter)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (res: DefaultReturnObjDto<PagedListDto<MassiveHistoric>>) => {
          this.loading = false

          if (!res.data.pageItems.length) {
            this.dataSource.data = []
            return
          }
          this.dataSource.data = res.data.pageItems.map((data) => {
            data.isLoadingInputUrl = false
            data.isLoadingOutputUrl = false
            data.inputUrl = data.inputUrl.split('/').pop()
            data.outputUrl = data.outputUrl.split('/').pop()
            return data
          })
          this.paging.actualPage = res.data.pageNumber
          this.paging.pageSize = res.data.pageSize
          this.paging.length = res.data.totalItems
        },
        () => {
          this.loading = false
          this.matSnackBar.open('Erro ao buscar registros!', '', {
            duration: 5000
          })
        }
      )
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.fetchData()
  }

  onFileChange (fileList: FileList): void {
    if (fileList.length > 0) {
      this.fileName = fileList[0].name
      this.disablePostFile = false
    }
  }

  confirmUploadFile (fileList: FileList): void {
    if (fileList.length > 0) {
      this.dialog
        .open(ConfirmDialogComponent, {
          disableClose: true,
          data: {
            dialogTitle: 'Confirmar Envio?',
            dialogText:
              'Atenção: Deseja processar o arquivo para Ativar ou Inativar as\n' +
              'lojas através dos CNPJs informados?.',
            dialogConfirmText: 'CONFIRMAR'
          }
        })
        .afterClosed()
        .subscribe((res) => {
          if (res.event === 'CONFIRM') {
            this.loading = true
            this.disableProcess = false
            const file = fileList[0]
            this.uploadFile(file)
          } else if (res.event === 'CLOSE') {
            this.clearFile()
          }
        })
    }
  }

  uploadFile (file: File): void {
    this.accreditationService
      .uploadStoreStatusProcessing(file)
      .pipe(
        take(1),
        finalize(() => {
          this.loading = false
          this.disableProcess = false
          this.clearFile()
          this.fetchData()
          this.matSnackBar.open('Arquivo ' + file.name + ' enviado.', '', {
            duration: 4000
          })
        })
      )
      .subscribe((err) => {
        this.matSnackBar.open(
          this.utilsService.getErrorMessage(
            err,
            'Existem inconsistências. Por favor, faça a correção dos dados!'
          ),
          '',
          {
            duration: 4000
          }
        )
      })
  }

  clearFile (): void {
    this.disablePostFile = true
    this.disableProcess = true
    this.fileName = 'Carregar'
    this.inputFile.nativeElement.value = ''
  }

  clickFilters (): void {
    this.showFilters = this.showFilters ? false : true
  }

  downloadFile (type: 'input' | 'output', obj: MassiveHistoric): void {
    switch (type) {
    case 'input':
      obj.isLoadingInputUrl = true
      this.accreditationService
        .downloadStoreStatusProcessing(obj.inputUrl)
        .pipe(
          finalize(() => (obj.isLoadingInputUrl = false)),
          take(1)
        )
        .subscribe(
          () => {
            return null
          },
          (err) => {
            this.matSnackBar.open(
              this.utilsService.getErrorMessage(
                err,
                'Não foi possível fazer o download do arquivo. Por favor, tente mais tarde!'
              ),
              '',
              {
                duration: 4000
              }
            )
          }
        )
      break

    case 'output':
      obj.isLoadingOutputUrl = true
      this.accreditationService
        .downloadStoreStatusProcessing(obj.outputUrl)
        .pipe(
          finalize(() => (obj.isLoadingOutputUrl = false)),
          take(1)
        )
        .subscribe(
          () => {
            return null
          },
          (err) => {
            this.matSnackBar.open(
              this.utilsService.getErrorMessage(
                err,
                'Não foi possível fazer o download do arquivo. Por favor, tente mais tarde!'
              ),
              '',
              {
                duration: 4000
              }
            )
          }
        )
      break
    }
  }
}
