import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import icAdd from '@iconify/icons-ic/twotone-add'
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz'
import icSearch from '@iconify/icons-ic/twotone-search'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { Crumb } from '../../models/crumb'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { PagedListDto } from '../../models/PagedListDto'
import { ProviderListDto } from '../../models/ProviderDto'
import { ProviderFilter } from '../../models/ProviderFIlter'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'
import { ProviderTypeDto } from '../../models/ProviderTypeDto'
import { Router } from '@angular/router'
import { DataService } from 'src/app/services/data/data.service'

@Component({
  selector: 'vex-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
  animations: [stagger40ms]
})
export class ProviderComponent implements OnInit {
  icSearch = icSearch
  icAdd = icAdd
  icMoreHoriz = icMoreHoriz
  loading = false
  searchCtrl: FormControl
  dataSource: MatTableDataSource<ProviderListDto>
  displayedColumns: string[] = [
    'nickname',
    'pixName',
    'walletType',
    'status',
    'storeQuantity',
    'actions'
  ]
  canCreate: boolean
  canList: boolean
  breadcrumbs: Crumb[] = [{ name: 'Provedores de Pagamentos', path: [''] }]

  priorityTypes: Array<ProviderTypeDto> = [
    { id: 0, name: 'Primary', description: 'Primário' },
    { id: 1, name: 'Secondary', description: 'Secundário' },
    { id: 2, name: 'Tertiary', description: 'Terceário' }
  ]

  @ViewChild(MatPaginator) paginator: MatPaginator

  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }

  filter: ProviderFilter = {
    pageNumber: this.paging.actualPage,
    pageSize: this.paging.pageSize
  }

  constructor (
    private readonly router: Router,
    private readonly utilsService: UtilsService,
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    private readonly dataService: DataService
  ) {
    this.canCreate =
      this.utilsService.canShowElementByFunctionality('RegisterProvider')
    this.canList =
      this.utilsService.canShowElementByFunctionality('ProviderList')
    this.dataSource = new MatTableDataSource()
    this.searchCtrl = new FormControl()
  }

  ngOnInit (): void {
    this.retrieveData()

    this.searchCtrl.valueChanges
      .pipe(debounceTime(500), startWith(''), distinctUntilChanged())
      .subscribe((val) => {
        if ((!val.length || val.length >= 3) && !this.loading) {
          this.filter.searchBy = val
          this.filter.pageNumber = 0
          this.paging.actualPage = 0
          this.fetchData()
        }
      })
  }

  private _getProviderPaged (filter: ProviderFilter): void {
    this.accreditationService.getProviderPaged(filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<ProviderListDto>>) => {
        this.loading = false

        if (!res.data.pageItems.length) {
          this.dataSource.data = []
          return
        }

        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Nenhum provedor encontrado!', '', {
          duration: 5000
        })
      }
    )
  }

  fetchData (): void {
    if (this.loading) return

    this.loading = true
    this.dataSource.data = []
    this._getProviderPaged(this.filter)
  }

  updateFilterObjectWithThePaging (): void {
    this.filter.pageNumber = this.paging.actualPage
    this.filter.pageSize = this.paging.pageSize
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.updateFilterObjectWithThePaging()
    this.fetchData()
  }

  getProviderType (priorityType: string): string {
    if (priorityType == null)
      return this.priorityTypes.filter((pt) => pt.name == 'Primary')[0]
        .description

    return this.priorityTypes.filter((pt) => pt.name == priorityType)[0]
      .description
  }

  retrieveData (): void {
    if (this.dataService.hasData()) {
      const data = this.dataService.getData()

      this.searchCtrl = data.autocomplete
      this.filter = data.filter
      this.dataSource.data = data.data

      this.fetchData()
    }
  }

  openDetails (id: string): void {
    this.dataService.setData({
      autocomplete: this.searchCtrl,
      filter: this.filter,
      data: this.dataSource.data
    })

    this.router.navigate([`provider/edit-provider/${id}`])
  }
}
