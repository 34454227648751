export const environment = {
  showLoginPage: true,
  production: true,
  envName: '',
  baseUrl: 'https://barbershop-api.itcbrasil.com.br',
  getClientIp: 'https://api64.ipify.org/?format=json',
  target: 'linx-local',
  targetNewProducts: 'linx-local',
  keyCredentialsConci: 'c8aa0049a9364548af7033220968085c',
  keyCredentialsCRUD: 'e617bca9c0624c5489474ebaf9995bc8',
  widgetEnv: 'dev',
  internalGroup: {
    id: 'FDBE900D-01A6-4CF8-9435-95906815726A',
    name: 'All Stores'
  },

  loginUrl: 'https://barbershop.itcbrasil.com.br/login',
  baseUrlSso: 'https://barbershop-api.itcbrasil.com.br',

  contingency: {
    subscriptionKeyContingency: '6a8be01aad7d47738cf337b2cef88222',
    baseUrl: 'https://localhost:7214',
    path: ''
  },

  transacional: {
    baseUrl: 'https://localhost:44310',
    subscriptionKeyTransacional: '6572b723c30545e583f45debd06409dd',
    path: ''
  },

  monitoring: {
    subscriptionKeyMonitoring: '6a8be01aad7d47738cf337b2cef88222',
    baseUrl: 'https://localhost:46987',
    path: ''
  }
}
