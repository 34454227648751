import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import icSearch from '@iconify/icons-ic/twotone-search'
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators'
import { CrudService } from 'src/app/services/crud/crud.service'
import { StoresUserAccessDto } from '../../models/StoresUserAccessDto'
import dateFormat from 'dateformat'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'

@Component({
  selector: 'vex-filter-cnpj-data',
  templateUrl: './filter-cnpj-data.component.html',
  styleUrls: ['./filter-cnpj-data.component.scss']
})
export class FilterCnpjDataComponent implements OnInit {
  @Output() onSearch: EventEmitter<any> = new EventEmitter()
  today = new Date()
  icSearch = icSearch
  form: FormGroup
  storeFilter: FormControl
  cnpjListOptions: any
  loading = false

  constructor (
    private readonly matSnackBar: MatSnackBar,
    private readonly crudService: CrudService,
    private readonly acreditationService: AccreditationService,
    private readonly fb: FormBuilder
  ) {
    this.storeFilter = new FormControl()
  }

  ngOnInit (): void {
    const finalDate = new Date().setDate(new Date().getDate() - 7)

    this.form = this.fb.group({
      cnpj: ['', Validators.required],
      initialDate: [new Date(finalDate), Validators.required],
      finalDate: [new Date(), Validators.required]
    })

    this.initAutoCompleteCnpj()
  }

  getToday (): Date {
    return this.today
  }

  setCnpjValue (value: StoresUserAccessDto): void {
    this.form.get('cnpj').setValue(value.cnpj)
    this.search()
  }

  getOptionText (option: StoresUserAccessDto): string {
    if (option) {
      return option?.nameWithCnpj
    }
  }

  private _filterStore (value: string): any {
    let newValue = value.replace(/[^\d]+/g, '')
    if (!newValue) {
      newValue = value
    }

    this.loading = true
    return this.acreditationService.getStoresFromUserFiltered(newValue)
      .pipe(
        map(response => {
          this.loading = false

          if (!response.data.length) {
            this.matSnackBar.open('Nenhum estabelecimento encontrado!', '', {
              duration: 5000
            })
            return
          }
          response.data.forEach(option => {
            option.nameWithCnpj = option.trading_name + ' - ' + option.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
          })
          return response.data
        }, () => {
          this.loading = false
          this.matSnackBar.open('Nenhum estabelecimento encontrado!', '', {
            duration: 5000
          })
        })
      )
  }

  initAutoCompleteCnpj (): void {
    this.cnpjListOptions = this.storeFilter.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        distinctUntilChanged(),
        switchMap(val => {
          if (val && typeof val === 'string') {
            if (val.length >= 3)
              return this._filterStore(val)
          } else {
            if (val.cnpj)
              return this._filterStore(val.cnpj)
          }
          return new Subject()
        })
      )
  }

  search (): void {
    if (this.form.valid) {
      const diffTime = Math.abs(this.form.get('finalDate').value - this.form.get('initialDate').value)
      if (Math.ceil(diffTime / (1000 * 60 * 60 * 24)) > 7) {
        this.matSnackBar.open('Período selecionado excede 7 dias!', '', {
          duration: 5000
        })
        return
      }

      this.onSearch.emit({
        initialDate: dateFormat(this.form.get('initialDate').value, 'yyyy-mm-dd'),
        finalDate: dateFormat(this.form.get('finalDate').value, 'yyyy-mm-dd'),
        cnpj: this.form.get('cnpj').value
      })
    }
  }
}
