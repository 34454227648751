<mat-card-content>
  <div
    fxLayoutGap="25px"
    fxLayout="column"
    fxLayoutAlign="space-around none"
    [formGroup]="form"
  >
    <mat-form-field>
      <mat-label>CNPJ</mat-label>
      <input
        matInput
        formControlName="cnpj"
        placeholder="Informe o cnpj da loja"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Carteiras</mat-label>
      <mat-select formControlName="walletName">
        <mat-option *ngFor="let wallet of wallets" [value]="wallet.name">
          {{ wallet.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Marcas</mat-label>
      <mat-select formControlName="brandName">
        <mat-option *ngFor="let brand of brands" [value]="brand.name">
          {{ brand.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Tipo de integração Pix</mat-label>
      <mat-select formControlName="integrationTypePix">
        <mat-option *ngFor="let psp of pixPsp" [value]="psp.integrationType">
          {{ psp.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <button
      (click)="clearFilter()"
      class="ml-4"
      color="primary"
      fxFlex="none"
      matTooltip="Limpar"
      type="button"
    >
      Limpar
    </button>

    <button
      (click)="filterData()"
      class="ml-4"
      color="primary"
      mat-raised-button
      fxFlex="none"
      matTooltip="Filtrar"
      type="button"
    >
      Filtrar
    </button>
  </div>
</mat-card-content>
