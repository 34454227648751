export class DataService {

  private data: any;

  setData (data: any): void {
    this.data = data
  }

  clean (): void {
    this.data = null
  }

  getData (): any {
    return this.data
  }

  hasData (): boolean {
    return !!this.data
  }
}
