import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { Stores } from '../../models/stores'
import icAdd from '@iconify/icons-ic/twotone-add'
import icDelete from '@iconify/icons-ic/twotone-delete'
import { FilterStoreComponent } from '../filter-store/filter-store.component'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { GroupService } from 'src/app/services/group/group.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { Group } from '../../models/group'

@Component({
  selector: 'vex-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
  loading: boolean
  form: FormGroup
  stores: MatTableDataSource<Stores>
  icAdd = icAdd
  icDelete = icDelete
  displayedColumns: string[] = [
    'name',
    'cnpj',
    'actions'
  ];

  constructor (
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<CreateGroupComponent>,
    private readonly snackbar: MatSnackBar,
    private readonly utilService: UtilsService,
    private readonly groupService: GroupService,
    private readonly fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { group: Group }
  ) { }

  ngOnInit (): void {
    this.stores = new MatTableDataSource()
    this.form = this.fb.group({
      name: ['', Validators.required]
    })

    // if (this.data && this.data.group) {
    //   this.form = this.fb.group({
    //     name: [this.data.group.name, Validators.required]
    //   })
    //   // this.stores.data = this.data.group.stores
    // }

  }

  add (): void {
    this.dialog.open(FilterStoreComponent, {
      disableClose: true,
      minWidth: '40%'
    }).afterClosed().subscribe(res => {
      this.stores.data = res.data
    })
  }

  removeStore (store: Stores): void {
    const data = this.stores.data
    data.splice(data.findIndex(e => e.id === store.id), 1)
    this.stores.data = data
  }

  saveGroup (): void {
    if (this.loading || !this.form.valid || !this.stores.data || !this.stores.data.length) return

    this.loading = true

    const data = this.form.getRawValue()
    data.stores = this.stores.data.map(e => {
      return { store_id: e.id }
    })

    this.loading = true
    this.groupService.createGroup(data).subscribe(
      () => {
        this.loading = false
        this.snackbar.open('Grupo criado com sucesso!', '', {
          duration: 4000
        })
        return this.dialogRef.close()
      },
      (err) => {
        this.loading = false
        this.snackbar.open(this.utilService.getErrorMessage(err, 'Não foi possível criar o grupo. Tente novamente!'), '', {
          duration: 4000
        })
      }
    )
  }
}
