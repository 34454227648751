import { Component, Inject, OnInit, Optional } from '@angular/core'
import { inject } from '@angular/core/testing'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { BrandDto } from '../../../models/BrandDto'
import { DefaultReturnObjDto } from '../../../models/DefaultReturnObjDto'
import { ResponseApi, ResponseError } from '../../../models/ResponseApi'

@Component({
  selector: 'vex-create-brand',
  templateUrl: './create-brand.component.html',
  styleUrls: ['./create-brand.component.scss']
})
export class CreateBrandComponent implements OnInit {
  loadingSaveBrand = false;
  brandForm: FormGroup
  
  constructor (
    public dialogRef: MatDialogRef<CreateBrandComponent>,
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public dataIn: { brand: BrandDto }
  ) 
  { 
    this.brandForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl('', Validators.required)
    })
  }

  ngOnInit (): void {
    if (this.dataIn) {
      this.brandForm = new FormGroup({
        id: new FormControl(this.dataIn.brand.id),
        name: new FormControl(this.dataIn.brand.name, Validators.required)
      })
    }
  }

  clickCloseSuccess (): void {
    this._closeDialog()
  }

  SaveBrand () {

    if (!this.brandForm.valid) return

    const data = this.brandForm.value
    this.loadingSaveBrand = true
    if (data.id) {
      this._updateBrand(data)
    }
    else {
      this._saveBrand(data)
    }
    
  }

  private _saveBrand (brand: BrandDto): void {
    this.accreditationService.createBrand(brand)
      .subscribe((res: ResponseApi<BrandDto>) => {
        this.loadingSaveBrand = false
        this.matSnackBar.open('Marca salva com sucesso!', '', {
          duration: 5000
        })

        this._closeDialog()
      }, (res: ResponseApi<BrandDto>) => {
        const error = this._getCompleteMessageError(res.error.erros)
        this.loadingSaveBrand = false
        this.matSnackBar.open(error, '', {
          duration: 5000
        })

        this._closeDialog()
      })
  }

  private _updateBrand (brand: BrandDto): void {
    this.accreditationService.updateBrand(brand)
      .subscribe((res: DefaultReturnObjDto<BrandDto>) => {
        this.loadingSaveBrand = false
        this.matSnackBar.open('Marca atualizada com sucesso!', '', {
          duration: 5000
        })

        this._closeDialog()
      }, (res: ResponseApi<BrandDto>) => {
        const error = this._getCompleteMessageError(res.error.erros)
        this.loadingSaveBrand = false
        this.matSnackBar.open(error, '', {
          duration: 5000
        })

        this._closeDialog()
      })
  }

  private _closeDialog () {
    this.dialogRef.close({ event: 'SUCCESS' })
  }

  private _getCompleteMessageError (erros:ResponseError[]): string {
    if (erros.length == 0)
      return 'Não foi possivel salvar a Marca'

    let messageError = ''

    for (const index in erros)
    { 
      messageError = messageError + (index == '0' ? '' : ' ') + erros[index].description
    }
   
    return messageError
  }

}
