import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DashboardFilterDto } from 'src/app/layouts/client-layout/models/DashboardFilterDto'
import { DashboardFiltersDto } from 'src/app/layouts/client-layout/models/DashboardFiltersDto'
import { DefaultReturnConciliadorDto } from 'src/app/layouts/client-layout/models/DefaultReturnConciliadorDto'
import { environment } from 'src/environments/environment'
import { UtilsService } from '../utils/utils.service'
import { LocalStorangeService } from '../local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private baseUrl = environment.baseUrl
  constructor (
    private readonly httpClient: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly localStorangeService: LocalStorangeService
  ) { }

  public getPaymentsByStatus (
    filters: DashboardFiltersDto
  ): Observable<DefaultReturnConciliadorDto<any>> {
    return this.httpClient.post<DefaultReturnConciliadorDto<any>>(
      `${this.baseUrl}/wallet-qrlinx-nova-conciliacao/v1/dashboard/cobranca-por-status`, filters,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  public getPaymentsByWallets (
    filters: DashboardFiltersDto
  ): Observable<DefaultReturnConciliadorDto<any>> {
    return this.httpClient.post<DefaultReturnConciliadorDto<any>>(
      `${this.baseUrl}/wallet-qrlinx-nova-conciliacao/v1/dashboard/transacoes-por-carteira`, filters,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  public getPaymentsStatusByWallets (
    filters: DashboardFilterDto
  ): Observable<DefaultReturnConciliadorDto<any>> {
    return this.httpClient.post<DefaultReturnConciliadorDto<any>>(
      `${this.baseUrl}/wallet-qrlinx-nova-conciliacao/v1/dashboard/status-por-carteira`, filters,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  public getPaymentsResponseTime (
    filters: DashboardFiltersDto
  ): Observable<DefaultReturnConciliadorDto<any>> {
    return this.httpClient.post<DefaultReturnConciliadorDto<any>>(
      `${this.baseUrl}/wallet-qrlinx-nova-conciliacao/v1/dashboard/tempo-medio-autorizacao`, filters,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }
}
