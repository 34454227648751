import {
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LocalStorangeService } from '../local-storage.service'
import { KeyValue, User } from 'src/app/layouts/client-layout/models/Functionalities'
import { Observable } from 'rxjs'
import { EventType } from 'src/app/layouts/client-layout/models/EventType'
import { FormControl } from '@angular/forms'
import { Icon } from '@visurel/iconify-angular'
import * as saveAS from 'file-saver'
import iconAdd from '@iconify/icons-ic/baseline-add'
import iconDoneAll from '@iconify/icons-ic/done-all'
import iconTroubleshoot from '@iconify/icons-ic/round-troubleshoot'
import iconDisabled from '@iconify/icons-ic/error-outline'


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

    user: User = {
      funcionalities: [],
      qrFuncionalities: [],
      name: null,
      email: null,
      products: []
    }

    constructor (private _localStorangeService: LocalStorangeService) {}

    getHeadersCredentials (token: string): any {
      return {'Content-Type': 'application/json',Authorization: 'Bearer ' + token}
    }

    getHeadersCredentialsFormFile (token: string): any {
      return {enctype: 'multipart/form-data',Accept: 'application/json',Authorization: 'Bearer ' + token}
    }

    getErrorMessage (err: HttpErrorResponse, messageDefault: string): string {
      let errorMessage = messageDefault
    
      if (err &&
          err.error &&
          err.error.error &&
          err.error.error.languages &&
          err.error.error.languages[0] &&
          err.error.error.languages[0].description
      ) { errorMessage = err.error.error.languages[0].description

      } else if (err &&
          err.error &&
          err.error.errors &&
          err.error.errors[0] &&
          err.error.errors[0].description
      ) { errorMessage = err.error.errors[0].description
      } else if (err &&
          err.error &&
          err.error.erros &&
          err.error.erros[0] &&
          err.error.erros[0].description
      ) { errorMessage = err.error.erros[0].description }

      return errorMessage
    }

    public canShowElementByFunctionality (functionality: string): boolean {
      const userType = this._localStorangeService.getUserAuthenticatedtUserType()
      return userType == functionality
    }

    public encodeQueryData (data: any): string {
      const ret = []
      for (const d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
      return ret.join('&')
    }

    public addParams (keyValues: KeyValue[]): HttpParams {
      let params = new HttpParams()
      keyValues.forEach(
        (keyValue) => (params = params.set(keyValue.key, keyValue.value))
      )
      return params
    }
    
  removeEmpty = (obj) => {
    Object.keys(obj).forEach(
      (k) => !obj[k] && obj[k] !== undefined && delete obj[k]
    )
    return obj
  }

  makeId (length: number): string {
    const result = ''
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    
    // for (let i = 0 i < length i++) {
    //   result += characters.charAt(this.getRandomInt(0, charactersLength))
    // }
    return result
  }

  getRandomInt (min: number, max: number): number {       
    const byteArray = new Uint8Array(1)
    window.crypto.getRandomValues(byteArray)
  
    const range = max - min + 1
    const maxRange = 256

    if (byteArray[0] >= Math.floor(maxRange / range) * range)
      return this.getRandomInt(min, max)

    return min + (byteArray[0] % range)
  }

 
  public downloadFile (data: any, name: string, extension: string, type: string) {
    const blob = new Blob([data], { type })
    // saveAS.saveAs(blob, ${name ?? new Date().getTime()}.${extension})
  }

  public convertPixStatus (pixStatus: string): string {
    switch (pixStatus) {
    case 'Nenhum':
      return 'Não Identificado'
    case 'Devolvido':
      return 'Devolvido'
    case 'DevolucaoPendente':
      return 'Devolução Pendente'
    case 'NaoDevolvido':
      return 'Não devolvido'
    case 'Pendente':
      return 'Pendente'
    case 'Transferido':
      return 'Transferido'
    default:
      return '---'
    }
  }
  
  public convertPaymentStatus (convertPaymentStatus: number): string {
    switch (convertPaymentStatus) {
    case 4:
      return 'Devolvido'
    case 5:
      return 'Confirmado'
    case 6:
      return 'Pendente'
    case 7:
      return 'Autorizado'
    case 9:
      return 'Desfeito'
    case 15:
      return 'Devolução Parcial'
    case 16:
      return 'Negado'
    case 17:
      return 'Falha'
    default:
      return '---'
    }
  }

  convertMultiPSP (convertMultiPSP: boolean): string {
    return convertMultiPSP ? 'Sim' : 'Não'
  }

  convertStatusProcessing (statusProcessing: string): string {
    switch (statusProcessing) {
    case 'Waiting':
      return 'Aguardando'
    case 'Running':
      return 'Executando'
    case 'Finished':
      return 'Finalizado'
    case 'Failure':
      return 'Falha'
    default:
      return '---'
    }
  }

  public isUserFromLinx (): boolean {
    // if (this.isHomologEnvironment()) return true

    // let terminaComValorDoArray = false

    // for (const email of this.linxEmails) {
    //   if (this.user.email.endsWith(email)) {
    //     terminaComValorDoArray = true
    //     break
    //   }
    return true
  }

 
  onlyUnique (value: any, index: any, self: any): any {
    return self.indexOf(value) === index
  }

  capWords (str: string): string {
    const strArr = str.split(' ')
    
    return strArr.join(' ')
  }

  getCnpjWithMask (document: string): string {
    return document.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    )
  }

  getEventType (event: EventType): string {
    const eventType = EventType
    return eventType[event]
  }

  getMercadoPagoDocument (): string {
    return '10573521000191'
  }

  getPedeProntoDocument (): string {
    return '16967068000184'
  }

  getHubEloDocument (): string {
    return '09227084000175'
  }

  setState (control: FormControl, state: boolean): void {
    if (state) {
      control.setErrors({ required: true })
    } else {
      control.reset()
    }
  }

  
  getEventIcon (event: string): Icon {
    switch (event.toString()) {
    case 'CREATE':
      return iconAdd
    case 'PROCESSING-STONE':
      return iconTroubleshoot
    case 'PROCESSING-PAGARME':
      return iconTroubleshoot
    case 'ACTIVE':
      return iconDoneAll
    case 'INACTIVE':
      return iconDisabled
    }
  }

  getEventDescription (event: string): string {
    switch (event.toString()) {
    case 'CREATE':
      return 'Provedor criado'
    case 'PROCESSING-STONE':
      return 'Aguardando ativação da conta Stone'
    case 'PROCESSING-PAGARME':
      return 'Aguardando ativação da conta Pagar.me'
    case 'VALIDATED':
      return 'Credenciais validadas'
    case 'ACTIVE':
      return 'Provedor disponível para transacionar'
    case 'INACTIVE':
      return 'Provedor desativado'
    default:
      return '---'
    }
  }

  camelToSnakeCase = (str: string): string =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

  
  convertMonitorStatus (monitorStatus: string): string {
    switch (monitorStatus) {
    case 'None':
      return 'Não Identificado'
    case 'Open':
      return 'Aberto'
    case 'Running':
      return 'Em Andamento'
    case 'Closed':
      return 'Encerrado'
    default:
      return '---'
    }
  }
}

// import {
//   HttpClient,
//   HttpErrorResponse,
//   HttpParams
// } from '@angular/common/http'
// import { Injectable } from '@angular/core'
// import { Observable } from 'rxjs'
// import {
//   KeyValue,
//   User
// } from 'src/app/layouts/client-layout/models/Functionalities'
// import { environment } from 'src/environments/environment'
// import * as saveAS from 'file-saver'
// import { EventType } from 'src/app/layouts/client-layout/models/EventType'
// import iconDone from '@iconify/icons-ic/done'
// import iconAdd from '@iconify/icons-ic/baseline-add'
// import iconDoneAll from '@iconify/icons-ic/done-all'
// import iconTroubleshoot from '@iconify/icons-ic/round-troubleshoot'
// import iconDisabled from '@iconify/icons-ic/error-outline'
// import { Icon } from '@visurel/iconify-angular'
// import { FormControl } from '@angular/forms'
// @Injectable({
//   providedIn: 'root'
// })
// export class UtilsService {
//   user: User = {
//     funcionalities: [],
//     qrFuncionalities: [],
//     name: null,
//     email: null,
//     products: []
//   }

//   linxEmails = [
//     '@linx.com.br',
//     '@terceiroslinx.com.br',
//     '@franqueadoslinx.com.br'
//   ]

//   constructor (private readonly httpClient: HttpClient) {}

//   getIp (): Observable<any> {
//     return this.httpClient.get(environment.getClientIp)
//   }

//   getHeadersCredentials (): any {
//     return {
//       'Content-Type': 'application/json'
//     }
//   }

//   onlyUnique (value: any, index: any, self: any): any {
//     return self.indexOf(value) === index
//   }

//   capWords (str: string): string {
//     const strArr = str.split(' ')
//     for (let i = 0 i <= str.length i++) {
//       if (strArr[i])
//         strArr[i] = strArr[i][0].toUpperCase() + strArr[i].substring(1)
//     }
//     return strArr.join(' ')
//   }

//   getCnpjWithMask (document: string): string {
//     return document.replace(
//       /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
//       '$1.$2.$3/$4-$5'
//     )
//   }

//   getEventType (event: EventType): string {
//     const eventType = EventType
//     return eventType[event]
//   }

//   getMercadoPagoDocument (): string {
//     return '10573521000191'
//   }

//   getPedeProntoDocument (): string {
//     return '16967068000184'
//   }

//   getHubEloDocument (): string {
//     return '09227084000175'
//   }

//   setState (control: FormControl, state: boolean): void {
//     if (state) {
//       control.setErrors({ required: true })
//     } else {
//       control.reset()
//     }
//   }

//   getEventColor (event: string): string {
//     switch (event) {
//     case 'CREATE':
//       return '#c1cc28'
//     case 'VALIDATED':
//       return '#F48328'
//     case 'PROCESSING-STONE':
//       return '#68A505'
//     case 'PROCESSING-PAGARME':
//       return '#65a300'
//     case 'ACTIVE':
//       return '#40cd28'
//     case 'INACTIVE':
//       return '#ff0000'
//     default:
//       return '#40cd28'
//     }
//   }

//   getEventIcon (event: string): Icon {
//     switch (event.toString()) {
//     case 'CREATE':
//       return iconAdd
//     case 'VALIDATED':
//       return iconDone
//     case 'PROCESSING-STONE':
//       return iconTroubleshoot
//     case 'PROCESSING-PAGARME':
//       return iconTroubleshoot
//     case 'ACTIVE':
//       return iconDoneAll
//     case 'INACTIVE':
//       return iconDisabled
//     }
//   }

//   getEventDescription (event: string): string {
//     switch (event.toString()) {
//     case 'CREATE':
//       return 'Provedor criado'
//     case 'PROCESSING-STONE':
//       return 'Aguardando ativação da conta Stone'
//     case 'PROCESSING-PAGARME':
//       return 'Aguardando ativação da conta Pagar.me'
//     case 'VALIDATED':
//       return 'Credenciais validadas'
//     case 'ACTIVE':
//       return 'Provedor disponível para transacionar'
//     case 'INACTIVE':
//       return 'Provedor desativado'
//     default:
//       return '---'
//     }
//   }

//   camelToSnakeCase = (str: string): string =>
//     str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

//   getHeadersCredentialsFormFile (target?: string): any {
//     return {
//       enctype: 'multipart/form-data',
//       Accept: 'application/json',
//       'Apim-Company-Channel': 'portal-qrlinx',
//       'Apim-Company-Target': target ? target : environment.target
//     }
//   }

//   getHeadersCredentialsNoContentType (
//     target?: string
//   ): any {
//     return {
//       'Apim-Company-Channel': 'portal-qrlinx',
//       'Apim-Company-Target': target ? target : environment.target
//     }
//   }

//   convertPixStatus (pixStatus: string): string {
//     switch (pixStatus) {
//     case 'Nenhum':
//       return 'Não Identificado'
//     case 'Devolvido':
//       return 'Devolvido'
//     case 'DevolucaoPendente':
//       return 'Devolução Pendente'
//     case 'NaoDevolvido':
//       return 'Não devolvido'
//     case 'Pendente':
//       return 'Pendente'
//     case 'Transferido':
//       return 'Transferido'
//     default:
//       return '---'
//     }
//   }

//   convertMonitorStatus (monitorStatus: string): string {
//     switch (monitorStatus) {
//     case 'None':
//       return 'Não Identificado'
//     case 'Open':
//       return 'Aberto'
//     case 'Running':
//       return 'Em Andamento'
//     case 'Closed':
//       return 'Encerrado'
//     default:
//       return '---'
//     }
//   }

//   convertReportStatus (reportStatus: string): string {
//     switch (reportStatus) {
//     case 'PENDENTE':
//       return 'Aguardando'

//     case 'PROCESSANDO':
//       return 'Gerando Relatório'

//     case 'LIDO':
//       return 'Lido'

//     case 'FINALIZADO':
//       return 'Diponível para Download'

//     case 'EXPIRADO':
//       return 'Relatório Expirado'

//     default:
//       return '---'
//     }
//   }

//   encodeQueryData (data: any): string {
//     const ret = []
//     for (const d in data)
//       ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
//     return ret.join('&')
//   }

//   convertPaymentStatus (convertPaymentStatus: number): string {
//     switch (convertPaymentStatus) {
//     case 4:
//       return 'Devolvido'
//     case 5:
//       return 'Confirmado'
//     case 6:
//       return 'Pendente'
//     case 7:
//       return 'Autorizado'
//     case 9:
//       return 'Desfeito'
//     case 15:
//       return 'Devolução Parcial'
//     case 16:
//       return 'Negado'
//     case 17:
//       return 'Falha'
//     default:
//       return '---'
//     }
//   }

//   convertMultiPSP (convertMultiPSP: boolean): string {
//     return convertMultiPSP ? 'Sim' : 'Não'
//   }

//   convertStatusProcessing (statusProcessing: string): string {
//     switch (statusProcessing) {
//     case 'Waiting':
//       return 'Aguardando'
//     case 'Running':
//       return 'Executando'
//     case 'Finished':
//       return 'Finalizado'
//     case 'Failure':
//       return 'Falha'
//     default:
//       return '---'
//     }
//   }

//   getErrorMessage (err: HttpErrorResponse, messageDefault: string): string {
//     let errorMessage = messageDefault

//     if (
//       err &&
//       err.error &&
//       err.error.error &&
//       err.error.error.languages &&
//       err.error.error.languages[0] &&
//       err.error.error.languages[0].description
//     ) {
//       errorMessage = err.error.error.languages[0].description
//     } else if (
//       err &&
//       err.error &&
//       err.error.errors &&
//       err.error.errors[0] &&
//       err.error.errors[0].description
//     ) {
//       errorMessage = err.error.errors[0].description
//     } else if (
//       err &&
//       err.error &&
//       err.error.erros &&
//       err.error.erros[0] &&
//       err.error.erros[0].description
//     ) {
//       errorMessage = err.error.erros[0].description
//     }

//     return 'Ooops... ' + errorMessage
//   }

//   public isHomologEnvironment (): boolean {
//     return environment.target.includes('linx-local')
//   }

//   public canShowElementByFunctionality (functionality: string): boolean {
//     if (this.isHomologEnvironment()) return true

//     if (
//       this.user &&
//       this.user.funcionalities &&
//       !this.user.funcionalities.length
//     )
//       return false

//     return this.user.funcionalities.some((u) => u === functionality)
//   }

//   public canShowElementByQrFunctionality (functionality: string): boolean {
//     if (this.isHomologEnvironment()) return true

//     if (
//       this.user &&
//       this.user.qrFuncionalities &&
//     )
//       return false

//     return this.user.qrFuncionalities.some(
//       (u) => u.name === functionality && u.any_user_store_has_functionality
//     )
//   }

//   public isUserFromLinx (): boolean {
//     if (this.isHomologEnvironment()) return true

//     let terminaComValorDoArray = false

//     for (const email of this.linxEmails) {
//       if (this.user.email.endsWith(email)) {
//         terminaComValorDoArray = true
//         break
//       }
//     }

//     return terminaComValorDoArray
//   }

//   removeEmpty = (obj) => {
//     Object.keys(obj).forEach(
//       (k) => !obj[k] && obj[k] !== undefined && delete obj[k]
//     )
//     return obj
//   }

//   makeId (length: number): string {
//     let result = ''
//     const characters =
//       'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
//     const charactersLength = characters.length
    
//     for (let i = 0 i < length i++) {
//       result += characters.charAt(this.getRandomInt(0, charactersLength))
//     }
//     return result
//   }

//   getRandomInt (min: number, max: number): number {       
//     const byteArray = new Uint8Array(1)
//     window.crypto.getRandomValues(byteArray)
  
//     const range = max - min + 1
//     const maxRange = 256

//     if (byteArray[0] >= Math.floor(maxRange / range) * range)
//       return this.getRandomInt(min, max)

//     return min + (byteArray[0] % range)
//   }

//   addParams (keyValues: KeyValue[]): HttpParams {
//     let params = new HttpParams()
//     keyValues.forEach(
//       (keyValue) => (params = params.set(keyValue.key, keyValue.value))
//     )
//     return params
//   }

//   downloadFile (data: any, name: string, extension: string, type: string) {
//     const blob = new Blob([data], { type })
//     saveAS.saveAs(blob, ${name ?? new Date().getTime()}.${extension}`)
//   }
// }
