import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { GlobalService } from '../Global.service'
import { LocalStorangeService } from '../local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private isAutentication: boolean = false;

  constructor (
    private globalService: GlobalService,
    private localStorangeService: LocalStorangeService
  ) { }

  autenticar (login: any): Promise<any> {
    return this.globalService.post(login, `${environment.baseUrl}${'/api/account-management/signin'}`, true)
  }

  setAutenticado (userLog: any): void {
    this.localStorangeService.setUserLog(userLog)
    // this.isAutentication = true;    
  }

  isUserAuthentication (): boolean {
    return this.localStorangeService.getTokenUser() != ''
  }

  clearUserLog (): void {
    this.localStorangeService.clearUserLog()
  }
}
