<h1 mat-dialog-title>Edição de Grupos</h1>
<div mat-dialog-content>
    <form autocomplete="off" (submit)="saveGroup()" [formGroup]="form" fxLayout="column">
        <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Nome do Grupo</mat-label>

                <input type="text" formControlName="name" matInput>
                <mat-error *ngIf="form.get('name').hasError('required')">Nome do grupo é obrigatório</mat-error>
                <mat-hint class="text-right w-full">{{ form.get('name').value?.length || 0 }}/100</mat-hint>
            </mat-form-field>
        </div>

        <h2 fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-title>
            Lojas

            <button (click)="add()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
                matTooltip="Add Lojas no Grupo" type="button">
                <mat-icon [icIcon]="icAdd"></mat-icon>
            </button>
        </h2>
         
        <div class="bg-card rounded-full border px-4" fxFlex="1000%" fxFlex.lt-md="auto" fxHide.xs
             fxLayout="row" fxLayoutAlign="start center">
                <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
                    <input [formControl]="searchCtrl" class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                        placeholder="Buscar loja por cnpj" type="text">
        </div>

        <div mat-dialog-content style="max-height: 400px; overflow: auto;">

        <table *ngIf="stores.data && stores.data.length" mat-table [dataSource]="stores" class="w-full">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element">
                    {{element.tradingName}} </td>
            </ng-container>

            <ng-container matColumnDef="cnpj">
                <th mat-header-cell *matHeaderCellDef> CNPJ </th>
                <td mat-cell *matCellDef="let element">
                    {{element.cnpj}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Ações </th>
                <td mat-cell *matCellDef="let element">
                    <button (click)="removeStore(element)" matTooltip="Remover Loja do Grupo">
                        <mat-icon [icIcon]="icDelete"></mat-icon>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="!stores.data || !stores.data.length" class="w-full center">
            NENHUMA LOJA VINCULADA!
        </div>
    </div>
        
        <div *ngIf="!loading" class="mt-3 mb-1" mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
            <button mat-button mat-dialog-close>FECHAR</button>
            <button mat-raised-button type="submit" color="primary">Atualizar</button>
        </div>

        <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
        </div>
    </form>
</div>