import { Component, Inject, OnInit, Optional } from '@angular/core'
import { SoftwarePdvDto } from '../../../models/StoreDto'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { ResponseApi, ResponseError } from '../../../models/ResponseApi'
import { DefaultReturnObjDto } from '../../../models/DefaultReturnObjDto'

@Component({
  selector: 'vex-create-software-pdv',
  templateUrl: './create-software-pdv.component.html',
  styleUrls: ['./create-software-pdv.component.scss']
})
export class CreateSoftwarePdvComponent implements OnInit {
 softwarePdvForm: FormGroup
 loadingCreateSoftwarePdv = false;
 constructor (
     public dialogRef: MatDialogRef<CreateSoftwarePdvComponent>,
     private readonly matSnackBar: MatSnackBar,
     private readonly accreditationService: AccreditationService,
     @Optional() @Inject(MAT_DIALOG_DATA) public dataIn: { softwarePdvDto: any }
 ) 
 { 
   this.softwarePdvForm = new FormGroup({
     id: new FormControl(''),
     name: new FormControl('', Validators.required)
   })
 }

 ngOnInit (): void {
   if (this.dataIn) {
     this.softwarePdvForm = new FormGroup({
       id: new FormControl(this.dataIn.softwarePdvDto.id),
       name: new FormControl(this.dataIn.softwarePdvDto.name, Validators.required)
     })
   }
 }

 clickCloseSuccess (): void {
   this._closeDialog()
 }

 private _closeDialog () {
   this.dialogRef.close({ event: 'SUCCESS' })
 }

 SaveSoftwarePdv () {

   if (!this.softwarePdvForm.valid) return

   const data = this.softwarePdvForm.value
   this.loadingCreateSoftwarePdv = true
   if (data.id) {
     this._updateSoftwarePdv(data)
   }
   else {
     this._saveSoftwarePdv(data)
   }
    
 }

 private _saveSoftwarePdv (softwarePdv: SoftwarePdvDto): void {
   this.accreditationService.createSoftwarePdv(softwarePdv)
     .subscribe((res: ResponseApi<SoftwarePdvDto>) => {
       this.loadingCreateSoftwarePdv = false
       this.matSnackBar.open('Automação salva com sucesso!', '', {
         duration: 5000
       })

       this._closeDialog()
     }, (res: ResponseApi<SoftwarePdvDto>) => {
       const error = this._getCompleteMessageError(res.error.erros)
       this.loadingCreateSoftwarePdv = false
       this.matSnackBar.open(error, '', {
         duration: 5000
       })

       this._closeDialog()
     })
 }

 private _updateSoftwarePdv (softwarePdv: SoftwarePdvDto): void {
   this.accreditationService.updateSoftwarePdv(softwarePdv)
     .subscribe((res: DefaultReturnObjDto<SoftwarePdvDto>) => {
       this.loadingCreateSoftwarePdv = false
       this.matSnackBar.open('Automação atualizada com sucesso!', '', {
         duration: 5000
       })

       this._closeDialog()
     }, (res: ResponseApi<SoftwarePdvDto>) => {
       const error = this._getCompleteMessageError(res.error.erros)
       this.loadingCreateSoftwarePdv = false
       this.matSnackBar.open(error, '', {
         duration: 5000
       })

       this._closeDialog()
     })
 }

 private _getCompleteMessageError (erros:ResponseError[]): string {
   if (erros.length == 0)
     return 'Não foi possivel salvar a automação'

   let messageError = ''

   for (const index in erros)
   { 
     messageError = messageError + (index == '0' ? '' : ' ') + erros[index].description
   }
   
   return messageError
 }

}
