import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route
} from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from './Auth.service'
import { UtilsService } from '../utils/utils.service'
import icBank from '@iconify/icons-ic/account-balance'
import icBalanceWallet from '@iconify/icons-ic/twotone-account-balance-wallet'
import icTicket from '@iconify/icons-ic/twotone-confirmation-number'
import icHistory from '@iconify/icons-ic/twotone-history'
import icGroup from '@iconify/icons-ic/twotone-group'
import icAccountTree from '@iconify/icons-ic/twotone-account-tree'
import icRegistration from '@iconify/icons-ic/twotone-app-registration'
import icManageSearch from '@iconify/icons-ic/twotone-manage-search'
import icGroupAdd from '@iconify/icons-ic/twotone-group-add'
import icSplit from '@iconify/icons-ic/outline-monetization-on'
import icLayers from '@iconify/icons-ic/twotone-layers'
import icManage from '@iconify/icons-ic/twotone-manage-accounts'
import icPaid from '@iconify/icons-ic/twotone-paid'
import icQuery from '@iconify/icons-ic/search'
import icPDV from '@iconify/icons-ic/sharp-point-of-sale'
import icStores from '@iconify/icons-ic/sharp-store'
import icCategory from '@iconify/icons-ic/sharp-category'
import icBrand from '@iconify/icons-ic/copyright'
import icStatusPage from '@iconify/icons-ic/round-add-chart'
import icSoftware from '@iconify/icons-ic/outline-monitor'
import icPersonAdd from '@iconify/icons-ic/twotone-person-add'
import icSummarize from '@iconify/icons-ic/twotone-summarize'
import icPending from '@iconify/icons-ic/round-pending-actions'
import icPix from '@iconify/icons-ic/sharp-pix'
import { NavigationService } from 'src/@vex/services/navigation.service'

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  constructor (
    private router: Router,
    private authService: AuthService,
    private utilsService: UtilsService,
    private readonly navigationService: NavigationService
  ) { }

  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.verificarAcesso()
  }

  canLoad (route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.verificarAcesso()
  }

  private verificarAcesso () {
    console.log(this.authService.isUserAuthentication())
    if (this.authService.isUserAuthentication()) {
      // const userTypeComum =
      //   this.utilsService.canShowElementByFunctionality('COMUM')
      // if (userTypeComum) {
      //   const path = location.pathname
      //   if (
      //     path == '/' ||
      //     path == '/dashboard' ||
      //     path == '/consultas/projeto' ||
      //     path == '/login'
      //   ) {
      //     1 == 1
      //   } else { this.router.navigate(['/pages/notfound']) }
      // }
      this.generateMenu()
      return true
    }
    this.router.navigate(['/login'])
    return false
  }

  generateMenu (): void {
    this.navigationService.items = [
      {
        type: 'link',
        label: 'Dashboard',
        route: '/dashboard',
        showItem: true,
        icon: icLayers
      },
      {
        type: 'dropdown',
        showItem:
          this.utilsService.canShowElementByFunctionality('loja') ||
          this.utilsService.canShowElementByFunctionality('marca') ||
          this.utilsService.canShowElementByFunctionality('ADMIN'),
        label: 'Consultas',
        icon: icQuery,
        children: [
          {
            type: 'link',
            label: 'Lojas',
            showItem: this.utilsService.canShowElementByFunctionality('ADMIN'),
            route: '/stores',
            icon: icStores
          },
          {
            type: 'link',
            label: 'Marcas',
            showItem: this.utilsService.canShowElementByFunctionality('ADMIN'),
            route: '/brands',
            icon: icBrand
          }
        ]
      },
      {
        type: 'dropdown',
        // showItem: this.checkShowAdminMenu(),
        showItem: this.utilsService.canShowElementByFunctionality('ADMIN'),
        label: 'Administração',
        icon: icManage,
        children: [
          {
            showItem: this.checkShowUserGroup(),
            icon: icGroupAdd,
            type: 'dropdown',
            label: 'Grupos e Usuários',
            children: [
              {
                showItem:
                  this.utilsService.canShowElementByFunctionality(
                    'ADMIN'
                  ) ||
                  this.utilsService.canShowElementByFunctionality(
                    'cliente-qrlinx.cadastra-grupo'
                  ),
                icon: icGroup,
                type: 'link',
                label: 'Grupos',
                route: 'config/groups'
              },
              {
                showItem:
                  this.utilsService.canShowElementByFunctionality(
                    'ADMIN'
                  ) ||
                  this.utilsService.canShowElementByFunctionality(
                    'cliente-qrlinx.cadastra-usuario'
                  ),
                icon: icPersonAdd,
                type: 'link',
                label: 'Usuários',
                route: 'config/users'
              }
            ]
          }
        ]
      }
    ]
  }

  checkShowMonitoring (): boolean {
    return (
      this.utilsService.canShowElementByFunctionality(
        'alerta-ticket-pagina-status'
      ) ||
      this.utilsService.canShowElementByFunctionality('admin-statuspage') ||
      this.utilsService.canShowElementByFunctionality('list-psps')
    )
  }

  checkShowUserGroup (): boolean {
    return (
      this.utilsService.canShowElementByFunctionality(
        'cliente-qrlinx.consulta-grupo'
      ) ||
      this.utilsService.canShowElementByFunctionality(
        'cliente-qrlinx.cadastra-grupo'
      ) ||
      this.utilsService.canShowElementByFunctionality(
        'cliente-qrlinx.consulta-usuario'
      ) ||
      this.utilsService.canShowElementByFunctionality(
        'ADMIN'
      )
    )
  }

  checkShowAdminMenu (): boolean {
    return (
      this.utilsService.canShowElementByFunctionality(
        'cliente-qrlinx.consulta-grupo'
      ) ||
      this.utilsService.canShowElementByFunctionality(
        'cliente-qrlinx.cadastra-grupo'
      ) ||
      this.utilsService.canShowElementByFunctionality(
        'cliente-qrlinx.consulta-usuario'
      ) ||
      this.utilsService.canShowElementByFunctionality(
        'cliente-qrlinx.cadastra-usuario'
      ) ||
      this.utilsService.canShowElementByFunctionality(
        'alerta-ticket-pagina-status'
      ) ||
      this.utilsService.canShowElementByFunctionality(
        'alerta-ticket-pagina-status'
      )
    )
  }

}


// import { HttpClient } from '@angular/common/http'
// import { Injectable } from '@angular/core'
// import {
//   CanActivate,
//   Router,
//   RouterStateSnapshot,
//   ActivatedRouteSnapshot
// } from '@angular/router'
// import {
//   NavigationDropdown,
//   NavigationItem,
//   NavigationLink
// } from '../../../@vex/interfaces/navigation-item.interface'
// import icBank from '@iconify/icons-ic/account-balance'
// import icBalanceWallet from '@iconify/icons-ic/twotone-account-balance-wallet'
// import icTicket from '@iconify/icons-ic/twotone-confirmation-number'
// import icHistory from '@iconify/icons-ic/twotone-history'
// import icGroup from '@iconify/icons-ic/twotone-group'
// import icAccountTree from '@iconify/icons-ic/twotone-account-tree'
// import icRegistration from '@iconify/icons-ic/twotone-app-registration'
// import icManageSearch from '@iconify/icons-ic/twotone-manage-search'
// import icGroupAdd from '@iconify/icons-ic/twotone-group-add'
// import icSplit from '@iconify/icons-ic/outline-monetization-on'
// import icLayers from '@iconify/icons-ic/twotone-layers'
// import icManage from '@iconify/icons-ic/twotone-manage-accounts'
// import icPaid from '@iconify/icons-ic/twotone-paid'
// import icQuery from '@iconify/icons-ic/search'
// import icPDV from '@iconify/icons-ic/sharp-point-of-sale'
// import icStores from '@iconify/icons-ic/sharp-store'
// import icCategory from '@iconify/icons-ic/sharp-category'
// import icBrand from '@iconify/icons-ic/copyright'
// import icStatusPage from '@iconify/icons-ic/round-add-chart'
// import icSoftware from '@iconify/icons-ic/outline-monitor'
// import icPersonAdd from '@iconify/icons-ic/twotone-person-add'
// import icSummarize from '@iconify/icons-ic/twotone-summarize'
// import icPending from '@iconify/icons-ic/round-pending-actions'
// import icPix from '@iconify/icons-ic/sharp-pix'
// import { Observable, of } from 'rxjs'
// import { catchError, map, switchMap } from 'rxjs/operators'
// import { NavigationService } from 'src/@vex/services/navigation.service'
// import { environment } from 'src/environments/environment'
// import { UtilsService } from '../utils/utils.service'
// import icOperations from '@iconify/icons-ic/settings'
// import { AccreditationService } from '../accreditation/accreditation.service'
// import { DefaultReturnObjDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjDto'
// import { FuncionalityDto } from 'src/app/layouts/client-layout/models/FuncionalityDto'
// import { QrLinxFunctionalities } from 'src/app/layouts/client-layout/models/QrLinxFunctionalities'

// @Injectable({
//   providedIn: 'root'
// })
// export class CanActivateService implements CanActivate {
//   routeAccessed: string
//   clientCreated = false

//   constructor(
//     private readonly utilsService: UtilsService,
//     private readonly navigationService: NavigationService,
//     private readonly accreditationService: AccreditationService,
//     private http: HttpClient,
//     private router: Router
//   ) { }

//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): boolean | Observable<boolean> {
//     this.routeAccessed = state.url
//     if (environment.target.includes('linx-local')) {
//       this.generateMenu()
//       return true
//     }

//     if (this.clientCreated) {
//       return this.validateAuth().pipe(
//         map((response) => {
//           if (!response.success) {
//             this.redirectToLogin()
//             throw new Error('Authentication failed')
//           }

//           return response?.data?.valid ?? false
//         }),
//         catchError((err) => {
//           console.error(err)
//           return of(false)
//         })
//       )
//     }

// if (!this.clientCreated) {
//   return this.createAuth().pipe(
//     switchMap((response) => {
//       if (!response.success) {
//         this.redirectToLogin()
//         throw new Error('Authentication failed')
//       }

//       this.utilsService.user = {
//         name: response?.data?.tokenInfo?.displayName ?? null,
//         email: response?.data?.tokenInfo?.userEmail ?? null,
//         products: [],
//         funcionalities:
//           response?.data?.product?.functionalities?.map(
//             (functionality) => functionality.variable
//           ) ?? []
//       }

//       return this.accreditationService.getAllFuncionaliesByUser()
//     }),
//     map((res: DefaultReturnObjDto<FuncionalityDto[]>) => {
//       if (!res.success) {
//         throw new Error('Failed to fetch QR functionalities')
//       }

//       this.utilsService.user.qrFuncionalities = res.data
//     }),
//     switchMap(() => {
//       if (
//         this.utilsService.user.funcionalities &&
//         this.utilsService.user.qrFuncionalities
//       ) {
//         this.generateMenu()
//       } else {
//         console.warn('Permissões do QrLinx não foram carregadas')
//       }

//       if (!this.canAccessThisUrl()) {
//         this.redirectToDashboard()
//       }

//       return this.getUserInfo()
//     }),
//     map((userInfo: any) => {
//       this.utilsService.user.products = userInfo.data.products.map((e => {
//         return {
//           id: e.id,
//           name: e.name,
//           url: e.url,
//           imageUrl: e.imageUrl,
//           description: e.description
//         }
//       }))

//       this.clientCreated = true
//       return true
//     }),
//     catchError((err) => {
//       console.error(err)
//       return of(false)
//     })
//   )
// }
// }

// private canAccessThisUrl(): boolean {
//   const isLink = (item: NavigationItem): item is NavigationLink =>
//     item.type === 'link'

//   const menuLinkItems: NavigationLink[] = this.navigationService.items.reduce(
//     (acc, item) => {
//       if (isLink(item)) {
//         acc.push(item)
//       } else if (item.type === 'dropdown') {
//         if (item.children.length) {
//           acc.push(...(item as NavigationDropdown).children.filter(isLink))

//           const dropdown = item.children.filter((e) => e.type == 'dropdown')
//           if (dropdown.length) {
//             dropdown.forEach((subItem) => {
//               const dropSubItem = subItem as NavigationDropdown
//               if (dropSubItem.children.length) {
//                 dropSubItem.children.forEach((dropChild) => {
//                   acc.push(dropChild)
//                 })
//               }
//             })
//           }
//         }
//       }
//       return acc
//     },
//     []
//   )

//   return menuLinkItems.some((item: NavigationLink) => {
//     const startsWithRoute =
//       this.routeAccessed.startsWith(item.route) ||
//       this.routeAccessed.startsWith('/' + item.route)
//     return item.showItem && startsWithRoute
//   })
// }

// redirectToDashboard(): void {
//   this.router.navigate(['/dashboard'])
// }



// generateMenu (): void {
//   this.navigationService.items = [
//     {
//       type: 'link',
//       label: 'Dashboard',
//       route: '/dashboard',
//       showItem: true,
//       icon: icLayers
//     },
//     {
//       type: 'dropdown',
//       showItem: true,
//       label: 'Cobranças',
//       icon: icBalanceWallet,
//       children: [
//         {
//           type: 'link',
//           label: 'Transações',
//           showItem: true,
//           route: '/transactions',
//           icon: icPaid
//         },
//         {
//           type: 'link',
//           label: 'Relatórios',
//           showItem:
//             this.utilsService.canShowElementByFunctionality(
//               'portal-report-create'
//             ) ||
//             this.utilsService.canShowElementByFunctionality(
//               'portal-report-download'
//             ) ||
//             this.utilsService.canShowElementByFunctionality(
//               'portal-report-list'
//             ),
//           route: '/reports',
//           icon: icSummarize
//         },
//         {
//           type: 'link',
//           label: 'Estornos Pendentes',
//           showItem: this.utilsService.canShowElementByFunctionality(
//             'consulta-pendencias'
//           ),
//           route: '/pending-refunds',
//           icon: icPending
//         }
//       ]
//     },
//     {
//       type: 'dropdown',
//       showItem:
//         this.utilsService.canShowElementByFunctionality('loja') ||
//         this.utilsService.canShowElementByFunctionality('marca') ||
//         this.utilsService.canShowElementByFunctionality('pontodevenda'),
//       label: 'Consultas',
//       icon: icQuery,
//       children: [
//         {
//           type: 'link',
//           label: 'Lojas',
//           showItem: this.utilsService.canShowElementByFunctionality('loja'),
//           route: '/stores',
//           icon: icStores
//         },
//         {
//           type: 'link',
//           label: 'Marcas',
//           showItem: this.utilsService.canShowElementByFunctionality('marca'),
//           route: '/brands',
//           icon: icBrand
//         },
//         {
//           type: 'link',
//           label: 'Automações',
//           showItem: this.utilsService.canShowElementByFunctionality('marca'),
//           route: '/automations',
//           icon: icSoftware
//         },
//         {
//           type: 'link',
//           label: 'Categorias',
//           showItem: this.utilsService.canShowElementByFunctionality('marca'),
//           route: '/categories',
//           icon: icCategory
//         },
//         {
//           type: 'link',
//           label: 'Pontos de Venda',
//           showItem:
//             this.utilsService.canShowElementByFunctionality('pontodevenda'),
//           route: '/pos',
//           icon: icPDV
//         }
//       ]
//     },
//     {
//       type: 'dropdown',
//       showItem:
//         this.utilsService.canShowElementByFunctionality(
//           'listar-provedores-em-lote'
//         ) ||
//         this.utilsService.canShowElementByFunctionality(
//           'store.status.processing'
//         ),
//       label: 'Operações',
//       icon: icOperations,
//       children: [
//         {
//           type: 'link',
//           label: 'Atualizar Lojas',
//           showItem: this.utilsService.canShowElementByFunctionality(
//             'store.status.processing'
//           ),
//           route: '/store-status-processing',
//           icon: icStores
//         }
//       ]
//     },
//     {
//       type: 'dropdown',
//       label: 'Provedores',
//       children: [
//         {
//           type: 'link',
//           label: 'Cadastro/Edição',
//           showItem:
//             this.utilsService.canShowElementByFunctionality(
//               'RegisterProvider'
//             ) ||
//             this.utilsService.canShowElementByFunctionality('ProviderList'),
//           route: '/provider',
//           icon: icAccountTree
//         },
//         {
//           type: 'link',
//           label: 'Histórico',
//           showItem:
//             this.utilsService.canShowElementByFunctionality('historico'),
//           route: '/provider-history',
//           icon: icHistory
//         },
//         {
//           type: 'link',
//           label: 'Edição em Lote',
//           showItem: this.utilsService.canShowElementByFunctionality(
//             'listar-provedores-em-lote'
//           ),
//           route: '/operations-provider-edit',
//           icon: icRegistration
//         },
//         {
//           showItem: this.utilsService.canShowElementByFunctionality(
//             'admin-service-provider'
//           ),
//           type: 'link',
//           label: 'Consulta Resumida',
//           route: '/support-provider',
//           icon: icManageSearch
//         }
//       ],
//       showItem: this.checkShowProviderMenu(),
//       icon: icBank
//     },
//     {
//       type: 'dropdown',
//       showItem: this.checkShowAdminMenu(),
//       label: 'Administração',
//       icon: icManage,
//       children: [
//         {
//           showItem: this.checkShowUserGroup(),
//           icon: icGroupAdd,
//           type: 'dropdown',
//           label: 'Grupos e Usuários',
//           children: [
//             {
//               showItem:
//                 this.utilsService.canShowElementByFunctionality(
//                   'cliente-qrlinx.consulta-grupo'
//                 ) ||
//                 this.utilsService.canShowElementByFunctionality(
//                   'cliente-qrlinx.cadastra-grupo'
//                 ),
//               icon: icGroup,
//               type: 'link',
//               label: 'Grupos',
//               route: 'config/groups'
//             },
//             {
//               showItem:
//                 this.utilsService.canShowElementByFunctionality(
//                   'cliente-qrlinx.consulta-usuario'
//                 ) ||
//                 this.utilsService.canShowElementByFunctionality(
//                   'cliente-qrlinx.cadastra-usuario'
//                 ),
//               icon: icPersonAdd,
//               type: 'link',
//               label: 'Usuários',
//               route: 'config/users'
//             },
//             {
//               showItem:
//                 this.utilsService.canShowElementByFunctionality(
//                   'cliente-qrlinx.cadastra-usuario'
//                 ) &&
//                 this.utilsService.canShowElementByFunctionality(
//                   'cliente-qrlinx.cadastra-grupo'
//                 ),
//               icon: icRegistration,
//               type: 'link',
//               label: 'Cadastro em Lote',
//               route: 'config/batch-registration'
//             }
//           ]
//         },
//         {
//           showItem: this.checkShowMonitoring(),
//           icon: icGroupAdd,
//           type: 'dropdown',
//           label: 'Monitoração',
//           children: [
//             {
//               showItem: this.utilsService.canShowElementByFunctionality(
//                 'alerta-ticket-pagina-status'
//               ),
//               icon: icTicket,
//               type: 'link',
//               label: 'Tickets',
//               route: 'config/tickets'
//             },
//             {
//               showItem:
//                 this.utilsService.canShowElementByFunctionality('list-psps'),
//               type: 'link',
//               label: 'Provedores',
//               route: '/psps',
//               icon: icPix
//             },
//             {
//               showItem:
//                 this.utilsService.canShowElementByFunctionality(
//                   'admin-statuspage'
//                 ),
//               type: 'link',
//               label: 'Status Page',
//               route: '/statuspage',
//               icon: icStatusPage
//             }
//           ]
//         },
//         {
//           showItem:
//             this.utilsService.canShowElementByFunctionality(
//               'list-recipients'
//             ) &&
//             this.utilsService.canShowElementByQrFunctionality(
//               QrLinxFunctionalities.SplitDePagamentos
//             ),
//           icon: icSplit,
//           type: 'dropdown',
//           label: 'Split de Pagamento',
//           children: [
//             {
//               type: 'link',
//               label: 'Recebedores',
//               showItem: true,
//               route: '/recipients',
//               icon: icGroupAdd
//             }
//           ]
//         }
//       ]
//     }
//   ]
// }

// checkShowProviderMenu(): boolean {
//   return (
//     this.utilsService.canShowElementByFunctionality('RegisterProvider') ||
//     this.utilsService.canShowElementByFunctionality('ProviderList') ||
//     this.utilsService.canShowElementByFunctionality('historico') ||
//     this.utilsService.canShowElementByFunctionality(
//       'admin-service-provider'
//     ) ||
//     this.utilsService.canShowElementByFunctionality(
//       'listar-provedores-em-lote'
//     )
//   )
// }

// checkShowMonitoring(): boolean {
//   return (
//     this.utilsService.canShowElementByFunctionality(
//       'alerta-ticket-pagina-status'
//     ) ||
//     this.utilsService.canShowElementByFunctionality('admin-statuspage') ||
//     this.utilsService.canShowElementByFunctionality('list-psps')
//   )
// }

// checkShowUserGroup(): boolean {
//   return (
//     this.utilsService.canShowElementByFunctionality(
//       'cliente-qrlinx.consulta-grupo'
//     ) ||
//     this.utilsService.canShowElementByFunctionality(
//       'cliente-qrlinx.cadastra-grupo'
//     ) ||
//     this.utilsService.canShowElementByFunctionality(
//       'cliente-qrlinx.consulta-usuario'
//     ) ||
//     this.utilsService.canShowElementByFunctionality(
//       'cliente-qrlinx.cadastra-usuario'
//     )
//   )
// }

// checkShowAdminMenu(): boolean {
//   return (
//     this.utilsService.canShowElementByFunctionality(
//       'cliente-qrlinx.consulta-grupo'
//     ) ||
//     this.utilsService.canShowElementByFunctionality(
//       'cliente-qrlinx.cadastra-grupo'
//     ) ||
//     this.utilsService.canShowElementByFunctionality(
//       'cliente-qrlinx.consulta-usuario'
//     ) ||
//     this.utilsService.canShowElementByFunctionality(
//       'cliente-qrlinx.cadastra-usuario'
//     ) ||
//     this.utilsService.canShowElementByFunctionality(
//       'alerta-ticket-pagina-status'
//     ) ||
//     this.utilsService.canShowElementByFunctionality(
//       'alerta-ticket-pagina-status'
//     )
//   )
// }

// getProductId (): string {
//   const productId = environment.ssoProductId
//   if (!productId) {
//     location.href = environment.loginUrl
//     return
//   }

//   return productId
// }

// getUserInfo(): Observable < any > {
//   return this.http.get<any>(
//     `${environment.baseUrlSso}/sso/v1/user-management/me`,
//     {
//       withCredentials: true,
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     }
//   )
// }

// validateAuth(): Observable < any > {
//   return this.http.post<any>(
//     `${environment.baseUrlSso}/sso/v1/token-management/validate`,
//     {},
//     {
//       withCredentials: true,
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     }
//   )
// }

// createAuth (): Observable<any> {
//   return this.http.post<any>(
//     `${environment.baseUrlSso}/sso/v1/token-management/product/${this.getProductId()}/create`,
//     {},
//     {
//       withCredentials: true,
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     }
//   )
// }

//   logout(): Observable<any> {
//     return this.http.delete<any>(
//       `${environment.baseUrlSso}/sso-portal/v1/account-management/signout`,
//       {
//         withCredentials: true,
//         headers: {
//           'Content-Type': 'application/json'
//           // 'Ocp-Apim-Subscription-Key': environment.keyCredentialsSSO,
//           // 'Apim-Company-Channel': 'portal-qrlinx',
//           // 'Apim-Company-Target': environment.targetSSO
//         }
//       }
//     )
//   }

//   redirectToLogin(): void {
//     location.href = environment.loginUrl
//   }
// }
