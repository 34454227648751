import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { UtilsService } from '../utils/utils.service'
import { map } from 'rxjs/operators'
import { saveAs } from 'file-saver'
import { BatchRegistration } from 'src/app/layouts/client-layout/models/BatchRegistration'
import { PagedListDto } from 'src/app/layouts/client-layout/models/PagedListDto'
import { DefaultReturnObjDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjDto'
import { LocalStorangeService } from '../local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class BatchRegistrationService {
  private baseUrl =
    environment.baseUrl 

  constructor (
    private readonly httpClient: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly localStorangeService: LocalStorangeService
  ) {}

  public getAll (
    params: any
  ): Observable<DefaultReturnObjDto<PagedListDto<BatchRegistration>>> {
    return this.httpClient.get<
      DefaultReturnObjDto<PagedListDto<BatchRegistration>>
    >(
      `${this.baseUrl}/v1/batchProcessing/search?limit=${params.limit}&current_page=${params.offset}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  upload (file: File): Observable<any> {
    const formData = new FormData()
    formData.append('formFile', file)

    return this.httpClient.post(
      `${this.baseUrl}/v1/batchProcessing/upload`,
      formData,
      {
        reportProgress: true,
        responseType: 'arraybuffer',
        withCredentials: true
       
      }
    )
  }

  downloadFile (fileName: string): Observable<any> {
    return this.httpClient
      .get(`${this.baseUrl}/v1/batchProcessing/download?fileName=${fileName}`, {
        responseType: 'blob',
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
      .pipe(
        map((res) => {
          return saveAs(res, fileName)
        })
      )
  }
}
