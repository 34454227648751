import { Component, OnInit, Inject } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { PspDto } from 'src/app/layouts/client-layout/models/PspDto'
import { MonitoringService } from 'src/app/services/monitoring/monitoring.service'

@Component({
  selector: 'vex-edit-psp',
  templateUrl: './edit-psp.component.html',
  styleUrls: ['./edit-psp.component.scss']
})
export class EditPspComponent implements OnInit {
  form!: FormGroup
  psp: PspDto
  loading = false

  constructor (
    private fb: FormBuilder,
    private readonly monitoringService: MonitoringService,
    public dialogRef: MatDialogRef<EditPspComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PspDto
  ) {
    this.psp = data
  }

  ngOnInit (): void {
    this.form = this.fb.group({
      minTimeToAlert: ['', Validators.required],
      minPercentageToAlert: ['', Validators.required],
      resolvePspTimeWindow: ['', Validators.required],
      timeToSearchTransactions: ['', Validators.required]
    })

    this.form.get('minTimeToAlert').setValue(this.psp.minTimeToAlert ?? '')
    this.form
      .get('minPercentageToAlert')
      .setValue(this.psp.minPercentageToAlert ?? '')
    this.form
      .get('resolvePspTimeWindow')
      .setValue(this.psp.resolvePspTimeWindow ?? '')
    this.form
      .get('timeToSearchTransactions')
      .setValue(this.psp.timeToSearchTransactions ?? '')
  }

  public saveData (): void {
    if (!this.form.valid) return
    this.loading = true

    this.psp.minTimeToAlert = this.form.get('minTimeToAlert').value
    this.psp.minPercentageToAlert = this.form.get('minPercentageToAlert').value
    this.psp.resolvePspTimeWindow = this.form.get('resolvePspTimeWindow').value

    this.monitoringService.updatePsp(this.psp).subscribe(
      () => {
        this.loading = false
        this.dialogRef.close({ event: 'SAVED' })
      },
      () => {
        this.loading = false
        this.dialogRef.close({ event: 'ERROR' })
      }
    )
  }

  public clearFilter (): void {
    this.form.get('minTimeToAlert').setValue('')
    this.form.get('minPercentageToAlert').setValue('')
    this.form.get('resolvePspTimeWindow').setValue('')
  }
}
