import { Component, OnInit } from '@angular/core'
import ApexCharts from 'apexcharts'
import { Crumb } from '../../models/crumb'
import { DefaultReturnDto } from '../../models/DefaultReturnDto'
import { PspAverage, PspAvgDto, PspDto } from '../../models/PspDto'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MonitoringService } from 'src/app/services/monitoring/monitoring.service'
import { FilterConsolidateDto } from '../../models/FilterConsolidateDto'
import dateFormat from 'dateformat'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import icSearch from '@iconify/icons-ic/twotone-search'
import icTime from '@iconify/icons-ic/outline-timer'
import { addHours, format, subHours, subMinutes } from 'date-fns'

@Component({
  selector: 'vex-status-page',
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.scss']
})
export class StatusPageComponent implements OnInit {
  dateRangeForm: FormGroup
  charts: any[]
  startDateSearch: string
  endDateSearch: string
  allPspAvg = []
  loading = false
  pageLoading = false
  icSearch = icSearch
  icTime = icTime
  today = new Date()
  seeFilter = false
  maskDateHour = true
  allCharts = []
  filterDate: any

  breadcrumbs: Crumb[] = [{ name: 'Status Page', path: [''] }]

  constructor (
    private readonly monitoringService: MonitoringService,
    private readonly matSnackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) {
    this.dateRangeForm = this.formBuilder.group({
      customStartDate: ['', Validators.required],
      customEndDate: ['', Validators.required]
    })
  }

  ngOnInit (): void {
    this.filterDate = '60'
    this.getDateToSearch(60)

    this._getPsps({
      startDate: this.startDateSearch,
      endDate: this.endDateSearch
    })
  }

  private getDateToSearch (minutesToSubtract: number) {
    const currentDate = new Date()

    const startDate = subMinutes(addHours(currentDate, 3), minutesToSubtract)
    const endDate = addHours(currentDate, 3)

    this.startDateSearch = dateFormat(startDate, 'yyyy-mm-dd HH:MM')
    this.endDateSearch = dateFormat(endDate, 'yyyy-mm-dd  HH:MM')
  }

  private getDateFormToSearch () {
    const endDateForm = new Date(this.dateRangeForm.get('customEndDate').value)
    endDateForm.setHours(23, 59, 59)

    this.startDateSearch = dateFormat(
      new Date(this.dateRangeForm.get('customStartDate').value),
      'yyyy-mm-dd HH:MM'
    )
    this.endDateSearch = dateFormat(new Date(endDateForm), 'yyyy-mm-dd HH:MM')
  }

  private _getPsps (filter: FilterConsolidateDto): void {
    this.loading = true
    this.monitoringService.getPsps(filter).subscribe(
      (res: DefaultReturnDto<PspDto>) => {
        this.charts = res.data
        if (this.charts.length > 0) {
          setTimeout(() => {
            this.fillChart()
            this.fillCard()
          }, 200)
          return
        }
        this.matSnackBar.open('Nenhum dado encontrado!', '', {
          duration: 5000
        })
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Erro ao buscar os dados!', '', {
          duration: 5000
        })
      }
    )
  }

  private makeChart (element: any, graph: PspDto) {
    const chart = new ApexCharts(element, {
      series: [
        {
          name: 'Tempo médio notificação',
          data: graph.avg.map((e) => e.avg)
        }
      ],
      title: {
        style: {
          fontSize: '13px',
          fontWeight: 'bold',
          color: '#616161'
        },
        text: 'Tempo Médio de notificação ' + graph.name
      },
      chart: {
        height: 300,
        type: 'area',
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            pan: false,
            reset: false,
            zoomin: false,
            zoomout: false
          }
        }
      },
      colors: ['#6E4B87'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: ['#5e35b1'],
          inverseColors: true,
          opacityFrom: 0.5,
          opacityTo: 0.8,
          stops: [0, 100]
        }
      },
      stroke: {
        curve: 'smooth'
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        type: 'category',
        categories: graph.avg.map((e) =>
          format(
            subHours(new Date(e.date), 3),
            this.maskDateHour ? 'HH:mm' : 'dd/MM/yy HH:mm'
          )
        ),
        labels: {
          rotateAlways: false,
          style: {
            colors: ['#616161'],
            fontSize: '9px',
            fontFamily: 'Helvetica, Arial, sans-serif,Negrito',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label'
          }
        }
      },
      tooltip: {
        x: {
          format: 'dd/mm/yyyy HH:MM'
        }
      }
    })

    chart.render()
    this.allCharts.push(chart)
  }

  private makeChartDefault (element: any, pspName: string) {
    const chart = new ApexCharts(element, {
      series: [
        {
          name: 'Tempo médio notificação',
          data: [0]
        }
      ],
      title: {
        style: {
          fontSize: '13px',
          fontWeight: 'bold',
          color: '#616161'
        },
        text: 'Tempo Médio de notificação ' + pspName
      },
      chart: {
        height: 300,
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#6E4B87', '#F56F23'],
      fill: {
        colors: ['#6E4B87', '#F56F23']
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'category',
        categories: ['Sem dados no periodo buscado'],
        labels: {
          rotateAlways: false,
          style: {
            colors: ['#616161'],
            fontSize: '15px',
            fontFamily: 'Helvetica, Arial, sans-serif,Negrito',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label'
          }
        }
      },
      tooltip: {
        x: {
          format: 'dd/mm/yyyy HH:MM'
        }
      }
    })

    chart.render()
    this.allCharts.push(chart)
  }

  private fillChart () {
    this.charts.forEach((chart) => {
      const divChart = document.getElementById(chart.name)
      if (chart.avg.length > 0) {
        this.makeChart(divChart, chart)
      } else {
        this.makeChartDefault(divChart, chart.name)
      }
    })

    this.loading = false
  }

  private fillCard () {
    this.allPspAvg = []
    this.charts.forEach((chart) => {
      const psp: PspAverage = {}
      this.calculateAverage(chart.avg, psp, chart.name)
      this.allPspAvg.push(psp)
    })
  }

  private calculateAverage (
    avg: PspAvgDto[],
    psp: PspAverage,
    pspName: string
  ): void {
    psp.pspName = pspName
    if (avg.length == 0) {
      psp.time = null
      return
    }

    let sum = 0
    let qtd = 0

    avg.forEach((item) => {
      sum += item.avg
      qtd++
    })

    const average = sum / qtd

    if (Number(average.toFixed(2)) < 60) {
      const seg = Number(average.toFixed(2))
      psp.time = `${seg} seg`
      return
    }

    if (Number(average.toFixed(2)) < 3600) {
      const min = Math.floor(average / 60)
      const seg = Math.floor(average % 60)

      psp.time = `${min}m ${seg}s`
      return
    }

    const hours = Math.floor(average / 3600)
    const min = Math.floor((average % 3600) / 60)

    psp.time = `${hours}h ${min}m`
  }

  public refreshChart () {
    if (this.filterDate != 'openDialogDate') {
      this.clearCharts()
      const minutes = this.filterDate
      this.seeFilter = false
      this.maskDateHour = true

      this.getDateToSearch(minutes)

      this._getPsps({
        startDate: this.startDateSearch,
        endDate: this.endDateSearch
      })

      return
    }

    if (!this.seeFilter) {
      this.seeFilter = true
      return
    }

    this.clearCharts()
    this.maskDateHour = false

    this.getDateFormToSearch()

    this._getPsps({
      startDate: this.startDateSearch,
      endDate: this.endDateSearch
    })
  }

  isValidDateRange (): boolean {
    if (
      this.dateRangeForm.get('customStartDate').value &&
      this.dateRangeForm.get('customEndDate').value
    ) {
      const startDate = new Date(
        this.dateRangeForm.get('customStartDate').value
      )
      const endDate = new Date(this.dateRangeForm.get('customEndDate').value)

      const diffTime = Math.abs(endDate.getTime() - startDate.getTime())
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      return diffDays <= 7
    }

    return false
  }

  onFilterClick () {
    if (this.dateRangeForm.valid) {
      if (!this.isValidDateRange()) {
        this.matSnackBar.open('Período selecionado excede 7 dias!', '', {
          duration: 5000
        })
        return
      }

      this.refreshChart()
    }
  }

  private clearCharts () {
    this.allCharts.forEach((chart) => {
      chart.destroy()
    })
  }

  getToday (): Date {
    return this.today
  }
}
