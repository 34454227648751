import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import icEye from '@iconify/icons-ic/outline-remove-red-eye'
import icSearch from '@iconify/icons-ic/twotone-search'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { BrandDto } from '../../models/BrandDto'
import { Crumb } from '../../models/crumb'
import { DefaultReturnDto } from '../../models/DefaultReturnDto'
import { StoreProviders } from '../../models/StoresProviders'
import { DataService } from 'src/app/services/data/data.service'
import { Router } from '@angular/router'

@Component({
  selector: 'vex-provider',
  templateUrl: './support-provider.component.html',
  styleUrls: ['./support-provider.component.scss'],
  animations: [
    stagger40ms
  ]
})
export class SupportProviderComponent implements OnInit {
  icEye = icEye
  icSearch = icSearch
  loading = false
  loadingBrand = false
  form: FormGroup
  dataSource: MatTableDataSource<StoreProviders>
  displayedColumns: string[] = ['cnpj', 'brandName', 'pix', 'wallets', 'actions']
  breadcrumbs: Crumb[] = [
    { name: 'Consulta de Provedores de Pagamentos', path: [''] }
  ]
  cnpjListOptions: any
  brandFilter: FormControl

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor (
    private readonly router: Router,
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    private readonly dataService: DataService
  ) {
    this.brandFilter = new FormControl()
    this.dataSource = new MatTableDataSource()
  }

  ngOnInit (): void {
    this.retrieveData()
    this.initAutoCompleteCnpj()
  }

  initAutoCompleteCnpj (): void {
    this.cnpjListOptions = this.brandFilter.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(val => {
          if (!this.loading) {
            if (val.length == 18 || val.length == 14) {
              this.getStores(val)
              return new Subject()
            } else if (val.length >= 3) {
              return this._filterBrand(val)
            }
          }

          return new Subject()
        })
      )
  }

  retrieveData (): void {
    if (this.dataService.hasData()) {
      this.brandFilter = new FormControl()
      const data = this.dataService.getData()

      this.brandFilter = data.autocomplete
      this.dataSource.data = data.data

      if (data.data.length == 18 || data.data.length == 14) {
        this.getStores(data.data)
        return
      } else if (data.data.length >= 3) {
        return this._filterBrand(data.data)
      }
    }
  }

  private _filterBrand (filter: string): any {
    if (typeof filter != 'string')
      return

    let newValue = filter.replace(/[^\d]+/g, '')
    if (!newValue) {
      newValue = filter
    }

    this.loadingBrand = true
    return this.accreditationService.filterBrands({
      pageNumber: 1,
      pageSize: 20,
      searchBy: newValue
    })
      .pipe(
        map(response => {
          this.loadingBrand = false
          return response.data.pageItems
        }, () => {
          this.matSnackBar.open('Não foi possível listar as marcas. Tente novamente!', '', {
            duration: 4000
          })
          this.loadingBrand = false
        })
      )
  }

  setClientValue (value: BrandDto, autoSearch = false): void {
    if (autoSearch) this.getStores(value.id)
  }

  getOptionText (option: BrandDto): string {
    if (option) {
      return option?.name
    }
  }

  openDetails (storeId: string): Promise<boolean> {
    this.dataService.setData({
      autocomplete: this.brandFilter,
      data: this.dataSource.data
    })

    this.cnpjListOptions = []

    return this.router.navigate([`support-provider/${storeId}`])
  }

  private getStores (searchValue: string) {
    this.loading = true
    this.dataSource.data = []
    let filter = ''

    if (searchValue.length == 18 || searchValue.length == 14) {
      filter = `Cnpj=${searchValue.split('.').join('').split('-').join('').split('/').join('')}`
    } else {
      filter = `BrandId=${searchValue}`
    }

    this.accreditationService.getStores(filter).subscribe(
      (res: DefaultReturnDto<StoreProviders>) => {
        this.loading = false
        res.data.forEach(item => {
          const pixArray = []
          const walletesArray = []
          for (const key of item.providers) {
            if (key.pix != 'None' && !pixArray.includes(key.pix)) {
              pixArray.push(key.pix)
            }

            if (!walletesArray.includes(key.nickname)) {
              walletesArray.push(key.nickname)
            }
          }

          item.pix = String(pixArray)
          item.wallets = String(walletesArray)

          return item
        })
        this.dataSource.data = res.data
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Não foi possível listar os provedores de pagamentos. Tente novamente!', '', {
          duration: 4000
        })
      }
    )
  }
}
