import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { VexModule } from 'src/@vex/vex.module'
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module'
import { LayoutModule } from '@angular/cdk/layout'
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module'
import { MatInputModule } from '@angular/material/input'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatSelectModule } from '@angular/material/select'
import { IconModule } from '@visurel/iconify-angular'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { CurrencyMaskModule } from 'ng2-currency-mask'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatTableModule } from '@angular/material/table'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTabsModule } from '@angular/material/tabs'
import { MatChipsModule } from '@angular/material/chips'
import { FilterCnpjsDataComponent } from './filter-cnpjs-data.component'
import { NgxMaskModule } from 'ngx-mask'

@NgModule({
  declarations: [
    FilterCnpjsDataComponent
  ],
  imports: [
    MatPaginatorModule,
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    VexModule,
    BreadcrumbsModule,
    LayoutModule,
    MatTabsModule,
    PageLayoutModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatOptionModule,
    MatMenuModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatSelectModule,
    CurrencyMaskModule,
    IconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
    MatSnackBarModule,
    MatChipsModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    FilterCnpjsDataComponent
  ]
})
export class FilterCnpjsDataModule { }
