<form class="flex pt-4 flex-col" autocomplete="off" [formGroup]="form">
    <div formGroupName="store">
        <p class="text-bold pb-2">Dados Cadastrais</p>

        <div class="gap-5 flex flex-row">
          <mat-form-field class="flex-grow">
            <mat-label>CNPJ</mat-label>
            <input [readonly]="accountDocument" mask="00.000.000/0000-00" type="text" matInput required formControlName="document" />
            <mat-error *ngIf="
                f.store.controls.document.hasError('required')
              ">O CNPJ é obrigatório</mat-error>
            <mat-error *ngIf="
                f.store.controls.document.value &&
                f.store.controls.document.hasError('cnpjNotValid')
              ">CNPJ inválido</mat-error>
          </mat-form-field>

          <mat-form-field class="flex-grow">
            <mat-label>Nome Fantasia</mat-label>
            <input matInput formControlName="companyName" required />
            <mat-error *ngIf="
                f.store.controls.companyName.hasError('required')
              ">O nome fantasia é obrigatório</mat-error>

            <mat-error *ngIf="
                f.store.controls.companyName.hasError('maxlength')
              ">Tamanho do campo excede o máximo permitido</mat-error>
          </mat-form-field>

          <mat-form-field class="flex-grow">
            <mat-label>Razão Social</mat-label>
            <input matInput formControlName="corporateName" required />
            <mat-error *ngIf="
                f.store.controls.corporateName.hasError(
                  'required'
                )
              ">A razão social é obrigatória</mat-error>
            <mat-error *ngIf="
                f.store.controls.corporateName.hasError(
                  'maxlength'
                )
              ">Tamanho do campo excede o máximo permitido</mat-error>
          </mat-form-field>

          <div [hidden]="true">
            <mat-form-field class="flex-grow">
              <mat-label>Nome da Conta</mat-label>
              <input matInput formControlName="accountName" required />
              <mat-error *ngIf="
                  f.store.controls.accountName.hasError(
                    'required'
                  )
                ">O nome da conta é obrigatório</mat-error>
              <mat-error *ngIf="
                  f.store.controls.accountName.hasError(
                    'maxlength'
                  )
                ">Tamanho do campo excede o máximo permitido</mat-error>
            </mat-form-field>
          </div>
        </div>

          <div class="gap-5 flex flex-row">
            <mat-form-field class="flex-grow">
              <mat-label>Segmento</mat-label>
              <mat-select (selectionChange)="setCategories($event.value)" required formControlName="segment">
                <mat-option *ngFor="let segment of segments" [value]="segment.name">
                  {{ segment.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  f.store.controls.segment.hasError('required')
                ">Segmento é obrigatório</mat-error>
            </mat-form-field>

            <mat-form-field class="flex-grow">
              <mat-label>Categoria</mat-label>
              <mat-select required formControlName="subSegment">
                <mat-option *ngFor="let category of categories" [value]="category">
                  {{ category }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="
                  f.store.controls.subSegment.hasError('required')
                ">Categoria é obrigatória</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div formGroupName="bank">
            <p class="text-bold pb-2">Dados Bancários</p>

            <div class="gap-5 flex flex-row w-full">
              <mat-form-field *ngIf="readonlyInfo != null" class="flex-grow">
                <mat-label>Banco</mat-label>
                <input matInput type="text" formControlName="bankCode" />
              </mat-form-field>

                <mat-form-field *ngIf="readonlyInfo == null" class="flex-grow">
                  <mat-label>Banco</mat-label>

                  <mat-select  formControlName="name" #bankSelect (opened)="Openedselect()">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Digite o nome do seu banco" [noEntriesFoundLabel]="
                          bankFilterCtrl.value && bankFilterCtrl.value.length < 3
                            ? 'Digite pelo menos 3 caracteres'
                            : isSearchBankings
                            ? 'Pesquisando...'
                            : 'Nenhum banco encontrado'
                        " [formControl]="bankFilterCtrl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank.name">
                      {{ bank.code }} - {{ bank.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      f.bank.controls.name.hasError('required')
                    ">Selecione um banco</mat-error>
                </mat-form-field>
  
                <mat-form-field class="flex-grow">
                  <mat-label>Selecione o tipo de Conta</mat-label>
                  <mat-select formControlName="type">
                    <mat-option value="SavingsAccount">Conta Poupança</mat-option>
                    <mat-option value="CheckingAccount">Conta Corrente</mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.bank.controls.type.invalid">Tipo de conta é obrigatório</mat-error>
                </mat-form-field>
              </div>
  
              <div class="gap-5 flex flex-row w-full">
                <mat-form-field class="flex-grow">
                    <mat-label>Agência</mat-label>
                    <input matInput type="text" formControlName="accountAgency" placeholder="Informe a conta" />
                    <mat-error *ngIf="
                        f.bank.controls.accountAgency.hasError('required')
                      ">A conta é obrigatória</mat-error>
                  </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Conta</mat-label>
                  <input matInput type="text" formControlName="accountNumber" placeholder="Informe a conta" />
                  <mat-error *ngIf="
                      f.bank.controls.accountNumber.hasError('required')
                    ">A conta é obrigatória</mat-error>
                </mat-form-field>
  
                <mat-form-field class="flex-grow">
                  <mat-label>Dígito</mat-label>
                  <input matInput type="text" maxlength="2" formControlName="accountDigit"
                    placeholder="Informe o digito da conta" />
                  <mat-error *ngIf="f.bank.controls.accountDigit.hasError('required')">O dígito da conta é obrigatório</mat-error>
                  <mat-error *ngIf="f.bank.controls.accountDigit.hasError('min') ||  f.bank.controls.accountDigit.hasError('max')">O dígito da conta pode ter no máximo 2 caracteres</mat-error>
                </mat-form-field>
              </div>
        </div>

        <div formGroupName="address">
            <p class="text-bold pb-2">Dados de Endereço</p>

            <div *ngIf="loadingCep" class="ml-6" fxLayoutAlign="center center">
                <mat-spinner diameter="20"></mat-spinner>
              </div>

            <div *ngIf="!loadingCep" class="gap-5 flex flex-row">
              <mat-form-field class="flex-grow">
                <mat-label>CEP</mat-label>
                <input required mask="00000-000" (focusout)="getAddressInfo()" type="text" matInput
                  formControlName="zipcode" />
                <mat-error *ngIf="
                    f.address.controls.zipcode.hasError('required')
                  ">O CEP é obrigatório</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Estado</mat-label>
                <input required type="text" matInput formControlName="state" />
                <mat-error *ngIf="
                    f.address.controls.state.hasError('required')
                  ">O estado é obrigatório</mat-error>
                <mat-error *ngIf="
                    f.address.controls.state.hasError('maxlength')
                  ">Tamanho do campo excede o máximo permitido</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Cidade</mat-label>
                <input required type="text" matInput formControlName="city" />
                <mat-error *ngIf="
                    f.address.controls.city.hasError('required')
                  ">A cidade é obrigatória</mat-error>
                <mat-error *ngIf="
                    f.address.controls.city.hasError('maxlength')
                  ">Tamanho do campo excede o máximo permitido</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Bairro</mat-label>
                <input required type="text" matInput formControlName="neighborhood" />
                <mat-error *ngIf="
                    f.address.controls.neighborhood.hasError(
                      'required'
                    )
                  ">O bairro é obrigatório</mat-error>
                <mat-error *ngIf="
                    f.address.controls.neighborhood.hasError(
                      'maxlength'
                    )
                  ">Tamanho do campo excede o máximo permitido</mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="!loadingCep" class="gap-5 flex flex-row">
              <mat-form-field class="flex-grow">
                <mat-label>Endereço</mat-label>
                <input required type="text" matInput formControlName="street" />
                <mat-error *ngIf="
                    f.address.controls.street.hasError('required')
                  ">O endereço é obrigatório</mat-error>
                <mat-error *ngIf="
                    f.address.controls.street.hasError('maxlength')
                  ">Tamanho do campo excede o máximo permitido</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Número</mat-label>
                <input required type="text" matInput formControlName="number" />
                <mat-error *ngIf="
                    f.address.controls.number.hasError('required')
                  ">O número é obrigatório</mat-error>
                <mat-error *ngIf="
                    f.address.controls.number.hasError('maxlength')
                  ">Tamanho do campo excede o máximo permitido</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Complemento</mat-label>
                <input type="text" matInput formControlName="complement" />
              </mat-form-field>
              <mat-error *ngIf="
                  f.address.controls.complement.hasError(
                    'maxlength'
                  )
                ">Tamanho do campo excede o máximo permitido</mat-error>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center">
            <button [disabled]="readonlyInfo != null" (click)="done()" mat-raised-button
              color="primary">
              PRÓXIMO
            </button>
          </div>
</form>