import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'vex-validar-credenciais',
  templateUrl: './validar-credenciais.component.html',
  styleUrls: ['./validar-credenciais.component.scss']
})
export class ValidarCredenciaisComponent implements OnInit {
  constructor (
    public matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ValidarCredenciaisComponent>
  ) { }
  ngOnInit (): void {
    // do nothing.
  }

  clickCloseSuccess (): void {
    this.dialogRef.close({ event: 'SUCCESS' })
  }
}
