<vex-page-layout>
  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="mt-0 mb-1"><b>Status Page</b></h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">
    <div class="card overflow-auto -mt-16">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
          <span>Status Page</span>
        </h2>

        <div fxFlex="460px" fxFlex.lt-md="auto" fxLayout="row" fxLayoutAlign="start center">
          <div class="h-14">
            <mat-form-field>
              <mat-label>Filtrar por</mat-label>
              <mat-select [(ngModel)]="filterDate" (selectionChange)="refreshChart()" class="bg-transparent">
                <mat-option value="5">Últimos 5 minutos</mat-option>
                <mat-option value="30">Últimos 30 minutos</mat-option>
                <mat-option value="60">Última hora</mat-option>
                <mat-option value="180">Últimas 3 horas</mat-option>
                <mat-option value="openDialogDate">Personalizado</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="h-14 ml-5" *ngIf="filterDate === 'openDialogDate'">
            <mat-form-field style="margin-right: 10px">
              <mat-label>Período de busca</mat-label>
              <mat-date-range-input [max]="getToday()" [rangePicker]="picker" [formGroup]="dateRangeForm">
                <input matStartDate formControlName="customStartDate" placeholder="Data inicial" />
                <input matEndDate formControlName="customEndDate" placeholder="Data final" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="h-14" *ngIf="filterDate === 'openDialogDate'" fxLayoutAlign="start center">
            <button class="h-10" (click)="onFilterClick()" color="primary" mat-mini-fab matTooltip="Pesquisar">
              <mat-icon class="h-10" [icIcon]="icSearch"></mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="loading" class="p-3 align" fxLayoutAlign="center">
        <mat-spinner diameter="30"></mat-spinner>
      </div>

      <div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xs:grid-cols-5 gap-5 w-full p-2">
          <ng-container *ngFor="let average of allPspAvg">
            <vex-average class="pt-3" [hidden]="loading" [PspAverage]="average"></vex-average>
          </ng-container>
        </div>
        <div class="md:flex sm:block w-full flex-wrap">
          <ng-container *ngFor="let chart of charts">
            <div class="p-2 lg:w-6/12 sm:w-full flex-wrap" gdGap="5px">
              <div [hidden]="loading" [id]="chart.name" class="p-5 card" style="width: 100%; border-radius: 10px"></div>
            </div>
          </ng-container>
        </div>

      </div>
    </div>
  </vex-page-layout-content>
</vex-page-layout>