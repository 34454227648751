import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { LayoutService } from 'src/@vex/services/layout.service'

@Component({
  selector: 'vex-banner-dialog',
  templateUrl: './banner-dialog.component.html',
  styleUrls: ['./banner-dialog.component.scss']
})
export class BannerDialogComponent implements OnInit {
  readonly basePath = 'assets/img'
  readonly mobileImage = `${this.basePath}/banner-portal-qr.jpg`
  readonly desktopImage = `${this.basePath}/banner-portal-qr.jpg`
  imagePath: string

  constructor (
    public dialog: MatDialogRef<BannerDialogComponent>,
    private readonly layoutService: LayoutService
  ) {}
  ngOnInit (): void {
    this.layoutService.isDesktop$.subscribe(
      (isDesktop) =>
        (this.imagePath = isDesktop ? this.desktopImage : this.mobileImage)
    )
  }

  clickClose (): void {
    this.dialog.close({ event: 'CLOSE' })
  }
}
