<h1 mat-dialog-title> CPF válido com conta na Pede Pronto</h1>
<div mat-dialog-content>
    <form autocomplete="off" (submit)="save()" [formGroup]="form" fxLayout="column">
        <mat-form-field class="w-full" appearance="fill">
            <mat-label>CPF</mat-label>
            <input mask="000.000.000-99" type="text" formControlName="document" matInput>
            <mat-error *ngIf="form.get('document').hasError('required')">CPF é obrigatório</mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>FECHAR</button>
    <button mat-raised-button (click)="save()" type="submit" color="primary">CRIAR COBRANÇA DE TESTE</button>
</div>