<div class="flex">
  <div class="p-6 pb-0 w-full" vexContainer>
    <div class="p-3 card">
      <vex-filter-cnpjs-data (onSearch)="filterDashboard($event)"></vex-filter-cnpjs-data>
    </div>

    <div *ngIf="!(cnpj.length > 0)" class="mt-10" fxLayout="column" fxLayoutAlign="center center">
      <div class="w-1/4 md:w-2/12">
        <img class="m-auto " src="../../../../../assets/img/data-analysis.png" alt="Nenhum gráfico disponível">
      </div>
      <p class="text-center text-cnpj">Selecione a data e o CNPJ para ter acesso aos gráficos</p>
    </div>

  </div>
</div>

<div style="overflow-y: auto;" [class.hidden]="!(cnpj.length > 0)">
  <div class="md:flex sm:block">
    <div class="p-6 w-full lg:w-8/12" gdGap="10px" vexContainer>
      <div class="p-3 card">
        <div [class.hidden]="chartElementEmpty" #chart></div>

        <div *ngIf="loadingChart1 && (cnpj.length > 0)" class="align">
          <mat-spinner diameter="30"></mat-spinner>
        </div>

        <div *ngIf="chartElementEmpty" class="align">
          <p class="text-center text-cnpj">Nenhum dado encontrado para disponibilização do gráfico</p>
        </div>
      </div>

      <div class="p-3 card">
        <div [class.hidden]="chartElement3Empty" #chart3></div>

        <div *ngIf="loadingChart3 && (cnpj.length > 0)" class="align">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
        <div *ngIf="chartElement3Empty" class="align">
          <p class="text-center text-cnpj">Nenhum dado encontrado para disponibilização do gráfico</p>
        </div>
      </div>
    </div>

    <div class="md:pl-0 p-6 w-full lg:w-4/12" gdGap="10px" vexContainer>
      <div class="card">

        <table mat-table [class.hidden]="chartElementEmpty" *ngIf="(cnpj.length > 0) && paymentsByStatus"
          [dataSource]="paymentsByStatus" class="w-full">

          <ng-container matColumnDef="situacaoCobranca">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{convertPaymentStatus(element.situacaoCobranca)}} </td>
          </ng-container>

          <ng-container matColumnDef="quantidade">
            <th mat-header-cell *matHeaderCellDef> Quantidade </th>
            <td mat-cell *matCellDef="let element"> {{element.quantidade}} </td>
          </ng-container>

          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef> Valor </th>
            <td mat-cell *matCellDef="let element"> {{element.valor| currency}} </td>
          </ng-container>

          <ng-container matColumnDef="percentual">
            <th mat-header-cell *matHeaderCellDef> Percentual </th>
            <td mat-cell *matCellDef="let element"> {{ round(element.percentual)}}% </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="loadingChart1 && (cnpj.length > 0)" class="align">
          <mat-spinner diameter="30"></mat-spinner>
        </div>

        <div *ngIf="chartElementEmpty" class="align">
          <p class="text-center text-cnpj">Nenhum dado encontrado para disponibilização do gráfico</p>
        </div>
      </div>
      <div class="p-3 card">
        <div [class.hidden]="chartElement2Empty" #chart2></div>

        <div *ngIf="loadingChart2 && (cnpj.length > 0)" class="align">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
        <div *ngIf="chartElement2Empty" class="align">
          <p class="text-center text-cnpj">Nenhum dado encontrado para disponibilização do gráfico</p>
        </div>
      </div>

    </div>
  </div>
</div>