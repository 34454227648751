import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { MaxSizeValidator } from '@angular-material-components/file-input'
import icAttachFile from '@iconify/icons-ic/attach-file'
import { finalize, take } from 'rxjs/operators'
import { BatchRegistrationService } from 'src/app/services/batch-registration/batch-registration.service'

@Component({
  selector: 'vex-create-batch-registration',
  templateUrl: './create-batch-registration.component.html',
  styleUrls: ['./create-batch-registration.component.scss']
})
export class CreateBatchRegistrationComponent implements OnInit {
  loading: boolean
  form: FormGroup
  icAttachFile = icAttachFile

  maxSize = 30000;
  multiple = false
  accept = '.csv'
  isProcess = false

  constructor (
    private readonly dialogRef: MatDialogRef<CreateBatchRegistrationComponent>,
    private readonly snackbar: MatSnackBar,
    private readonly utilService: UtilsService,
    private readonly batchRegistrationService: BatchRegistrationService,
    private readonly fb: FormBuilder
  ) { }

  ngOnInit (): void {
    this.form = this.fb.group({
      file: ['', [Validators.required, MaxSizeValidator(this.maxSize * 1024)]]
    })
  }

  uploadFile (): void {
    this.loading = true
    const data = this.form.get('file').value

    this.batchRegistrationService.upload(data).pipe(take(1), finalize(() => this.loading = false)).subscribe(
      () => {
        this.snackbar.open('Planilha enviada para credenciamento massivo.', '', {
          duration: 4000
        })
        return this.dialogRef.close()
      },
      (err) => {
        this.snackbar.open(this.utilService.getErrorMessage(err, 'Existem inconsistências. Por favor, faça a correção dos dados!'), '', {
          duration: 4000
        })
      }
    )
  }
}
