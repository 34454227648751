import { formatDate } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { SegmentDto } from 'src/app/layouts/client-layout/models/SegmentDto'
import { fieldsStone } from 'src/app/utils/fieldsStone'
import { GenericValidator } from 'src/app/utils/genericValidator'
import { getSegmentList } from '../../info-data'
import cep from 'cep-promise'
import { MatSnackBar } from '@angular/material/snack-bar'
import { BankDto } from 'src/app/layouts/client-layout/models/RecipientDto'
import { Observable, of } from 'rxjs'
import { MatSelect } from '@angular/material/select'
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators'
import { DefaultReturnObjDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjDto'
import { PagedListDto } from 'src/app/layouts/client-layout/models/PagedListDto'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { BankOriginEnum, FilterBanksSearch } from 'src/app/layouts/client-layout/models/FilterBanksSearch'

@Component({
  selector: 'create-pagarme-account-form',
  templateUrl: './create-pagarme-account-form.component.html',
  styleUrls: ['./create-pagarme-account-form.component.scss']
})
export class CreatePagarmeAccountFormComponent implements OnInit {
  form: FormGroup
  @ViewChild('bankSelect') bankSelect: MatSelect
  segments: Array<SegmentDto> = getSegmentList()
  categories: Array<string> = []
  loadingCep = false
  banks: BankDto[] = []
  bankFilterCtrl: FormControl = new FormControl()
  filteredBanks: Observable<BankDto[]>
  isSearchBankings: boolean
  selectedBank: BankDto
  @Input() accountDocument = ''
  @Input() readonlyInfo = null
  @Output() onNext = new EventEmitter<any>();
  bankFilter: FilterBanksSearch = {
    pageNumber: 0,
    pageSize: 10,
    origin: BankOriginEnum.QrLinx
  }
  
  
  constructor (
    private readonly fb: FormBuilder,
    private readonly matSnackBar: MatSnackBar,
    private readonly accreditationService: AccreditationService
  ) { }
    
    
  ngOnInit (): void {
    this.form = this.fb.group({
      store: this.fb.group(
        {
          document: new FormControl(this.readonlyInfo?.document ?? this.accountDocument, [
            Validators.required,
            GenericValidator.isValidCnpj()
          ]),
          companyName: new FormControl(this.readonlyInfo?.companyName, [
            Validators.required,
            Validators.maxLength(255)
          ]),
          corporateName: new FormControl(this.readonlyInfo?.tradingName, [
            Validators.required,
            Validators.maxLength(255)
          ]),
          segment: new FormControl(this.readonlyInfo?.segment ?? '', Validators.required),
          subSegment: new FormControl(this.readonlyInfo?.category ?? '',Validators.required
          ),
          accountName: new FormControl(this.readonlyInfo?.accountName ?? fieldsStone.accountName, [
            Validators.required,
            Validators.maxLength(255)
          ])
        },
        {
          validators: [Validators.required]
        }
      ),
      contact: this.fb.group(
        {
          type: new FormControl(fieldsStone.type, Validators.required),
          name: new FormControl(fieldsStone.name, [
            Validators.required,
            Validators.maxLength(255)
          ]),
          email: new FormControl(fieldsStone.email, [
            Validators.required,
            Validators.email,
            Validators.maxLength(255)
          ]),
          phone: new FormControl(fieldsStone.phone, Validators.required),
          birthday: [
            formatDate(fieldsStone.birthday, 'yyyy-MM-dd', 'en'),
            [Validators.required, GenericValidator.isEighteenYearsOld()]
          ],
          mothersName: new FormControl(fieldsStone.mothersName, [
            Validators.required,
            Validators.maxLength(255)
          ])
        },
        {
          validators: [Validators.required]
        }
      ),
      address: this.fb.group(
        {
          zipcode: new FormControl(this.readonlyInfo?.address.zipcode ?? '', Validators.required),
          state: new FormControl(this.readonlyInfo?.address.state ?? '', [
            Validators.required,
            Validators.maxLength(30)
          ]),
          city: new FormControl(this.readonlyInfo?.address.city ?? '', [
            Validators.required,
            Validators.maxLength(40)
          ]),
          neighborhood: new FormControl(this.readonlyInfo?.address.neighborhood ?? '', [
            Validators.required,
            Validators.maxLength(50)
          ]),
          street: new FormControl(this.readonlyInfo?.address.street ?? '', [
            Validators.required,
            Validators.maxLength(255)
          ]),
          number: new FormControl(this.readonlyInfo?.address.number ?? '', [
            Validators.required,
            Validators.maxLength(10)
          ]),
          complement: new FormControl(
            this.readonlyInfo?.address.complement ?? '',
            Validators.maxLength(50)
          )
        },
        {
          validators: [Validators.required]
        }
      ),
      bank: this.fb.group(
        {
          name: new FormControl(this.readonlyInfo?.bank.bankCode ?? '', Validators.required),
          type: new FormControl(this.readonlyInfo?.bank.type ?? '', Validators.required),
          bankCode: new FormControl(this.readonlyInfo?.bank.bankCode ?? '', Validators.required),
          accountNumber: new FormControl(this.readonlyInfo?.bank.accountNumber ?? '', Validators.required),
          accountAgency: new FormControl(this.readonlyInfo?.bank.accountAgency ?? '', Validators.required),
          accountDigit: new FormControl(this.readonlyInfo?.bank.accountDigit ?? '', Validators.required)
        }
      )
    })

    this.form
      .get('bank.name')
      .valueChanges.subscribe((selectedBank) => {
        if (selectedBank) {
          this.selectedBank = this.banks.find(
            (bank) => bank.name === selectedBank
          )
          if (this.selectedBank)
            this.form.get('bank.bankCode').setValue(this.selectedBank.code)
        } else {
          this.selectedBank = null
        }
      })

    if (this.readonlyInfo != null) {
      this.setCategories(this.readonlyInfo?.segment)
      this.bankFilterCtrl.setValue(this.readonlyInfo?.bank.bankCode)
      this.form.disable()
    }
  }

  get f (): any {
    return this.form.controls
  }

  done (): void {
    if (!this.form.valid) return


    this.onNext.emit(this.form.getRawValue())
  }

  setCategories (event: string): void {
    this.categories = this.segments.find(e => e.name == event).category
  }

  getAddressInfo (): void {
    const zipcode = this.form.get('address.zipcode').value

    if (!zipcode || this.loadingCep) return

    this.loadingCep = true

    cep(zipcode)
      .then((response) => {
        this.loadingCep = false
        if (response.state) this.form.get('address.state').disable()

        this.form.get('address.state').setValue(response.state)

        if (response.city) this.form.get('address.city').disable()

        this.form.get('address.city').setValue(response.city)

        if (response.neighborhood)
          this.form.get('address.neighborhood').disable()

        this.form
          .get('address.neighborhood')
          .setValue(response.neighborhood)

        if (response.street)
          this.form.get('address.street').disable()

        this.form.get('address.street').setValue(response.street)
      })
      .catch(() => {
        this.loadingCep = false

        this.form.get('address.state').disable()
        this.form.get('address.city').disable()
        this.form.get('address.neighborhood').disable()
        this.form.get('address.street').disable()

        this.matSnackBar.open(
          'Não foi possível encontrar o CEP informado!',
          '',
          { duration: 4000 }
        )
      })
  }

  Openedselect (): void {
    this.filteredBanks = this.bankFilterCtrl.valueChanges.pipe(
      filter(() => !this.selectedBank || this.bankSelect.panelOpen),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((value) => {
        this.isSearchBankings = true
        if (value && value.length >= 3) {
          return this.accreditationService
            .getBanks({ ...this.bankFilter, searchBy: value })
            .pipe(
              map((res: DefaultReturnObjDto<PagedListDto<BankDto>>) => {
                this.isSearchBankings = false
                if (res.success) {
                  this.banks = res.data.pageItems
                  return this.banks
                } else {
                  return []
                }
              })
            )
        } else {
          return of([])
        }
      })
    )
    startWith(''), map((value: string) => this._filterBanks(value))
  }

  private _filterBanks (value: string): any[] {
    const filterValue = value.toLowerCase()

    if (!this.banks) {
      return []
    }

    return this.banks.filter(
      (bank) =>
        bank.name.toLowerCase().includes(filterValue) ||
        bank.code.toLowerCase().includes(filterValue)
    )
  }

}
