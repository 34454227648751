import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz'
import icAdd from '@iconify/icons-ic/twotone-add'
import icExport from '@iconify/icons-ic/sharp-download'
import icSearch from '@iconify/icons-ic/twotone-search'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { Crumb } from '../../models/crumb'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { PagedListDto } from '../../models/PagedListDto'
import { FormControl } from '@angular/forms'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'
import { RecipientDto } from '../../models/RecipientDto'
import { FilterRecipientsSearch } from '../../models/FilterRecipientsSearch'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { MatDialog } from '@angular/material/dialog'
import { DialogEditRecipientComponent } from './dialog-edit-recipient/dialog-edit-recipient.component'

@Component({
  selector: 'vex-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss'],
  animations: [stagger40ms]
})
export class RecipientsComponent implements OnInit {
  icAdd = icAdd
  icExport = icExport
  icSearch = icSearch
  icMoreHoriz = icMoreHoriz
  loading = false

  breadcrumbs: Crumb[] = [{ name: 'Recebedores', path: [''] }]

  searchCtrl: FormControl

  canList: boolean
  canEdit: boolean

  dataSource: MatTableDataSource<RecipientDto>
  @ViewChild(MatPaginator) paginator: MatPaginator
  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }
  filter: FilterRecipientsSearch = {
    pageNumber: this.paging.actualPage,
    pageSize: this.paging.pageSize
  }

  displayedColumns: string[] = [
    'id',
    'name',
    'document',
    'receivePaymentsAutomatically',
    'automaticAnticipationIsEnabled',
    'status',
    'createdAtUtc'
  ]
  isLinxUser: boolean

  constructor (
    private readonly utilsService: UtilsService,
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.searchCtrl = new FormControl()
    this.canList =
      this.utilsService.canShowElementByFunctionality('list-recipients')
    this.canEdit =
      this.utilsService.canShowElementByFunctionality('edit-recipients')
    this.dataSource = new MatTableDataSource()
    this.isLinxUser = this.utilsService.isUserFromLinx()
    this.loading = !this.isLinxUser
  }

  ngOnInit (): void {
    if (!this.isLinxUser)
      this.accreditationService
        .getStoresFromUserFiltered('')
        .subscribe((response) => {
          if (response.data.length == 1)
            this.searchCtrl.setValue(response.data[0].cnpj)

          this.loading = false
          this.fetchData()
        })

    this.searchCtrl.valueChanges
      .pipe(debounceTime(500), startWith(''), distinctUntilChanged())
      .subscribe((val) => {
        if (!val.length || val.length >= 3) {
          this.dataSource.data = []
          this.filter.cnpj = val
          this.filter.pageNumber = 0
          this.paging.actualPage = 0

          if (this.loading) return

          this.fetchData()
        }
      })
  }

  fetchData (): void {
    if (this.loading) return

    if (this.canEdit && this.displayedColumns.indexOf('actions') == -1) {
      this.displayedColumns.push('actions')
    }

    if (!this.filter.cnpj) {
      return
    }

    if (this.filter.cnpj) {
      this.filter.cnpj = this.filter.cnpj.replace(/[^0-9]+/g, '')
    }

    if (this.filter.cnpj.length) this.loading = true
    this.dataSource.data = []

    this.accreditationService.searchRecipients(this.filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<RecipientDto>>) => {
        this.loading = false

        if (!res.data.pageItems.length) {
          this.dataSource.data = []
          return
        }

        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
      },
      () => {
        this.loading = false
        this.matSnackBar.open(
          'Ocorreu um erro ao filtrar os recebedores!',
          '',
          {
            duration: 4000
          }
        )
      }
    )
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.updateFilterObjectWithThePaging()
    this.fetchData()
  }

  updateFilterObjectWithThePaging (): void {
    this.filter.pageNumber = this.paging.actualPage
    this.filter.pageSize = this.paging.pageSize
  }

  changeStatus (recipient: RecipientDto, newStatus: string): void {
    const patchData: RecipientDto = {
      status: newStatus
    }

    this.accreditationService.patchRecipient(recipient.id, patchData).subscribe(
      (res: DefaultReturnObjDto<RecipientDto>) => {
        this.loading = false
        if (res.success) {
          const foundRecipient = this.dataSource.data.find(
            (r) => r.id === recipient.id
          )
          if (foundRecipient) {
            foundRecipient.status = newStatus
            this.dataSource.data = [...this.dataSource.data]
          }

          this.matSnackBar.open(
            'Status do recebedor foi atualizado com sucesso!',
            '',
            {
              duration: 4000
            }
          )
        }
      },
      () => {
        this.loading = false
        this.matSnackBar.open(
          'Ocorreu um erro ao alterar o status do recebedor!',
          '',
          {
            duration: 4000
          }
        )
      }
    )
  }

  editRecipient (recipientDto: RecipientDto): void {
    this.dialog
      .open(DialogEditRecipientComponent, {
        disableClose: false,
        data: recipientDto,
        width: '590px'
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && res.event === 'SUCCESS' && res.recipient) {
          this.matSnackBar.open('Recebedor atualizado com sucesso!', '', {
            duration: 4000
          })
          this.fetchData()
        }
      })
  }
}
