import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'


@Component({
  selector: 'vex-mercado-pago-authorization',
  templateUrl: './mercado-pago-authorization.component.html',
  styleUrls: ['./mercado-pago-authorization.component.scss']
})

export class MercadoPagoAuthorizationComponent implements OnInit {
  dialogObj: {
    providerId: string,
    clientId: string
  }
  authorizationLink: string
  showMercadoPagoAuth = false
  mercadoPagoAuthWindow: Window

  constructor (
    private sanitizer: DomSanitizer,
    public matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MercadoPagoAuthorizationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      providerId: string,
      clientId: string
    }
  ) {
    this.dialogObj = data
  }

  ngOnInit (): void {
    this.authorizationLink =
      `https://auth.mercadopago.com.br/authorization?client_id=${this.dialogObj.clientId}` +
      '&response_type=code' +
      '&platform_id=mp' +
      `&state=${this.dialogObj.providerId}`
  }

  clickCloseSuccess (): void {
    this.dialogRef.close({ event: 'SUCCESS' })
  }

  clickClose (): void {
    this.dialogRef.close({ event: 'CLOSE' })
  }

  openDialogWindow () {
    const w = 992
    const h = 540
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    this.mercadoPagoAuthWindow = window.open(this.authorizationLink, '_blank',
      `
      resizable=no, toolbar=no, scrollbars=yes, menubar=no, status=no, directories=no, location=no,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )

    const timer = setInterval(function (parent) {
      if (parent.mercadoPagoAuthWindow.closed) {
        clearInterval(timer)
        parent.clickCloseSuccess()
      }
    }, 1000, this)

    if (window.focus) this.mercadoPagoAuthWindow.focus()
  }
}
