import { AbstractControl, ValidationErrors } from '@angular/forms'

function isInvalidCPF (cpf: string): boolean {
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf.length !== 11) return true

  for (let i = 0; i < 10; i++) {
    if (cpf === Array(12).join(String(i))) return true
  }

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += Number(cpf.charAt(i)) * (10 - i)
  }

  let remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== Number(cpf.charAt(9))) return true

  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += Number(cpf.charAt(i)) * (11 - i)
  }

  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== Number(cpf.charAt(10))) return true

  return false
}

// Validadores

export function cpfValidator (
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value
  if (isInvalidCPF(value)) {
    return { cpfInvalid: true }
  }
  return null
}
