import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { DefaultReturnDto } from 'src/app/layouts/client-layout/models/DefaultReturnDto'
import { ExclusiveStoneStoresDto } from 'src/app/layouts/client-layout/models/ExclusiveStoneStoresDto'
import { DefaultReturnObjDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjDto'
import { PagedListDto } from 'src/app/layouts/client-layout/models/PagedListDto'
import { MessageDto } from 'src/app/layouts/client-layout/models/MessageDto'
import {
  AccreditationResponseDto,
  CreateProviderDto,
  PagarmeAccountResponseDto,
  ProviderDto,
  ProviderHistoryDto,
  ProviderListDto,
  ProvidersStoreDto
} from 'src/app/layouts/client-layout/models/ProviderDto'
import { ProviderFilter } from 'src/app/layouts/client-layout/models/ProviderFIlter'
import {
  CancelPaymentTest,
  CheckPaymentTest,
  PartnerInfo,
  PaymentTest,
  StoreProviders,
  Stores
} from 'src/app/layouts/client-layout/models/stores'
import { environment } from 'src/environments/environment'
import { UtilsService } from '../utils/utils.service'
import { BrandDto } from './../../layouts/client-layout/models/BrandDto'
import { StoresUserAccessDto } from 'src/app/layouts/client-layout/models/StoresUserAccessDto'
import { PointOfSaleListDto } from 'src/app/layouts/client-layout/models/PointOfSaleListDto'
import {
  SoftwarePdvDto,
  CategoryDto,
  StoreDto,
  PointOfSaleDto
} from 'src/app/layouts/client-layout/models/StoreDto'
import { FilterStoresSearch } from 'src/app/layouts/client-layout/models/FilterStoresSearch'
import { MassiveHistoric } from 'src/app/layouts/client-layout/models/MassiveHistoric'
import { catchError, map } from 'rxjs/operators'
import { saveAs } from 'file-saver'
import { FilterMassiveHistoric } from 'src/app/layouts/client-layout/models/filterMassiveHistoric'
import { ProviderHistoryFilter } from 'src/app/layouts/client-layout/models/ProviderHistoryFilter'
import { FilterCategoriesSearch } from 'src/app/layouts/client-layout/models/FilterCategoriesSearch'
import { FuncionalityDto } from 'src/app/layouts/client-layout/models/FuncionalityDto'
import { FilterRecipientsSearch } from 'src/app/layouts/client-layout/models/FilterRecipientsSearch'
import {
  BankDto,
  RecipientDto
} from 'src/app/layouts/client-layout/models/RecipientDto'
import { FilterBanksSearch } from 'src/app/layouts/client-layout/models/FilterBanksSearch'
import { GetRecipientByProviderIdDto } from 'src/app/layouts/client-layout/models/GetRecipientByProviderIdDto'
import { WalletInfo } from 'src/app/layouts/client-layout/models/WalletInfoDto'
import { LocalStorangeService } from '../local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class AccreditationService {
  private baseUrl =
    environment.baseUrl

  constructor (
    private httpClient: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly localStorangeService: LocalStorangeService
  ) {}

  public _getStoresWithoutProviders (): Observable<
    DefaultReturnObjDto<boolean>
    > {
    return this.httpClient.get<DefaultReturnObjDto<boolean>>(
      `${this.baseUrl}/v1/store/storesWithoutProviders`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public _getDefaultConfigProviders (
    id: string,
    integrationType?: string
  ): Observable<
    DefaultReturnObjDto<
      {
        name: string
        value: string
        isNullabe: boolean
        conciliation: boolean
        alias: string
        isBoolean: boolean
      }[]
    >
  > {
    return this.httpClient.get<
      DefaultReturnObjDto<
        {
          name: string
          value: string
          isNullabe: boolean
          conciliation: boolean
          alias: string
          isBoolean: boolean
        }[]
      >
    >(
      `${this.baseUrl}/v1/provider/default-configurations/${id}${
        integrationType != 'Default' ? '/' + integrationType : ''
      }`,
      {
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getProviderByWallet (
    checkPix: boolean,
    cnpjList: any
  ): Observable<DefaultReturnObjDto<boolean>> {
    return this.httpClient.post<DefaultReturnObjDto<boolean>>(
      `${this.baseUrl}/v1/provider/wallet`,
      {
        checkPix,
        ...cnpjList
      },
      {
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public checkIfHasStoreStoneGroup (data: {
    IntegrationTypePix: string
    StoreProviders: string[]
  }): Observable<DefaultReturnObjDto<boolean>> {
    return this.httpClient.put<DefaultReturnObjDto<boolean>>(
      `${this.baseUrl}/v1/provider/check/stone-group`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public cleanStoreProvider (data: {
    primaryId: string
    secondaryId?: string
  }): Observable<DefaultReturnObjDto<boolean>> {
    // const params = this.utilsService.addParams(
    //   Object.entries(data).map(([key, value]) => {
    //     return { key, value }
    //   })
    // )

    return this.httpClient.delete<DefaultReturnObjDto<boolean>>(
      `${this.baseUrl}/v1/provider`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getProviderInfoFromPartner (
    cnpj: string[]
  ): Observable<DefaultReturnDto<PartnerInfo>> {
    return this.httpClient.post<DefaultReturnDto<PartnerInfo>>(
      `${this.baseUrl}/v1/store/data/psp`,
      {
        cnpjs: cnpj
      },
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getProviderPaged (
    filter: ProviderFilter
  ): Observable<DefaultReturnObjDto<PagedListDto<ProviderListDto>>> {
    const params = Object.entries(filter)
      .filter(([key, value]) => value !== undefined && value !== null)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value.toString() }), {})

    return this.httpClient.get<
      DefaultReturnObjDto<PagedListDto<ProviderListDto>>
    >(`${this.baseUrl}/v1/provider`, {
      params,
      withCredentials: true,
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
    })
  }

  public getProviderHistoryPaged (
    filter: ProviderHistoryFilter
  ): Observable<DefaultReturnObjDto<PagedListDto<ProviderHistoryDto>>> {
    return this.httpClient.get<
      DefaultReturnObjDto<PagedListDto<ProviderHistoryDto>>
    >(
      `${this.baseUrl}/v1/providerhistory?pageNumber=${
        filter.pageNumber
      }&pageSize=${filter.pageSize}&searchBy=${
        filter.searchBy
      }&startDate=${filter.startDate.toJSON()}&endDate=${filter.endDate.toJSON()}&operationType=${
        filter.operationType
      }&cnpj=${filter.cnpj}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public exportProviderHistoryCsvFile (
    filter: ProviderHistoryFilter
  ): Observable<Blob> {
    return this.httpClient
      .get<Blob>(
        `${this.baseUrl}/v1/providerhistory/csv?pageNumber=${
          filter.pageNumber
        }&pageSize=${filter.pageSize}&searchBy=${
          filter.searchBy
        }&startDate=${filter.startDate.toJSON()}&endDate=${filter.endDate.toJSON()}&operationType=${
          filter.operationType
        }&cnpj=${filter.cnpj}`,
        {
          withCredentials: true,
          headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser()),
          responseType: 'blob' as 'json'
        }
      )
      .pipe()
  }

  public getPointOfSalePaged (
    data: any
  ): Observable<DefaultReturnObjDto<PagedListDto<PointOfSaleListDto>>> {
    return this.httpClient.post<
      DefaultReturnObjDto<PagedListDto<PointOfSaleListDto>>
    >(`${this.baseUrl}/v1/pos/search`, data, {
      withCredentials: true,
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
    })
  }

  ExportCsvFile (searchBy: string): Observable<Blob> {
    // const params = this.utilsService.addParams([
    //   { key: 'searchBy', value: searchBy }
    // ])

    return this.httpClient
      .get<Blob>(`${this.baseUrl}/v1/pos/ExportCsvFile`, {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser()),
        responseType: 'blob' as 'json'
        // params
      })
      .pipe()
  }

  public getProviderByid (
    id: string
  ): Observable<DefaultReturnObjDto<ProviderDto[]>> {
    return this.httpClient.get<DefaultReturnObjDto<ProviderDto[]>>(
      `${this.baseUrl}/v1/provider/${id}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getStoresFromUserFiltered (
    tradingNameOrCnpj: string
  ): Observable<DefaultReturnDto<StoresUserAccessDto>> {
    return this.httpClient.get<DefaultReturnDto<StoresUserAccessDto>>(
      `${this.baseUrl}/v1/store/cnpj-and-trading-name?tradingNameOrCnpj=${tradingNameOrCnpj}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public filterBrands (
    data: any
  ): Observable<DefaultReturnObjDto<PagedListDto<BrandDto>>> {
    return this.httpClient.post<DefaultReturnObjDto<PagedListDto<BrandDto>>>(
      `${this.baseUrl}/v1/brand/search`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getBrands (): Observable<DefaultReturnDto<BrandDto>> {
    return this.httpClient.get<DefaultReturnDto<BrandDto>>(
      `${this.baseUrl}/v1/brand`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getBrandsByFilter (data: any): Observable<Blob> {
    // const params = this.utilsService.addParams([
    //   { key: 'searchBy', value: data }
    // ])

    return this.httpClient.get<Blob>(
      `${this.baseUrl}/v1/brand/GetBrandsCsvFile`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser()),
        responseType: 'blob' as 'json'
        // params
      }
    )
  }

  public getBrandsPaged (
    data: any
  ): Observable<DefaultReturnObjDto<PagedListDto<BrandDto>>> {
    return this.httpClient.post<DefaultReturnObjDto<PagedListDto<BrandDto>>>(
      `${this.baseUrl}/v1/brand/search`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public createBrand (
    data: BrandDto
  ): Observable<DefaultReturnObjDto<BrandDto>> {
    return this.httpClient.post<DefaultReturnObjDto<BrandDto>>(
      `${this.baseUrl}/v1/brand`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public updateBrand (
    data: BrandDto
  ): Observable<DefaultReturnObjDto<BrandDto>> {
    return this.httpClient.put<DefaultReturnObjDto<BrandDto>>(
      `${this.baseUrl}/v1/brand/${data.id}`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public UpdateStore (
    data: StoreDto
  ): Observable<DefaultReturnObjDto<StoreDto>> {
    return this.httpClient.post<DefaultReturnObjDto<StoreDto>>(
      `${this.baseUrl}/v1/store/UpdateStore`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getSoftwarePdvPaged (
    data: any
  ): Observable<DefaultReturnObjDto<PagedListDto<SoftwarePdvDto>>> {
    return this.httpClient.post<
      DefaultReturnObjDto<PagedListDto<SoftwarePdvDto>>
    >(`${this.baseUrl}/v1/SoftwarePdv/search`, data, {
      withCredentials: true,
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
    })
  }

  public createSoftwarePdv (
    data: SoftwarePdvDto
  ): Observable<DefaultReturnObjDto<SoftwarePdvDto>> {
    return this.httpClient.post<DefaultReturnObjDto<SoftwarePdvDto>>(
      `${this.baseUrl}/v1/SoftwarePdv`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public updateSoftwarePdv (
    data: SoftwarePdvDto
  ): Observable<DefaultReturnObjDto<SoftwarePdvDto>> {
    return this.httpClient.post<DefaultReturnObjDto<SoftwarePdvDto>>(
      `${this.baseUrl}/v1/SoftwarePdv/update`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getSoftwarePdvsByFilter (data: any): Observable<Blob> {
    // const params = this.utilsService.addParams([
    //   { key: 'searchBy', value: data }
    // ])

    return this.httpClient.get<Blob>(
      `${this.baseUrl}/v1/SoftwarePdv/GetSoftwarePdvsCsvFile`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser()),
        responseType: 'blob' as 'json'
        // params
      }
    )
  }

  public hasStoneAccount (
    document: string
  ): Observable<DefaultReturnDto<StoreProviders>> {
    return this.httpClient.get<DefaultReturnDto<StoreProviders>>(
      `${this.baseUrl}/v1/accreditation/stone/${document}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getPagarmeAccount (
    document: string
  ): Observable<DefaultReturnObjDto<PagarmeAccountResponseDto>> {
    return this.httpClient.get<DefaultReturnObjDto<PagarmeAccountResponseDto>>(
      `${this.baseUrl}/v1/accreditation/pagarme/${document}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public createPagarmeAccreditation (
    data: any
  ): Observable<DefaultReturnObjDto<AccreditationResponseDto>> {
    return this.httpClient.post<DefaultReturnObjDto<AccreditationResponseDto>>(
      `${this.baseUrl}/v1/accreditation`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getWallets (): Observable<DefaultReturnDto<WalletInfo>> {
    return this.httpClient.get<DefaultReturnDto<WalletInfo>>(
      `${this.baseUrl}/v1/wallet`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getStores (
    filter: string
  ): Observable<DefaultReturnDto<StoreProviders>> {
    return this.httpClient.get<DefaultReturnDto<StoreProviders>>(
      `${this.baseUrl}/v1/store?${filter}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getStoreInfo (
    storeId: string
  ): Observable<DefaultReturnObjDto<StoreProviders>> {
    return this.httpClient.get<DefaultReturnObjDto<StoreProviders>>(
      `${this.baseUrl}/v1/store/${storeId}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public CreatePaymentTest (
    data: any
  ): Observable<DefaultReturnObjDto<PaymentTest>> {
    return this.httpClient.post<DefaultReturnObjDto<PaymentTest>>(
      `${this.baseUrl}/v1/payment/paymentTest`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public CheckPayment (
    paymentId: string
  ): Observable<DefaultReturnObjDto<CheckPaymentTest>> {
    return this.httpClient.get<DefaultReturnObjDto<CheckPaymentTest>>(
      `${this.baseUrl}/v1/payment/check/${paymentId}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public ManualPaymentConfirmation (data: any) {
    return this.httpClient.post<DefaultReturnObjDto<CheckPaymentTest>>(
      `${this.baseUrl}/v1/payment/confirm`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public RefundPayment (data: any) {
    return this.httpClient.patch<DefaultReturnObjDto<CancelPaymentTest>>(
      `${this.baseUrl}/v1/payment/cancel`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getAllFuncionalies (): Observable<
    DefaultReturnObjDto<FuncionalityDto[]>
    > {
    return this.httpClient.get<DefaultReturnObjDto<FuncionalityDto[]>>(
      `${this.baseUrl}/v1/functionality`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getAllFuncionaliesByUser (): Observable<
    DefaultReturnObjDto<FuncionalityDto[]>
    > {
    return this.httpClient.get<DefaultReturnObjDto<FuncionalityDto[]>>(
      `${this.baseUrl}/v1/functionality/listByUser`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getStoresPaged (
    filter: ProviderFilter
  ): Observable<DefaultReturnObjDto<PagedListDto<StoreProviders>>> {
    return this.httpClient.get<
      DefaultReturnObjDto<PagedListDto<StoreProviders>>
    >(
      `${this.baseUrl}/v1/store/listforregisterprovider?providerId=${filter.providerId}&pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}&searchBy=${filter.searchBy}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getStoresProviderById (
    storeId: string
  ): Observable<DefaultReturnObjDto<Array<ProvidersStoreDto>>> {
    return this.httpClient.get<DefaultReturnObjDto<Array<ProvidersStoreDto>>>(
      `${this.baseUrl}/v1/store/providersstore?storeId=${storeId}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getStoresProvidersPaged (
    filter: ProviderFilter
  ): Observable<DefaultReturnObjDto<PagedListDto<StoreProviders>>> {
    return this.httpClient.get<
      DefaultReturnObjDto<PagedListDto<StoreProviders>>
    >(
      `${this.baseUrl}/v1/store/listforregisterprovider?providerId=${filter.providerId}&pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}&searchBy=${filter.searchBy}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public createProvider (
    data: CreateProviderDto[]
  ): Observable<DefaultReturnObjDto<ProviderDto[]>> {
    return this.httpClient.post<DefaultReturnObjDto<ProviderDto[]>>(
      `${this.baseUrl}/v1/provider`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public updateProvider (
    data: CreateProviderDto[]
  ): Observable<DefaultReturnObjDto<ProviderDto[]>> {
    return this.httpClient.put<DefaultReturnObjDto<ProviderDto[]>>(
      `${this.baseUrl}/v1/provider`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public updateProviderMP (
    data: CreateProviderDto[], cnpjMPToCopyToken: string
  ): Observable<DefaultReturnObjDto<ProviderDto[]>> {
    return this.httpClient.put<DefaultReturnObjDto<ProviderDto[]>>(
      `${this.baseUrl}/v1/provider?cnpjMPToCopyToken=${cnpjMPToCopyToken}`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public invertPriorities (
    providerId: string
  ): Observable<DefaultReturnObjDto<string>> {   
    return this.httpClient.post<DefaultReturnObjDto<string>>(
      `${this.baseUrl}/v1/provider/${providerId}/invert-priorities`,   
      null,   
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentialsFormFile(
          environment.targetNewProducts
        )
      }
    )
  }

  public upload (file: File): Observable<any> {
    const formData = new FormData()
    formData.append('formFile', file)

    return this.httpClient.post(
      `${this.baseUrl}/v1/provider/upload`,
      formData,
      {
        reportProgress: true,
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentialsFormFile(
          environment.targetNewProducts
        )
      }
    )
  }

  public downloadFile (fileName: string): Observable<any> {
    return this.httpClient
      .get(`${this.baseUrl}/v1/provider/download?fileName=${fileName}`, {
        responseType: 'blob',
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
      .pipe(
        map((res) => {
          return saveAs(res, fileName)
        })
      )
  }

  public getAllStores (): Observable<DefaultReturnDto<StoreDto>> {
    const url = `${this.baseUrl}/v1/store/list-all-from-user`

    return this.httpClient.get<DefaultReturnDto<StoreDto>>(url, {
      withCredentials: true,
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
    })
  }

  public getExclusiveStoneStores (): Observable<DefaultReturnObjDto<ExclusiveStoneStoresDto>> {
    const url = `${this.baseUrl}/v1/store/count-exclusive-stone-stores`

    return this.httpClient.get<DefaultReturnObjDto<ExclusiveStoneStoresDto>>(url, {
      withCredentials: true,
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
    })
  }

  public searchStore (
    filter: FilterStoresSearch
  ): Observable<DefaultReturnObjDto<PagedListDto<StoreDto>>> {
    const params = Object.keys(filter)
      .filter((key) => filter[key] !== undefined && filter[key] !== null)
      .map((key) => `${key}=${filter[key]}`)
      .join('&')

    const url = `${this.baseUrl}/api/Client/paged${params ? `?${params}` : ''}`

    return this.httpClient.get<DefaultReturnObjDto<PagedListDto<StoreDto>>>(
      url,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    
    )
  }

  redirectToLogin (): void {
    location.href = environment.loginUrl
  }

  public ExportStoresCsvFile (filter: FilterStoresSearch): Observable<Blob> {
    const params = this.utilsService.addParams(
      Object.entries(filter).map(([key, value]) => {
        return { key, value }
      })
    )

    return this.httpClient
      .get<Blob>(`${this.baseUrl}/v1/store/ExportCsvFile`, {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser()),
        responseType: 'blob' as 'json',
        params
      })
      .pipe()
  }

  public GetMassiveHistorics (
    dateTimeBegin: string,
    filters: FilterMassiveHistoric
  ): Observable<DefaultReturnObjDto<PagedListDto<MassiveHistoric>>> {
    const params = this.utilsService.addParams([
      { key: 'requestNumber', value: filters.requestNumber },
      { key: 'operatorUserName', value: filters.operatorUserName },
      { key: 'startDate', value: dateTimeBegin },
      { key: 'endDate', value: filters.dateTimeEnd },
      { key: 'processStatus', value: filters.selectedStatus },
      { key: 'pageSize', value: filters.pageSize },
      { key: 'pageNumber', value: filters.pageNumber }
    ])

    return this.httpClient.get<
      DefaultReturnObjDto<PagedListDto<MassiveHistoric>>
    >(`${this.baseUrl}/v1/provider/search-Batch-Processing-History-paged`, {
      withCredentials: true,
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser()),
      params
    })
  }
  //#endregion

  //#region Categories
  public searchCategories (
    filter: FilterCategoriesSearch
  ): Observable<DefaultReturnObjDto<PagedListDto<CategoryDto>>> {
    const params = Object.keys(filter)
      .filter((key) => filter[key] !== undefined && filter[key] !== null)
      .map((key) => `${key}=${filter[key]}`)
      .join('&')

    const url = `${this.baseUrl}/v1/category/search${
      params ? `?${params}` : ''
    }`

    return this.httpClient.get<DefaultReturnObjDto<PagedListDto<CategoryDto>>>(
      url,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  ExportCategoriesCsvFile (filter: FilterCategoriesSearch): Observable<Blob> {
    const params = this.utilsService.addParams(
      Object.entries(filter).map(([key, value]) => {
        return { key, value }
      })
    )

    return this.httpClient
      .get<Blob>(`${this.baseUrl}/v1/category/ExportCsvFile`, {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser()),
        responseType: 'blob' as 'json',
        params
      })
      .pipe()
  }

  public createCategory (
    data: CategoryDto
  ): Observable<DefaultReturnObjDto<CategoryDto>> {
    return this.httpClient.post<DefaultReturnObjDto<CategoryDto>>(
      `${this.baseUrl}/v1/category`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public updateCategory (
    data: CategoryDto
  ): Observable<DefaultReturnObjDto<CategoryDto>> {
    return this.httpClient.put<DefaultReturnObjDto<CategoryDto>>(
      `${this.baseUrl}/v1/category`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }
  //#endregion

  //#region Store Status Processing
  public getStoreStatusProcessing (
    dateTimeBegin: string,
    filters: FilterMassiveHistoric
  ): Observable<DefaultReturnObjDto<PagedListDto<MassiveHistoric>>> {
    const params = this.utilsService.addParams([
      { key: 'historicType', value: 'StoreStatus' },
      { key: 'requestNumber', value: filters.requestNumber },
      { key: 'operatorUserName', value: filters.operatorUserName },
      { key: 'startDate', value: dateTimeBegin },
      { key: 'endDate', value: filters.dateTimeEnd },
      { key: 'processStatus', value: filters.selectedStatus },
      { key: 'pageSize', value: filters.pageSize },
      { key: 'pageNumber', value: filters.pageNumber }
    ])

    return this.httpClient.get<
      DefaultReturnObjDto<PagedListDto<MassiveHistoric>>
    >(`${this.baseUrl}/v1/StoreStatusProcessing/Search`, {
      withCredentials: true,
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser()),
      params
    })
  }

  public uploadStoreStatusProcessing (file: File): Observable<any> {
    const formData = new FormData()
    formData.append('formFile', file)
    return this.httpClient.post(
      `${this.baseUrl}/v1/StoreStatusProcessing/Upload`,
      formData,
      {
        reportProgress: true,
        responseType: 'arraybuffer',
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentialsFormFile(          
          environment.targetNewProducts
        )
      }
    )
  }

  public downloadStoreStatusProcessing (fileName: string): Observable<Blob> {
    return this.httpClient
      .get(
        `${this.baseUrl}/v1/StoreStatusProcessing/Download?fileName=${fileName}`,
        {
          responseType: 'blob',
          withCredentials: true,
          headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
        }
      )
      .pipe(
        map((res) => {
          return saveAs(res, fileName)
        })
      )
  }

  public searchRecipients (
    filter: FilterRecipientsSearch
  ): Observable<DefaultReturnObjDto<PagedListDto<RecipientDto>>> {
    const params = Object.keys(filter)
      .filter((key) => filter[key] !== undefined && filter[key] !== null)
      .map((key) => `${key}=${filter[key]}`)
      .join('&')

    const url = `${this.baseUrl}/v1/recipient${params ? `?${params}` : ''}`

    return this.httpClient.get<DefaultReturnObjDto<PagedListDto<RecipientDto>>>(
      url,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public createRecipient (
    data: RecipientDto
  ): Observable<DefaultReturnObjDto<RecipientDto>> {
    return this.httpClient.post<DefaultReturnObjDto<RecipientDto>>(
      `${this.baseUrl}/v1/recipient`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public patchRecipient (
    id: string,
    data: RecipientDto
  ): Observable<DefaultReturnObjDto<RecipientDto>> {
    return this.httpClient.patch<DefaultReturnObjDto<RecipientDto>>(
      `${this.baseUrl}/v1/recipient/${id}`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public putRecipient (
    data: RecipientDto
  ): Observable<DefaultReturnObjDto<RecipientDto>> {
    return this.httpClient.put<DefaultReturnObjDto<RecipientDto>>(
      `${this.baseUrl}/v1/recipient`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getRecipientById (
    recipientId: string
  ): Observable<DefaultReturnObjDto<RecipientDto>> {
    return this.httpClient.get<DefaultReturnObjDto<RecipientDto>>(
      `${this.baseUrl}/v1/recipient/${recipientId}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getBanks (
    filter: FilterBanksSearch
  ): Observable<DefaultReturnObjDto<PagedListDto<BankDto>>> {
    const params = Object.keys(filter)
      .filter((key) => filter[key] !== undefined && filter[key] !== null)
      .map((key) => `${key}=${filter[key]}`)
      .join('&')

    const url = `${this.baseUrl}/v1/bank${params ? `?${params}` : ''}`

    return this.httpClient.get<DefaultReturnObjDto<PagedListDto<BankDto>>>(
      url,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getRecipient (
    data: GetRecipientByProviderIdDto
  ): Observable<DefaultReturnObjDto<boolean>> {
    const param =
      '?document=' +
      data.document +
      '&' +
      data.providerIdList.map((v) => `providerIdList=${v}`).join('&')

    return this.httpClient.get<DefaultReturnObjDto<boolean>>(
      `${this.baseUrl}/v1/recipient/check${param}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public findPdvIfNotFindCreate (
    storeId: string,
    channel: string
  ): Observable<DefaultReturnObjDto<PointOfSaleDto>> {
    return this.httpClient.get<DefaultReturnObjDto<PointOfSaleDto>>(
      `${this.baseUrl}/v1/pos/get-create-pos/${storeId}/${channel}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getStoresByGroup (groupId?: string, storeCnpj?: string): Observable<DefaultReturnObjDto<Array<Stores>>> {
    let queryParams = ''
    if (groupId) {
      queryParams += `groupId=${groupId}`
    }
    if (storeCnpj) {
      queryParams += `&storeCnpj=${storeCnpj}`
    }
    const url = `${this.baseUrl}/v1/store/list-by-group?${queryParams}`
    return this.httpClient.get<DefaultReturnObjDto<Array<Stores>>>(url, {
      withCredentials: true,
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
    })
  }

  public getPendingMessages (): Observable<DefaultReturnObjDto<Array<MessageDto>>> {
    return this.httpClient.get<DefaultReturnObjDto<Array<MessageDto>>>(
      `${this.baseUrl}/v1/licenseserver/pending-messages`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }
  
  public confirmReading (messageId: string): Observable<DefaultReturnObjDto<boolean>> {
    return this.httpClient.put<DefaultReturnObjDto<boolean>>(
      `${this.baseUrl}/v1/licenseserver/confirm-reading`,
      { messageId: messageId },
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }
}
