<div class="card flex flex-col items-center w-full rounded">
  <div class="p-3">
    <div class="flex items-center justify-center rounded-full text-center"
      style="background: #ffffff; width: 60px; height: 60px">
      <img matTooltipPosition="above" [matTooltip]="PspAverage.pspName ?? '---'"
        [src]="getLogoByPspName(PspAverage.pspName)" alt="QrLinx" />
    </div>
  </div>
  <div class="flex items-center justify-center">
    <h4 class="text-1xl text-center">Tempo médio no período</h4>
  </div>
  <div class="text-2xl my-2 font-bold">
    {{ PspAverage.time ?? 'Sem dados' }}
  </div>
</div>