import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UtilsService } from '../utils/utils.service'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { PagedListDto } from 'src/app/layouts/client-layout/models/PagedListDto'
import { DefaultReturnObjDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjDto'
import { FilterConsolidateDto } from 'src/app/layouts/client-layout/models/FilterConsolidateDto'
import { RefundDto } from 'src/app/layouts/client-layout/models/RefundDto'
import { LocalStorangeService } from '../local-storage.service'

@Injectable({
  providedIn: 'root'
})

export class ContingencyService { 
private baseUrl = environment.contingency.baseUrl + environment.contingency.path
private subscriptionKey = environment.contingency.subscriptionKeyContingency

constructor (
    private readonly httpClient: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly localStorangeService: LocalStorangeService
) { }


public getPendingRefunds (
  filters: FilterConsolidateDto
): Observable<DefaultReturnObjDto<PagedListDto<RefundDto>>> {
  return this.httpClient.post<DefaultReturnObjDto<PagedListDto<RefundDto>>>(
    `${this.baseUrl}/v1/refund/search`, filters,
    {
      withCredentials: true,
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
    })
}
}


 
