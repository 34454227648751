<vex-page-layout>
  <vex-page-layout-header class="pb-17" fxLayout="column" fxLayoutAlign="center start">
    <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
      <div *ngIf="loading == false">
        <h1 class=" mt-0 mb-1"><b>Dados do estabelecimento - {{ storeProviders?.trading_name }}</b></h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">

    <div class="card overflow-auto -mt-17">
      <div class="m-6" fxLayoutAlign="center center" *ngIf="loading">
        <mat-spinner diameter="36"></mat-spinner>
      </div>
      <mat-card fxLayoutAlign="center start" fxLayout.lt-md="column" fxLayout.md="row" fxLayout="row">
        <form class="w-full md:w-4/6 lg:w-5/6" autocomplete="off" fxLayout="column" fxLayoutAlign="start"
          *ngIf="!loading">
          <mat-card class="mat-elevation-z6" style="margin-bottom: 25px">
            <mat-card-content>
              <div fxLayoutGap="15px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column" fxLayout="row"
                fxLayoutAlign="space-between none">
                <div class="w-full" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column" fxLayout="column"
                  fxLayoutAlign="start">

                  <div fxLayout.lt-md="column" fxLayoutGap="15px" fxLayout.md="column" fxLayout.lg="column"
                    fxLayout="row" fxLayoutAlign="space-between none">
                    <mat-form-field>
                      <mat-label>Marca</mat-label>
                      <input matInput value="{{ storeProviders?.brand_name }}" readonly>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Categoria</mat-label>
                      <input matInput value="{{ storeProviders?.category_name }}" readonly>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>Automação</mat-label>
                      <input matInput value="{{ storeProviders?.automation }}" readonly>
                    </mat-form-field>
                  </div>

                  <div fxLayout.lt-md="column" fxLayoutGap="12px" fxLayout.md="column" fxLayout.lg="column"
                    fxLayout="row" fxLayoutAlign="space-between none">
                    <mat-form-field class="w-full">
                      <mat-label>Razão Social</mat-label>
                      <input matInput value="{{ storeProviders?.name }}" readonly>
                    </mat-form-field>

                    <mat-form-field class="w-full">
                      <mat-label>Nome fantasia</mat-label>
                      <input matInput value="{{ storeProviders?.trading_name }}" readonly>
                    </mat-form-field>
                  </div>

                  <mat-form-field>
                    <mat-label>CNPJ</mat-label>
                    <input matInput value="{{ storeProviders?.cnpj | mask: 'CPF_CNPJ'}}" readonly>
                  </mat-form-field>

                  <div fxLayout.lt-md="column" fxLayoutGap="12px" fxLayout.md="column" fxLayout.lg="column"
                    fxLayout="row" fxLayoutAlign="space-between none">

                    <mat-form-field class="w-full">
                      <mat-label>Nome do usuário</mat-label>
                      <input matInput value="{{ storeProviders?.username }}" readonly>
                    </mat-form-field>

                    <mat-form-field class="w-full">
                      <mat-label>E-mail do usuário</mat-label>
                      <input matInput value="{{ storeProviders?.useremail }}" readonly>
                    </mat-form-field>
                  </div>
                </div>

                <div class="w-full" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column" fxLayout="column"
                  fxLayoutAlign="start">
                  <div fxLayoutGap="12px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column"
                    fxLayout="row" fxLayoutAlign="space-between none">
                    <mat-form-field class="w-full">
                      <mat-label>Endereço</mat-label>
                      <input matInput value="{{ storeProviders?.address?.street }}" readonly>
                    </mat-form-field>

                    <mat-form-field fxFlex="25">
                      <mat-label>Número</mat-label>
                      <input matInput value="{{ storeProviders?.address?.number }}" readonly>
                    </mat-form-field>
                  </div>
                  <div fxLayoutGap="12px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column"
                    fxLayout="row" fxLayoutAlign="space-between none">
                    <mat-form-field class="w-full">
                      <mat-label>Cidade</mat-label>
                      <input matInput value="{{ storeProviders?.address?.city }}" readonly>
                    </mat-form-field>
                    <mat-form-field fxFlex="18">
                      <mat-label>Estado</mat-label>
                      <input matInput value="{{ storeProviders?.address?.state }}" readonly>
                    </mat-form-field>
                  </div>

                  <div fxLayout.lt-md="column" fxLayoutGap="12px" fxLayout.md="column" fxLayout.lg="column"
                    fxLayout="row" fxLayoutAlign="space-between none">


                    <mat-form-field class="w-full">
                      <mat-label>Complemento</mat-label>
                      <input matInput value="{{ storeProviders?.address?.complement }}" readonly>
                    </mat-form-field>

                    <mat-form-field>
                      <mat-label>CEP</mat-label>
                      <input matInput value="{{ storeProviders?.address?.postal_code | mask: '99999-999' }}" readonly>
                    </mat-form-field>
                  </div>
                  <div fxLayoutGap="12px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column"
                    fxLayout="row" fxLayoutAlign="space-between none">
                    <mat-form-field class="w-full">
                      <mat-label>Status</mat-label>
                      <input matInput value="{{ storeProviders?.status }}" readonly>
                    </mat-form-field>

                    <mat-form-field class="w-full">
                      <mat-label>Criado em</mat-label>
                      <input matInput value="{{ storeProviders?.created_at | date : 'dd/MM/yyyy HH:mm:ss' }}" readonly>
                    </mat-form-field>
                  </div>

                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card class="mat-elevation-z6" style="margin-bottom: 25px"
            *ngFor="let element of storeProviders?.providers">
            <mat-card-content>
            <mat-card-subtitle style="font-size: 18px;">Dados do provedor</mat-card-subtitle>

              <div class="w-full" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column" fxLayout="column"
                fxLayoutAlign="start">
                <div fxLayoutGap="12px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column" fxLayout="row"
                  fxLayoutAlign="space-between none">
                    <mat-form-field class="w-full" *ngIf="element.pix != 'None'">
                      <mat-label>Provedor de Serviços de Pagamento (PSP)</mat-label>
                      <input matInput value="{{ element.pix }}" readonly>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                      <mat-label>Provedor</mat-label>
                      <input matInput value="{{ element.wallet_name }}" readonly>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                      <mat-label>Apelido</mat-label>
                      <input matInput value="{{ element.nickname }}" readonly>
                    </mat-form-field>

                    <mat-form-field *ngIf="element.pix == 'None'" class="w-full">
                      <mat-label>Conciliação via Equals</mat-label>
                      <input matInput value="{{ element.sendToEquals ? 'Sim': 'Não' }}" readonly>
                    </mat-form-field>

                    <mat-form-field class="w-full" *ngIf="element.pix != 'None' && element.status == 'Ativo'">
                      <mat-label>Prioridade do Provedor</mat-label>
                      <input matInput [value]="AdapterProviderPriority(element.priority)" readonly>
                    </mat-form-field>
                  </div>

                  <div fxLayoutGap="12px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column" fxLayout="row"
                  fxLayoutAlign="space-between none">
                  <mat-form-field class="w-full">
                    <mat-label>Data de criação</mat-label>
                    <input matInput value="{{ element.created_at | date:'dd/MM/yyyy HH:mm:ss' }}" readonly>
                  </mat-form-field>
                  <mat-form-field class="w-full">
                    <mat-label>Data de ativação</mat-label>
                    <input matInput value="{{ element.ActivatedAt | date:'dd/MM/yyyy HH:mm:ss' }}" readonly>
                  </mat-form-field>
                  <mat-form-field class="w-full">
                    <mat-label>Data da última atualização</mat-label>
                    <input matInput value="{{ element.last_updated | date:'dd/MM/yyyy HH:mm:ss' }}" readonly>
                  </mat-form-field>
                  </div>

                  

            <mat-card-subtitle style="font-size: 18px;">{{element.config.length > 1 ? 'Credenciais' : 'Credencial'}}</mat-card-subtitle>

                  <div fxLayoutGap="12px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout.lg="column" fxLayout="row"
                  fxLayoutAlign="space-between none">
                  <mat-form-field class="w-full" *ngFor="let i = index; let config of element.config">
                    <mat-label>{{getLabelName(config.key)}}</mat-label>
                    <input matInput type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="config.value" readonly>
                  </mat-form-field>

                </div>
              </div>

                <div fxLayoutAlign="center">
                  <b> Status: <span [ngClass]="element.status">{{ element.status }}</span></b>
                </div>

                <div *ngIf="element.pix != 'None' && element.status == 'Ativo'" fxLayoutAlign="center">
                  <b *ngIf="element.status_psp"> Situação do provedor: <span>{{ element.status_psp }}</span></b>
                  <div *ngIf="loadingPsp" class="m-6" fxLayoutAlign="center center">
                    <mat-spinner diameter="20"></mat-spinner>
                  </div>
                </div>

            </mat-card-content>
          </mat-card>


        </form>
      </mat-card>


    </div>
    <div class="mt-10">
      <button mat-raised-button color="primary" (click)="location.back()">Voltar</button>
    </div>
  </vex-page-layout-content>
</vex-page-layout>