<h1 *ngIf="!isEditMode" mat-dialog-title>Cadastro de Ticket</h1>
<h1 *ngIf="isEditMode" mat-dialog-title>Editar Ticket</h1>
<div mat-dialog-content>
    <form autocomplete="off" (submit)="save()" [formGroup]="form" fxLayout="column">
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Título</mat-label>
                <input type="text" formControlName="title" matInput [readonly]="isEditMode" maxlength="100">
                <mat-hint class="text-right w-full">{{form.get('title').value?.length || 0}}/100</mat-hint> 
                <mat-error *ngIf="form.get('title').hasError('required')">Título é obrigatório</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <mat-form-field *ngIf="!isEditMode" class="w-full" appearance="fill">
                <mat-label>Sistema/API</mat-label>
                <mat-select [(ngModel)]="selectedSystem" formControlName="system">
                    <mat-option *ngFor="let system of systems" [value]="system.systemId">
                        {{system.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('system').hasError('required')">Sistema/API é obrigatório</mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Data de início do evento</mat-label>
                <input [max]="maxDate" matInput [ngxMatDatetimePicker]="picker" placeholder="Data de termino do evento"
                    formControlName="dateTimeBegin" [readonly]="isEditMode">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker>
                </ngx-mat-datetime-picker>

                <mat-error *ngIf="form.get('dateTimeBegin').hasError('required')">Data é obrigatório</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isEditMode" class="w-full" appearance="fill">
                <mat-label>Data de termino do evento</mat-label>
                <input [max]="maxDate" matInput formControlName="dateTimeEnd" [matDatepicker]="datepicker"
                    [readonly]="isEditMode">
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker> </mat-datepicker>
                <mat-error *ngIf="form.get('dateTimeEnd').hasError('required')">Data é obrigatório</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Descrição</mat-label>
                <textarea type="text" formControlName="description" matInput [readonly]="isEditMode" maxlength="400"></textarea>
                <mat-hint class="text-right w-full">{{form.get('description').value?.length || 0}}/400</mat-hint> 
                <mat-error *ngIf="form.get('description').hasError('required')">Descrição obrigatório</mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!loading" class="mt-3 mb-1" mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
            <button mat-button mat-dialog-close>FECHAR</button>
            <button mat-raised-button type="submit" color="primary">CADASTRAR</button>
        </div>

        <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
        </div>
    </form>

</div>