import { Component, OnInit } from '@angular/core'
import { Crumb } from '../../models/crumb'
import icSearch from '@iconify/icons-ic/twotone-search'
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz'
import { FormControl } from '@angular/forms'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { PspDto } from '../../models/PspDto'
import { PspFilterDto } from '../../models/PspFilterDto'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'
import { PageEvent } from '@angular/material/paginator'
import { MonitoringService } from 'src/app/services/monitoring/monitoring.service'
import { PagedListDto } from '../../models/PagedListDto'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { EditPspComponent } from './dialogs/edit-psp/edit-psp.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'vex-psps',
  templateUrl: './psps.component.html',
  styleUrls: ['./psps.component.scss']
})
export class PspsComponent implements OnInit {
  breadcrumbs: Crumb[] = [{ name: 'Provedores', path: [''] }]

  icSearch = icSearch
  icMoreHoriz = icMoreHoriz

  loading = true

  searchCtrl: FormControl
  dataSource: MatTableDataSource<PspDto>

  displayedColumns: string[] = [
    'name',
    'warning',
    'status',
    'minTimeToAlert',
    'minPercentageToAlert',
    'resolvePspTimeWindow',
    'actions'
  ]
  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }
  filter: PspFilterDto = {
    pageNumber: this.paging.actualPage,
    pageSize: this.paging.pageSize
  }

  canList: boolean
  canEdit: boolean

  constructor (
    private readonly utilsService: UtilsService,
    private readonly monitoringService: MonitoringService,
    private readonly matSnackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.canEdit = this.utilsService.canShowElementByFunctionality('edit-psps')
    this.canList = this.utilsService.canShowElementByFunctionality('list-psps')
    this.dataSource = new MatTableDataSource()
    this.searchCtrl = new FormControl()
  }

  ngOnInit (): void {
    this.searchCtrl.valueChanges
      .pipe(debounceTime(500), startWith(''), distinctUntilChanged())
      .subscribe((val) => {
        if (!val.length || val.length >= 3) {
          this.filter.searchBy = val
          this.filter.pageNumber = 0
          this.paging.actualPage = 0
          this.fetchData()
        }
      })
  }

  fetchData (): void {
    this.loading = true
    this.dataSource.data = []

    this.monitoringService.getPspsPaged(this.filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<PspDto>>) => {
        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
        this.loading = false
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Erro ao buscar os dados!', '', {
          duration: 5000
        })
      }
    )
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.updateFilterObjectWithThePaging()
    this.fetchData()
  }

  updateFilterObjectWithThePaging (): void {
    this.filter.pageNumber = this.paging.actualPage
    this.filter.pageSize = this.paging.pageSize
  }

  editPsp (pspId: string): void {
    const psp = this.dataSource.data.filter((psp) => psp.pspId == pspId)[0]

    this.dialog
      .open(EditPspComponent, {
        disableClose: false,
        data: psp,
        width: '590px'
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && res.event === 'SAVED') {
          this.matSnackBar.open('Provedor atualizado com sucesso!', '', {
            duration: 5000
          })
          this.fetchData()
        } else if (res && res.event === 'ERROR')
          this.matSnackBar.open('Ocorreu um erro ao atualizar o provedor!', '', {
            duration: 5000
          })
      })
  }
}
