<vex-page-layout>
  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class=" mt-0 mb-1"><b>Automação</b></h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content class="-mt-6">
    <div class="card overflow-auto -mt-16">

      <form [formGroup]="form" autocomplete="off" fxLayout="column">

        <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutGap="20px"
          fxLayoutAlign="start center">
          <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
            <span>Lista de automações</span>
          </h2>
          
          <div class="bg-card rounded-full border" fxFlex="460px" fxFlex.lt-md="auto" fxHide.xs
            style="margin-top: -0.085vh;" fxLayout="row" fxLayoutAlign="start center">
            <ic-icon [icIcon]="icSearch" class="ml-2" size="25px"></ic-icon>
            <input [formControl]="searchCtrl" class="px-4 py-3 border-5 outline-none w-full bg-transparent"
              placeholder="Digite o nome da automação para filtrar" type="text">
              
          </div>
          <div *ngIf="loadingAutomationWithFilter" class="m-3" fxLayoutAlign="center center">
            <mat-spinner diameter="20"></mat-spinner>
          </div>
          <span fxFlex></span>

           <button (click)="fetchData()"  color="primary" fxFlex="none" mat-mini-fab matTooltip="Pesquisar" type="submit"> 
            <mat-icon [icIcon]="icSearch"></mat-icon>
          </button>

          <button (click)="openCreateSoftwarePdvDialog()" color="primary" fxFlex="none" mat-mini-fab matTooltip="Criar Automacao"
            type="button" title="Antes da inclusão de uma nova automação, utilize o filtro para verificar se ela já existe cadastrada">
            <mat-icon [icIcon]="icAdd"></mat-icon>
          </button>

          <button (click)="ExportTOExcel()" mat-raised-button color="primary" fxFlex="none" mat-mini-fab matTooltip="Pesquisar">
            <mat-icon [icIcon]="icDownload"></mat-icon>
          </button>
          
        </div>
         
      </form>

      <div id="tbAutomacaoList" fxLayout="column">
        <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
          <ng-container matColumnDef="softwarePdvName">
            <th mat-header-cell *matHeaderCellDef style="width: 110%;"> Automação </th>
            <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
          </ng-container>
          
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Editar </th>
            <td mat-cell *matCellDef="let element">
              <button (click)="openUpdateSoftwarePdvDialog(element)" >
                <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="loadingAutomation" class="m-6" fxLayoutAlign="center center">
          <mat-spinner diameter="36"></mat-spinner>
        </div>
      </div>
       <div class="center" fxLayoutAlign="center center"
        *ngIf="(!dataSource.data || dataSource.data.length === 0) && !loadingAutomation">
        NENHUMA AUTOMAÇÃO ENCONTRADA!
      </div>

      <mat-paginator [pageSize]="paging.pageSize" (page)="changePage($event)" [length]="paging.length"
        [pageSizeOptions]="[10, 50, 100]" aria-label="Select page">
      </mat-paginator>
    </div>
  </vex-page-layout-content>
</vex-page-layout>
