<h1 mat-dialog-title>{{dialogObj.dialogTitle}}</h1>

<div class="flex flex-col items-center">
  <div mat-dialog-content class="text-center mb-2" style="white-space: pre-line;">{{ dialogObj.dialogText }}</div>
  <ul class="text-center">
    <li *ngFor="let cnpj of dialogObj.cnpjList" class="my-2">{{ cnpj }}</li>
  </ul>
  <div mat-dialog-content class="text-center mt-1" style="white-space: pre-line;">{{ dialogObj.dialogTextEnd }}</div>
</div>

<div class="flex flex-col items-center">
  {{ dialogObj.dialogTextComplement }}
</div>
<div class="mt-3 mb-1 flex flex-col items-center" mat-dialog-actions  xLayout="row" fxLayoutAlign="space-around center">
  <button (click)="clickClose()" *ngIf="dialogObj?.dialogCloseText" mat-button
    mat-dialog-close>{{dialogObj?.dialogCloseText ?? 'Fechar'}}</button>
  <button (click)="clickConfirm()" mat-raised-button color="primary">{{dialogObj.dialogConfirmText}}</button>
</div>