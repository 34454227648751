<h1 mat-dialog-title>{{this.dataIn ?"Edite a automação":"Nova automação"}}</h1>
<div mat-dialog-content [formGroup]="softwarePdvForm">
  <mat-form-field appearance="fill">
    <mat-label>Automação</mat-label>
    <input maxlength="60" formControlName="name" matInput placeholder="Informe o nome da automação">
    <mat-error *ngIf="softwarePdvForm.get('name').hasError('required')">É necessário informar o nome da Automação</mat-error>
    <mat-hint class="text-right w-full">{{softwarePdvForm.get('name').value?.length || 0}}/60</mat-hint> 
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button (click)="clickCloseSuccess()">Fechar</button>
  <button mat-raised-button (click)="SaveSoftwarePdv()" color="primary">Salvar</button>
</div>
<div *ngIf="loadingCreateSoftwarePdv" class="m-3" fxLayoutAlign="center center">
    <mat-spinner diameter="20"></mat-spinner>
</div>