import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DetailTransactionDto } from 'src/app/layouts/client-layout/models/DetailTransactionDto'
import { DaysListDto } from 'src/app/layouts/client-layout/models/DaysListDto'
import { DefaultReturnDto } from 'src/app/layouts/client-layout/models/DefaultReturnDto'
import { DefaultReturnObjDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjDto'
import { FilterConsolidateDayDto } from 'src/app/layouts/client-layout/models/FilterConsolidateDayDto'
import { FilterConsolidateDto } from 'src/app/layouts/client-layout/models/FilterConsolidateDto'
import { PaymentCancelDto } from 'src/app/layouts/client-layout/models/PaymentCancelDto'
import { PaymentMethodDto } from 'src/app/layouts/client-layout/models/PaymentMethodDto'
import { WalletInfo } from 'src/app/layouts/client-layout/models/WalletInfoDto'
import { environment } from 'src/environments/environment'
import { UtilsService } from '../utils/utils.service'
import { LocalStorangeService } from '../local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  private baseUrl = environment.baseUrl
  constructor (
    private readonly utilsService: UtilsService,
    private readonly httpClient: HttpClient,
    private readonly localStorangeService: LocalStorangeService
  ) { }

  public getConsolidate ( 
    filters: FilterConsolidateDto
  ): Observable<DefaultReturnDto<DaysListDto>> {
    return this.httpClient.get<DefaultReturnDto<DaysListDto>>(
      `${this.baseUrl}/qrlinx-crud/v1/transaction/report?startDate=${filters.startDate}&endDate=${filters.endDate}&storeCnpj=${filters.storeCnpj}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  public getConsolidateByDay (
    filters: FilterConsolidateDayDto
  ): Observable<DefaultReturnDto<DaysListDto>> {
    const queryString = this.encodeQueryData(filters)
    return this.httpClient.get<DefaultReturnDto<DaysListDto>>(
      `${this.baseUrl}/qrlinx-crud/v1/transaction/report-detail-list?${queryString}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  public getPaymentById (
    paymentId: string
  ): Observable<DefaultReturnObjDto<DetailTransactionDto>> {
    return this.httpClient.get<DefaultReturnObjDto<DetailTransactionDto>>(
      `${this.baseUrl}/qrlinx-crud/v1/transaction/report-detail/${paymentId}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  public getWallets (): Observable<DefaultReturnDto<WalletInfo>> {
    return this.httpClient.get<DefaultReturnDto<WalletInfo>>(
      `${this.baseUrl}/qrlinx-crud/v1/wallet/search?status=Ativo`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  public getPaymentMethods (): Observable<DefaultReturnDto<PaymentMethodDto>> {
    return this.httpClient.get<DefaultReturnDto<PaymentMethodDto>>(
      `${this.baseUrl}/qrlinx-crud/v1/paymentmethods`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  cancelPayment (data: PaymentCancelDto): Observable<DefaultReturnObjDto<any>> {
    return this.httpClient.post<DefaultReturnObjDto<any>>(
      `${this.baseUrl}/qrlinx-crud/v1/payment/cancel`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  encodeQueryData (data): string {
    const ret = []
    for (const d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    return ret.join('&')
  }
}
