import { Injectable } from '@angular/core'
import { MatPaginatorIntl } from '@angular/material/paginator'

@Injectable()
export class PaginatorIntlPtBr extends MatPaginatorIntl {
  itemsPerPageLabel = 'Itens por página:'
  nextPageLabel = 'Próxima página'
  previousPageLabel = 'Página anterior'
  firstPageLabel = 'Primeira página'
  lastPageLabel = 'Última página'

  getRangeLabel = function (
    page: number,
    pageSize: number,
    length: number
  ): string {
    const start = page * pageSize + 1
    const end = (page + 1) * pageSize
    return `${start} - ${end} de ${length}`
  }
}
