import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import dateFormat from 'dateformat'
import { MonitoringService } from 'src/app/services/monitoring/monitoring.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { MonitorSystem } from '../../models/MonitorSystem'
import icAdd from '@iconify/icons-ic/twotone-add'
import icDelete from '@iconify/icons-ic/twotone-delete'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Ticket } from '../../models/ticket'

@Component({
  selector: 'vex-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent implements OnInit {
  maxDate: Date = new Date()
  loading: boolean
  icAdd = icAdd
  icDelete = icDelete
  form: FormGroup
  groups: MatTableDataSource<Ticket>
  isEditMode = false
  isFinishMode = false
  selectedSystem: string
  systems: MonitorSystem[]

  constructor (
    private readonly fb: FormBuilder,
    private readonly snackbar: MatSnackBar,
    private readonly monitoringService: MonitoringService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<CreateTicketComponent>,
    private readonly utilsService: UtilsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { ticket: Ticket }
  ) { }

  ngOnInit (): void {
    this.groups = new MatTableDataSource()
    this.loading = false
    this.getSystems()

    this.form = this.fb.group({
      ticketId: [''],
      status: [''],
      title: ['', Validators.required],
      system: ['', Validators.required],
      dateTimeBegin: [new Date(), Validators.required],
      dateTimeEnd: [''],
      description: ['', Validators.required]
    })

    if (this.data && this.data.ticket) {
      this.loading = true
      this.isEditMode = true
      this.monitoringService.getTicket(this.data.ticket.ticketId).subscribe((res) => {
        this.form = this.fb.group({
          ticketId: [res.data.ticketId],
          status: [res.data.status],
          title: [res.data.title, Validators.required],
          system: [res.data.system.name, Validators.required],
          dateTimeBegin: [dateFormat(res.data.dateTimeBegin, 'yyyy-mm-dd'), Validators.required],
          dateTimeEnd: [dateFormat(res.data.dateTimeEnd, 'yyyy-mm-dd'), Validators.required],
          description: [res.data.description, Validators.required]
        })
        this.loading = false
      })
    }
  }

  getSystems (): void {
    this.loading = true

    this.monitoringService.getSystems().subscribe((res) => {
      this.systems = res.data
      this.loading = false
    }, (err) => {
      this.loading = false
      this.snackbar.open(this.utilsService.getErrorMessage(err, 'Erro ao obter sistemas.'), '', {
        duration: 4000
      })
    })
  }

  save (): void {
    if (this.loading || !this.form.valid) return

    this.loading = true
    const data: Ticket = this.form.getRawValue()

    if (this.data && this.data.ticket) {
      this.monitoringService.patchTicket({
        dateTimeEnd: data.dateTimeEnd,
        status: data.status
      }, data.ticketId).subscribe(() => {
        this.loading = false
        this.snackbar.open('Ticket atualizado com sucesso!', '', {
          duration: 4000
        })
        return this.dialogRef.close()
      }, (err) => {
        this.loading = false
        this.snackbar.open(this.utilsService.getErrorMessage(err, 'Erro ao atualizar o ticket. Tente novamente!'), '', {
          duration: 4000
        })
      })
    } else {
      this.monitoringService.postTicket({
        dateTimeBegin: data.dateTimeBegin,
        description: data.description,
        systemId: this.selectedSystem,
        title: data.title
      }).subscribe(() => {
        this.loading = false
        this.snackbar.open('Ticket cadastrado com sucesso!', '', {
          duration: 4000
        })
        return this.dialogRef.close()
      }, (err) => {
        this.loading = false
        this.snackbar.open(this.utilsService.getErrorMessage(err, 'Erro ao cadastrar ticket. Tente novamente!'), '', {
          duration: 4000
        })
      })
    }
  }
}
