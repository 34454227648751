import { SelectionModel } from '@angular/cdk/collections'
import { formatDate } from '@angular/common'
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import iconAdd from '@iconify/icons-ic/baseline-add'
import iconRemoveBaseLine from '@iconify/icons-ic/baseline-remove'
import icSearch from '@iconify/icons-ic/twotone-search'
import { Icon } from '@visurel/iconify-angular'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component'
import { Crumb } from '../../../models/crumb'
import { DefaultReturnObjDto } from '../../../models/DefaultReturnObjDto'
import { EventType } from '../../../models/EventType'
import { PagedListDto } from '../../../models/PagedListDto'
import { PixDto } from '../../../models/PixDto'
import { BrandDto } from '../../../models/BrandDto'
import cep from 'cep-promise'
import icDoneAll from '@iconify/icons-ic/twotone-done-all'
import {
  CreateProviderDto,
  GetStoresProvider,
  ProviderConfigurations,
  ProviderDto,
  ProviderEvent
} from '../../../models/ProviderDto'
import { ProviderEventType } from '../../../models/ProviderEventType'
import { ProviderFilter } from '../../../models/ProviderFIlter'
import { StoreProviders } from '../../../models/stores'
import { WalletDto } from '../../../models/WalletDto'
import { TestPaymentsComponent } from './dialog/test-payments/test-payments.component'
import { MercadoPagoAuthorizationComponent } from './dialog/mercado-pago-authorization/mercado-pago-authorization.component'
import { ValidarCredenciaisComponent } from './dialog/validar-credenciais/validar-credenciais.component'
import { MessageWithListComponent } from '../../../dialogs/message-with-list/message-with-list.component'
import { UtilsService } from 'src/app/services/utils/utils.service'
import {
  getPspList,
  getProviderType,
  getSegmentList,
  getCategoriesList,
  getTypesList
} from './info-data'
import { MatStepper } from '@angular/material/stepper'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'
import { PayerDocumentComponent } from './dialog/payer-document/payer-document.component'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { environment } from 'src/environments/environment'
import { SegmentDto } from '../../../models/SegmentDto'
import { GenericValidator } from 'src/app/utils/genericValidator'
import { ProviderDocumentComponent } from './dialog/provider-document/provider-document.component'
import { fieldsStone } from 'src/app/utils/fieldsStone'

@Component({
  selector: 'vex-create-provider',
  templateUrl: './create-provider.component.html',
  styleUrls: ['./create-provider.component.scss'],
  animations: [stagger40ms]
})
export class CreateProviderComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper
  icDoneAll = icDoneAll
  iconRemoveBaseLine = iconRemoveBaseLine
  loading = false
  loadingCep = false
  loadingCnpj = false
  loadingStore = true
  testWasSuccess = false
  eventName: ProviderEventType
  eventProvider = ProviderEventType
  icSearch = icSearch
  iconAdd = iconAdd
  hasChangePage = false
  dataSource: MatTableDataSource<StoreProviders>
  displayedColumns: string[] = [
    'select',
    'cnpj',
    'brand',
    'pix',
    'wallets',
    'routerActive'
  ]
  progressBarValue = 0
  selection = new SelectionModel<StoreProviders>(true, [])
  primaryStepForm: FormGroup
  stoneStepForm: FormGroup
  searchCtrl: FormControl = new FormControl('')
  providerId: string
  providerSecondaryId = null
  statusProvider: string
  accountDocument: string
  providers: ProviderEvent = { events: [] }
  providerStatus: 'active' | 'inactive'
  wallets: Array<WalletDto>
  brands: Array<BrandDto>
  walletsToShow: Array<WalletDto>
  isCard = false
  showWalletSelect = false
  showPixSelect = false
  showStores = false
  showProviderSecondary = false
  hasSecondaryPix = false
  hideButtons = false
  walletPix: string
  storeAccountFind: Array<{ accountId: string; pixKey: string }> = []
  providerConfig: ProviderConfigurations[] = []
  conciliationConfig: ProviderConfigurations[] = []
  providerConfigSecondary: ProviderConfigurations[] = []
  providerConfigFromEdit: Array<{ key: string; value: string }> = []
  providerConfigSecondaryFromEdit: Array<{ key: string; value: string }> = []
  pixPsp: Array<PixDto> = getPspList().sort((a, b) =>
    a.name > b.name ? 1 : -1
  )
  segments: Array<SegmentDto> = getSegmentList()
  categories: Array<string> = getCategoriesList()
  types: Array<string> = getTypesList()
  pixPspSecondaryList: Array<PixDto> = []
  storeProviders: Array<GetStoresProvider> = []
  notAllowedStores: Array<StoreProviders> = []
  providerTypes: Array<{ type: string; name: string }> = getProviderType()
  credentialsValid = new FormControl('', Validators.required)
  conciliationValid = new FormControl('', Validators.required)
  storesValid = new FormControl('', Validators.required)
  providerValid = new FormControl('', Validators.required)
  stoneValid = new FormControl('', Validators.required)
  pagarmeValid = new FormControl('', Validators.required)
  pagarmeAccountInfo: any
  readonlyInfo: any = null
  attemptsToCreateTestPayment = 0
  paging = { pageSize: 10, length: 0, actualPage: 1 }
  allSelected = false
  isStone = false
  exclusiveStone = false
  breadcrumbs: Crumb[] = [
    { name: 'Provedores de Pagamentos', path: ['provider'] },
    { name: this._eventProvider(), path: [this.router.url] }
  ]
  primaryProviderHasRecipients:boolean
  secondaryProviderHasRecipients:boolean
  showSkipTest = true
  cnpjMPToCopyToken = ''

  constructor (
    private readonly accreditationService: AccreditationService,
    private readonly utilsService: UtilsService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly matSnackBar: MatSnackBar
  ) {
    this.dataSource = new MatTableDataSource()
    this.providerId = this.activatedRoute?.snapshot?.params?.id
    this.searchCtrl.valueChanges
      .pipe(debounceTime(500), startWith(''), distinctUntilChanged())
      .subscribe((val) => {
        if (!val.length || val.length >= 3) {
          this.filterStores()
        }
      })
  }

  backPage (): void {
    this.router.navigateByUrl('/provider')
  }

  canChange (): boolean {
    if (
      (this.providerId ||
        this.router.url.startsWith('/provider/edit-provider')) &&
      this.f.integrationTypePix.value == 12
    ) {
      return false
    }

    return true
  }

  isAllowedPagarMeByName (name: string): boolean {
    const brand = this.brands.find(item => item.name === name)
    return brand ? brand.allowPagarMe : false
  }

  removeIntegrationByName (name: string): void {
    const index = this.pixPsp.findIndex(integration => integration.name === name)
    if (index !== -1) {
      this.pixPsp.splice(index, 1)
    }
  }

  isAllowedPagarMe (items: Array<BrandDto>): boolean {
    return items.some(item => item.allowPagarMe)
  }

  ngOnInit (): void {
    this.eventName = this._eventProvider()
    this.primaryStepForm = this.fb.group({
      nickname: new FormControl('', Validators.required),
      walletId: new FormControl(''),
      integrationTypePix: new FormControl(''),
      integrationTypePixSecondary: new FormControl(''),
      priority: new FormControl(''),
      acceptTerms: new FormControl(false),
      isPix: new FormControl('', [Validators.required]),
      sendToEquals: new FormControl(false, [Validators.required]),
      hasStoneAccount: new FormControl(null),
      hasPagarmeAccount: new FormControl(null)
    })

    this.stoneStepForm = this.fb.group({
      store: this.fb.group(
        {
          document: new FormControl('', [
            Validators.required,
            GenericValidator.isValidCnpj()
          ]),
          companyName: new FormControl('', [
            Validators.required,
            Validators.maxLength(255)
          ]),
          corporateName: new FormControl('', [
            Validators.required,
            Validators.maxLength(255)
          ]),
          segment: new FormControl(fieldsStone.segment, Validators.required),
          subSegment: new FormControl(
            fieldsStone.subSegment,
            Validators.required
          ),
          accountName: new FormControl(fieldsStone.accountName, [
            Validators.required,
            Validators.maxLength(255)
          ])
        },
        {
          validators: [Validators.required]
        }
      ),
      contact: this.fb.group(
        {
          type: new FormControl(fieldsStone.type, Validators.required),
          name: new FormControl(fieldsStone.name, [
            Validators.required,
            Validators.maxLength(255)
          ]),
          email: new FormControl(fieldsStone.email, [
            Validators.required,
            Validators.email,
            Validators.maxLength(255)
          ]),
          phone: new FormControl(fieldsStone.phone, Validators.required),
          birthday: [
            formatDate(fieldsStone.birthday, 'yyyy-MM-dd', 'en'),
            [Validators.required, GenericValidator.isEighteenYearsOld()]
          ],
          mothersName: new FormControl(fieldsStone.mothersName, [
            Validators.required,
            Validators.maxLength(255)
          ])
        },
        {
          validators: [Validators.required]
        }
      ),
      address: this.fb.group(
        {
          zipcode: new FormControl(fieldsStone.zipcode, Validators.required),
          state: new FormControl(fieldsStone.state, [
            Validators.required,
            Validators.maxLength(30)
          ]),
          city: new FormControl(fieldsStone.city, [
            Validators.required,
            Validators.maxLength(40)
          ]),
          neighborhood: new FormControl(fieldsStone.neighborhood, [
            Validators.required,
            Validators.maxLength(50)
          ]),
          street: new FormControl(fieldsStone.street, [
            Validators.required,
            Validators.maxLength(255)
          ]),
          number: new FormControl(fieldsStone.number, [
            Validators.required,
            Validators.maxLength(10)
          ]),
          complement: new FormControl(
            fieldsStone.complement,
            Validators.maxLength(50)
          )
        },
        {
          validators: [Validators.required]
        }
      )
    })

    this._getWallets()
    this._getBrandData()

    this.accreditationService.getExclusiveStoneStores().subscribe((res) => {
      const { notExclusiveStone, exclusiveStone } = res.data

      if (notExclusiveStone > 0) {
        this.exclusiveStone = false
      } else if (exclusiveStone > 0 && notExclusiveStone === 0) {
        this.exclusiveStone = true
        this.pixPsp = this.pixPsp.filter((psp => psp.exclusiveStone === this.exclusiveStone))
      }
    })
  }

  hideConfigValue (): void {
    if (this.f.integrationTypePix.value == 12 || this.f.integrationTypePix.value == 4) {
      for (const config of this.providerConfig) {
        const stringArray = [
          config.value.slice(0, 4),
          config.value.slice(config.value.length - 3),
          config.value.slice(4, config.value.length - 3)
        ]
        const arr = Array.from('*'.repeat(stringArray[2].length))
        const hiddenValue = stringArray[0] + arr.join('') + stringArray[1]
        config.hiddenValue = hiddenValue
      }
    }

    if (this.f.integrationTypePixSecondary.value == 12 || this.f.integrationTypePixSecondary.value == 4) {
      for (const config of this.providerConfigSecondary) {
        const stringArray = [
          config.value.slice(0, 4),
          config.value.slice(config.value.length - 3),
          config.value.slice(4, config.value.length - 3)
        ]
        const arr = Array.from('*'.repeat(stringArray[2].length))
        const hiddenValue = stringArray[0] + arr.join('') + stringArray[1]
        config.hiddenValue = hiddenValue
      }
    }
  }

  updateSegmentCat (event: { value: string }): void {
    const segment = this.segments.find((e) => e.name == event.value)
    this.categories = segment.category
  }

  getAddressInfo (): void {
    const zipcode = this.stoneStepForm.get('address.zipcode').value

    if (!zipcode || this.loadingCep) return

    this.loadingCep = true

    cep(zipcode)
      .then((response) => {
        this.loadingCep = false
        if (response.state) this.stoneStepForm.get('address.state').disable()

        this.stoneStepForm.get('address.state').setValue(response.state)

        if (response.city) this.stoneStepForm.get('address.city').disable()

        this.stoneStepForm.get('address.city').setValue(response.city)

        if (response.neighborhood)
          this.stoneStepForm.get('address.neighborhood').disable()

        this.stoneStepForm
          .get('address.neighborhood')
          .setValue(response.neighborhood)

        if (response.street)
          this.stoneStepForm.get('address.street').disable()

        this.stoneStepForm.get('address.street').setValue(response.street)
      })
      .catch(() => {
        this.loadingCep = false

        this.stoneStepForm.get('address.state').disable()
        this.stoneStepForm.get('address.city').disable()
        this.stoneStepForm.get('address.neighborhood').disable()
        this.stoneStepForm.get('address.street').disable()

        this.matSnackBar.open(
          'Não foi possível encontrar o CEP informado!',
          '',
          { duration: 4000 }
        )
      })
  }

  goToCredentials (): void {
    if (!this.primaryStepForm.valid) return

    if (!this.f.acceptTerms.value && !this.providerId) {
      return this.openConfirmDialog()
    }

    // if (this._eventProvider() == ProviderEventType.edit && this.hasSecondaryPix && !this.providerSecondaryId) {
    //   if (this.f.integrationTypePixSecondary.value == 7 || this.f.integrationTypePixSecondary.value == 12 || 
    //     this.f.integrationTypePix.value == 7 || this.f.integrationTypePix.value == 12) {
    //     this.matSnackBar.open(
    //       'Para utilizar o MultiPsp com Pagar.me/Stone, caso seja um provedor já existente, você deve criar um novo provedor!',
    //       '',
    //       { duration: 10000 }
    //     )
    //     return
    //   }
    // }

    this.hideConfigValue()

    this.providerValid.setValue(true)
    this.stepper.next()
  }

  checkPropertyNotFilled (): boolean {
    const propertyNotFill = Object.values(this.providerConfig).find(
      (e) => !e.value && !e.isNullabe
    )

    if (this.hasSecondaryPix) {
      const propertyNotFillSecondary = Object.values(
        this.providerConfigSecondary
      ).find((e) => !e.value && !e.isNullabe)

      if (propertyNotFillSecondary && !propertyNotFillSecondary.isNullabe)
        return true
    }

    return propertyNotFill && !propertyNotFill.isNullabe
  }

  checkPropertyPagarmeNotFilled (): boolean {
    return !this.stoneStepForm.valid
  }

  checkPropertyConciliationNotFilled (): boolean {
    const propertyNotFill = Object.values(this.conciliationConfig).find(
      (e) => !e.value && !e.isNullabe
    )

    return propertyNotFill && !propertyNotFill.isNullabe
  }

  goToStores (): void {
    const propertyNotFill = Object.values(this.providerConfig).find(
      (e) => !e.value && !e.isNullabe
    )
    if (propertyNotFill && !propertyNotFill.isNullabe) {
      this.matSnackBar.open(
        `Você deve preencher a ${this.getLabelName(propertyNotFill.name)}!`,
        '',
        { duration: 4000 }
      )

      return
    }
    
    this.credentialsValid.setValue(true)
    this.stepper.next()
  }

  onFinishPagarmeForm (event: any): void {
    this.pagarmeAccountInfo = event
    this.pagarmeValid.setValue(true)
    this.hideButtons = false
    this.stepper.next()
  }

  goToNextStone (): void {
    if (!this.stoneStepForm.valid) return

    this.stoneValid.setValue(true)
    this.stepper.next()
  }

  goToNext (): void {
    const propertyNotFill = Object.values(this.conciliationConfig).find(
      (e) => !e.value && !e.isNullabe
    )

    if (propertyNotFill && !propertyNotFill.isNullabe) {
      this.matSnackBar.open(
        `Você deve preencher a ${this.getLabelName(propertyNotFill.name)}!`,
        '',
        { duration: 4000 }
      )

      return
    }
    this.conciliationValid.setValue(true)
    this.stepper.next()
  }

  onChange (isConciliation: boolean): void {
    this.testWasSuccess = false
    this.attemptsToCreateTestPayment = 0
    if (isConciliation) {
      this.checkPropertyConciliationNotFilled()
    } else {
      this.checkPropertyNotFilled()
    }
  }

  private _eventProvider (): ProviderEventType {
    return this.router.url == '/provider/create-provider'
      ? this.eventProvider.create
      : this.eventProvider.edit
  }

  getLabelName (name: string): string {
    return this.utilsService.capWords(name.replace('_', ' '))
  }

  public isPix (): boolean {
    return this.primaryStepForm.get('isPix')?.value == 1
  }

  public addSecondProvider (): void {
    this.hasSecondaryPix = true

    this.pixPspSecondaryList = this.pixPsp.filter(
      (e) => e.integrationType != this.f.integrationTypePix.value
    )

    this.cdRef.detectChanges()
    this.primaryStepForm
      .get('integrationTypePixSecondary')
      .addValidators([Validators.required])
    this.primaryStepForm.controls[
      'integrationTypePixSecondary'
    ].updateValueAndValidity()
  }

  public removeSecondProvider (): void {
    this.hasSecondaryPix = false
    this.cdRef.detectChanges()
    this.primaryStepForm
      .get('integrationTypePixSecondary')
      .removeValidators([Validators.required])
    this.primaryStepForm.controls[
      'integrationTypePixSecondary'
    ].updateValueAndValidity()
  }

  public getPspNameByIntegrationTypePix (integrationTypePix: number): string {
    return (
      this.pixPsp.find((e) => e.integrationType == integrationTypePix)?.name ??
      ''
    )
  }

  public checkToOpenPixSecondary (): void {
    if (!this.f.integrationTypePixSecondary.value) return

    const isPix = this.isPix()

    //this._checkisStone()

    this.accreditationService
      ._getDefaultConfigProviders(
        isPix ? this.walletPix : this.f.walletId.value,
        isPix ? this.f.integrationTypePixSecondary.value : 'None'
      )
      .subscribe((res) => {
        this.providerConfigSecondary = res.data

        if (this.providerConfigSecondaryFromEdit) {
          this.providerConfigSecondaryFromEdit.forEach((element) => {
            const provider = this.providerConfigSecondary.find((e) => {
              return e.name == element.key
            })

            if (provider) provider.value = element.value
          })
        }
      })
  }

  public checkToOpenPix (): void {
    this.isStone = false
    const isPix = this.isPix()

    if (this.f.integrationTypePix.value == 12) {
      this.isStone = true
    }

    this.primaryStepForm.get('integrationTypePixSecondary').setValue(null)
    this.primaryStepForm
      .get('integrationTypePixSecondary')
      .removeValidators([Validators.required])
    this.primaryStepForm.controls[
      'integrationTypePixSecondary'
    ].updateValueAndValidity()

    if (this.hasSecondaryPix) {
      this.pixPspSecondaryList = getPspList().filter(
        (e) => e.integrationType != this.f.integrationTypePix.value
      )
      this.cdRef.detectChanges()
      this.primaryStepForm
        .get('integrationTypePixSecondary')
        .addValidators([Validators.required])
      this.primaryStepForm.controls[
        'integrationTypePixSecondary'
      ].updateValueAndValidity()

      if (this.f.integrationTypePixSecondary.value) {
        if (this.f.integrationTypePixSecondary.value == 12) {
          this.isStone = true
        }
        this.accreditationService
          ._getDefaultConfigProviders(
            isPix ? this.walletPix : this.f.walletId.value,
            isPix ? this.f.integrationTypePixSecondary.value : 'None'
          )
          .subscribe((res) => {
            this.providerConfigSecondary = res.data

            if (this.providerConfigSecondaryFromEdit) {
              this.providerConfigSecondaryFromEdit.forEach((element) => {
                const provider = this.providerConfigSecondary.find(
                  (e) => e.name == element.key
                )

                if (provider) provider.value = element.value
              })
            }
          })
      }
    }

    //this._checkisStone()

    this.accreditationService
      ._getDefaultConfigProviders(
        isPix ? this.walletPix : this.f.walletId.value,
        isPix ? this.f.integrationTypePix.value : 'None'
      )
      .subscribe((res) => {
        this.conciliationConfig = res.data.filter((e) => e.conciliation)
        this.providerConfig = res.data.filter(
          (e) => !e.conciliation && !e.isBoolean
        )

        if (this.providerConfigFromEdit) {
          this.providerConfigFromEdit.forEach((element) => {
            const provider = this.providerConfig.find((e) => {
              return e.name == element.key
            })

            if (provider) provider.value = element.value

            const conciliation = this.conciliationConfig.find((e) => {
              return e.name == element.key
            })

            if (conciliation) conciliation.value = element.value
          })
        }
      })
  }

  showProviderSecondaryCard (status: boolean): void {
    this.showProviderSecondary = status
  }

  removeProviderSecondary (): void {
    this.testWasSuccess = false
    this.hasSecondaryPix = false
    this.showProviderSecondaryCard(false)
  }

  addProviderSecondary (): void {
    this.testWasSuccess = false
    this.hasSecondaryPix = true
    this.showProviderSecondaryCard(true)
  }

  public checkToOpenPixOrWallet (event: { value: string }): void {
    this.isCard = false

    if (event.value == '0') {
      this.testWasSuccess = false
      this.showWalletSelect = true
      this.showPixSelect = false

      this.walletsToShow = this.wallets.filter(e => e.integrationType != 'Card')

      this.cdRef.detectChanges()

      this.primaryStepForm
        .get('integrationTypePix')
        .removeValidators([Validators.required])
      this.primaryStepForm.get('walletId').addValidators([Validators.required])

      this.primaryStepForm.controls[
        'integrationTypePix'
      ].updateValueAndValidity()
      this.primaryStepForm.controls['walletId'].updateValueAndValidity()
      this.removeProviderSecondary()
    }

    if (event.value == '1') {
      this.testWasSuccess = false
      this.showPixSelect = true
      this.showWalletSelect = false

      this.cdRef.detectChanges()
      this.primaryStepForm
        .get('walletId')
        .removeValidators([Validators.required])
      this.primaryStepForm
        .get('integrationTypePix')
        .addValidators([Validators.required])
      this.primaryStepForm.controls[
        'integrationTypePix'
      ].updateValueAndValidity()
      this.primaryStepForm.controls['walletId'].updateValueAndValidity()
      this.f.walletId.setValue(null)
    }

    // if (event.value == '2') {
    //   this.isCard = true
    //   this.showWalletSelect = true
    //   this.walletsToShow = this.wallets.filter(e => e.integrationType == 'Card')
    //   this.showPixSelect = false
    //   this.cdRef.detectChanges()
    //   this.primaryStepForm
    //     .get('integrationTypePix')
    //     .removeValidators([Validators.required])
    //   this.primaryStepForm.get('walletId').addValidators([Validators.required])

    //   this.primaryStepForm.controls[
    //     'integrationTypePix'
    //   ].updateValueAndValidity()

    //   this.primaryStepForm.controls['walletId'].updateValueAndValidity()
    //   this.removeProviderSecondary()
    // }
  }

  public checkPixConfig (event: { value: string }): void {
    this.accreditationService
      ._getDefaultConfigProviders(this.walletPix, event.value ?? 'None')
      .subscribe((res) => {
        this.conciliationConfig = []
        this.providerConfig = res.data
        if (this.providerConfigFromEdit.length) {
          this.providerConfigFromEdit.forEach((element) => {
            const provider = this.providerConfig.find((e) => {
              return e.name == element.key
            })

            if (provider) provider.value = element.value
          })
        }
      })
  }

  public checkPagarmeAccount (): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.isPix() || (this.f.integrationTypePix.value != 7 && this.f.integrationTypePixSecondary.value != 7)) {
        return resolve()
      }

      this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          dialogTitle: 'Você já possui conta Pagar.me?',
          dialogText: '',
          dialogCloseText: 'NÃO',
          dialogConfirmText: 'SIM'
        }
      })
        .afterClosed()
        .subscribe((res) => {
          if (res.event == 'CONFIRM') {
            this.dialog
              .open(ProviderDocumentComponent, { disableClose: true, data: { name: 'Pagar.me' } })
              .afterClosed()
              .subscribe((res) => {
                this.loading = true
                if (!res?.document) {
                  this.loading = false
                  return reject()
                }

                this.accountDocument = res?.document
                this.accreditationService
                  .getPagarmeAccount(res.document)
                  .subscribe(
                    (res) => {
                      if (res.data.secretKey != null) {
                        if (this.f.integrationTypePixSecondary?.value == 7) {
                          this.providerConfigSecondary.find(e => e.name == 'pix_key').value = res.data.secretKey
                        } else {
                          this.providerConfig.find(e => e.name == 'pix_key').value = res.data.secretKey
                        }
                      }
                      this.loading = false
                      this.f.hasPagarmeAccount.setValue(res.data.secretKey != null)
                      return resolve()
                    },
                    (err) => {
                      this.loading = false
                      this.showStoneError(err)
                      this.f.hasPagarmeAccount.setValue(false)
                      return resolve()
                    }
                  )
              })
          } else {
            this.f.hasPagarmeAccount.setValue(false)
            return resolve()
          }
        })
    })
  }

  public checkStoneAccount (): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.isPix() || this.f.integrationTypePix.value != 12) {
        return resolve()
      }

      this.hideButtons = false

      this.dialog
        .open(ProviderDocumentComponent, { disableClose: true, data: { name: 'Stone' } })
        .afterClosed()
        .subscribe((res) => {
          this.loading = true
          if (!res?.document) {
            this.loading = false
            return reject()
          }

          this.stoneStepForm.get('store.document').setValue(res.document)

          this.accreditationService
            .hasStoneAccount(this.stoneStepForm.get('store.document').value)
            .subscribe(
              () => {
                this.loading = false
                this.stoneStepForm.enable()
                this.f.hasStoneAccount.setValue(false)
                return resolve()
              },
              (err) => {
                this.loading = false
                this.showStoneError(err)
                return reject('Erro')
              }
            )
        })
    })
  }

  private showStoneError (err): void {
    this.stoneStepForm.disable()
    this.hideButtons = true
    this.stoneStepForm.get('store.document').enable()

    this.matSnackBar.open(
      this.utilsService.getErrorMessage(
        err,
        'Não foi possível consultar a conta Stone!'
      ),
      '',
      { duration: 12000 }
    )
  }

  private checkToOpenPagarmeBox (provider: ProviderDto): void {
    if (provider.integrationTypePix != 7) return

    if (
      provider.accreditation != null &&
      provider?.accreditation?.status != 'FINISHED'
    ) {
      this.readonlyInfo = provider.accreditation
      this.pagarmeValid.setValue(true)
      this.providerValid.setValue(true)
      this.f.hasPagarmeAccount.setValue(false)
      this.primaryStepForm.disable()

      this.hideButtons = true
      return
    }

    this.f.hasPagarmeAccount.setValue(true)
  }

  private checkToOpenStoneBox (provider: ProviderDto): void {
    if (provider.integrationTypePix != 12) return

    if (
      provider.accreditation != null &&
      provider?.accreditation?.status != 'FINISHED'
    ) {
      //this._checkisStone()

      this.stoneValid.setValue(true)
      this.providerValid.setValue(true)
      this.f.hasStoneAccount.setValue(false)
      this.primaryStepForm.disable()

      this.stoneStepForm
        .get('store.document')
        .setValue(provider?.accreditation?.document)
      this.stoneStepForm
        .get('store.companyName')
        .setValue(provider?.accreditation?.tradingName)
      this.stoneStepForm
        .get('store.corporateName')
        .setValue(provider?.accreditation?.companyName)
      this.stoneStepForm
        .get('store.segment')
        .setValue(provider?.accreditation?.segment)

      this.stoneStepForm
        .get('store.subSegment')
        .setValue(provider?.accreditation?.category)
      this.stoneStepForm
        .get('store.accountName')
        .setValue(provider?.accreditation?.accountName)

      this.stoneStepForm
        .get('contact.type')
        .setValue(provider?.accreditation?.contact?.type)
      this.stoneStepForm
        .get('contact.name')
        .setValue(provider?.accreditation?.contact.name)
      this.stoneStepForm
        .get('contact.email')
        .setValue(provider?.accreditation?.contact?.email)
      this.stoneStepForm
        .get('contact.phone')
        .setValue(provider?.accreditation?.contact?.phone)
      this.stoneStepForm
        .get('contact.birthday')
        .setValue(provider?.accreditation?.contact?.birthday)
      this.stoneStepForm
        .get('contact.mothersName')
        .setValue(provider?.accreditation?.contact?.mothersName)

      this.stoneStepForm
        .get('address.zipcode')
        .setValue(provider?.accreditation?.address?.zipcode)
      this.stoneStepForm
        .get('address.state')
        .setValue(provider?.accreditation?.address?.state)
      this.stoneStepForm
        .get('address.city')
        .setValue(provider?.accreditation?.address?.city)
      this.stoneStepForm
        .get('address.number')
        .setValue(provider?.accreditation?.address?.number)
      this.stoneStepForm
        .get('address.neighborhood')
        .setValue(provider?.accreditation?.address?.neighborhood)
      this.stoneStepForm
        .get('address.street')
        .setValue(provider?.accreditation?.address?.street)
      this.stoneStepForm
        .get('address.complement')
        .setValue(provider?.accreditation?.address?.complement)

      this.hideButtons = true

      this.stoneStepForm.disable()

      return
    }

    this.f.hasStoneAccount.setValue(true)
    if (provider.status == 'Inativo') {
      this.isStone = false
    }
  }

  private _getProviderById (): void {
    this.accreditationService.getProviderByid(this.providerId).subscribe(
      (res: DefaultReturnObjDto<ProviderDto[]>) => {
        this.hasSecondaryPix = res.data.length > 1
        this.primaryProviderHasRecipients = res.data[0].hasRecipients
        this.loading = false
        if (res.data[0].wallet.integrationType == 'Card') {
          this.walletsToShow = this.wallets.filter(e => e.integrationType == 'Card')
        }
        this.statusProvider = res.data[0].status
        this.f.walletId.setValue(res.data[0].walletId)
        this.f.nickname.setValue(res.data[0].nickname)
        this.f.acceptTerms.setValue(true)
        this.f.sendToEquals.setValue(res.data[0].sendToEquals)

        this.providerStatus =
          res.data[0].status == 'Ativo' ? 'active' : 'inactive'

        this.f.integrationTypePix.setValue(
          res.data[0].integrationTypePix != 0
            ? res.data[0].integrationTypePix
            : null
        )

        this.f.isPix.setValue(
          res.data[0].walletId == this.walletPix ? '1' : '0'
        )

        if (res.data[0].storeProviders.length) {
          this.storeProviders = res.data[0].storeProviders
        }
        this.providerConfigFromEdit = res.data[0].providerConfig

        this.checkToOpenPixOrWallet({
          value: res.data[0].walletId == this.walletPix ? '1' : res.data[0].wallet.integrationType == 'Card' ? '2' : '0'
        })

        this.checkToOpenPix()

        if (this.hasSecondaryPix) {
          this.secondaryProviderHasRecipients = res.data[1].hasRecipients
          this.f.integrationTypePixSecondary.setValue(
            res.data[1].integrationTypePix
          )
          this.providerConfigSecondaryFromEdit = res.data[1].providerConfig
          this.providerSecondaryId = res.data[1].id
          this.checkToOpenPixSecondary()
        }

        this._getNoAllowedStores()

        for (const provider of this.storeProviders) {
          for (const rowData of this.dataSource.data) {
            if (provider.storeId === rowData.id) {
              if (!this.selection.isSelected(rowData)) {
                this.selection.select(rowData)
                //get the first cnpj selected to use for coping its token to other
                if (this.cnpjMPToCopyToken == '')
                  this.cnpjMPToCopyToken = rowData.cnpj
              }
            }
          }
        }

        this.providers = {
          events: []
        }

        if (res.data[0]?.createdAtUtc)
          this.eventHistory('CREATE', res.data[0].createdAtUtc)

        const accreditation = res.data[0]?.accreditation || res.data[1]?.accreditation

        if (
          (res.data[0]?.integrationTypePix == 12 ||
            res.data[1]?.integrationTypePix == 12) && (accreditation && accreditation?.status != 'FINISHED')
        ) {
          this.eventHistory(
            'PROCESSING-STONE',
            res.data[0]?.accreditation?.createdAtUtc ??
            res.data[0]?.accreditation?.updatedAtUtc ??
            res.data[1]?.accreditation?.createdAtUtc ??
            res.data[1]?.accreditation?.updatedAtUtc
          )
        }

        if (
          (res.data[0]?.integrationTypePix == 7 ||
            res.data[1]?.integrationTypePix == 7) && (accreditation && accreditation?.status != 'FINISHED')
        ) {
          this.eventHistory(
            'PROCESSING-PAGARME',
            res.data[0]?.accreditation?.createdAtUtc ??
            res.data[0]?.accreditation?.updatedAtUtc ??
            res.data[1]?.accreditation?.createdAtUtc ??
            res.data[1]?.accreditation?.updatedAtUtc
          )
        }
        if (res.data[0]?.validatedAtUtc)
          this.eventHistory('VALIDATED', res.data[0]?.validatedAtUtc)

        if (
          this.statusProvider != 'Criado' &&
          res.data[0]?.activatedAtUtc
        ) {
          this.eventHistory('ACTIVE', res.data[0]?.activatedAtUtc)
          this.providerValid.setValue(true)
          this.credentialsValid.setValue(true)
        }

        if (this.statusProvider == 'Inativo')
          this.eventHistory('INACTIVE', res.data[0]?.updatedAtUtc)

        this.checkToOpenStoneBox(res.data[0])
        this.checkToOpenPagarmeBox(res.data[0])

        if (res.data[1]) {
          this.checkToOpenStoneBox(res.data[1])
          this.checkToOpenPagarmeBox(res.data[1])
        }
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Provedor não encontrado.', '', {
          duration: 5000
        })
        this.backPage()
      }
    )
  }

  private _getStoresPaged (filter: ProviderFilter): void {
    this.loadingStore = true
    this.accreditationService.getStoresProvidersPaged(filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<StoreProviders>>) => {
        this.loadingStore = false
        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems

        const itemsToAdd: any = this.dataSource.data.filter(
          (item: StoreProviders) => {
            const foundItem: any =
              this.selection.selected.find(
                (selectedItem) => selectedItem.id === item.id
              ) ||
              this.storeProviders.find(
                (selectedItem) => selectedItem.storeId === item.id
              )

            if (!foundItem) return

            this.selection.deselect(foundItem)
            const storeProvider = this.storeProviders.find(
              (selectedItem) => selectedItem.storeId === item.id
            )

            if (storeProvider) {
              const index = this.storeProviders.indexOf(storeProvider)
              this.storeProviders.splice(index, 1)
            }

            return item
          }
        )

        if (itemsToAdd && itemsToAdd.length > 0) {
          this.hasChangePage = false

          itemsToAdd.forEach((element) => {
            const storeProvider = this.storeProviders.find(
              (selectedItem) => selectedItem.storeId === element.id
            )

            if (!storeProvider) {
              this.storeProviders.push({
                storeId: element.id
              })
            }
            this.selection.select(element)
          })
        }

        if (itemsToAdd && itemsToAdd.length === this.dataSource.data.length) {
          this.allSelected = true
        }
      },
      () => {
        this.loadingStore = false
        this.matSnackBar.open('Nenhum estabelecimento encontrado!', '', {
          duration: 5000
        })
      }
    )
  }

  private _getNoAllowedStores (): void {

    this.accreditationService.getStoresProvidersPaged(
      {
        pageNumber: 1,
        pageSize: this.storeProviders.length,
        searchBy: '',
        providerId:
          this._eventProvider() == ProviderEventType.edit ? this.providerId : ''
      }
    ).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<StoreProviders>>) => {
        this.notAllowedStores = res.data.pageItems.filter((e) => !this.isAllowedPagarMeByName(e.brand))
      }
    )
  }

  private _getBrandData (): Promise<void> {
    return new Promise((resolve) => {
      this.accreditationService
        .getBrands()
        .subscribe((res: DefaultReturnObjDto<Array<BrandDto>>) => {
          this.brands = res.data
          if (this.brands !== undefined && !this.isAllowedPagarMe(this.brands)) {
            this.removeIntegrationByName('Pagar.me')
          }
          resolve()
        })
    })
  }

  private _getWallets (): void {
    this.loading = true
    this.accreditationService.getWallets().subscribe(
      (res: DefaultReturnObjDto<Array<WalletDto>>) => {
        this.wallets = res.data.filter((item) => item.cnpj != '00000000000000')

        const pix = res.data.filter((item) => item.cnpj == '00000000000000')
        this.walletPix = pix != null && pix.length ? pix[0].id : 'ERRO'

        if (this.eventName == this.eventProvider.edit) {
          return this._getProviderById()
        }

        this.loading = false
      },
      () => {
        this.wallets = []
        this.matSnackBar.open(
          'Não foi possível listar as Carteiras Digitais',
          '',
          { duration: 4000 }
        )
      }
    )
  }

  invertProviders (): void {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        dialogTitle: 'Confirmar inversão de provedores?',
        dialogText: 'Atenção: Essa ação irá inverter o cadastro dos provedores de pagamento, sendo assim, a loja passará a transacionar com o provedor secundário como primário. Caso esteja ciente da alteração clique em Confirmar.',
        dialogConfirmText: 'CONFIRMAR',
        dialogCloseText: 'CANCELAR'
      }
    }).afterClosed().subscribe(res => {
      if (res.event === 'CONFIRM') {
        this.loading = true
        this.accreditationService
          .invertPriorities(this.providerId).toPromise()
          .then((result) => {
            this.loading = false
            this.matSnackBar.open(
              'Inversão de provedores enviada para processamento com sucesso! Consulte novamente em alguns minutos.',
              '',
              {
                duration: 4000
              }
            )
            this.backPage()
          })
          .catch(() => {
            this.loading = false
            this.matSnackBar.open('Não foi possível inverter os provedores!', '', {
              duration: 4000
            })
          })
      }
    })
  }

  openDialogDisableActiveProvider (event: MatSlideToggleChange): void {
    if (this.loading) return

    const statusMessage = event.checked ? 'ativar' : 'desativar'

    this.providerStatus = event.checked ? 'active' : 'inactive'

    const dialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        dialogTitle:
          'Tem certeza que deseja ' + statusMessage + ' esse provedor?',
        dialogCloseText: 'Não',
        dialogConfirmText: 'Sim, continuar'
      }
    })

    dialog.afterClosed().subscribe((result) => {
      if (result.event == 'CONFIRM') return this._disableProvider()

      this.providerStatus = !event.checked ? 'active' : 'inactive'
    })
  }

  private _checkIfHasStoreStoneGroup (): Promise<void> {
    return new Promise((resolve, reject) => {
      const data = this.primaryStepForm.getRawValue()
      if (!data.integrationTypePix) {
        return resolve()
      }

      this.accreditationService
        .checkIfHasStoreStoneGroup({
          IntegrationTypePix: data.integrationTypePix,
          StoreProviders: this?.storeProviders?.map((e) => e?.storeId) ?? []
        })
        .subscribe(
          () => {
            if (this.hasSecondaryPix) {
              this.accreditationService
                .checkIfHasStoreStoneGroup({
                  IntegrationTypePix: data.integrationTypePixSecondary,
                  StoreProviders:
                    this?.storeProviders?.map((e) => e?.storeId) ?? []
                })
                .subscribe(
                  () => {
                    return resolve()
                  },
                  (err) => {
                    return reject(err)
                  }
                )
            }

            return resolve()
          },
          (err) => {
            return reject(err)
          }
        )
    })
  }

  private _disableProvider () {
    this.loading = true

    if (this.eventName != this.eventProvider.edit) return

    this._checkIfHasStoreStoneGroup()
      .then(
        async () => {
          this.accreditationService
            .cleanStoreProvider({
              primaryId: this.providerId,
              secondaryId: this.providerSecondaryId ?? ''
            })
            .subscribe(
              async () => {
                const promises = []
                const storeProvidersToSend =
                  this?.storeProviders?.map((e) => e?.storeId) ?? []
                const chunkSize =0 ?? 5
                for (
                  let i = 0;
                  i < storeProvidersToSend.length;
                  i += chunkSize
                ) {
                  const chunkStoreProvider = storeProvidersToSend.slice(
                    i,
                    i + chunkSize
                  )

                  const data = this.primaryStepForm.getRawValue()
                  const dataToSave: CreateProviderDto[] = [
                    this._factoryProvider(
                      data,
                      'Primary',
                      this.providerStatus == 'active' ? 'Ativo' : 'Inativo',
                      this.providerId,
                      [
                        ...this.providerConfig,
                        ...(this.conciliationConfig ?? [])
                      ],
                      this.isPix() ? data.integrationTypePix : '0',
                      chunkStoreProvider ?? []
                    )
                  ]

                  if (this.hasSecondaryPix) {
                    dataToSave.push(
                      this._factoryProvider(
                        data,
                        'Secondary',
                        this.providerStatus == 'active' ? 'Ativo' : 'Inativo',
                        this.providerSecondaryId,
                        this.providerConfigSecondary,
                        data.integrationTypePixSecondary ?? null,
                        chunkStoreProvider ?? []
                      )
                    )
                  }

                  promises.push(
                    await this.accreditationService
                      .updateProvider(dataToSave)
                      .toPromise()
                      .catch((err) => {
                        this.loading = false

                        this.providerStatus = 'inactive'
                        this.matSnackBar.open(
                          this.utilsService.getErrorMessage(
                            err,
                            'Não foi possível desabilitar o provedor'
                          ),
                          '',
                          {
                            duration: 4000
                          }
                        )

                        throw new Error('Não foi possível salvar o provedor')
                      })
                  )
                }

                Promise.all(promises)
                  .then(() => {
                    this.loading = false
                    return this.router.navigateByUrl('/provider')
                  })
                  .catch(() => {
                    this.loading = false

                    this.matSnackBar.open(
                      'Não foi possível desabilitar o provedor',
                      '',
                      {
                        duration: 4000
                      }
                    )
                  })
              },
              () => {
                this.loading = false

                this.matSnackBar.open(
                  'Não foi possível desabilitar o provedor',
                  '',
                  {
                    duration: 4000
                  }
                )
              }
            )
        },
        () => {
          this.loading = false

          this.matSnackBar.open('Não foi possível desabilitar o provedor', '', {
            duration: 4000
          })
        }
      )
      .catch(() => {
        this.matSnackBar.open('Não foi possível desabilitar o provedor', '', {
          duration: 4000
        })
      })
  }

  openMultiPspNotActiveDialog (cnpjList: string[]): void {
    let message = null
    if (cnpjList.length > 5) {
      message = ' e mais ' + (cnpjList.length - 5) + ' ...'
    }

    this.dialog.open(MessageWithListComponent, {
      disableClose: true,
      data: {
        dialogTitle:
          'Uma ou mais lojas selecionadas não estão habilitadas para o Roteamento MultiPsp',
        dialogText: 'São elas:',
        dialogTextEnd: message,
        dialogTextComplement:
          'Por favor, entre em contato com o seu responsável comercial para habilitar o serviço!',
        dialogConfirmText: 'Ok',
        cnpjList: cnpjList.length > 5 ? cnpjList.slice(0, 5) : cnpjList
      }
    })

  }
  openStoresWhithSameKeyDialog (): Promise<string> {

    return new Promise<string>((resolve) => {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          dialogTitle: 'Compartilhamento de Chave PIX entre estabelecimentos!',
          dialogText:
            `<div class="text-center" style="text-align: center">Alguns estados brasileiros possuem decretos fiscais que obrigam o CNPJ da loja ser o mesmo que é exibido no comprovante do cliente, as orientações variam de cada estado, porém caso a loja emita um comprovante com o CNPJ diferente isso irá gerar impacto junto a Receita Federal.
          <br>Os estados que possuem alguma restrição são: AL, DF, MA, MT, PB, PE, PI, RN, RS, RR.<br>
          Este provedor possui mais de um estabelecimento vinculado à mesma chave PIX, verifique se o seu estado não possui nenhuma restrição quanto a isso e caso esteja de acordo clique em <b>Continuar</b>.</div>`,
          dialogConfirmText: 'Continuar',
          dialogCloseText: 'Voltar'
        }
      })

      dialog.afterClosed().subscribe((result) => {
        if (result && result.event === 'CONFIRM') {
          resolve('CONFIRM')
        } else {
          resolve('NOTCONFIRMED')
        }
      })
    })
  }

  get f (): any {
    return this.primaryStepForm.controls
  }

  get pagarmeForm (): any {
    return this.stoneStepForm.controls
  }

  filterStores (): void {
    if (!this.searchCtrl.valid) return

    this._getStoresPaged({
      pageNumber: this.paging.actualPage,
      pageSize: this.paging.pageSize,
      searchBy: this.searchCtrl.value,
      providerId:
        this._eventProvider() == ProviderEventType.edit ? this.providerId : ''
    })
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1

    this.hasChangePage = true
    this.allSelected = false

    this._getStoresPaged({
      pageNumber: this.paging.actualPage,
      pageSize: this.paging.pageSize,
      searchBy: this.searchCtrl.value,
      providerId:
        this._eventProvider() == ProviderEventType.edit ? this.providerId : ''
    })
  }

  isAllSelected (): boolean {
    return this.allSelected
  }

  masterToggle (): void {
    this.testWasSuccess = false

    if (this.allSelected) {
      this.dataSource.data.forEach((row) => {
        this.selection.deselect(row)

        const storeFind = this.storeProviders.find((e) => e.storeId == row.id)

        if (storeFind) {
          const index = this.storeProviders.indexOf(storeFind)
          this.storeProviders.splice(index, 1)
        }

        if (!this.isAllowedPagarMeByName(row.brand)) {
          const index = this.notAllowedStores.indexOf(row)
          this.notAllowedStores.splice(index, 1)
        }
      })
      this.allSelected = false
      return
    }

    this.dataSource.data.forEach((row) => {
      this.selection.select(row)
      const storeFind = this.storeProviders.find((e) => e.storeId == row.id)

      if (!storeFind) {
        this.storeProviders.push({ storeId: row.id })
      }

      if (!this.isAllowedPagarMeByName(row.brand)) {
        const storeFind = this.notAllowedStores.find((e) => e.id == row.id)
        if (!storeFind) {
          this.notAllowedStores.push(row)
        }
      }
    })
    this.allSelected = true
  }

  checkIfHaveAtLeastOneStoreSelected (): boolean {
    return this.selection.selected.length == 0
  }

  eventHistory (event: string, transactionDate: string): void {
    if (transactionDate && !transactionDate.endsWith('Z')) {
      transactionDate += 'Z'
    }

    this.providers.events.push({
      event: event,
      transactionDate: transactionDate
    })
  }

  openTestPaymentDialog (clientDocument?: string, isPedePronto = false): void {
    if (
      this.hasSecondaryPix &&
      this.selection?.selected?.filter((e) => !e.routerActive)?.length
    ) {
      return this.openMultiPspNotActiveDialog(
        this.selection.selected
          .filter((e) => !e.routerActive)
          .map((e) => this.utilsService.getCnpjWithMask(e?.cnpj))
      )
    }

    if (this.attemptsToCreateTestPayment < 3) {
      this.testWasSuccess = false
      this.dialog
        .open(TestPaymentsComponent, {
          data: {
            isPedePronto,
            clientDocument: clientDocument ? clientDocument : null,
            disableClose: true,
            walletId: this.isPix() ? this.walletPix : this.f.walletId.value,
            providerId: this.providerId,
            providerSecondaryId: this.hasSecondaryPix
              ? this.providerSecondaryId
              : null,
            storeProviders: [...this.storeProviders].map(
              (s) => <GetStoresProvider>{ storeId: s.storeId }
            )
          }
        })
        .afterClosed()
        .subscribe((result) => {
          if (result && result.event == 'SUCCESS') {
            this.testWasSuccess = true
            this.attemptsToCreateTestPayment = 0
          } else if (result && result.event == 'CLOSE') {
            this.attemptsToCreateTestPayment += 1
            if (this.attemptsToCreateTestPayment >= 3)
              this.dialog.open(ValidarCredenciaisComponent)
          }
        })
    } else {
      this.dialog.open(ValidarCredenciaisComponent)
    }
  }

  finish (): void {
    if (this.loading) return

    if (
      this.hasSecondaryPix &&
      this.selection?.selected?.filter((e) => !e.routerActive)?.length
    ) {
      return this.openMultiPspNotActiveDialog(
        this.selection.selected
          .filter((e) => !e.routerActive)
          .map((e) => this.utilsService.getCnpjWithMask(e?.cnpj))
      )
    }

    this.loading = true
    const data = this.primaryStepForm.getRawValue()
    this.progressBarValue = 0

    const message =
      this.eventName == this.eventProvider.create ? 'cadastrado' : 'atualizado'

    this._checkIfHasStoreStoneGroup()
      .then(() => {
        this.accreditationService
          .cleanStoreProvider({
            primaryId: this.providerId,
            secondaryId: this.providerSecondaryId ?? ''
          })
          .subscribe(
            async () => {
              const promises = []
              const storeProvidersToSend =
                this?.storeProviders?.map((e) => e?.storeId) ?? []
              const chunkSize = 0 ?? 5

              let qtdToProgress = Math.round(
                storeProvidersToSend.length / chunkSize
              )
              for (let i = 0; i < storeProvidersToSend.length; i += chunkSize) {
                const chunkStoreProvider = storeProvidersToSend.slice(
                  i,
                  i + chunkSize
                )
                const dataToSave: CreateProviderDto[] = [
                  this._factoryProvider(
                    data,
                    'Primary',
                    'Ativo',
                    this.providerId,
                    [
                      ...this.providerConfig.filter(
                        (e) => e?.value != null && e?.value != ''
                      ),
                      ...(this.conciliationConfig.filter(
                        (e) => e?.value != null && e?.value != ''
                      ) ?? [])
                    ],
                    this.isPix() ? data.integrationTypePix : '0',
                    chunkStoreProvider
                  )
                ]

                if (this.hasSecondaryPix) {
                  dataToSave.push(
                    this._factoryProvider(
                      data,
                      'Secondary',
                      'Ativo',
                      this.providerSecondaryId,
                      this.providerConfigSecondary,
                      data.integrationTypePixSecondary ?? null,
                      chunkStoreProvider
                    )
                  )
                }

                if (!this.hasSecondaryPix && this.providerSecondaryId) {
                  dataToSave.push(
                    this._factoryProvider(
                      data,
                      'Secondary',
                      'Inativo',
                      this.providerSecondaryId,
                      this.providerConfigSecondary,
                      data.integrationTypePixSecondary ?? null,
                      chunkStoreProvider
                    )
                  )
                }

                if (this.isMercadoPagoWallet()) {
                  promises.push(
                    await this.accreditationService
                      .updateProviderMP(dataToSave, this.cnpjMPToCopyToken)
                      .toPromise()
                      .then(() => {
                        this.progressBarValue = Math.round(100 / qtdToProgress)
                        qtdToProgress--
                      })
                      .catch((err) => {
                        this.matSnackBar.open(
                          this.utilsService.getErrorMessage(
                            err,
                            `Não foi possível ${message} o cadastro do provedor`
                          ),
                          '',
                          { duration: 4000 }
                        )
                        this.loading = false

                        throw new Error('Não foi possível salvar o provedor')
                      })
                  )
                } else {
                  promises.push(
                    await this.accreditationService
                      .updateProvider(dataToSave)
                      .toPromise()
                      .then(() => {
                        this.progressBarValue = Math.round(100 / qtdToProgress)
                        qtdToProgress--
                      })
                      .catch((err) => {
                        this.matSnackBar.open(
                          this.utilsService.getErrorMessage(
                            err,
                            `Não foi possível ${message} o cadastro do provedor`
                          ),
                          '',
                          { duration: 4000 }
                        )
                        this.loading = false

                        throw new Error('Não foi possível salvar o provedor')
                      })
                  )
                }
              }

              Promise.all(promises)
                .then(() => {
                  this.matSnackBar.open(
                    `Provedor ${message} com sucesso!`,
                    '',
                    {
                      duration: 4000
                    }
                  )

                  this.loading = false

                  return this.router.navigateByUrl('/provider')
                })
                .catch(() => {
                  this.matSnackBar.open(
                    `Não foi possível ${message} o cadastro do provedor`,
                    '',
                    { duration: 4000 }
                  )
                  this.loading = false
                })
            },
            () => {
              this.matSnackBar.open(
                `Não foi possível ${message} o cadastro do provedor`,
                '',
                { duration: 4000 }
              )
              this.loading = false
            }
          )
      })
      .catch(() => {
        this.matSnackBar.open(
          `Não foi possível ${message} o cadastro do provedor`,
          '',
          {
            duration: 4000
          }
        )
      })
  }

  skipPaymentTest (): void {
    if (
      this.hasSecondaryPix &&
      this.selection?.selected?.filter((e) => !e.routerActive)?.length
    ) {
      return this.openMultiPspNotActiveDialog(
        this.selection.selected
          .filter((e) => !e.routerActive)
          .map((e) => this.utilsService.getCnpjWithMask(e?.cnpj))
      )
    }

    const message =
      this.eventName == this.eventProvider.create
        ? 'concluir a criação do provedor'
        : 'atualizar o provedor'

    const dialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        dialogTitle: 'Pular cobrança teste',
        dialogText: `<div class="text-center" style="text-align: center">Ao pular a cobrança teste você garante que as credenciais <br> estão corretas e não possui necessidade de realizar essa verificação! <br> 
          <b>Deseja continuar e ${message}?</b></div>`,
        dialogCloseText: 'Não',
        dialogConfirmText: 'Sim'
      }
    })

    dialog.afterClosed().subscribe((result) => {
      if (result.event == 'CONFIRM') {
        this.testWasSuccess = true
        this.attemptsToCreateTestPayment = 0

        this.finish()
      }
    })
  }

  openConfirmDialog (): void {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        dialogTitle: 'Termo de consentimento',
        dialogText:
          'Precisamos da sua autorização para compartilhamento de dados de contato e do estabelicimento com o provedor selecionado. <br>Isso é necessário para o funcionamento da integração de pagamento via QR Code. <br>Para maiores informações, acesse nossa Politica de Privacidade',
        dialogCloseText: 'Não autorizo',
        dialogConfirmText: 'Autorizo'
      }
    })

    dialog.afterClosed().subscribe((result) => {
      if (result.event == 'CONFIRM') {
        // if (this.f.integrationTypePix.value == 7 || this.f.integrationTypePixSecondary.value == 7) {
        //   this.checkPagarmeAccount().then(() => {
        //     this.createProvider().then(() => {
        //       this.f.acceptTerms.value = true
        //       this.providerValid.setValue(true)
        //       this.stepper.next()
        //     })
        //   })
        // } else {
        this.checkStoneAccount().then(() => {
          this.createProvider().then(() => {
            this.f.acceptTerms.value = true
            this.providerValid.setValue(true)
            this.stepper.next()
          })
        })
        // }
      } else {
        this.f.acceptTerms.value = false
        this.providerValid.setValue(null)
      }
    })
  }

  openPagarMeBlockDialog (cnpjList: string[]): void {
    let message = null
    if (cnpjList.length > 5) {
      message = ' e mais ' + (cnpjList.length - 5) + ' ...'
    }

    const dialog = this.dialog.open(MessageWithListComponent, {
      disableClose: true,
      data: {
        dialogTitle:
          'O credenciamento Pagar.me não é permitido para as seguintes lojas:',
        dialogText: '',
        dialogTextEnd: message,
        dialogTextComplement:
          'Deseja remover essas lojas e avançar ?',
        dialogConfirmText: 'REMOVER',
        dialogCloseText:'CANCELAR',
        cnpjList: cnpjList.length > 5 ? cnpjList.slice(0, 5) : cnpjList
      }
    })

    dialog.afterClosed().subscribe((result) => {
      if (result.event == 'CONFIRM') {
        this.removeNotAllowedStores()
        this.nextStepToSave()
      }
    })
  }

  openMercadoPagoAutorizationDialog (): void {
    this.testWasSuccess = false
    this.dialog
      .open(MercadoPagoAuthorizationComponent, {
        data: {
          disableClose: true,
          providerId: this.providerId,
          clientId: this.providerConfig.find((f) => f.name == 'client_id').value
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.event == 'SUCCESS') this.openTestPaymentDialog()
      })
  }

  startTestPaymentFlow (): void {
    if (this.isMercadoPagoWallet()) {
      this.openMercadoPagoAutorizationDialog()
    } else {
      if (this.isPedeProntoWallet()) {
        this.dialog
          .open(PayerDocumentComponent)
          .afterClosed()
          .subscribe((res) => {
            this.openTestPaymentDialog(res.document, true)
          })

        return
      }

      this.openTestPaymentDialog()
    }
  }

  isHubElo (): boolean {
    const selectedWallet = this._getSelectedWallet()
    if (selectedWallet == null) return false

    return selectedWallet.cnpj == this.utilsService.getHubEloDocument()
  }

  isGroupStone (integrationType): boolean {
    return integrationType == 12
  }

  isPedeProntoWallet (): boolean {
    const selectedWallet = this._getSelectedWallet()
    if (selectedWallet == null) return false

    return selectedWallet.cnpj == this.utilsService.getPedeProntoDocument()
  }

  isMercadoPagoWallet (): boolean {
    const selectedWallet = this._getSelectedWallet()
    if (selectedWallet == null) return false

    return selectedWallet.cnpj == this.utilsService.getMercadoPagoDocument()
  }

  getEventIcon (event: string): Icon {
    return this.utilsService.getEventIcon(event.toString())
  }

  createAccreditation (bodyToSend?: any): void {
    if (this.loading) return

    const data = bodyToSend ?? this.stoneStepForm.getRawValue()

    let providerId = this.providerId
    if (this.f.integrationTypePixSecondary.value === 7) {
      providerId = this.providerSecondaryId
    }

    let dataToSend: any = {
      providerId: providerId,
      secondProviderId: this.providerSecondaryId ?? null,
      document: data.store.document.replace(/\D/g, ''),
      accountName: data.store.accountName,
      tradingName: data.store.companyName,
      companyName: data.store.corporateName,
      segment: data.store.segment,
      category: data.store.subSegment,
      address: {
        street: data.address.street,
        zipcode: data.address.zipcode.replace('-', ''),
        number: data.address.number,
        complement: data.address.complement,
        state: data.address.state,
        city: data.address.city,
        neighborhood: data.address.neighborhood
      },
      contact: {
        type: data.contact.type,
        name: data.contact.name,
        email: data.contact.email,
        phone: data.contact.phone,
        birthday: data.contact.birthday,
        mothersName: data.contact.mothersName
      }
    }

    if (data.bank) {
      dataToSend = {
        ...dataToSend, bank: {
          type: bodyToSend.bank.type,
          bankCode: bodyToSend.bank.bankCode,
          accountNumber: bodyToSend.bank.accountNumber,
          accountAgency: bodyToSend.bank.accountAgency,
          accountDigit: bodyToSend.bank.accountDigit
        }
      }
    }

    this.loading = true
    this.accreditationService.createPagarmeAccreditation(dataToSend).subscribe(
      (response) => {
        this.loading = false

        if ((!this.f.hasStoneAccount.value && (this.f.integrationTypePix.value === 12 || this.f.integrationTypePixSecondary.value === 12)) ||
          (!this.f.hasPagarmeAccount.value && (this.f.integrationTypePix.value === 7 || this.f.integrationTypePixSecondary.value === 7))) {
          if (this.f.integrationTypePixSecondary.value === 12 || this.f.integrationTypePixSecondary.value === 7) {
            response.data.config.forEach((element) => {
              if (element.value) {
                const provider = this.providerConfigSecondary.find((e) => {
                  return e.name == element.key
                })

                if (provider) provider.value = element.value
              }
            })
          } else {
            response.data.config.forEach((element) => {
              if (element.value) {
                const provider = this.providerConfig.find((e) => {
                  return e.name == element.key
                })

                if (provider) provider.value = element.value
              }
            })
          }
        }

        this.saveProvider('Criado')
      },
      (err) => {
        this.loading = false
        this.matSnackBar.open(
          this.utilsService.getErrorMessage(
            err,
            'Não foi possível salvar a solicitação da conta Stone!'
          ),
          '',
          {
            duration: 4000
          }
        )
      }
    )
  }

  isSelectedNotAllowedStore (): boolean {
    if (!Array.isArray(this.notAllowedStores) || this.notAllowedStores.length === 0) {
      return false
    }
    return true
  }

  removeNotAllowedStores (): void {
    if (!Array.isArray(this.notAllowedStores) || this.notAllowedStores.length === 0) {
      return
    }

    this.notAllowedStores.forEach(provider => {
      const index = this.selection.selected.findIndex(store => store.id === provider.id)
      if (index !== -1) {
        this.allSelected = false
        this.selection.deselect(this.selection.selected[index])
      }

      const storeIndex = this.storeProviders.findIndex((selectedItem) => selectedItem.storeId === provider.id)

      if (storeIndex !== -1) {
        this.storeProviders.splice(storeIndex, 1)
      }
    })

    this.notAllowedStores = []
  }

  nextStepToSave (): void {
    this.showSkipTestButton()

    if (this.isPix() && this.f.integrationTypePix.value === 7 && this.isSelectedNotAllowedStore()) {
      return this.openPagarMeBlockDialog(
        this.notAllowedStores
          .map((e) => this.utilsService.getCnpjWithMask(e?.cnpj))
      )
    }

    if (
      this.hasSecondaryPix &&
      this.selection?.selected?.filter((e) => !e.routerActive)?.length
    ) {
      return this.openMultiPspNotActiveDialog(
        this.selection.selected
          .filter((e) => !e.routerActive)
          .map((e) => this.utilsService.getCnpjWithMask(e?.cnpj))
      )
    }

    if ((!this.f.hasStoneAccount.value && (this.f.integrationTypePix.value === 12 || this.f.integrationTypePixSecondary.value === 12))
    // ||
    // (!this.f.hasPagarmeAccount.value && (this.f.integrationTypePix.value === 7 || this.f.integrationTypePixSecondary.value === 7))
    ) {
      this.createAccreditation(this.pagarmeAccountInfo)
      return
    }

    if (this.isHubElo()) {
      return this.finish()
    }

    if (this.isPix() && this.selection?.selected?.length > 1) {
      this.openStoresWhithSameKeyDialog().then((res: string) => {
        if (res == 'CONFIRM') {
          this.saveProvider()
        }
      })
    } else {
      this.saveProvider()
    }
  }

  createProvider (): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loading = true

      const data = this.primaryStepForm.getRawValue()

      const dataToSave: CreateProviderDto[] = [
        this._factoryProvider(
          data,
          'Primary',
          'Criado',
          this.providerId,
          [],
          this.isPix() ? data.integrationTypePix : '0',
          this?.storeProviders?.map((e) => e?.storeId) ?? []
        )
      ]

      if (this.hasSecondaryPix)
        dataToSave.push(
          this._factoryProvider(
            data,
            'Secondary',
            'Criado',
            this.providerSecondaryId,
            this.providerConfigSecondary,
            data.integrationTypePixSecondary ?? null,
            this?.storeProviders?.map((e) => e?.storeId) ?? []
          )
        )

      this.saveProviderService(dataToSave)
        .then(() => {
          this.storesValid.setValue(true)
          this.stepper.next()

          return resolve()
        })
        .catch(() => reject())
    })
  }

  private _factoryProvider (
    data: CreateProviderDto,
    priority: string,
    status: string,
    providerId: string,
    providerConfig: ProviderConfigurations[] = [],
    integrationTypePix: string,
    storeProviders: string[]
  ): CreateProviderDto {
    return {
      id: providerId,
      acceptTerms: data.acceptTerms,
      nickname: data.nickname,
      walletId: data.walletId ?? this.walletPix,
      priority: priority,
      integrationTypePix: integrationTypePix,
      providerConfigurations: providerConfig,
      storeProviders: storeProviders,
      status: status,
      sendToEquals: data.sendToEquals
    }
  }

  saveProvider (status = 'Criado'): void {
    if (this.loading) return

    this._openDialogStoreHaveProviderActive().then((res: string) => {
      if (res == 'CONFIRM') {
        this.loading = true

        const storeProviderList = [
          ...this.providerConfig.filter(
            (e) => e?.value != null && e?.value != ''
          ),
          ...(this.conciliationConfig.filter(
            (e) => e?.value != null && e?.value != ''
          ) ?? [])
        ]

        const promises = []

        this.progressBarValue = 0
        this._checkIfHasStoreStoneGroup()
          .then(() => {
            this.accreditationService
              .cleanStoreProvider({
                primaryId: this.providerId,
                secondaryId: this.providerSecondaryId ?? ''
              })
              .subscribe(
                async (res) => {
                  const storeProvidersToSend =
                    this?.storeProviders?.map((e) => e?.storeId) ?? []
                  const chunkSize = 0 ?? 5

                  let qtdToProgress = Math.round(
                    storeProvidersToSend.length / chunkSize
                  )

                  for (
                    let i = 0;
                    i < storeProvidersToSend.length;
                    i += chunkSize
                  ) {
                    const chunkStoreProvider = storeProvidersToSend.slice(
                      i,
                      i + chunkSize
                    )

                    const data = this.primaryStepForm.getRawValue()
                    const dataToSave: CreateProviderDto[] = [
                      this._factoryProvider(
                        data,
                        'Primary',
                        status,
                        this.providerId,
                        storeProviderList,
                        this.isPix() ? data.integrationTypePix : '0',
                        chunkStoreProvider
                      )
                    ]

                    if (this.hasSecondaryPix)
                      dataToSave.push(
                        this._factoryProvider(
                          data,
                          'Secondary',
                          status,
                          this.providerSecondaryId,
                          this.providerConfigSecondary,
                          data.integrationTypePixSecondary ?? null,
                          chunkStoreProvider
                        )
                      )

                    if (!this.hasSecondaryPix && this.providerSecondaryId) {
                      dataToSave.push(
                        this._factoryProvider(
                          data,
                          'Secondary',
                          'Inativo',
                          this.providerSecondaryId,
                          this.providerConfigSecondary,
                          data.integrationTypePixSecondary ?? null,
                          chunkStoreProvider
                        )
                      )
                    }

                    promises.push(
                      await this.accreditationService
                        .updateProvider(dataToSave)
                        .toPromise()
                        .then(() => {
                          this.progressBarValue = Math.round(
                            100 / qtdToProgress
                          )
                          qtdToProgress--
                        })
                        .catch((err) => {
                          this.loading = false

                          this.matSnackBar.open(
                            this.utilsService.getErrorMessage(
                              err,
                              'Não foi possível salvar o provedor'
                            ),
                            '',
                            {
                              duration: 4000
                            }
                          )

                          throw new Error('Não foi possível salvar o provedor')
                        })
                    )
                  }

                  Promise.all(promises)
                    .then(() => {
                      if (status === 'Criado' && this.isGroupStone(this.f.integrationTypePix.value)) {
                        this.matSnackBar.open(
                          `Criação da conta ${this.f.integrationTypePix.value == 12 ? 'Stone' : 'Pagar.me'} solicitada com sucesso! Assim que a conta estiver disponível notificaremos por e-mail para conclusão do cadastro do provedor.`,
                          '',
                          {
                            duration: 4000
                          }
                        )

                        return this.router.navigateByUrl('/provider')
                      }

                      if (this.isHubElo()) {
                        this.matSnackBar.open(
                          'Provedor com cartão de crédito cadastrado com sucesso!',
                          '',
                          {
                            duration: 4000
                          }
                        )

                        return this.router.navigateByUrl('/provider')
                      }

                      this._getProviderById()
                      this.storesValid.setValue(true)
                      this.loading = false
                      this.stepper.next()
                    })
                    .catch(() => {
                      this.loading = false

                      this.matSnackBar.open(
                        'Não foi possível salvar o provedor',
                        '',
                        {
                          duration: 4000
                        }
                      )
                    })
                },
                () => {
                  this.loading = false

                  this.matSnackBar.open(
                    'Não foi possível salvar o provedor',
                    '',
                    {
                      duration: 4000
                    }
                  )
                }
              )
          })
          .catch((err) => {
            this.loading = false
            this.matSnackBar.open(
              this.utilsService.getErrorMessage(
                err,
                'Não foi possível salvar o provedor'
              ),
              '',
              {
                duration: 4000
              }
            )
          })
      }
    })
  }

  private saveProviderService (dataToSave): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accreditationService.createProvider(dataToSave).subscribe(
        (res: DefaultReturnObjDto<ProviderDto[]>) => {
          this.providerId = res.data[0].id

          if (res.data && res.data.length > 1)
            this.providerSecondaryId = res.data[1].id

          if (res.data[0]?.createdAtUtc)
            this.eventHistory('CREATE', res.data[0].createdAtUtc)

          if (this.statusProvider != 'Criado' && res.data[0]?.activatedAtUtc)
            this.eventHistory('ACTIVE', res.data[0]?.activatedAtUtc)

          this.matSnackBar.open('Provedor salvo com sucesso', '', {
            duration: 4000
          })

          this.loading = false

          return resolve()
        },
        () => {
          this.loading = false

          this.matSnackBar.open('Não foi possível salvar o provedor', '', {
            duration: 4000
          })

          return reject()
        }
      )
    })
  }

  private _getSelectedWallet (): WalletDto {
    if (this.wallets != null) {
      return this.wallets.find((dto) => {
        return dto.id == this.primaryStepForm.get('walletId').value
      })
    }
  }

  private _openDialogStoreHaveProviderActive (): Promise<string> {
    return new Promise((resolve) => {
      if (!this.isPix()) return resolve('CONFIRM')

      this.accreditationService
        .getProviderByWallet(this.isPix(), {
          documents: this.storeProviders.map((e) => ({ document: e.storeId }))
        })
        .subscribe((res) => {
          if (res.data) {
            const dialog = this.dialog.open(ConfirmDialogComponent, {
              disableClose: true,
              data: {
                dialogTitle: 'Cadastro no provedor de pagamentos',
                dialogText:
                  '<div class="text-center">Uma ou mais lojas selecionadas já estão associadas a um provedor de pagamento.\n' +
                  '<b>Você deseja removê-las do provedor existente e continuar?</b></div>',
                dialogCloseText: 'Não',
                dialogConfirmText: 'Sim, continuar'
              }
            })

            dialog.afterClosed().subscribe((result) => {
              if (result.event == 'CONFIRM') return resolve('CONFIRM')

              resolve('NOTCONFIRMED')
            })
          } else {
            resolve('CONFIRM')
          }
        })
    })
  }

  getEventType (event: EventType): string {
    return ''
  }

  getEventColor (event: string): string {
    return ''
  }

  getEventDescription (event: string): string {
    return ''
  }

  selectStoreProvider (event: MatCheckboxChange, row: any): void {
    this.testWasSuccess = false

    this.storesValid.setValue(null)

    if (event.checked) {
      const storeFind = this.storeProviders.find((e) => e.storeId == row.id)
      if (!storeFind) {
        this.storeProviders.push({ storeId: row.id })
      }

      if (!this.isAllowedPagarMeByName(row.brand)) {
        const storeFind = this.notAllowedStores.find((e) => e.id == row.id)
        if (!storeFind) {
          this.notAllowedStores.push(row)
        }
      }

      this.selection.select(row)
    } else {
      this.selection.toggle(row)
      const storeFind = this.storeProviders.find((e) => e.storeId == row.id)

      if (storeFind) {
        const index = this.storeProviders.indexOf(storeFind)
        this.storeProviders.splice(index, 1)
      }

      if (!this.isAllowedPagarMeByName(row.brand)) {
        const index = this.notAllowedStores.indexOf(row)
        this.notAllowedStores.splice(index, 1)
      }
      this.allSelected = false
    }
  }

  //Defines which ocasions the skip button will be showed
  showSkipTestButton (): boolean {
    this.showSkipTest = false

    if (!this.isMercadoPagoWallet() && !this.testWasSuccess) {
      this.showSkipTest = true
    } else if (this.eventName == this.eventProvider.edit && this.statusProvider == 'Ativo' && !this.checkMPCredentialsHasChanged()) {
      this.showSkipTest = true
    }
    return this.showSkipTest
  }

  checkMPCredentialsHasChanged (): boolean {
    const clientId = this.providerConfig[0].value
    const clientSecret = this.providerConfig[1].value
    const mpOriginalClientId = this.providerConfigFromEdit[0].value
    const mpOriginalClientSecret = this.providerConfigFromEdit[1].value

    if (clientId != mpOriginalClientId || clientSecret != mpOriginalClientSecret) {
      return true
    }

    return false
  }
}
