import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'vex-message-with-list',
  templateUrl: './message-with-list.component.html',
  styleUrls: ['./message-with-list.component.scss']
})
export class MessageWithListComponent {
  dialogObj: {
    dialogTitle: string,
    dialogText: string,
    dialogTextEnd: string,
    dialogTextComplement: string
    dialogConfirmText: string,
    dialogCloseText?: string,
    cnpjList: string[]
  }

  constructor (
    public dialogRef: MatDialogRef<MessageWithListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      dialogTitle: string,
      dialogText: string,
      dialogTextEnd: string,
      dialogTextComplement: string,
      dialogConfirmText: string,
      dialogCloseText?: string,
      cnpjList: string[]
    }
  ) {
    this.dialogObj = data
  }

  clickConfirm (): void {
    this.dialogRef.close({ event: 'CONFIRM' })
  }

  clickClose (): void {
    this.dialogRef.close({ event: 'CLOSE' })
  }

}
