<div class="dropdown">
  <div *ngIf="canListNotification">
    <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <div class="dropdown-heading">Notificações de Relatórios</div>
        <div class="dropdown-subheading" *ngIf="!loading && notifications.length">Você tem {{ notificationsQtd }}
          relatório{{notificationsQtd > 1 ? 's': ''}} pronto{{notificationsQtd > 1 ? 's': ''}} para download.
        </div>
      </div>
    </div>

    <div class="dropdown-content">
      <a *ngFor="let notification of notifications; trackBy: trackById" [class.read]="notification.read"
        (click)="downloadReport(notification.id)" class="notification" fxLayout="row" fxLayoutAlign="start center"
        matRipple>
        <ic-icon [icon]="icDownload" class="notification-icon" fxFlex="none" size="24px"></ic-icon>
        <div fxFlex="auto">
          <div class="notification-label">Rel. Vendas - {{ notification.initialPeriod }} à {{ notification.finalPeriod
            }}
          </div>
          <div class="notification-description">Expira {{ notification.expireDate | relativeDateTime }}</div>
        </div>
      </a>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner *ngIf="loading && canListNotification" diameter="32" class="mt-4 mb-4"></mat-spinner>
    <p class="p-6" *ngIf="!loading && !notifications.length && canListNotification">
      Não existem relatórios disponíveis!
    </p>
    <p class="p-6 text-center" *ngIf="!canListNotification">
      Você não tem permissão para ver notificações!
    </p>

  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center center">
    <button (click)="openReportPage()" color="primary" mat-button type="button">Ver todos os relatórios</button>
  </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon [icIcon]="icClearAll"></mat-icon>
    <span>Mark all as read</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>Disable Notifications</span>
  </button>
</mat-menu>