<h1 mat-dialog-title>
  {{ this.data?.id ? 'Edite a categoria' : 'Nova categoria' }}
</h1>
<mat-card-content>
  <div
    fxLayoutGap="25px"
    fxLayout="column"
    fxLayoutAlign="space-around none"
    [formGroup]="form"
  >
    <mat-form-field>
      <mat-label>Nome:</mat-label>
      <input
        matInput
        formControlName="name"
        maxlength="60"
        placeholder="Informe o nome da categoria"
      />
      <mat-error *ngIf="form.get('name').hasError('required')"
        >É necessário informar o nome da categoria</mat-error
      >
      <mat-hint class="text-right w-full"
        >{{ form.get('name').value?.length || 0 }}/60</mat-hint
      >
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" *ngIf="!loadingSaveCategory">
    <button
      (click)="SaveCategory()"
      class="ml-4"
      color="primary"
      mat-raised-button
      fxFlex="none"
      matTooltip="Filtrar"
      type="button"
    >
      Salvar
    </button>
  </div>

  <div *ngIf="loadingSaveCategory" class="m-3" fxLayoutAlign="end center">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</mat-card-content>
