import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DefaultReturnDto } from 'src/app/layouts/client-layout/models/DefaultReturnDto'
import { Group } from 'src/app/layouts/client-layout/models/group'
import { Stores } from 'src/app/layouts/client-layout/models/stores'
import { CreateGroupDto } from 'src/app/layouts/client-layout/models/CreateGroupDto'
import { environment } from 'src/environments/environment'
import { UtilsService } from '../utils/utils.service'
import { UpdateGroupDto } from 'src/app/layouts/client-layout/models/UpdateGroupDto'
import { LocalStorangeService } from '../local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private baseUrl =
    environment.baseUrl 

  constructor (
    private readonly httpClient: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly localStorangeService: LocalStorangeService
  ) {}

  public getGroups (params: {
    limit: number
    currentPage: number
    name?: string
  }): Observable<DefaultReturnDto<Group>> {
    const queryString = this.utilsService.encodeQueryData(params)
    return this.httpClient.get<DefaultReturnDto<Group>>(
      `${this.baseUrl}/v1/group?${queryString}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public deleteGroup (groupId: string): Observable<DefaultReturnDto<Group>> {
    return this.httpClient.delete<DefaultReturnDto<Group>>(
      `${this.baseUrl}/v1/group/${groupId}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public createGroup (group: CreateGroupDto): Observable<DefaultReturnDto<any>> {
    return this.httpClient.post<DefaultReturnDto<any>>(
      `${this.baseUrl}/v1/group`,
      group,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getStores (cnpjOrName?: string): Observable<DefaultReturnDto<Stores>> {
    return this.httpClient.get<DefaultReturnDto<Stores>>(
      `${this.baseUrl}/v1/store/list${
        cnpjOrName ? '?nameOrCnpj=' + cnpjOrName : ''
      }`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public editGroup (group: UpdateGroupDto): Observable<DefaultReturnDto<any>> {
    return this.httpClient.put<DefaultReturnDto<any>>(
      `${this.baseUrl}/v1/group`,
      group,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }
}
