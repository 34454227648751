<h1 mat-dialog-title>Filtro de Lojas</h1>
<div mat-dialog-content>
    <form autocomplete="off" [formGroup]="form" fxLayout="column">
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Nome / CNPJ</mat-label>

                <input maxlength="60" type="text" formControlName="nameOrCnpj" matInput>
                <mat-error *ngIf="form.get('nameOrCnpj').hasError('required')">Nome ou CNPJ da loja é obrigatório
                </mat-error>
            </mat-form-field>


            <button (click)="filterStore()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
                matTooltip="Filtrar lojas" type="button">
                <mat-icon [icIcon]="icSearch"></mat-icon>
            </button>
        </div>


        <table *ngIf="stores.data && stores.data.length" mat-table [dataSource]="stores" class="w-full">
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> # </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox color="primary" (change)="addStore($event, element)"></mat-checkbox>
                </td>
            </ng-container>


            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element">
                    {{element.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="cnpj">
                <th mat-header-cell *matHeaderCellDef> CNPJ </th>
                <td mat-cell *matCellDef="let element">
                    {{element.cnpj | mask: 'CPF_CNPJ'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="!stores.data || !stores.data.length" class="w-full center">
            NENHUMA LOJA ENCONTRADA!
        </div>
    </form>
</div>

<div *ngIf="!loading" class="mt-3 mb-1" mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
    <button mat-button mat-dialog-close>FECHAR</button>
    <button (click)="addStores()" mat-raised-button type="submit" color="primary">VINCULAR LOJA{{storesToAdd.length
        > 1 ? 'S': ''}}
        <span *ngIf="storesToAdd.length >= 1">({{storesToAdd.length}})</span>
    </button>
</div>

<div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
    <mat-spinner diameter="36"></mat-spinner>
</div>