<h2 mat-dialog-title>Duas ou mais contas localizadas - {{pspName}}</h2>
<mat-dialog-content>
    <mat-label>
        Prezado cliente, foi identificado mais de um ID para os estabelecimentos selecionados, caso prefira utilizar uma
        das contas encontradas para todas as lojas, selecione a conta abaixo e clique em "ACEITAR", ou então, realize o
        cadastro de provedor de forma separada.
    </mat-label>
    <p><b>Atenção:</b> Ao selecionar uma conta, também será definido uma única chave pix para todas as lojas.</p>

    <div class="mt-4 mb-4">
        <mat-radio-group [(ngModel)]="accountSelected">
            <mat-radio-button class="mt-2" color="primary" *ngFor="let account of accounts" [value]="account">
                Conta: {{ account.accountId }} - Pix: {{ account.pixKey }}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="mt-3 mb-3" fxLayoutAlign="space-around center" fxLayout="row">
        <button mat-raised-button (click)="close()">CANCELAR</button>
        <button mat-raised-button color="primary" (click)="accept()">ACEITAR</button>
    </div>

</mat-dialog-content>