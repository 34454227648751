import { AbstractControl, ValidationErrors } from '@angular/forms'

function isInvalidCNPJ (cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj.length !== 14) return true

  for (let i = 0; i < 13; i++) {
    if (cnpj === Array(15).join(String(i))) return true
  }

  const weights1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  const weights2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

  const calcDigit = (slice: string, weights: number[]) => {
    let sum = 0
    for (let i = 0; i < slice.length; i++) {
      sum += Number(slice.charAt(i)) * weights[i]
    }
    const remainder = sum % 11
    return remainder < 2 ? 0 : 11 - remainder
  }

  if (calcDigit(cnpj.slice(0, 12), weights1) !== Number(cnpj.charAt(12)))
    return true
  if (calcDigit(cnpj.slice(0, 13), weights2) !== Number(cnpj.charAt(13)))
    return true

  return false
}

export function cnpjValidator (
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value
  if (isInvalidCNPJ(value)) {
    return { cnpjInvalid: true }
  }
  return null
}
