import { Component, OnInit, ViewChild } from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormArray,
  Validators
} from '@angular/forms'
import { RecipientEventType } from '../../../models/RecipientEventType'
import { ActivatedRoute, Router } from '@angular/router'
import { Crumb } from '../../../models/crumb'
import icDoneAll from '@iconify/icons-ic/twotone-done-all'
import icSearch from '@iconify/icons-ic/twotone-search'
import icWarning from '@iconify/icons-ic/outline-warning-amber'
import { MatStepper } from '@angular/material/stepper'
import { cpfValidator } from 'src/app/utils/cpfValitador'
import { cnpjValidator } from 'src/app/utils/cnpjValitador'
import { GenericValidator } from 'src/app/utils/genericValidator'
import { BankDto, RecipientDto } from '../../../models/RecipientDto'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { DefaultReturnObjDto } from '../../../models/DefaultReturnObjDto'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ProviderDto, ProviderListDto } from '../../../models/ProviderDto'
import { ProviderFilter } from '../../../models/ProviderFIlter'
import { PagedListDto } from '../../../models/PagedListDto'
import { MatTableDataSource } from '@angular/material/table'
import { SelectionModel } from '@angular/cdk/collections'
import { PageEvent } from '@angular/material/paginator'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  map,
  switchMap,
  filter
} from 'rxjs/operators'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { BehaviorSubject, Observable, of } from 'rxjs'
import {
  BankOriginEnum,
  FilterBanksSearch
} from '../../../models/FilterBanksSearch'
import { MatSelect } from '@angular/material/select'

@Component({
  selector: 'vex-create-recipient',
  templateUrl: './create-recipient.component.html',
  styleUrls: ['./create-recipient.component.scss'],
  animations: [stagger40ms]
})
export class CreateRecipientComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper
  @ViewChild('bankSelect') bankSelect: MatSelect
  icDoneAll = icDoneAll
  icSearch = icSearch
  icWarning = icWarning

  loading = true
  loadingProviders = true
  eventName: RecipientEventType
  editRecipient: boolean
  breadcrumbs: Crumb[] = [
    { name: 'Recebedores', path: ['recipients'] },
    { name: this._eventRecipient(), path: [this.router.url] }
  ]

  providersForm: FormGroup
  identificationForm: FormGroup
  recipientForm: FormGroup
  bankAccountForm: FormGroup
  automaticAnticipationForm: FormGroup
  transferForm: FormGroup

  filter: ProviderFilter = {
    pageNumber: 0,
    pageSize: 10,
    integrationTypePix: 7,
    status: 'Ativo'
  }
  bankFilter: FilterBanksSearch = {
    pageNumber: 0,
    pageSize: 10,
    origin: BankOriginEnum.QrLinx
  }
  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }
  providersDataSource: MatTableDataSource<ProviderListDto>
  displayedColumns: string[] = [
    'select',
    'pixName',
    'nickname',
    'storeQuantity'
  ]
  providersSelection = new SelectionModel<ProviderListDto>(true, [])
  selectedProviderIds: string[] = []
  allSelected: boolean

  banks: BankDto[] = []
  bankFilterCtrl: FormControl = new FormControl()
  filteredBanks: Observable<BankDto[]>
  isSearchBankings: boolean
  selectedBank: BankDto

  recipientId: string
  recipient: RecipientDto

  subjectBank: BehaviorSubject<BankDto[]> = new BehaviorSubject<BankDto[]>(
    {} as BankDto[]
  )

  constructor (
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly matSnackBar: MatSnackBar,
    private readonly accreditationService: AccreditationService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.providersDataSource = new MatTableDataSource()
    this.recipientId = this.activatedRoute?.snapshot?.params?.id

    this.filteredBanks = this.subjectBank.asObservable()
  }

  private _getProviderPaged (): void {
    this.filter.pageNumber = this.paging.actualPage
    this.filter.pageSize = this.paging.pageSize
    this.filter.onlyStoresWithSplit = true

    if (this.providersForm.get('searchBy')?.value)
      this.filter.searchBy = this.providersForm.get('searchBy')?.value

    this.accreditationService.getProviderPaged(this.filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<ProviderListDto>>) => {
        this.loadingProviders = false
        this.providersDataSource.data = res.data.pageItems

        this.recipientProviderSelection()

        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
      },
      () => {
        this.loadingProviders = false
        this.matSnackBar.open('Nenhum provedor encontrado!', '', {
          duration: 5000
        })
      }
    )
  }

  recipientProviderSelection (): void {
    this.loadingProviders = true
    this.providersSelection.clear()
    this.providersDataSource.data.forEach((row: any) => {
      if (this.selectedProviderIds.includes(row.id) || this.allSelected) {
        this.providersSelection.select(row)
      }
    })
    this.loadingProviders = false
  }

  private _getRecipientById (recipientId: string): void {
    this.accreditationService.getRecipientById(recipientId).subscribe(
      (res: DefaultReturnObjDto<RecipientDto>) => {
        this.loading = false
        this.recipient = res.data

        if (this.recipient) {
          if (this.recipient.providerRecipients) {
            this.recipient.providerRecipients.forEach((row) => {
              this.selectedProviderIds.push(row.providerId)
            })
          }

          this.identificationForm.patchValue({
            documentType: this.recipient.document.length <= 11 ? 'CPF' : 'CNPJ',
            document: this.recipient.document
          })

          this.recipientForm.patchValue({
            name: this.recipient.name,
            email: this.recipient.email,
            description: this.recipient.description
          })

          this.bankAccountForm.patchValue({
            accountType: this.recipient.account.type,
            bankResponsableName: this.recipient.account.responsableName,
            branch: this.recipient.account.branch,
            branchDigit: this.recipient.account.branchDigit,
            account: this.recipient.account.accountNumber,
            accountDigit: this.recipient.account.accountDigit,
            bankName: this.recipient.account.bank.name
          })

          this.subjectBank.next([this.recipient.account.bank])
          this.selectedBank = this.recipient.account.bank

          this.automaticAnticipationForm.patchValue({
            automaticAnticipationIsEnabled:
              this.recipient.automaticAnticipationIsEnabled,
            automaticAnticipationType: this.recipient.automaticAnticipationType,
            automaticAnticipationVolumePercentage:
              this.recipient.automaticAnticipationVolumePercentage,
            automaticAnticipationDelay:
              this.recipient.automaticAnticipationDelay
          })

          this.transferForm.patchValue({
            receivePaymentsAutomatically:
              this.recipient.receivePaymentsAutomatically,
            transferInterval: this.recipient?.transferInterval?.toLowerCase(),
            transferDay: this.recipient?.transferDay?.toString()
          })
        }
        this.recipientProviderSelection()
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Recebedor não encontrado!', '', {
          duration: 5000
        })
      }
    )
  }

  Openedselect (): void {
    if (!this.editRecipient) return

    this.filteredBanks = this.bankFilterCtrl.valueChanges.pipe(
      filter(() => !this.selectedBank || this.bankSelect.panelOpen),
      debounceTime(100),
      distinctUntilChanged(),
      switchMap((value) => {
        this.isSearchBankings = true
        if (value && value.length >= 3) {
          return this.accreditationService
            .getBanks({ ...this.bankFilter, searchBy: value })
            .pipe(
              map((res: DefaultReturnObjDto<PagedListDto<BankDto>>) => {
                this.isSearchBankings = false
                if (res.success) {
                  this.banks = res.data.pageItems
                  return this.banks
                } else {
                  return []
                }
              })
            )
        } else {
          return of([this.recipient.account.bank])
        }
      })
    )
    startWith(''), map((value: string) => this._filterBanks(value))
  }

  private _addProviderValidation (): void {
    this.providersForm = this.fb.group({
      searchBy: new FormControl('')
    })
  }

  private _addIdentificationValidation (): void {
    this.loading = false
    this.identificationForm = this.fb.group({
      document: new FormControl('', Validators.required),
      documentType: new FormControl('', Validators.required)
    })

    if (this.editRecipient) this.identificationForm.disable()

    this.identificationForm
      .get('documentType')
      ?.valueChanges.subscribe((type) => {
        const documentControl = this.identificationForm.get('document')
        if (type === 'CPF') {
          documentControl?.setValidators([Validators.required, cpfValidator])
        } else if (type === 'CNPJ') {
          documentControl?.setValidators([Validators.required, cnpjValidator])
        } else {
          documentControl?.setValidators([Validators.required])
        }
        documentControl?.updateValueAndValidity()
      })
  }

  private _addRecipientValidation (): void {
    this.recipientForm = this.fb.group({
      name: new FormControl('', [
        Validators.required,
        GenericValidator.nameValidators
      ]),
      email: new FormControl('', [
        Validators.required,
        GenericValidator.emailValidator
      ]),
      description: new FormControl('', [Validators.required])
    })
  }
  private _addBankAccountValidation (): void {
    this.bankAccountForm = this.fb.group({
      accountType: new FormControl('', [Validators.required]),
      bankResponsableName: new FormControl('', [Validators.required]),
      bankName: new FormControl('', [Validators.required]),
      branch: new FormControl('', [Validators.required]),
      branchDigit: new FormControl('', [Validators.min(0), Validators.max(9)]),
      account: new FormControl('', [Validators.required]),
      accountDigit: new FormControl('', [
        Validators.required,
        Validators.min(0),
        Validators.max(99)
      ])
    })

    if (this.editRecipient) this.bankAccountForm.disable()
  }

  private _addAutomaticAnticipationValidation (): void {
    this.automaticAnticipationForm = this.fb.group({
      automaticAnticipationIsEnabled: new FormControl({
        value: '',
        disabled: this.editRecipient
      }),
      automaticAnticipationType: new FormControl({
        value: '',
        disabled: this.editRecipient
      }),
      automaticAnticipationVolumePercentage: new FormControl({
        value: '',
        disabled: this.editRecipient
      }),
      automaticAnticipationDelay: new FormControl({
        value: '',
        disabled: this.editRecipient
      })
    })

    this.automaticAnticipationForm
      .get('automaticAnticipationIsEnabled')
      ?.valueChanges.subscribe((selected) => {
        const automaticAnticipationTypeControl =
          this.automaticAnticipationForm.get('automaticAnticipationType')
        const automaticAnticipationVolumePercentageControl =
          this.automaticAnticipationForm.get(
            'automaticAnticipationVolumePercentage'
          )
        const automaticAnticipationDelayControl =
          this.automaticAnticipationForm.get('automaticAnticipationDelay')

        if (selected) {
          automaticAnticipationTypeControl?.setValidators([Validators.required])
          automaticAnticipationTypeControl?.setValue(null)
          automaticAnticipationTypeControl?.updateValueAndValidity()
        } else {
          automaticAnticipationTypeControl?.setValidators([])
          automaticAnticipationTypeControl?.updateValueAndValidity()
        }

        automaticAnticipationVolumePercentageControl?.setValidators([])
        automaticAnticipationVolumePercentageControl?.updateValueAndValidity()
        automaticAnticipationDelayControl?.setValidators([])
        automaticAnticipationDelayControl?.updateValueAndValidity()
      })

    this.automaticAnticipationForm
      .get('automaticAnticipationType')
      ?.valueChanges.subscribe((type) => {
        const automaticAnticipationVolumePercentageControl =
          this.automaticAnticipationForm.get(
            'automaticAnticipationVolumePercentage'
          )
        const automaticAnticipationDelayControl =
          this.automaticAnticipationForm.get('automaticAnticipationDelay')
        if (type === 'Volume') {
          automaticAnticipationVolumePercentageControl?.setValidators([
            Validators.required,
            GenericValidator.positiveNumberValidator
          ])
          automaticAnticipationVolumePercentageControl.updateValueAndValidity()
          automaticAnticipationDelayControl?.setValidators([])
        } else {
          automaticAnticipationDelayControl?.setValidators([
            Validators.required,
            GenericValidator.positiveNumberValidator
          ])
          automaticAnticipationDelayControl.updateValueAndValidity()
          automaticAnticipationVolumePercentageControl?.setValidators([])
        }
        automaticAnticipationVolumePercentageControl?.updateValueAndValidity()
        automaticAnticipationDelayControl?.updateValueAndValidity()
      })
  }

  private _addTransferValidation (): void {
    this.transferForm = this.fb.group({
      receivePaymentsAutomatically: new FormControl(''),
      transferInterval: new FormControl(''),
      transferDay: new FormControl('')
    })

    if (this.editRecipient) this.transferForm.disable()

    this.transferForm
      .get('receivePaymentsAutomatically')
      ?.valueChanges.subscribe((selected) => {
        const transferInterval = this.transferForm.get('transferInterval')
        const transferDayControl = this.transferForm.get('transferDay')

        if (selected) {
          transferInterval.setValidators([Validators.required])
        } else {
          transferInterval.setValidators([])
          transferDayControl?.setValidators([])
        }
        transferInterval?.updateValueAndValidity()
        transferDayControl?.updateValueAndValidity()
      })

    this.transferForm
      .get('transferInterval')
      ?.valueChanges.subscribe((selected) => {
        if (selected == '') return

        const transferDayControl = this.transferForm.get('transferDay')

        if (selected == 'daily') {
          transferDayControl?.setValidators([])
        } else {
          transferDayControl?.setValidators([
            Validators.required,
            Validators.min(1),
            Validators.max(31)
          ])
        }
        transferDayControl?.setValue(1)
        transferDayControl?.updateValueAndValidity()
      })
  }

  backPage (): void {
    this.router.navigateByUrl('/recipients')
  }

  ngOnInit (): void {
    this.eventName = this._eventRecipient()

    if (this.editRecipient) {
      this._getRecipientById(this.recipientId)
    }

    this._addProviderValidation()
    this._addIdentificationValidation()
    this._addRecipientValidation()
    this._addBankAccountValidation()
    this._addAutomaticAnticipationValidation()
    this._addTransferValidation()

    this.providersForm
      .get('searchBy')
      ?.valueChanges.pipe(
        debounceTime(500),
        startWith(''),
        distinctUntilChanged()
      )
      .subscribe((val) => {
        if (!val.length || val.length >= 3) {
          this._getProviderPaged()
        }
      })

    this.bankAccountForm
      .get('bankName')
      .valueChanges.subscribe((selectedBank) => {
        if (selectedBank) {
          this.selectedBank = this.banks.find(
            (bank) => bank.name === selectedBank
          )
        } else {
          this.selectedBank = null
        }
      })

    if (!this.editRecipient) {
      this.filteredBanks = this.bankFilterCtrl.valueChanges.pipe(
        filter(() => !this.selectedBank || this.bankSelect.panelOpen),
        debounceTime(100),
        distinctUntilChanged(),
        switchMap((value) => {
          this.isSearchBankings = true
          if (value && value.length >= 3) {
            return this.accreditationService
              .getBanks({ ...this.bankFilter, searchBy: value })
              .pipe(
                map((res: DefaultReturnObjDto<PagedListDto<BankDto>>) => {
                  this.isSearchBankings = false
                  if (res.success) {
                    this.banks = res.data.pageItems
                    return this.banks
                  } else {
                    return []
                  }
                })
              )
          } else {
            return of([])
          }
        })
      )
      startWith(''), map((value: string) => this._filterBanks(value))
    }
  }

  private _filterBanks (value: string): any[] {
    const filterValue = value.toLowerCase()

    if (!this.banks) {
      return []
    }

    return this.banks.filter(
      (bank) =>
        bank.name.toLowerCase().includes(filterValue) ||
        bank.code.toLowerCase().includes(filterValue)
    )
  }

  private _eventRecipient (): RecipientEventType {
    if (this.router.url == '/recipients/create') {
      return RecipientEventType.create
    }

    this.editRecipient = true
    return RecipientEventType.edit
  }

  goToNextStep (formGroup: FormGroup): void {
    if (!formGroup.valid && formGroup.status != 'DISABLED') return

    this.stepper.next()
  }

  async validReceiverAlreadyExistsAndGoToNextStep (
    formGroup: FormGroup
  ): Promise<void> {

    if (this.editRecipient) this.stepper.next()

    if (!formGroup.valid) return

    if (!this.editRecipient) {
      if (await this.receiverAlreadyExistsInTheProvider()) {
        this.matSnackBar.open(
          'Documento já cadastrado como recebedor em um dos provedores selecionados, para alterar alguma informação, acesse a pagina de recebedores e edite o recebedor desejado!',
          '',
          {
            duration: 4000
          }
        )
        return
      }
    }

    this.stepper.next()
  }

  receiverAlreadyExistsInTheProvider (): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const document = this.identificationFields.document.value
      const providerIdList = this.selectedProviderIds
      this.loading = true

      this.accreditationService
        .getRecipient({ document, providerIdList })
        .subscribe(
          (res) => {
            this.loading = false

            return resolve(res.data)
          },
          (err) => {
            this.loading = false

            return reject(err)
          }
        )
    })
  }

  getDocumentMask (): string {
    return this.identificationFields.documentType.value == 'CNPJ'
      ? '00.000.000/0000-99'
      : '000.000.000-99'
  }

  finish (): void {
    this.loading = true
    const recipientDto = this._getRecipientDtoData()

    if (this.eventName == RecipientEventType.create) {
      this.accreditationService.createRecipient(recipientDto).subscribe(
        (res: DefaultReturnObjDto<RecipientDto>) => {
          this.loading = false
          if (res.success) {
            this.matSnackBar.open('Recebedor salvo com sucesso!', '', {
              duration: 3000
            })
            this.router.navigate(['/recipients'])
            return
          }

          this.matSnackBar.open(
            'Erro ao salvar o recebedor!' +
              recipientDto.automaticAnticipationIsEnabled
              ? 'Verifique se a antecipação automática está liberada junto a Pagar.me.'
              : '',
            '',
            {
              duration: 5000
            }
          )
        },
        () => {
          this.loading = false
          this.matSnackBar.open('Erro ao salvar o recebedor!', '', {
            duration: 5000
          })
        }
      )
    } else {
      this.accreditationService.putRecipient(recipientDto).subscribe(
        (res: DefaultReturnObjDto<RecipientDto>) => {
          this.loading = false
          if (res.success) {
            this.matSnackBar.open('Recebedor editado com sucesso!', '', {
              duration: 3000
            })
            this.router.navigate(['/recipients'])
          } else {
            this.matSnackBar.open('Erro ao editar o recebedor!', '', {
              duration: 5000
            })
          }
        },
        () => {
          this.loading = false
          this.matSnackBar.open('Erro ao editar o recebedor!', '', {
            duration: 5000
          })
        }
      )
    }
  }

  private _getRecipientDtoData (): RecipientDto {
    const identificationData = this.identificationForm.value
    const recipientData = this.recipientForm.value
    const bankAccountData = this.bankAccountForm.value
    const automaticAnticipationData = this.automaticAnticipationForm.value
    const transferData = this.transferForm.value
    return {
      id: this.eventName == RecipientEventType.edit ? this.recipientId : null,
      selectedProviderIds: this.allSelected ? null : this.selectedProviderIds,
      allProvidersSelected: this.allSelected,
      type: identificationData.documentType == 'CPF' ? 'Individual' : 'Company',
      document: identificationData.document,
      name: recipientData.name,
      email: recipientData.email,
      description: recipientData.description,

      automaticAnticipationIsEnabled:
        automaticAnticipationData.automaticAnticipationIsEnabled,
      automaticAnticipationType:
        automaticAnticipationData.automaticAnticipationType,
      automaticAnticipationVolumePercentage:
        automaticAnticipationData.automaticAnticipationVolumePercentage,
      automaticAnticipationDelay:
        automaticAnticipationData.automaticAnticipationDelay,
      receivePaymentsAutomatically: transferData.receivePaymentsAutomatically,
      transferInterval: transferData.transferInterval,
      transferDay: transferData.transferDay,
      account: {
        bank: {
          code: this.selectedBank ? this.selectedBank.code : null,
          ispb: this.selectedBank ? this.selectedBank.ispb : null,
          document: identificationData.document,
          name: bankAccountData.bankName
        },
        branch: bankAccountData.branch,
        branchDigit: bankAccountData.branchDigit,
        accountNumber: bankAccountData.account,
        accountDigit: bankAccountData.accountDigit,
        document: identificationData.document,
        type: bankAccountData.accountType,
        responsableName: bankAccountData.bankResponsableName
      }
    }
  }
  get selectedProviders (): FormArray {
    return this.providersForm.get('selectedProviders') as FormArray
  }

  addProvider (provider: ProviderDto): void {
    this.selectedProviders.push(this.fb.control(provider))
  }

  get providersFields (): any {
    return this.providersForm.controls
  }

  get identificationFields (): any {
    return this.identificationForm.controls
  }

  get recipientFields (): any {
    return this.recipientForm.controls
  }

  get bankAccountFields (): any {
    return this.bankAccountForm.controls
  }

  get automaticAnticipationFields (): any {
    return this.automaticAnticipationForm.controls
  }

  get transferFields (): any {
    return this.transferForm.controls
  }

  masterToggle (): void {
    if (this.allSelected) {
      this.providersDataSource.data.forEach((row) => {
        const index = this.selectedProviderIds.indexOf(row.Id)
        if (index > -1) {
          this.selectedProviderIds.splice(index, 1)
        }
        this.providersSelection.deselect(row)
      })
      this.allSelected = false
      return
    }

    this.providersDataSource.data.forEach((row) => {
      if (!this.selectedProviderIds.includes(row.Id)) {
        this.selectedProviderIds.push(row.Id)
      }
      this.providersSelection.select(row)
    })
    this.allSelected = true
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1

    this._getProviderPaged()
  }

  selectProvider (event: MatCheckboxChange, row: any): void {
    if (event.checked) {
      this.providersSelection.select(row)
      this.selectedProviderIds.push(row.id)
    } else {
      this.providersSelection.deselect(row)
      const index = this.selectedProviderIds.indexOf(row.id)
      if (index > -1) {
        this.selectedProviderIds.splice(index, 1)
      }
    }
  }
}
