import {
  HttpInterceptor,
  HttpClient,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http'

import {
  from,
  Observable,
  of,
  throwError
} from 'rxjs'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { catchError, timeout } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  data: { ip: string } = null
  constructor (private httpClient: HttpClient) { }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.setWithCredentials(req, next))
  }

  async setWithCredentials (req: HttpRequest<any>, next: HttpHandler): Promise<any> {
    if (!environment.target.includes('linx-local') &&
      req.url !== environment.getClientIp) {

      if (!this.data) {
        const response = await this.httpClient.get<{ ip: string }>(environment.getClientIp).pipe(timeout(1000), catchError(error => {
          console.error('Timeout occurred:', error)
          return of({ ip: 'IpNotFound' })
        })).toPromise()
        this.data = { ip: response.ip }
      }

      const newRequest = req.clone({
        withCredentials: true,
        setHeaders: {
          'X-Forwarded-For': this.data.ip
        }
      })
      return next.handle(newRequest).pipe(
        catchError(x => this.handleAuthError(x))
      ).toPromise()
    } else {
      return next.handle(req).toPromise()
    }
  }

  private handleAuthError (err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      location.href = environment.loginUrl
      return of(err.message)
    }
    return throwError(err)
  }
}
