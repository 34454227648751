<mat-card-content>
  <h1 mat-dialog-title>Editar PSP - {{ psp.name }}</h1>
  <div
    fxLayoutGap="25px"
    fxLayout="column"
    fxLayoutAlign="space-around none"
    [formGroup]="form"
  >
    <mat-form-field>
      <mat-label>Tempo mínimo para alertar (em segundos)</mat-label>
      <input
        matInput
        type="number"
        formControlName="minTimeToAlert"
        placeholder="Tempo mínimo para gerar um alerta"
      />
      <mat-error *ngIf="form.get('minTimeToAlert').hasError('required')"
        >É necessário informar o tempo mínimo para alertar</mat-error
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label>Porcentagem mínima para alertar</mat-label>
      <input
        matInput
        type="number"
        formControlName="minPercentageToAlert"
        placeholder="Porcentagem mínima (de transações acima da média) para gerar um alerta"
      />
      <mat-error *ngIf="form.get('minPercentageToAlert').hasError('required')"
        >É necessário informar a porcentagem mínima para alertar</mat-error
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label
        >Tempo a ser cosiderado para resolver alertas (em minutos)</mat-label
      >
      <input
        matInput
        type="number"
        formControlName="resolvePspTimeWindow"
        placeholder="Para resolver um alerta, as transações dos ultimos X minutos devem estar abaixo da média"
      />
      <mat-error *ngIf="form.get('resolvePspTimeWindow').hasError('required')"
        >É necessário informar o tempo mínimp para resolver os
        alertar</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>Tempo minimo para buscar as transações (em minutos)</mat-label>
      <input
        matInput
        type="number"
        formControlName="timeToSearchTransactions"
        placeholder="o sistema buscara as transações dos ultimos X minutos para validar a quantidade"
      />
      <mat-error
        *ngIf="form.get('timeToSearchTransactions').hasError('required')"
        >É necessário informar o tempo mínimo para buscar as
        transações</mat-error
      >
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <button
      (click)="clearFilter()"
      class="ml-4"
      color="primary"
      fxFlex="none"
      matTooltip="Limpar"
      type="button"
    >
      Limpar
    </button>

    <button
      (click)="saveData()"
      class="ml-4"
      color="primary"
      mat-raised-button
      fxFlex="none"
      matTooltip="Salvar"
      type="button"
      [disabled]="loading"
    >
      {{ loading ? 'Salvando alterações ...' : 'Salvar' }}
    </button>
  </div>
</mat-card-content>
