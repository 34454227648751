<vex-page-layout>
  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="mt-0 mb-1">
          <b>{{ eventName }}</b>
        </h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="true" class="-mt-6" (click)="hideConfigValue()">
    <mat-vertical-stepper class="-mt-16" [linear]="true" #stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon [icIcon]="icDoneAll"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon [icIcon]="icDoneAll"></mat-icon>
      </ng-template>
      <mat-step [stepControl]="providerValid">
        <ng-template class="w-full" matStepLabel>
          <div class="w-full" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="flex-grow">Provedor</div>
            <div *ngIf="isPix() && hasSecondaryPix" class="flex-grow flex justify-end">
              <button id="invert-provider" (click)="invertProviders()" [disabled]="loading" mat-raised-button
                color="primary">
                Inverter Provedores
              </button>
            </div>
            <div *ngIf="providerId" class="ml-6 flex justify-end">
              <mat-slide-toggle [checked]="providerStatus == 'inactive' ? false : true" [disabled]="
                  hideButtons ||
                  loading ||
                  (statusProvider != 'Ativo' && statusProvider != 'Inativo')
                " (change)="openDialogDisableActiveProvider($event)" class="example-margin" color="primary">
                {{
                providerStatus == 'inactive'
                ? 'Ativar provedor'
                : 'Desativar provedor'
                }}
              </mat-slide-toggle>
            </div>
          </div>
        </ng-template>
        <form class="flex pt-4 flex-col" autocomplete="off" [formGroup]="primaryStepForm">
          <div *ngIf="loading" class="mt-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
          </div>
          <div *ngIf="!loading" class="gap-5 flex flex-row">
            <mat-form-field class="flex-grow">
              <mat-label>Apelido do Provedor</mat-label>
              <input matInput formControlName="nickname" placeholder="Ex. Carteira MP - Lojas Sul" />
              <mat-error *ngIf="f.nickname.invalid">O apelido do provedor é obrigatório</mat-error>
            </mat-form-field>

            <mat-form-field class="flex-grow">
              <mat-label>Provedor</mat-label>
              <input *ngIf="!canChange()" disabled matInput [value]="f.isPix?.value == true ? 'Pix' : 'Carteira'" />

              <mat-select *ngIf="canChange()" (selectionChange)="checkToOpenPixOrWallet($event)"
                formControlName="isPix">
                <mat-option *ngFor="let provider of providerTypes" [value]="provider.type">
                  {{ provider.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f.isPix.invalid">Tipo do provedor é obrigatório</mat-error>
            </mat-form-field>

            <mat-form-field class="flex-grow" *ngIf="showPixSelect">
              <mat-label>PSP Pix</mat-label>
              <input *ngIf="!canChange()" disabled matInput [value]="
                  getPspNameByIntegrationTypePix(f.integrationTypePix?.value)
                " />
              <mat-select *ngIf="canChange()" (selectionChange)="checkToOpenPix()" formControlName="integrationTypePix">
                <mat-option *ngFor="let pix of pixPsp" [value]="pix.integrationType">
                  {{ pix.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f.integrationTypePix.invalid">Selecione um provedor</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="hasSecondaryPix" class="flex-grow">
              <mat-label>PSP Pix - Secundário</mat-label>
              <mat-select [disabled]="!f.integrationTypePix.value" (selectionChange)="checkToOpenPixSecondary()"
                formControlName="integrationTypePixSecondary">
                <mat-option *ngFor="let pix of pixPspSecondaryList" [value]="pix.integrationType">
                  {{ pix.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!f.integrationTypePix.value">Selecione o provedor principal</mat-error>
              <mat-error *ngIf="
                  f.integrationTypePix.value &&
                  f.integrationTypePixSecondary.invalid
                ">Selecione um provedor</mat-error>
            </mat-form-field>

            <button id="add-second-provider" [disabled]="hideButtons" (click)="addSecondProvider()"
              *ngIf="isPix() && !hasSecondaryPix" class="ml-2 mt-2" color="primary" fxFlex="none" mat-mini-fab
              matTooltip="Adicionar provedor secundário" type="submit">
              <mat-icon [icIcon]="iconAdd"></mat-icon>
            </button>

            <button id="remove-second-provider" [disabled]="hideButtons" (click)="removeSecondProvider()"
              *ngIf="isPix() && hasSecondaryPix" class="ml-2 mt-2" color="primary" fxFlex="none" mat-mini-fab
              matTooltip="Remover provedor secundário" type="submit">
              <mat-icon [icIcon]="iconRemoveBaseLine"></mat-icon>
            </button>

            <mat-form-field class="flex-grow" *ngIf="showWalletSelect">
              <mat-label>Carteiras</mat-label>
              <mat-select (selectionChange)="checkToOpenPix()" formControlName="walletId">
                <mat-option *ngFor="let wallet of walletsToShow" [value]="wallet.id">
                  {{ wallet.tradingName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="!loading" class="gap-5 flex flex-row">
            <mat-form-field class="flex-grow" *ngIf="isCard == false && showWalletSelect">
              <mat-label>Utiliza EDI Linx?</mat-label>
              <mat-select formControlName="sendToEquals">
                <mat-option [value]="true">Sim</mat-option>
                <mat-option [value]="false">Não</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button id="go-to-credentials" [disabled]="!primaryStepForm.valid || loading" (click)="goToCredentials()"
            mat-raised-button color="primary">
            PRÓXIMO
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="stoneValid" *ngIf="f.hasStoneAccount.value == false">
        <ng-template matStepLabel>Conta Stone</ng-template>

        <form class="flex pt-4 flex-col" autocomplete="off" [formGroup]="stoneStepForm">
          <div formGroupName="store">
            <p class="text-bold pb-2">Dados Cadastrais</p>

            <div *ngIf="loadingCnpj" class="mt-6 mb-6" fxLayoutAlign="center center">
              <mat-spinner diameter="36"></mat-spinner>
            </div>

            <div *ngIf="!loading" class="gap-5 flex flex-row">
              <mat-form-field class="flex-grow">
                <mat-label>CNPJ</mat-label>
                <input readonly mask="00.000.000/0000-00" type="text" matInput required formControlName="document" />
                <mat-error *ngIf="
                    pagarmeForm.store.controls.document.hasError('required')
                  ">O CNPJ é obrigatório</mat-error>
                <mat-error *ngIf="
                    pagarmeForm.store.controls.document.value &&
                    pagarmeForm.store.controls.document.hasError('cnpjNotValid')
                  ">CNPJ inválido</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Nome Fantasia</mat-label>
                <input matInput formControlName="companyName" required />
                <mat-error *ngIf="
                    pagarmeForm.store.controls.companyName.hasError('required')
                  ">O nome fantasia é obrigatório</mat-error>

                <mat-error *ngIf="
                    pagarmeForm.store.controls.companyName.hasError('maxlength')
                  ">Tamanho do campo excede o máximo permitido</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Razão Social</mat-label>
                <input matInput formControlName="corporateName" required />
                <mat-error *ngIf="
                    pagarmeForm.store.controls.corporateName.hasError(
                      'required'
                    )
                  ">A razão social é obrigatória</mat-error>
                <mat-error *ngIf="
                    pagarmeForm.store.controls.corporateName.hasError(
                      'maxlength'
                    )
                  ">Tamanho do campo excede o máximo permitido</mat-error>
              </mat-form-field>

              <div [hidden]="true">
                <mat-form-field class="flex-grow">
                  <mat-label>Nome da Conta</mat-label>
                  <input matInput formControlName="accountName" required />
                  <mat-error *ngIf="
                      pagarmeForm.store.controls.accountName.hasError(
                        'required'
                      )
                    ">O nome da conta é obrigatório</mat-error>
                  <mat-error *ngIf="
                      pagarmeForm.store.controls.accountName.hasError(
                        'maxlength'
                      )
                    ">Tamanho do campo excede o máximo permitido</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div [hidden]="true">
              <div class="gap-5 flex flex-row">
                <mat-form-field class="flex-grow">
                  <mat-label>Segmento</mat-label>
                  <mat-select required formControlName="segment">
                    <mat-option *ngFor="let segment of segments" [value]="segment.name">
                      {{ segment.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      pagarmeForm.store.controls.segment.hasError('required')
                    ">Segmento é obrigatório</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Categoria</mat-label>
                  <mat-select required formControlName="subSegment">
                    <mat-option *ngFor="let category of categories" [value]="category">
                      {{ category }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      pagarmeForm.store.controls.subSegment.hasError('required')
                    ">Categoria é obrigatória</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div [hidden]="true">
            <div formGroupName="contact">
              <p class="text-bold pb-2">Dados de Contato</p>
              <div *ngIf="!loading" class="gap-5 flex flex-row">
                <mat-form-field class="flex-grow">
                  <mat-label>Tipo de Contato</mat-label>
                  <mat-select required formControlName="type">
                    <mat-option *ngFor="let type of types" [value]="type">
                      {{ type }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.type.hasError('required')
                    ">Tipo de contato é obrigatório</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Nome</mat-label>
                  <input required type="text" matInput formControlName="name" />
                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.name.hasError('required')
                    ">O nome é obrigatório</mat-error>
                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.name.hasError('maxlength')
                    ">Tamanho do campo excede o máximo permitido</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>E-mail</mat-label>
                  <input required type="email" matInput formControlName="email" />
                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.email.hasError('required')
                    ">O e-mail é obrigatório</mat-error>
                  <mat-error *ngIf="pagarmeForm.contact.controls.email.hasError('email')">E-mail é inválido</mat-error>
                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.email.hasError('maxlength')
                    ">Tamanho do campo excede o máximo permitido</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Telefone</mat-label>
                  <input required mask="(00) 00000-0009" type="text" matInput formControlName="phone" />
                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.phone.hasError('required')
                    ">O telefone é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div *ngIf="!loading" class="gap-5 flex flex-row">
                <mat-form-field class="flex-grow">
                  <mat-label>Data de Nascimento</mat-label>
                  <input required type="date" matInput formControlName="birthday" />
                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.birthday.hasError('required')
                    ">A data de nascimento é obrigatória</mat-error>

                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.birthday.value &&
                      pagarmeForm.contact.controls.birthday.hasError(
                        'menorDeIdade'
                      )
                    ">Idade inválida</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Nome da mãe</mat-label>
                  <input required type="text" matInput formControlName="mothersName" />
                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.mothersName.hasError(
                        'required'
                      )
                    ">O nome da mãe é obrigatório</mat-error>
                  <mat-error *ngIf="
                      pagarmeForm.contact.controls.mothersName.hasError(
                        'maxlength'
                      )
                    ">Tamanho do campo excede o máximo permitido</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="loadingCep" class="ml-6" fxLayoutAlign="center center">
              <mat-spinner diameter="20"></mat-spinner>
            </div>

            <div formGroupName="address">
              <p class="text-bold pb-2">Dados de Endereço</p>

              <div *ngIf="!loading" class="gap-5 flex flex-row">
                <mat-form-field class="flex-grow">
                  <mat-label>CEP</mat-label>
                  <input required mask="00000-000" (focusout)="getAddressInfo()" type="text" matInput
                    formControlName="zipcode" />
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.zipcode.hasError('required')
                    ">O CEP é obrigatório</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Estado</mat-label>
                  <input required type="text" matInput formControlName="state" />
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.state.hasError('required')
                    ">O estado é obrigatório</mat-error>
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.state.hasError('maxlength')
                    ">Tamanho do campo excede o máximo permitido</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Cidade</mat-label>
                  <input required type="text" matInput formControlName="city" />
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.city.hasError('required')
                    ">A cidade é obrigatória</mat-error>
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.city.hasError('maxlength')
                    ">Tamanho do campo excede o máximo permitido</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Bairro</mat-label>
                  <input required type="text" matInput formControlName="neighborhood" />
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.neighborhood.hasError(
                        'required'
                      )
                    ">O bairro é obrigatório</mat-error>
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.neighborhood.hasError(
                        'maxlength'
                      )
                    ">Tamanho do campo excede o máximo permitido</mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="!loading" class="gap-5 flex flex-row">
                <mat-form-field class="flex-grow">
                  <mat-label>Endereço</mat-label>
                  <input required type="text" matInput formControlName="street" />
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.street.hasError('required')
                    ">O endereço é obrigatório</mat-error>
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.street.hasError('maxlength')
                    ">Tamanho do campo excede o máximo permitido</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Número</mat-label>
                  <input required type="text" matInput formControlName="number" />
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.number.hasError('required')
                    ">O número é obrigatório</mat-error>
                  <mat-error *ngIf="
                      pagarmeForm.address.controls.number.hasError('maxlength')
                    ">Tamanho do campo excede o máximo permitido</mat-error>
                </mat-form-field>

                <mat-form-field class="flex-grow">
                  <mat-label>Complemento</mat-label>
                  <input type="text" matInput formControlName="complement" />
                </mat-form-field>
                <mat-error *ngIf="
                    pagarmeForm.address.controls.complement.hasError(
                      'maxlength'
                    )
                  ">Tamanho do campo excede o máximo permitido</mat-error>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center">
            <button id="go-next-stone" [disabled]="
                hideButtons || checkPropertyPagarmeNotFilled() || loading
              " (click)="goToNextStone()" mat-raised-button color="primary">
              PRÓXIMO
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="conciliationValid"
        *ngIf="f.sendToEquals.value === true && conciliationConfig.length > 0">
        <ng-template matStepLabel>Conciliação</ng-template>

        <div fxLayoutGap="25px" fxLayout.lt-md="column" fxLayout.lg="column" fxLayout.md="row" fxLayout="row"
          fxLayoutAlign="space-around none" class="pt-5">
          <mat-form-field class="w-full" *ngFor="let i = index; let config; of: conciliationConfig">
            <mat-label>{{
              getLabelName(config.alias ?? config.name)
              }}</mat-label>
            <input *ngIf="!config.isBoolean" (ngModelChange)="onChange(true)" matInput type="text" class="form-control"
              [ngModelOptions]="{ standalone: true }" [(ngModel)]="config.value" />
            <mat-select *ngIf="config.isBoolean" (ngModelChange)="onChange(true)" [(ngModel)]="config.value">
              <mat-option value="true">Sim</mat-option>
              <mat-option value="false">Não</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button id="go-from-conciliation" [disabled]="checkPropertyConciliationNotFilled() || loading"
            (click)="goToNext()" mat-raised-button color="primary">
            PRÓXIMO
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="credentialsValid" *ngIf="f.hasStoneAccount.value != false">
        <ng-template matStepLabel>Credenciais</ng-template>

        <p class="pt-4 pb-2">
          <b>Provedor primário
            {{ getPspNameByIntegrationTypePix(f.integrationTypePix.value) }}</b>
        </p>
        <div fxLayoutGap="25px" fxLayout.lt-md="column" fxLayout.lg="column" fxLayout.md="row" fxLayout="row"
          fxLayoutAlign="space-around none">
          <mat-form-field class="w-full" *ngFor="let i = index; let config; of: providerConfig">
            <mat-label>{{
              getLabelName(config.alias ?? config.name)
              }}</mat-label>

            <ng-container *ngIf="f.integrationTypePix.value == 12 || f.integrationTypePix.value == 4; else notStone">
              <input [readonly]="true" (ngModelChange)="onChange(false)" matInput type="text" class="form-control"
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="config.hiddenValue" />
              <input [hidden]="true" type="text" [value]="config.value" />
            </ng-container>
            <ng-template #notStone>
              <input (ngModelChange)="onChange(false)" matInput type="text" class="form-control"
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="config.value" />
            </ng-template>
          </mat-form-field>
        </div>

        <p *ngIf="hasSecondaryPix" class="pt-4 pb-2">
          <b>Provedor secundário -
            {{
            getPspNameByIntegrationTypePix(
            f.integrationTypePixSecondary.value
            )
            }}</b>
        </p>
        <div fxLayoutGap="25px" fxLayout.lt-md="column" fxLayout.lg="column" fxLayout.md="row" fxLayout="row"
          fxLayoutAlign="space-around none" *ngIf="hasSecondaryPix">
          <mat-form-field class="w-full" *ngFor="let i = index; let config; of: providerConfigSecondary">
            <mat-label>{{
              getLabelName(config.alias ?? config.name)
              }}</mat-label>
            <ng-container
              *ngIf="f.integrationTypePixSecondary.value == 12 || f.integrationTypePixSecondary.value == 4; else notStone2">
              <input [readonly]="true" (ngModelChange)="onChange(false)" matInput type="text" class="form-control"
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="config.hiddenValue" />
              <input [hidden]="true" type="text" [value]="config.value" />
            </ng-container>
            <ng-template #notStone2>
              <input (ngModelChange)="onChange(false)" matInput type="text" class="form-control"
                [ngModelOptions]="{ standalone: true }" [(ngModel)]="config.value" />
            </ng-template>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button id="go-stores" [disabled]="checkPropertyNotFilled() || loading" (click)="goToStores()"
            mat-raised-button color="primary">
            PRÓXIMO
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="storesValid">
        <ng-template matStepLabel>Estabelecimentos</ng-template>
        <div class="w-full pt-4" fxLayoutGap="25px">
          <div fxLayout="row">
            <div class="bg-card rounded-full border px-4" fxLayoutAlign="start center">
              <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
              <input [formControl]="searchCtrl" class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                placeholder="Pesquise os estabelecimentos por CNPJ ou Marca" type="text" />
            </div>

            <div *ngIf="loadingStore" class="ml-6" fxLayoutAlign="center center">
              <mat-spinner diameter="20"></mat-spinner>
            </div>
          </div>
        </div>

        <div class="card overflow-auto">
          <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [disabled]="hideButtons" (change)="$event ? masterToggle() : null" color="primary"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox [disabled]="hideButtons" (click)="$event.stopPropagation()" color="primary"
                  (change)="selectStoreProvider($event, row)" [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="cnpj">
              <th mat-header-cell *matHeaderCellDef>CNPJ</th>
              <td mat-cell *matCellDef="let element">
                {{ element.cnpj | mask : '00.000.000/0000-00' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="brand">
              <th mat-header-cell *matHeaderCellDef>Marca</th>
              <td mat-cell *matCellDef="let element">
                {{ element.brand }}
              </td>
            </ng-container>
            <ng-container matColumnDef="pix">
              <th mat-header-cell *matHeaderCellDef>Pix</th>
              <td mat-cell *matCellDef="let element">
                {{ element.pix != 'None' ? element.pix : '---' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="wallets">
              <th mat-header-cell *matHeaderCellDef>Carteiras Digitais</th>
              <td mat-cell *matCellDef="let element">
                {{ element.wallets }}
              </td>
            </ng-container>
            <ng-container matColumnDef="routerActive">
              <th mat-header-cell *matHeaderCellDef>Roteamento</th>
              <td mat-cell *matCellDef="let element">
                {{ element.routerActive ? 'Ativo' : 'Inativo' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <div fxLayout.lt-md="column" fxLayout.md="column" fxLayout="column" fxLayoutAlign="space-around center">
            <div class="center" *ngIf="
                (!dataSource.data || dataSource.data.length === 0) &&
                !loadingStore
              ">
              NENHUM ESTABELECIMENTO ENCONTRADO!
            </div>
            <div *ngIf="loadingStore" class="m-6" fxLayoutAlign="center center">
              <mat-spinner diameter="36"></mat-spinner>
            </div>
            <mat-paginator showFirstLastButtons [disabled]="hideButtons || loadingStore" [pageSize]="paging.pageSize"
              fxLayoutAlign="center" (page)="changePage($event)" [length]="paging.length"
              [pageSizeOptions]="[10, 50, 100, 1000]" aria-label="Select page">
            </mat-paginator>
          </div>
        </div>
        <mat-progress-bar *ngIf="loading" mode="determinate" [value]="progressBarValue"></mat-progress-bar>

        <div *ngIf="loading" class="mt-6" fxLayoutAlign="center center">
          <mat-spinner diameter="36"></mat-spinner>
        </div>

        <div *ngIf="!loading" fxLayout="row" class="pt-6" fxLayoutAlign="end center">
          <button id="go-to-test" [disabled]="
              hideButtons || checkIfHaveAtLeastOneStoreSelected() || loading
            " (click)="nextStepToSave()" mat-raised-button color="primary">
            {{ (f.hasStoneAccount.value != false || f.hasPagarmeAccount.value != false) && isCard == false ? 'PRÓXIMO' :
            'CREDENCIAR' }}
            <!-- {{ f.hasStoneAccount.value != false ? 'PRÓXIMO' : 'CREDENCIAR' }} -->
          </button>
        </div>
      </mat-step>

      <mat-step *ngIf="isCard == false && f.hasStoneAccount.value != false && f.hasPagarmeAccount.value != false"
        [stepControl]="storesValid">
        <!-- <mat-step *ngIf="f.hasStoneAccount.value != false" [stepControl]="storesValid"> -->
        <ng-template matStepLabel>Teste de Pagamento</ng-template>

        <p class="pt-6" style="margin-bottom: 15px">
          Este é um processo de validação do seu provedor de pagamento,
          disponibilizamos um teste para que você possa verificar se o provedor
          está funcionando corretamente.
        </p>
        <p style="margin-bottom: 15px">
          O sistema gerará uma cobrança de acordo com as informações contidas em
          "Dados do Provedor" acima e aparecerá um QR Code para que seja feito o
          pagamento, seguindo os critérios abaixo:
        </p>
        <li>
          Provedores Pix: o QRCode pode ser lido e pago utilizando o app do seu
          banco de preferência. Caso tenha incluídos dois provedores para
          utilizaçao do Roteamento de PSPs, o teste de pagamento deverá ser
          realizado nos dois provedores, para garantir pleno funcionamento.
        </li>
        <li>
          Provedores Carteira Digital: o QR Code deverá ser lido e pago
          utilizando o app da mesma carteira digital, porém utilizando uma outra
          conta diferente da exibida em "Dados do Provedor" acima.
        </li>
        <div style="margin-top: 15px" fxLayoutAlign="end">
          <button id="jump-test" [disabled]="hideButtons || loading" class="mr-3" *ngIf="showSkipTest" 
            mat-raised-button color="primary" (click)="skipPaymentTest()">
            Pular teste
          </button>

          <button id="execute-test" [disabled]="hideButtons || loading" *ngIf="!testWasSuccess" mat-raised-button
            color="primary" (click)="startTestPaymentFlow()">
            Executar teste
          </button>

          <button id="finish-all" [disabled]="hideButtons || loading" mat-raised-button color="primary"
            *ngIf="testWasSuccess" (click)="finish()">
            {{
            eventName == eventProvider.create
            ? 'Concluir cadastro'
            : 'Concluir edição'
            }}
          </button>
        </div>
        <mat-progress-bar *ngIf="loading" class="mt-3" mode="determinate" [value]="progressBarValue"></mat-progress-bar>
        <div *ngIf="loading" class="mt-6" fxLayoutAlign="center center">
          <mat-spinner diameter="36"></mat-spinner>
        </div>
      </mat-step>
    </mat-vertical-stepper>

    <div [ngClass]="{ 'center-timeline': providers?.events?.length == 1 }" style="padding-bottom: 84px"
      class="m-auto w-full md:w-2/5">
      <div class="navigation relative flex-row mt-12">
        <div *ngFor="let event of providers.events">
          <span>
            <div class="timeline-date">
              {{
              event.transactionDate
              ? (event.transactionDate | date : 'dd/MM/yyyy HH:mm:ss')
              : '---'
              }}
            </div>
            <div matTooltip="{{ getEventDescription(event.event) }}" matTooltipPosition="above"
              class="marker timeline-icon marker-active" [style.background-color]="getEventColor(event.event)">
              <ic-icon [icon]="getEventIcon(event.event)" size="24px"></ic-icon>
            </div>
          </span>
        </div>
        <div *ngIf="providers?.events?.length > 1" class="timeline"></div>
      </div>
    </div>

    <button id="back-provider" *ngIf="hideButtons" (click)="backPage()" mat-raised-button color="primary">
      VOLTAR
    </button>
  </vex-page-layout-content>
</vex-page-layout>