export enum EventType {
    AUTHORIZATION = 'CONFIRMADO',
    CANCELMENT = 'DEVOLVIDO',
    CAPTURE = 'CAPTURA',
    ANTIFRAUD = 'ANTI-FRAUDE',
    REGISTRATION = 'REGISTRO',
    PAYMENT = 'PAGO',
    TOKEN_EXPIRED = 'TOKEN EXPIRADO',
    CREATE = 'CRIADO',
    EXCLUSION = 'DESFEITO',
    VALIDATED = 'VALIDADO',
    ACTIVE = 'ATIVO',
    INACTIVE = 'INATIVO'
}
