import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'

@Pipe({
  name: 'convertTimezone'
})
export class ConvertTimezonePipe implements PipeTransform {
  transform (value: any, offset = 'UTC'): any {
    const date = moment.utc(value)
    if (offset.startsWith('UTC')) {
      const hours = parseInt(offset.replace('UTC', ''), 10)
      date.add(hours, 'hours')
    }
    return date.format('DD/MM/YYYY HH:mm')
  }
}
