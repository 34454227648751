/* eslint-disable @typescript-eslint/no-this-alias */
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DashboardService } from 'src/app/services/dashboard/dashboard.service'
import ApexCharts from 'apexcharts'
import dateFormat from 'dateformat'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { MatDialog } from '@angular/material/dialog'
import { PaymentsByWalletsComponent } from '../../dialogs/payments-by-wallets/payments-by-wallets.component'
import { DOCUMENT } from '@angular/common'
import { fromEvent, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { MessageDto } from '../../models/MessageDto'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'

@Component({
  selector: 'vex-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('chart', { static: true }) private chartElement: ElementRef
  @ViewChild('chart2', { static: true }) private chartElement2: ElementRef
  @ViewChild('chart3', { static: true }) private chartElement3: ElementRef

  displayedColumns: string[] = ['situacaoCobranca', 'quantidade', 'valor', 'percentual']
  cnpj: any = []
  canShowDashboard = false
  paymentsByStatus: any[] = []
  loadingChart1 = false
  loadingChart2 = false
  loadingChart3 = false

  chartElementEmpty = false
  chartElement2Empty = false
  chartElement3Empty = false

  chart: ApexCharts = null
  chart2: ApexCharts = null
  chart3: ApexCharts = null

  savedData: { cnpjs: string[], initialDate: string, finalDate: string }

  resizeSubscription$: Subscription
  clickSubscription$: Subscription

  constructor (
    private readonly matDialog: MatDialog,
    private readonly dashboardService: DashboardService,
    private readonly utilsService: UtilsService,
    private readonly accreditationService: AccreditationService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.canShowDashboard = utilsService.canShowElementByFunctionality('cliente-qrlinx.dashboard')

    this.resizeSubscription$ = fromEvent(window, 'resize').pipe(debounceTime(1000)).subscribe(() => { 
      if (this.savedData) {
        this.filterDashboard(this.savedData)
      }
    })
  }

  ngOnInit (): void {
    const elements = this.document.getElementsByClassName('buttonToggleCollapse')
    Array.from(elements).forEach(e => {
      this.clickSubscription$ = fromEvent(e, 'click').pipe(debounceTime(1000)).subscribe(() => this.filterDashboard(this.savedData))
    })
    this.showPendingMessages()
  }

  showPendingMessages (): void {
    this.accreditationService.getPendingMessages()
      .subscribe(
        (res: DefaultReturnObjDto<Array<MessageDto>>) => {
          if (res.data) {
            const messages = res.data
            messages.forEach(message => {
              this.matDialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                  dialogTitle: `${message.storeCnpj} - ${message.storeName}` ,
                  dialogText: message.text,
                  dialogConfirmText: 'Confirmar Leitura'
                }
              }).afterClosed().subscribe((result) => {
                if (result.event == 'CONFIRM') {
                  this.accreditationService.confirmReading(message.id)
                    .subscribe(res => { return })
                }
              })
            })
          }
        })
  }

  filterDashboard (data: { cnpjs: string[], initialDate: string, finalDate: string }): void {
    this.savedData = data
    if (data?.cnpjs?.length > 200) {
      return
    }

    if (this.loadingChart1 ||
      this.loadingChart2 ||
      this.loadingChart3) {
      return
    }

    this.cnpj = data.cnpjs
    if (this.cnpj.length == 0) {
      return
    }

    this.initSearch()

    this.dashboardService.getPaymentsByStatus({
      CnpjLoja: data.cnpjs,
      DataInicio: data.initialDate + 'T00:00:00',
      DataFim: data.finalDate + 'T23:59:59'
    }).subscribe(res => {
      this.loadingChart1 = false
      if (res.dados.length) {
        this.paymentsByStatus = res.dados
        this.chart = new ApexCharts(
          this.chartElement.nativeElement,
          {
            legend: {
              position: 'bottom',
              floating: false,
              fontSize: '13px',
              fontFamily: 'Helvetica, Arial',
              fontWeight: 400
            },
            series: [{
              name: 'Quantidade de Cobranças',
              type: 'column',
              data: res.dados.map(e => Number(this.round(e.quantidade)))
            }, {
              name: 'Percentual',
              type: 'line',
              data: res.dados.map(e => Number(this.round(e.percentual)))
            }],
            colors: ['#6E4B87', '#F56F23'],
            fill: {
              colors: ['#6E4B87', '#F56F23']
            },
            chart: {
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              },
              height: 300,
              width: '100%',
              type: 'line'
            },
            stroke: {
              width: [0, 4]
            },
            title: {
              style: {
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#616161'
              },
              text: 'Quantidade de Cobranças por Status'
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: [1]
            },
            labels: res.dados.map(e => this.utilsService.convertPaymentStatus(e.situacaoCobranca)),
            xaxis: {
              type: 'category'
            },
            yaxis: [{
              title: {
                text: 'Quantidade de Cobranças'
              }

            }, {
              opposite: true,
              title: {
                text: 'Percentual'
              }
            }]
          }
        )

        this.chart.render()
      } else {
        this.chartElementEmpty = true
      }
    }, () => {
      this.loadingChart1 = false
    })

    this.dashboardService.getPaymentsByWallets({
      CnpjLoja: data.cnpjs,
      DataInicio: data.initialDate + 'T00:00:00',
      DataFim: data.finalDate + 'T23:59:59'
    }).subscribe(res => {
      const self = this
      this.loadingChart2 = false
      if (res.dados.length) {
        this.chart2 = new ApexCharts(
          this.chartElement2.nativeElement,
          {
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true
                    },
                    value: {
                      show: true
                    },
                    total: {
                      show: true
                    }
                  }
                }
              }
            },
            title: {
              style: {
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#616161'
              },
              align: 'center',
              text: 'Transações Aprovadas por Carteira Digital'
            },
            colors: ['#6E4B87', '#F56F23', '#280A3C', '#FEAD2E', '#411E5A'],
            fill: {
              colors: ['#6E4B87', '#F56F23', '#280A3C', '#FEAD2E', '#411E5A']
            },
            labels: res.dados.map(e => e.carteira),
            series: res.dados.map(e => e.quantidade),
            chart: {
              events: {
                dataPointSelection: function (event, chartContext, config) {
                  self.matDialog.open(PaymentsByWalletsComponent, {
                    width: '40%',
                    data: {
                      cnpj: data.cnpjs,
                      initialDate: data.initialDate,
                      finalDate: data.finalDate,
                      walletName: config.w.config.labels[config.dataPointIndex],
                      walletId: config.w.config.xaxis.categories[config.dataPointIndex]
                    }
                  })
                }
              },
              width: '100%',
              type: 'donut'
            },
            legend: {
              position: 'bottom'
            },
            dataLabels: {
              enabled: true
            },
            xaxis: {
              type: 'category',
              categories: res.dados.map(e => e.idCarteira)
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: '100%'
                  // width: 400
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
        )

        this.chart2.render()
      } else {
        this.chartElement2Empty = true
      }
    }, () => {
      this.loadingChart2 = false
    })

    this.dashboardService.getPaymentsResponseTime({
      CnpjLoja: data.cnpjs,
      DataInicio: data.initialDate + 'T00:00:00',
      DataFim: data.finalDate + 'T23:59:59'
    }).subscribe(res => {
      this.loadingChart3 = false
      if (res.dados.length) {
        const filterPositiveValues = res.dados.filter(e => e.tempoMedioAutorizacao >= 0 && e.tempoMedioPagamento >= 0)
        this.chart3 = new ApexCharts(
          this.chartElement3.nativeElement,
          {
            series: [{
              name: 'Tempo Médio de Processamento da Transação',
              data: filterPositiveValues.map(e => e.tempoMedioAutorizacao)
            }, {
              name: 'Tempo Médio de Pagamento',
              data: filterPositiveValues.map(e => e.tempoMedioPagamento)
            }],
            title: {
              style: {
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#616161'
              },
              text: 'Tempo Médio de Processamento da Transação X Pagamento'
            },
            chart: {
              height: 300,
              type: 'area',
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            colors: ['#6E4B87', '#F56F23'],
            fill: {
              colors: ['#6E4B87', '#F56F23']
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'category',
              categories: filterPositiveValues.map(e => dateFormat(e.data, 'dd/mm/yyyy'))
            },
            tooltip: {
              x: {
                format: 'dd/MM/yyyy'
              }
            }
          }
        )

        this.chart3.render()
      } else {
        this.chartElement3Empty = true
      }
    }, () => {
      this.loadingChart3 = false
    })
  }

  initSearch (): void {
    this.paymentsByStatus = []
    this.chartElementEmpty = false
    this.chartElement2Empty = false
    this.chartElement3Empty = false
    this.loadingChart1 = true
    this.loadingChart2 = true
    this.loadingChart3 = true

    if (this.chart) {
      this.chart.destroy()
    }

    if (this.chart2) {
      this.chart2.destroy()
    }

    if (this.chart3) {
      this.chart3.destroy()
    }
  }

  round (number: number): number {
    return Math.round(number * 100) / 100
  }

  getRandomPosition (): string {
    const items = ['#6E4B87', '#F56F23', '#280A3C', '#FEAD2E', '#411E5A']
    return items[this.utilsService.getRandomInt(0, items.length)]
  }

  convertPaymentStatus = (convertPaymentStatus: number): string =>
    this.utilsService.convertPaymentStatus(convertPaymentStatus)

  ngOnDestroy (): void {
    this.resizeSubscription$.unsubscribe()
    this.clickSubscription$.unsubscribe()
  }
}
