import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import icAdd from '@iconify/icons-ic/twotone-add'
import icDelete from '@iconify/icons-ic/twotone-delete'
import icSearch from '@iconify/icons-ic/twotone-search'
import { GroupService } from 'src/app/services/group/group.service'
import { DefaultReturnDto } from '../../models/DefaultReturnDto'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialogRef } from '@angular/material/dialog'
import { Group } from '../../models/group'
import { PageEvent } from '@angular/material/paginator'

@Component({
  selector: 'vex-filter-groups',
  templateUrl: './filter-groups.component.html',
  styleUrls: ['./filter-groups.component.scss']
})
export class FilterGroupsComponent implements OnInit {
  loading: boolean
  form: FormGroup
  groups: MatTableDataSource<Group>
  groupsToAdd: Group[] = []
  icAdd = icAdd
  icSearch = icSearch
  icDelete = icDelete
  displayedColumns: string[] = ['actions', 'name']

  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }

  constructor (
    private readonly snackbar: MatSnackBar,
    private readonly groupService: GroupService,
    private readonly fb: FormBuilder,
    public readonly dialogRef: MatDialogRef<FilterGroupsComponent>
  ) {}

  ngOnInit (): void {
    this.groups = new MatTableDataSource()
    this.form = this.fb.group({
      name: ['', Validators.required]
    })
  }

  filterGroups (): void {
    if (this.loading || !this.form.valid) {
      this.loading = false
      this.groups.data = []
      this.paging.pageSize = 10
      this.paging.length = 0
      this.paging.actualPage = 1

      return
    }
    this.loading = true
    const params: { limit: number; currentPage: number; name?: string } = {
      limit: this.paging.pageSize,
      currentPage: this.paging.actualPage,
      name: this.form.get('name').value ?? ''
    }
    this.groupService.getGroups(params).subscribe(
      (res: DefaultReturnDto<Group>) => {
        this.loading = false
        if (res?.data?.length)
          res.data = res.data.map((e) => {
            if (
              this.groupsToAdd.findIndex((group) => group.id === e.id) != -1
            ) {
              e.checked = true
            }
            return e
          })

        this.groups.data = res.data
        this.paging.actualPage = res.paging.currentPage
        this.paging.length = res.paging.quantityRegisters
      },
      () => {
        this.loading = false
        this.snackbar.open('Nenhum grupo encontrado!', '', {
          duration: 4000
        })
      }
    )
  }

  addGroups (event: any, element: Group): void {
    if (event.checked) {
      this.groupsToAdd.push(element)
      return
    }

    const index = this.groupsToAdd.findIndex((e) => e.id === element.id)
    this.groupsToAdd.splice(index, 1)
  }

  addGroup (): void {
    if (!this.groupsToAdd.length) {
      this.snackbar.open('Você deve selecionar ao menos um grupo!', '', {
        duration: 4000
      })
      return
    }

    this.dialogRef.close({
      data: this.groupsToAdd
    })
  }

  changePage (event: PageEvent): void {
    this.loading = false
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.filterGroups()
  }
}
