<vex-page-layout>
  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="mt-0 mb-1"><b>Recebedores</b></h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">
    <div class="card overflow-auto -mt-16">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
          <span>Recebedores</span>
        </h2>

        <div class="bg-card rounded-full border px-4" fxFlex="400px" fxFlex.lt-md="auto" fxHide.xs fxLayout="row"
          fxLayoutAlign="start center">
          <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
          <input [formControl]="searchCtrl" class="px-4 py-3 border-0 outline-none w-full bg-transparent"
            placeholder="Procurar por CNPJ..." type="text" />
        </div>
        <span fxFlex></span>

        <button *ngIf="canList" (click)="fetchData()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
          matTooltip="Buscar recebedores" type="button">
          <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>

        <button *ngIf="canEdit" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
          matTooltip="Criar novo recebedor" type="button" [routerLink]="'/recipients/create'">
          <mat-icon [icIcon]="icAdd"></mat-icon>
        </button>
      </div>

      <table @stagger [dataSource]="dataSource" *ngIf="canList" class="w-full" mat-table matSort>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.externalId }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="document">
          <th mat-header-cell *matHeaderCellDef>CPF/CNPJ</th>
          <td mat-cell *matCellDef="let element">
            {{ element.document }}
          </td>
        </ng-container>

        <ng-container matColumnDef="receivePaymentsAutomatically">
          <th mat-header-cell *matHeaderCellDef>Transferência automática</th>
          <td mat-cell *matCellDef="let element">
            {{ element.receivePaymentsAutomatically ? 'Ativa' : 'Desativada' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="automaticAnticipationIsEnabled">
          <th mat-header-cell *matHeaderCellDef>Antecipação automática</th>
          <td mat-cell *matCellDef="let element">
            {{
            element.automaticAnticipationIsEnabled ? 'Ativa' : 'Desativada'
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span class="py-1 px-2 bg-green text-white rounded" *ngIf="element.status == 'Active'">Ativo</span>
            <span class="py-1 px-2 bg-red text-white rounded" *ngIf="element.status == 'Error'">Erro</span>
            <span class="py-1 px-2 bg-orange text-white rounded" *ngIf="element.status == 'Inactive'">Desativado</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAtUtc">
          <th mat-header-cell *matHeaderCellDef>Data de criação</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAtUtc | convertTimezone : 'UTC-3' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Opções</th>
          <td mat-cell *matCellDef="let element">
            <button [matMenuTriggerFor]="menu">
              <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button [routerLink]="'/recipients/edit-recipient/' + element.id" mat-menu-item>
                Editar
              </button>
              <button (click)="changeStatus(element, 'Inactive')" mat-menu-item *ngIf="element.status == 'Active'">
                Desativar
              </button>
              <button (click)="changeStatus(element, 'Active')" mat-menu-item *ngIf="
                  element.status == 'Inactive' && element.status != 'Error'
                ">
                Ativar
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <div fxLayoutGap="25px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout="column"
        fxLayoutAlign="space-around center">
        <div class="center" *ngIf="!canList">
          USUÁRIO NÃO TEM PERMISSÃO DE VISUALIZAÇÃO!
        </div>

        <div class="center" *ngIf="
            (!dataSource.data || dataSource.data.length === 0) &&
            !filter.cnpj &&
            !loading
          ">
          UTILIZE A BUSCA POR CNPJ PARA RETORNAR OS RECEBEDORES DE UMA LOJA
        </div>

        <div class="center" *ngIf="
            (!dataSource.data || dataSource.data.length === 0) &&
            filter.cnpj &&
            !loading
          ">
          NENHUM RECEBEDOR ENCONTRADO!
        </div>

        <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
          <mat-spinner diameter="36"></mat-spinner>
        </div>
      </div>
      <mat-paginator fxLayoutAlign="end" [pageSize]="paging.pageSize" (page)="changePage($event)"
        [length]="paging.length" [pageSizeOptions]="[10, 50, 100]" aria-label="Select page">
      </mat-paginator>
    </div>
  </vex-page-layout-content>
</vex-page-layout>