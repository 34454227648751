import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import { UserService } from 'src/app/services/user/user.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { environment } from 'src/environments/environment'
import { CreateUserDto } from '../../models/CreateUserDto'
import icAdd from '@iconify/icons-ic/twotone-add'
import icDelete from '@iconify/icons-ic/twotone-delete'
import { Group } from '../../models/group'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FilterGroupsComponent } from '../filter-groups/filter-groups.component'
import { GroupsDto, Users } from '../../models/users'

@Component({
  selector: 'vex-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  loading: boolean
  showCheckbox = false
  icAdd = icAdd
  icDelete = icDelete
  form: FormGroup
  groups: MatTableDataSource<GroupsDto>
  displayedColumns: string[] = [
    'name',
    'actions'
  ];

  constructor (
    private readonly fb: FormBuilder,
    private readonly snackbar: MatSnackBar,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<CreateUserComponent>,
    private readonly utilsService: UtilsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { user: Users }
  ) { }

  ngOnInit (): void {
    this.groups = new MatTableDataSource()

    this.form = this.fb.group({
      name: ['', Validators.required],
      internalUser: [''],
      email: ['', [Validators.required, Validators.email]]
    })

    if (this.data && this.data.user) {
      this.loading = true
      this.userService.getUser(this.data.user.id).subscribe((res) => {
        this.form = this.fb.group({
          name: [res.data.name, Validators.required],
          internalUser: [''],
          email: [res.data.email, [Validators.required, Validators.email]]
        })

        this.form.get('email').disable()

        this.onEmailChange()
        if (res.data.groups.find(e => e.id.toLocaleUpperCase() === environment.internalGroup.id)) {
          const userHasInternalGroup = res.data.groups.findIndex(e => e.id.toLocaleUpperCase() === environment.internalGroup.id)
          res.data.groups.splice(userHasInternalGroup, 1)
          this.form.get('internalUser').setValue(true)
        }
        this.groups.data = res.data.groups
        this.loading = false
      })
    }
  }

  onEmailChange (): void {
    this.showCheckbox = false
    const email = this.form.get('email').value
    const domain = email.substring(email.lastIndexOf('@') + 1)

    if (domain === 'linx.com.br' || domain === 'terceiroslinx.com.br')
      this.showCheckbox = true
  }

  removeGroup (group: Group): void {
    const data = this.groups.data
    data.splice(data.findIndex(e => e.id === group.id), 1)
    this.groups.data = data
  }

  add (): void {
    this.dialog.open(FilterGroupsComponent, {
      disableClose: true,
      minWidth: '40%'
    }).afterClosed().subscribe(res => {
      if (res.data)
        this.groups.data = [...this.groups.data, ...res.data].filter((elem, index, self) =>
          index === self.findIndex((t) => (t.id === elem.id))
        )
    })
  }

  saveUser (): void {
    if (this.loading || !this.form.valid) return

    this.loading = true
    const data: CreateUserDto = this.form.getRawValue()
    data.groups = []
    data.groups = this.groups.data.map(e => {
      return {
        groupId: e.id,
        id: e.id
      }
    })

    if (this.showCheckbox && this.form.get('internalUser').value)
      data.groups.push({
        groupId: environment.internalGroup.id,
        id: environment.internalGroup.id
      })

    if (this.data && this.data.user) {
      this.userService.updateUser(data).subscribe(() => {
        this.loading = false
        this.snackbar.open('Usuário atualizado com sucesso!', '', {
          duration: 4000
        })
        return this.dialogRef.close()
      }, (err) => {
        this.loading = false
        this.snackbar.open(this.utilsService.getErrorMessage(err, 'Erro ao atualizar o usuário. Tente novamente!'), '', {
          duration: 4000
        })
      })
    } else {
      this.userService.createUser(data).subscribe(() => {
        this.loading = false
        this.snackbar.open('Usuário cadastrado com sucesso!', '', {
          duration: 4000
        })
        return this.dialogRef.close()
      }, (err) => {
        this.loading = false
        this.snackbar.open(this.utilsService.getErrorMessage(err, 'Erro ao cadastrar usuário. Tente novamente!'), '', {
          duration: 4000
        })
      })
    }
  }

}
