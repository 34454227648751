<mat-card-content>
  <div
    fxLayoutGap="25px"
    fxLayout="column"
    fxLayoutAlign="space-around none"
    [formGroup]="form"
  >
    <mat-form-field>
      <mat-label>E-mail</mat-label>
      <input
        matInput
        formControlName="email"
        placeholder="E-mail do recebedor"
      />
      <mat-error *ngIf="form.get('email').hasError('email')"
        >E-mail do recebedor é inválido</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>Descrição</mat-label>
      <input
        matInput
        formControlName="description"
        placeholder="Descrição do recebedor"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Tipo</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of recipientTypes" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
    <mat-spinner diameter="36"></mat-spinner>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <button
      (click)="editRecipient()"
      class="ml-4"
      color="primary"
      mat-raised-button
      fxFlex="none"
      matTooltip="Editar recebedor"
      type="button"
    >
      Editar Recebedor
    </button>
  </div>
</mat-card-content>
