import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CreateTicketComponent } from './create-ticket.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatTableModule } from '@angular/material/table'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatDialogModule } from '@angular/material/dialog'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { FilterGroupsModule } from '../filter-groups/filter-groups.module'
import { MatIconModule } from '@angular/material/icon'
import { IconModule } from '@visurel/iconify-angular'
import { MatSelectModule } from '@angular/material/select'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker'

@NgModule({
  declarations: [CreateTicketComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    FilterGroupsModule,
    MatIconModule,
    IconModule,
    MatSelectModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule
  ],
  exports: [
    CreateTicketComponent
  ]
})
export class CreateTicketModule { }
