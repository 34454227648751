import { Component, Input } from '@angular/core'
import icTime from '@iconify/icons-ic/outline-timer'
import { PspAverage } from '../../models/PspDto'

@Component({
  selector: 'vex-average',
  templateUrl: './average.component.html',
  styleUrls: ['./average.component.scss']
})
export class AverageComponent {
  @Input() PspAverage: PspAverage;
  icTime = icTime;

  public getLogoByPspName (pspName: string): string {
    const psp = pspName.replace('Pix', '').replace(' ', '')
    return 'assets/img/logosParceiros/' + psp + 'Logo.png'
  }

}
