<h1 mat-dialog-title>Consulta de conta - {{providerName}}</h1>
<div mat-dialog-content>
    <form autocomplete="off" (submit)="save()" [formGroup]="form" fxLayout="column">
        <mat-form-field class="w-full" appearance="fill">
            <mat-label>CNPJ</mat-label>
            <input mask="00.000.000/0000-00" type="text" required formControlName="document" matInput>
            <mat-error *ngIf="form.get('document').hasError('required')">CNPJ é obrigatório</mat-error>
            <mat-error *ngIf="form.get('document').value && form.get('document').hasError('cnpjNotValid')">CNPJ inválido</mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button (click)="save()" type="submit" color="primary">PRÓXIMO</button>
</div>