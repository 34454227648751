import { Component, Input, OnInit } from '@angular/core'
import icHome from '@iconify/icons-ic/twotone-home'
import { trackByValue } from '../../utils/track-by'
import { Crumb } from '../../../app/layouts/client-layout/models/crumb'

@Component({
  selector: 'vex-breadcrumbs',
  template: `
    <div class="flex items-center">
      <vex-breadcrumb>
        <a [routerLink]="['/']">
          <ic-icon
            class="text-white"
            [icon]="icHome"
            inline="true"
            size="20px"
          ></ic-icon>
        </a>
      </vex-breadcrumb>
      <ng-container *ngFor="let crumb of crumbs; trackBy: trackByValue">
        <div class="w-1 h-1 bg-gray rounded-full ltr:mr-2 rtl:ml-2"></div>
        <vex-breadcrumb>
          <a class="text-white" [routerLink]="crumb.path">{{ crumb.name }}</a>
        </vex-breadcrumb>
      </ng-container>
    </div>
  `
})
export class BreadcrumbsComponent implements OnInit {
  @Input() crumbs: Crumb[] = []
  trackByValue = trackByValue
  icHome = icHome

  ngOnInit (): void {
    this.initBreadcrumbs()
  }

  private initBreadcrumbs () {
    const acumulator = ['/']
    this.crumbs = this.crumbs.map((crumb) => {
      crumb.path.forEach((pathSegment) => acumulator.push(pathSegment))
      crumb.path = [...acumulator]
      return crumb
    })
  }
}
