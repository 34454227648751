<h2 mat-dialog-title>Verifique suas credenciais</h2>
<mat-dialog-content>
	<div>
		<mat-label class="text-center" fxLayout="row" fxLayoutAlign="center center">
			Prezado cliente, foram registradas algumas tentativas sem sucesso para realização do teste de pagamento, por gentileza acione a equipe de suporte do LinxPay.
		</mat-label>

		<div fxLayout="column" fxLayoutAlign="center center">
			<div fxLayout="column">
				<button mat-raised-button color="primary" class="mt-3" (click)="clickCloseSuccess()">
					Fechar
				</button>
			</div>
		</div>
	</div>
</mat-dialog-content>