import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { DefaultReturnObjDto } from '../../../models/DefaultReturnObjDto'
import { ResponseApi, ResponseError } from '../../../models/ResponseApi'
import { CategoryDto } from '../../../models/StoreDto'

@Component({
  selector: 'vex-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss']
})
export class CategoryDialogComponent implements OnInit {
  loadingSaveCategory = false
  form: FormGroup

  constructor (
    public dialogRef: MatDialogRef<CategoryDialogComponent>,
    private readonly accreditationService: AccreditationService,
    private readonly utilsService: UtilsService,
    private readonly matSnackBar: MatSnackBar,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: CategoryDto
  ) {}

  ngOnInit (): void {
    this.form = new FormGroup({
      id: new FormControl(this.data?.id ?? ''),
      name: new FormControl(this.data?.name ?? '', Validators.required)
    })
  }

  clickCloseSuccess (): void {
    this._closeDialog()
  }

  SaveCategory (): void {
    if (!this.form.valid) return

    const data = this.form.value
    this.loadingSaveCategory = true
    if (data.id) {
      this._updateCategory(data)
    } else {
      this._saveCategory(data)
    }
  }

  private _saveCategory (category: CategoryDto): void {
    this.accreditationService.createCategory(category).subscribe(
      () => {
        this.matSnackBar.open('Categoria salva com sucesso!', '', {
          duration: 5000
        })

        this._closeDialog()
      },
      (res) => {
        this.loadingSaveCategory = false
        this.matSnackBar.open(
          this.utilsService.getErrorMessage(
            res,
            'Erro ao encerrar o ticket. Tente novamente!'
          ),
          '',
          {
            duration: 5000
          }
        )

        this._closeDialog()
      }
    )
  }

  private _updateCategory (category: CategoryDto): void {
    this.accreditationService.updateCategory(category).subscribe(
      (res: DefaultReturnObjDto<CategoryDto>) => {
        this.matSnackBar.open('Categoria atualizada com sucesso!', '', {
          duration: 5000
        })

        this._closeDialog()
      },
      (res: ResponseApi<CategoryDto>) => {
        const error = this._getCompleteMessageError(res.error.erros)
        this.loadingSaveCategory = false
        this.matSnackBar.open(error, '', {
          duration: 5000
        })
      }
    )
  }

  private _closeDialog () {
    this.dialogRef.close({ event: 'SUCCESS' })
  }

  private _getCompleteMessageError (erros: ResponseError[]): string {
    if (erros.length == 0) return 'Não foi possivel salvar a Categoria'

    let messageError = ''

    for (const index in erros) {
      messageError =
        messageError + (index == '0' ? '' : ' ') + erros[index].description
    }

    return messageError
  }
}
