<h1 mat-dialog-title>{{this.dataIn ?"Edite a marca":"Nova marca"}}</h1>
<div mat-dialog-content [formGroup]="brandForm">
  <mat-form-field appearance="fill">
    <mat-label>marca</mat-label>
    <input maxlength="100" formControlName="name" matInput placeholder="Informe o nome da marca">
    <mat-error *ngIf="brandForm.get('name').hasError('required')">É necessário informar o nome da Marca</mat-error>
    <mat-hint class="text-right w-full">{{brandForm.get('name').value?.length || 0}}/100</mat-hint> 
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button (click)="clickCloseSuccess()">Fechar</button>
  <button mat-raised-button color="primary" (click)="SaveBrand()">Salvar</button>
</div>
<div *ngIf="loadingSaveBrand" class="m-3" fxLayoutAlign="center center">
    <mat-spinner diameter="20"></mat-spinner>
</div>