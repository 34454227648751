<vex-page-layout>
    <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
        <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class=" mt-0 mb-1"><b>Consulta de Provedores de Pagamentos</b></h1>
                <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
            </div>
        </div>
    </vex-page-layout-header>
    <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">
        <div class="card overflow-auto -mt-16">
            <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
                <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none"
                    fxHide.xs>
                    <span>Provedores de Pagamentos</span>
                </h2>

                <div class="bg-card rounded-full border px-4" fxFlex="460px" fxFlex.lt-md="auto" fxHide.xs
                    fxLayout="row" fxLayoutAlign="start center">
                    <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
                    <input class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                        placeholder="Procurar por CNPJ ou Marca" type="text" [formControl]="brandFilter"
                        [matAutocomplete]="auto" matInput>
                    <mat-autocomplete [displayWith]="getOptionText" #auto="matAutocomplete"
                        (optionSelected)='setClientValue($event.option.value, true)'>
                        <mat-option *ngFor="let option of cnpjListOptions | async" [value]="option">
                            {{ option.name }}
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <mat-spinner diameter="20" *ngIf="loadingBrand"></mat-spinner>
            </div>

            <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
                <ng-container matColumnDef="cnpj">
                    <th mat-header-cell *matHeaderCellDef> CNPJ </th>
                    <td mat-cell *matCellDef="let element"> {{ element.cnpj | mask: 'CPF_CNPJ' }} </td>
                </ng-container>

                <ng-container matColumnDef="brandName">
                    <th mat-header-cell *matHeaderCellDef> Marca </th>
                    <td mat-cell *matCellDef="let element"> {{ element.brand_name }} </td>
                </ng-container>

                <ng-container matColumnDef="pix">
                    <th mat-header-cell *matHeaderCellDef> Pix </th>
                    <td mat-cell *matCellDef="let element"> {{ element.pix }} </td>
                </ng-container>

                <ng-container matColumnDef="wallets">
                    <th mat-header-cell *matHeaderCellDef> Carteiras Digitais </th>
                    <td mat-cell *matCellDef="let element"> {{ element.wallets }} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Ações </th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" (click)="openDetails(element.id)" matTooltip="Remover Grupo">
                            <mat-icon [icIcon]="icEye"></mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div fxLayoutGap="25px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout="column"
                fxLayoutAlign="space-around center">

                <div class="center" *ngIf="(!dataSource.data || dataSource.data.length === 0) && !loading">
                    Utilize o filtro de consulta para carregar os provedores.
                </div>

                <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
                    <mat-spinner diameter="36"></mat-spinner>
                </div>

            </div>


        </div>
    </vex-page-layout-content>
</vex-page-layout>