import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Ticket } from '../../layouts/client-layout/models/ticket'
import { PatchTicketDto } from '../../layouts/client-layout/models/patchTicketDto'
import { GetTicketDto } from '../../layouts/client-layout/models/getTicketDto'
import { DefaultReturnDto } from 'src/app/layouts/client-layout/models/DefaultReturnDto'
import { DefaultReturnObjDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjDto'
import { FilterGetTicketHistoryDto } from '../../layouts/client-layout/models/filterGetTicketHistoryDto'
import { environment } from '../../../environments/environment'
import { UtilsService } from '../utils/utils.service'
import { CreateTicketDto } from 'src/app/layouts/client-layout/models/CreateTicketDto'
import { MonitorSystem } from 'src/app/layouts/client-layout/models/MonitorSystem'
import { FilterConsolidateDto } from 'src/app/layouts/client-layout/models/FilterConsolidateDto'
import { PspDto } from 'src/app/layouts/client-layout/models/PspDto'
import { PspFilterDto } from 'src/app/layouts/client-layout/models/PspFilterDto'
import { PagedListDto } from 'src/app/layouts/client-layout/models/PagedListDto'
import { LocalStorangeService } from '../local-storage.service'
@Injectable({
  providedIn: 'root'
})
export class MonitoringService {
  private baseUrl = environment.monitoring.baseUrl + environment.monitoring.path

  private subscriptionKey = environment.monitoring.subscriptionKeyMonitoring

  constructor (
    private readonly httpClient: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly localStorangeService: LocalStorangeService
  ) {}

  getTickets (
    dateTimeBegin: string,
    filters: FilterGetTicketHistoryDto
  ): Observable<DefaultReturnDto<Ticket>> {
    const queryString = this.encodeQueryData(filters)
    return this.httpClient.get<DefaultReturnDto<Ticket>>(
      `${this.baseUrl}/v1/ticket/history/${dateTimeBegin}?${queryString}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  getTicket (ticketId: string): Observable<DefaultReturnObjDto<GetTicketDto>> {
    return this.httpClient.get<DefaultReturnObjDto<GetTicketDto>>(
      `${this.baseUrl}/v1/ticket/${ticketId}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  postTicket (ticket: CreateTicketDto): Observable<DefaultReturnDto<any>> {
    return this.httpClient.post<DefaultReturnDto<any>>(
      `${this.baseUrl}/v1/ticket`,
      ticket,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  patchTicket (
    ticket: PatchTicketDto,
    ticketId: string
  ): Observable<DefaultReturnObjDto<any>> {
    return this.httpClient.patch<DefaultReturnObjDto<any>>(
      `${this.baseUrl}/v1/ticket/${ticketId}`,
      ticket,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  getSystems (): Observable<DefaultReturnDto<MonitorSystem>> {
    return this.httpClient.get<DefaultReturnDto<MonitorSystem>>(
      `${this.baseUrl}/v1/system`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  encodeQueryData (data): string {
    const ret = []
    for (const d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    return ret.join('&')
  }

  getPsps (filter: FilterConsolidateDto): Observable<DefaultReturnDto<PspDto>> {
    return this.httpClient.get<DefaultReturnDto<PspDto>>(
      `${this.baseUrl}/v1/psp?startDate=${filter.startDate}&finalDate=${filter.endDate}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  getPspsPaged (
    filter: PspFilterDto
  ): Observable<DefaultReturnObjDto<PagedListDto<PspDto>>> {
    const params = Object.keys(filter)
      .filter((key) => filter[key] !== undefined && filter[key] !== null)
      .map((key) => `${key}=${filter[key]}`)
      .join('&')

    const url = `${this.baseUrl}/v1/psp/search${params ? `?${params}` : ''}`

    return this.httpClient.get<DefaultReturnObjDto<PagedListDto<PspDto>>>(
      `${url}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public updatePsp (data: PspDto): Observable<DefaultReturnObjDto<PspDto>> {
    return this.httpClient.put<DefaultReturnObjDto<PspDto>>(
      `${this.baseUrl}/v1/psp/`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }
}
