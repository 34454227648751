<mat-card-content>
  <div fxLayoutAlign="start center">
    <h1 mat-dialog-title>Edição da loja</h1>
  </div>
  <div [formGroup]="storeForm" class="rounded-none mb-5">
    <div fxLayout="row" class="flex" fxLayoutGap="15px">
      <mat-form-field class="flex-auto">
        <mat-label>Razão Social</mat-label>
        <input maxlength="100" formControlName="name" matInput />
        <mat-error *ngIf="f.name.invalid"
          >Razão social não pode ser vazio</mat-error
        >
        <mat-hint class="text-right w-full"
          >{{ storeForm.get('name').value?.length || 0 }}/100</mat-hint
        >
      </mat-form-field>
      <mat-form-field class="flex-auto">
        <mat-label>Nome Fantasia</mat-label>
        <input maxlength="100" matInput formControlName="tradingName" />
        <mat-error *ngIf="f.tradingName.invalid"
          >Nome Fantasia não pode ser vazio</mat-error
        >
        <mat-hint class="text-right w-full"
          >{{ storeForm.get('tradingName').value?.length || 0 }}/100</mat-hint
        >
      </mat-form-field>
    </div>
    <div fxLayout="row" class="flex mb-5">
      <mat-form-field class="flex-auto">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option value="Inativo"> Inativo </mat-option>
          <mat-option value="Ativo"> Ativo </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayoutAlign="start center">
    <h1 mat-dialog-title>Funcionalidades</h1>
  </div>
  <div class="mb-5" *ngFor="let funcionality of allFunctionalies">
    <div class="flex mb-1 items-center justify-between">
      <div class="flex flex-col">
        <div class="flex items-center">
          <p>{{ funcionality.name }}</p>
          <mat-icon
            class="text-primary text-[15px] ml-2"
            [icIcon]="icInfo"
            *ngIf="funcionality.info"
            [matTooltip]="funcionality.info"
            matTooltipPosition="above"
          ></mat-icon>
        </div>
        <p class="text-gray text-sm">{{ funcionality.description }}</p>
      </div>

      <mat-slide-toggle
        color="primary"
        [(ngModel)]="funcionality.status"
      ></mat-slide-toggle>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="close()">Fechar</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!this.storeForm.valid"
      (click)="save()"
    >
      Salvar
    </button>
  </div>
  <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
    <mat-spinner diameter="36"></mat-spinner>
  </div>
</mat-card-content>
