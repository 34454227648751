<a
  rel="noopener"
  href="https://static.linxpayhub.com.br/qrlinx/pdf/WalletHub-Manual_Portal_do_Cliente.pdf"
  target="_blank"
>
  <div mat-dialog-content fxLayout="row" fxLayoutAlign="center center">
    <img src="{{ imagePath }}" alt="QrLinx" />
  </div>
</a>
<div class="mt-3 mb-1" mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button
    mat-raised-button
    type="button"
    color="primary"
    (click)="clickClose()"
  >
    Fechar
  </button>
</div>
