import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DefaultReturnObjDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjDto'
import { GetStoresProvider } from 'src/app/layouts/client-layout/models/ProviderDto'
import {
  CancelPaymentTest,
  CheckPaymentTest,
  PaymentTest
} from 'src/app/layouts/client-layout/models/stores'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import iconDone from '@iconify/icons-ic/done'
import { PointOfSaleDto } from 'src/app/layouts/client-layout/models/StoreDto'

@Component({
  selector: 'vex-test-payments',
  templateUrl: './test-payments.component.html',
  styleUrls: ['./test-payments.component.scss']
})
export class TestPaymentsComponent implements OnInit {
  dialogObj: {
    isPedePronto: boolean
    clientDocument?: string
    providerId: string
    providerSecondaryId: string | null
    walletId: string
    storeProviders: Array<GetStoresProvider>
  }
  qrCodeString: string
  testAmount = 0.0
  elementType = 'url'
  paymentTestId: string
  paymentPosId: string
  paymentStates = [false, false, false, false, false]
  checkPaymentInterval: any
  loading = true
  showConfirmationButton = false
  showQrCode = false
  testEndStatus = false
  stageMessage = 'Criando Cobrança'
  iconDone = iconDone
  isClosed = false
  createPaymentWithProviderSecondary = false
  channelPdvToUse = 'Fisico'
  walletEPicPayId = '0ABA2308-A1AF-41EE-BC28-979E4049F4EF'
  constructor (
    private readonly accreditationService: AccreditationService,
    public matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TestPaymentsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isPedePronto: boolean
      clientDocument?: string
      providerId: string
      providerSecondaryId: string | null
      walletId: string
      storeProviders: Array<GetStoresProvider>
    }
  ) {
    this.dialogObj = data
  }

  ngOnInit (): void {
    this.dialogRef.disableClose = true

    this.dialogRef
      .backdropClick()
      .subscribe(() => clearInterval(this.checkPaymentInterval))

    this.dialogRef
      .beforeClosed()
      .subscribe(() => clearInterval(this.checkPaymentInterval))

    this._getPdvToUse()

    this.findPdvIfNotFindCreate(
      this.dialogObj.storeProviders[0]?.storeId,
      this.channelPdvToUse
    )
  }

  ngOnDestroy (): void {
    clearInterval(this.checkPaymentInterval)
  }

  findPdvIfNotFindCreate (storeId: string, channel: string): void {
    this.loading = true
    this.accreditationService
      .findPdvIfNotFindCreate(storeId, channel)
      .subscribe(
        (res: DefaultReturnObjDto<PointOfSaleDto>) => {
          setTimeout(() => {
            if (!this.isClosed) {
              this.CreatePaymentTest(
                this.dialogObj.providerId,
                this.dialogObj.storeProviders[0]?.storeId,
                channel,
                this.dialogObj.clientDocument
              )
            }
          }, 10000)
        },
        (error) => {
          this.clickClose()
          this.matSnackBar.open(
            'Não foi possivel criar o PDV para o pagamento de teste. Por favor tente novamente!',
            '',
            {
              duration: 5000
            }
          )
        }
      )
  }

  CreatePaymentTest (
    providerId: string,
    storeId: string,
    channel: string,
    clientDocument?: string
  ): void {
    const data = {
      clientDocument,
      providerId,
      storeId,
      channel
    }

    this.accreditationService.CreatePaymentTest(data).subscribe(
      (res: DefaultReturnObjDto<PaymentTest>) => {
        this.showQrCode = true
        this.paymentStates[0] = true
        this.stageMessage = 'Aguardando Pagamento'
        this.qrCodeString = res.data.qrcode
        this.testAmount = Number.parseFloat(res.data.amount)
        this.paymentTestId = res.data.paymentId
        this.paymentPosId = res.data.pointOfSaleId

        this.loading = false

        setTimeout(() => {
          this.loading = true
          if (!this.isClosed) this.CheckPayment()
        }, 10000)
      },
      (error) => {
        this.clickClose()
        console.error(error)
        this.matSnackBar.open(
          'Não foi possivel criar o pagamento de teste. Por favor tente novamente!',
          '',
          {
            duration: 5000
          }
        )
      }
    )
  }

  CheckPayment (): void {
    const stopCondition = ['Estornado', 'Cancelado', 'Desfeito']
    this.checkPaymentInterval = setInterval(() => {
      this.accreditationService.CheckPayment(this.paymentTestId).subscribe(
        (res: DefaultReturnObjDto<CheckPaymentTest>) => {
          if (res.data.status === 'Autorizado') {
            clearInterval(this.checkPaymentInterval)

            this.loading = false

            if (this.dialogObj.isPedePronto) {
              this.RefundPayment()
              return
            }

            this.stageMessage = 'Aguardando confirmação manual'
            this.paymentStates[1] = true
            this.showQrCode = false

            this.showConfirmationButton = true
          } else {
            if (stopCondition.indexOf(res.data.status) >= 0) {
              clearInterval(this.checkPaymentInterval)
              this.loading = false
              this.matSnackBar.open(
                'Não é possivel verificar o status do pagamento de teste. Por favor tente novamente!',
                '',
                {
                  duration: 5000
                }
              )
              this.clickClose()
            }
          }
        },
        (error) => {
          this.isError(error)
        }
      )
    }, 10000)
  }

  manualPaymentConfirmation (): void {
    this.loading = true
    const data = {
      pos_id: this.paymentPosId,
      payment: {
        paymentId: this.paymentTestId,
        amount: this.testAmount
      }
    }

    this.accreditationService.ManualPaymentConfirmation(data).subscribe(
      () => {
        this.loading = false
        this.showConfirmationButton = false
        this.paymentStates[2] = true
        this.stageMessage = 'Confirmando Pagamento'
        this.RefundPayment()
      },
      () => {
        this.clickClose()
        this.showConfirmationButton = false
        this.matSnackBar.open(
          'Não foi possivel confirmar o pagamento de teste. Por favor tente novamente!',
          '',
          {
            duration: 5000
          }
        )
      }
    )
  }

  RefundPayment (): void {
    const data = {
      payment_id: this.paymentTestId,
      pos_id: this.paymentPosId,
      wallet_id: this.dialogObj.walletId,
      value: this.testAmount
    }

    this.loading = true
    this.stageMessage = 'Devolvendo Pagamento'
    this.accreditationService.RefundPayment(data).subscribe(
      (res: DefaultReturnObjDto<CancelPaymentTest>) => {
        if (
          !this.dialogObj.providerSecondaryId ||
          this.createPaymentWithProviderSecondary
        ) {
          this.loading = false
          this.testEndStatus = true
          this.paymentStates[3] = true
          this.stageMessage = ''
          return
        }

        this._initPaymentStatesForSecondaryProvider()

        this.stageMessage =
          'Agora será realizado os mesmos procedimentos com o provedor secundário'
        setTimeout(() => {
          this.stageMessage = 'Criando cobrança com provedor secundário'
          this.CreatePaymentTest(
            this.dialogObj.providerSecondaryId,
            this.dialogObj.storeProviders[0]?.storeId,
            this.channelPdvToUse
          )
        }, 1000)
      },
      () => {
        this.clickClose()
        this.loading = false
        this.matSnackBar.open(
          'Não foi possivel devolver o pagamento de teste. Entre em contato com o suporte!',
          '',
          {
            duration: 5000
          }
        )
      }
    )
  }
  private _initPaymentStatesForSecondaryProvider () {
    this.paymentStates[0] = false
    this.paymentStates[1] = false
    this.paymentStates[2] = false
    this.paymentStates[3] = false
    this.paymentStates[4] = true
    this.createPaymentWithProviderSecondary = true
  }

  private _getPdvToUse (): void {
    if (this.dialogObj.walletId.toUpperCase() == this.walletEPicPayId) {
      this.channelPdvToUse = 'Online'
    } else {
      this.channelPdvToUse = 'Fisico'
    }
  }

  clickCloseSuccess (): void {
    this.dialogRef.close({ event: 'SUCCESS' })
  }

  clickClose (): void {
    this.dialogRef.close({ event: 'CLOSE' })
  }

  closeModal (): void {
    clearInterval(this.checkPaymentInterval)
    this.isClosed = true

    if (this.paymentStates[1] || !this.paymentTestId)
      this.dialogRef.close({ event: 'CLOSE' })
    else {
      this.accreditationService.CheckPayment(this.paymentTestId).subscribe(
        (res: DefaultReturnObjDto<CheckPaymentTest>) => {
          if (res.data.status === 'Autorizado') {
            this.loading = false
            this.stageMessage = 'Aguardando confirmação manual'
            this.paymentStates[1] = true
            this.showQrCode = false

            this.showConfirmationButton = true
          } else this.dialogRef.close({ event: 'CLOSE' })
        },
        (error) => {
          this.isError(error)
        }
      )
    }
  }

  isError (error: any): void {
    this.loading = false
    console.error(error)
    clearInterval(this.checkPaymentInterval)
    this.clickClose()
    this.matSnackBar.open(
      'Não foi possivel verificar o status do pagamento de teste. Por favor tente novamente!',
      '',
      {
        duration: 5000
      }
    )
  }
}
