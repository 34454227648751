<h1 mat-dialog-title>{{ (data && data.user) ? 'Edição' : 'Cadastro' }} de Usuários</h1>
<div mat-dialog-content>
    <form autocomplete="off" (submit)="saveUser()" [formGroup]="form" fxLayout="column">
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <mat-form-field class="w-full" appearance="fill">
                <mat-label>Nome do Usuário</mat-label>

                <input type="text" formControlName="name" matInput>
                <mat-error *ngIf="form.get('name').hasError('required')">Nome do usuário é obrigatório</mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" appearance="fill">
                <mat-label>E-mail do Usuário</mat-label>

                <input (input)="onEmailChange()" type="email" formControlName="email" matInput>
                <mat-error *ngIf="form.get('email').hasError('required')">E-mail do usuário é obrigatório</mat-error>
                <mat-error *ngIf="form.get('email').hasError('email')">E-mail do usuário é inválido</mat-error>
            </mat-form-field>
        </div>

        <h2 fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-title>
            Grupos
            <button (click)="add()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
                matTooltip="Adicionar Grupos" type="button">
                <mat-icon [icIcon]="icAdd"></mat-icon>
            </button>
        </h2>

        <table *ngIf="groups.data && groups.data.length" mat-table [dataSource]="groups" class="w-full">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element">
                    {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Ações </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" (click)="removeGroup(element)" matTooltip="Remover Grupo">
                        <mat-icon [icIcon]="icDelete"></mat-icon>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="!groups.data || !groups.data.length" class="w-full center">
            VINCULE UM GRUPO AO USUÁRIO!
        </div>

        <div *ngIf="showCheckbox" fxLayout="row" fxLayoutGap="16px" class="mt-2" fxLayoutAlign="start center">
            <mat-checkbox formControlName="internalUser" color="primary">Adicionar grupo interno Linx</mat-checkbox>
        </div>

        <div *ngIf="!loading" class="mt-3 mb-1" mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
            <button mat-button mat-dialog-close>FECHAR</button>
            <button mat-raised-button type="submit" color="primary">{{ (data && data.user) ? 'ATUALIZAR' : 'CADASTRAR' }}</button>
        </div>

        <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
        </div>
    </form>

</div>