import { Location } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { MonitoringService } from 'src/app/services/monitoring/monitoring.service'
import { Crumb } from '../../../models/crumb'
import { DefaultReturnObjDto } from '../../../models/DefaultReturnObjDto'
import { StoreProviders } from '../../../models/StoresProviders'
import { DefaultReturnDto } from '../../../models/DefaultReturnDto'
import { PspDto } from '../../../models/PspDto'
import { FilterConsolidateDto } from '../../../models/FilterConsolidateDto'

@Component({
  selector: 'vex-create-provider',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.scss'],
  animations: [
    stagger40ms
  ]
})
export class ProviderDetailsComponent implements OnInit {
  loading = true
  loadingPsp = true
  storeId: string
  storeProviders: StoreProviders
  breadcrumbs: Crumb[] = [
    { name: 'Consulta de Provedores de Pagamentos', path: ['support-provider'] },
    { name: 'Detalhes do Provedor', path: [this.router.url] }
  ]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  psps: PspDto[]
  startDateSearch: string
  endDateSearch: string

  constructor (
    private readonly accreditationService: AccreditationService,
    private readonly monitoringService: MonitoringService,
    private readonly router: Router,
    public matSnackBar: MatSnackBar,
    public location: Location,
    public activatedRoute: ActivatedRoute
  ) {
    this.storeId = this.activatedRoute.snapshot.params.id
  }

  ngOnInit (): void {
    this._getProvidersDetails()
  }

  private _getProvidersDetails (
  ): void {
    this.accreditationService.getStoreInfo(this.storeId).subscribe(
      (res: DefaultReturnObjDto<StoreProviders>) => {
        this.storeProviders = res.data
        this.loading = false

        const date = new Date()
        date.setMinutes(new Date().getMinutes() - 2)
        
        this._getPspsStatus({
          startDate: date.toISOString(),
          endDate: new Date().toISOString()
        }) 
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Não foi possível listar os dados do estabelecimento. Tente novamente!', '', {
          duration: 4000
        })
      }
    )    
  }

  AdapterProviderPriority (value: string): string {
    return value == 'Primary' ? 'Primário' : 'Secundário'
  }

  getLabelName (name: string): string {
    return this.capWords(name.replace('_', ' '))
  }

  capWords (str: string): string {
    const strArr = str.split(' ')
    for (let i = 0; i <= str.length; i++) {
      if (strArr[i])
        strArr[i] = strArr[i][0].toUpperCase() + strArr[i].substring(1)
    }
    return strArr.join(' ')
  }

  private _getPspsStatus (filter: FilterConsolidateDto): void {
    this.loadingPsp = true
    this.monitoringService.getPsps(filter).subscribe(
      (res: DefaultReturnDto<PspDto>) => {
        this.psps = res.data
        this.psps.forEach((element) => {     
          const provider = this.storeProviders.providers.find(
            (item) => item.pix === element.integrationTypePix && item.status == 'Ativo'
          )    
          if (provider)  
            provider.status_psp = element.warning
        })
        if (this.storeProviders.providers) {
          const providerPix = this.storeProviders.providers.filter((provider) => provider.pix != 'None' && provider.status == 'Ativo')
          if (providerPix?.length) {
            const primaryPsp = providerPix.find( i => i.priority == 'Primary')
            const secundaryPsp = providerPix.find( i => i.priority == 'Secondary')

            if (primaryPsp.status_psp && !secundaryPsp.status_psp) {
              primaryPsp.status_psp = 'Alerta de instabilidade'
              secundaryPsp.status_psp = 'Transacionando'
            } else if (primaryPsp.status_psp && secundaryPsp.status_psp) {
              primaryPsp.status_psp = 'Alerta de instabilidade'
              secundaryPsp.status_psp = 'Alerta de instabilidade'
            } else if (!primaryPsp.status_psp && secundaryPsp.status_psp) {
              primaryPsp.status_psp = 'Transacionando'
              secundaryPsp.status_psp = 'Alerta de instabilidade'
            } else {
              primaryPsp.status_psp = 'Transacionando'
              secundaryPsp.status_psp = 'Em espera'
            }
          } 
        }
        this.loadingPsp = false
      },
      () => {
        this.loadingPsp = false
        this.matSnackBar.open('Não foi possível carregar qual provedor esta transacionando. Tente novamente!', '', {
          duration: 4000
        })
      }
    )
  }
}
