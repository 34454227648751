export interface FilterBanksSearch {
  pageNumber: number
  pageSize: number
  searchBy?: string
  origin?: BankOriginEnum
}

export enum BankOriginEnum {
  QrLinx,
  ClientPortal
}
