import { Component, Inject, OnInit, Optional } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import dateFormat from 'dateformat'
import { MonitoringService } from 'src/app/services/monitoring/monitoring.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import icAdd from '@iconify/icons-ic/twotone-add'
import icDelete from '@iconify/icons-ic/twotone-delete'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Ticket } from '../../models/ticket'

@Component({
  selector: 'vex-finish-ticket',
  templateUrl: './finish-ticket.component.html',
  styleUrls: ['./finish-ticket.component.scss']
})
export class FinishTicketComponent implements OnInit {
  maxDate: Date = new Date()
  loading: boolean
  icAdd = icAdd
  icDelete = icDelete
  form: FormGroup
  groups: MatTableDataSource<Ticket>
  selectedSystem: string
  minDate: Date
  dateTimeBegin: string
  isReadOnly = true

  constructor (
    private readonly fb: FormBuilder,
    private readonly snackbar: MatSnackBar,
    private readonly monitoringService: MonitoringService,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<FinishTicketComponent>,
    private readonly utilsService: UtilsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { ticket: Ticket }
  ) { }

  ngOnInit (): void {
    this.groups = new MatTableDataSource()
    this.form = this.fb.group({
      ticketId: [''],
      status: [''],
      title: [''],
      system: [''],
      dateTimeBegin: [''],
      dateTimeEnd: [''],
      description: ['']
    })

    this.loading = true
    this.monitoringService.getTicket(this.data.ticket.ticketId).subscribe((res) => {
      this.minDate = new Date(res.data.dateTimeBegin)
      this.isReadOnly = (res.data.status.toString() == 'Closed')
      this.dateTimeBegin = dateFormat(res.data.dateTimeBegin, 'dd/mm/yyyy HH:MM')
      this.form = this.fb.group({
        ticketId: [res.data.ticketId],
        status: [{ value: res.data.status, disabled: true }],
        title: [{ value: res.data.title, disabled: true }],
        system: [{ value: res.data.system.name, disabled: true }],
        dateTimeBegin: [{ value: dateFormat(res.data.dateTimeBegin, 'yyyy-mm-dd HH:MM'), disabled: true }],
        dateTimeEnd: [{ value: dateFormat(res.data.dateTimeEnd, 'yyyy-mm-dd HH:MM:ss'), disabled: this.isReadOnly }, Validators.required],
        description: [{ value: res.data.description, disabled: true }]
      })
      this.loading = false
    })
  }

  save (): void {
    if (this.loading || !this.form.valid || this.isReadOnly) return

    this.loading = true
    const data: Ticket = this.form.getRawValue()

    this.monitoringService.patchTicket({
      dateTimeEnd: data.dateTimeEnd,
      status: 'Closed'
    }, data.ticketId).subscribe(() => {
      this.loading = false
      this.snackbar.open('Ticket encerrado com sucesso!', '', {
        duration: 4000
      })
      return this.dialogRef.close()
    }, (err) => {
      this.loading = false
      this.snackbar.open(this.utilsService.getErrorMessage(err, 'Erro ao encerrar o ticket. Tente novamente!'), '', {
        duration: 4000
      })
    })
  }
}
