import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatMenuModule } from '@angular/material/menu'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { RouterModule } from '@angular/router'
import { IconModule } from '@visurel/iconify-angular'
import { NgxMaskModule } from 'ngx-mask'
import { CardModule } from 'primeng/card'
import { TimelineModule } from 'primeng/timeline'
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module'
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module'
import { VexModule } from 'src/@vex/vex.module'
import { ConfigPanelModule } from '../../../@vex/components/config-panel/config-panel.module'
import { SidebarModule } from '../../../@vex/components/sidebar/sidebar.module'
import { FooterModule } from '../../../@vex/layout/footer/footer.module'
import { LayoutModule } from '../../../@vex/layout/layout.module'
import { QuickpanelModule } from '../../../@vex/layout/quickpanel/quickpanel.module'
import { SidenavModule } from '../../../@vex/layout/sidenav/sidenav.module'
import { ToolbarModule } from '../../../@vex/layout/toolbar/toolbar.module'
import { ClientLayoutComponent } from './client-layout.component'
import { FilterCnpjDataModule } from './components/filter-cnpj-data/filter-cnpj-data.module'
import { FilterCnpjsDataModule } from './components/filter-cnpjs-data/filter-cnpjs-data.module'
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component'
import { CreateBatchRegistrationModule } from './dialogs/create-batch-registration/create-batch-registration.module'
import { CreateGroupModule } from './dialogs/create-group/create-group.module'
import { UpdateGroupModule } from './dialogs/update-group/update-group.module'
import { CreateTicketModule } from './dialogs/create-ticket/create-ticket.module'
import { CreateUserModule } from './dialogs/create-user/create-user.module'
import { FilterStoreModule } from './dialogs/filter-store/filter-store.module'
import { FinishTicketModule } from './dialogs/finish-ticket/finish-ticket.module'
import { PaymentsByWalletsComponent } from './dialogs/payments-by-wallets/payments-by-wallets.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { CreateProviderComponent } from './pages/provider/create-provider/create-provider.component'
import { ProviderDetailsDialog } from './pages/provider/create-provider/dialog/provider-details.component'
import { ProviderComponent } from './pages/provider/provider.component'
import { TestPaymentsComponent } from './pages/provider/create-provider/dialog/test-payments/test-payments.component'
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode'
import { MatRadioModule } from '@angular/material/radio'
import { ProviderDetailsComponent } from './pages/support-provider/provider-details/provider-details.component'
import { SupportProviderComponent } from './pages/support-provider/support-provider.component'
import { BannerDialogComponent } from './dialogs/banner-dialog/banner-dialog.component'
import { WarningMultiplePspComponent } from './pages/provider/create-provider/dialog/warning-multiple-psp/warning-multiple-psp.component'
import { MercadoPagoAuthorizationComponent } from './pages/provider/create-provider/dialog/mercado-pago-authorization/mercado-pago-authorization.component'
import { MatChipsModule } from '@angular/material/chips'
import { PointOfSaleComponent } from './pages/point-of-sale/point-of-sale.component'
import { ValidarCredenciaisComponent } from './pages/provider/create-provider/dialog/validar-credenciais/validar-credenciais.component'
import { OperationsProviderEditComponent } from './pages/operations-provider-edit/operations-provider-edit.component'
import { BrandsComponent } from './pages/brands/brands.component'
import { CreateBrandComponent } from './pages/brands/create-brand/create-brand.component'
import { StoresComponent } from './pages/stores/stores.component'
import { DialogFilterStoresComponent } from './pages/stores/dialog-filter-stores/dialog-filter-stores.component'
import { AutomationsComponent } from './pages/automations/automations.component'
import { CreateSoftwarePdvComponent } from './pages/automations/create-software-pdv/create-software-pdv.component'
import { ProviderHistoryComponent } from './pages/provider-history/provider-history.component'
import { DialogFilterProviderHistoryComponent } from './pages/provider-history/dialog-filter-provider-history/dialog-filter-provider-history.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { CategoriesComponent } from './pages/categories/categories.component'
import { CategoryDialogComponent } from './pages/categories/category-dialog/category-dialog.component'
import { StoreStatusProcessingComponent } from './pages/store-status-processing/store-status-processing.component'
import { MessageWithListComponent } from './dialogs/message-with-list/message-with-list.component'
import { MatStepperModule } from '@angular/material/stepper'
import { PayerDocumentComponent } from './pages/provider/create-provider/dialog/payer-document/payer-document.component'
import { MatTooltipModule } from '@angular/material/tooltip'
import { PendingRefundsComponent } from './pages/pending-refunds/pending-refunds.component'
import { PendingRefundDetailComponent } from './pages/pending-refunds/dialog/refund-detail/pending-refund-detail.component'
import { StatusPageComponent } from './pages/status-page/status-page.component'
import { AverageComponent } from './components/average/average.component'
import { DialogStoreEditComponent } from './pages/stores/dialog-store-edit/dialog-store-edit.component'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { PspsComponent } from './pages/psps/psps.component'
import { EditPspComponent } from './pages/psps/dialogs/edit-psp/edit-psp.component'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { DataService } from 'src/app/services/data/data.service'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { ProviderDocumentComponent } from './pages/provider/create-provider/dialog/provider-document/provider-document.component'
import { RecipientsComponent } from './pages/recipients/recipients.component'
import { DialogEditRecipientComponent } from './pages/recipients/dialog-edit-recipient/dialog-edit-recipient.component'
import { ConvertTimezonePipe } from 'src/app/pipes/ConvertTimezonePipe'
import { CreateRecipientComponent } from './pages/recipients/create-recipient/create-recipient.component'
import { CreatePagarmeAccountFormComponent } from './pages/provider/create-provider/components/create-pagarme-account-form/create-pagarme-account-form.component'

@NgModule({
  declarations: [
    ClientLayoutComponent,
    SupportProviderComponent,
    ProviderDetailsComponent,
    DashboardComponent,
    ProviderComponent,
    PaymentsByWalletsComponent,
    ConfirmDialogComponent,
    CreateProviderComponent,
    ProviderDetailsDialog,
    TestPaymentsComponent,
    BannerDialogComponent,
    MercadoPagoAuthorizationComponent,
    WarningMultiplePspComponent,
    PointOfSaleComponent,
    ValidarCredenciaisComponent,
    OperationsProviderEditComponent,
    BrandsComponent,
    CreateBrandComponent,
    StoresComponent,
    DialogFilterStoresComponent,
    AutomationsComponent,
    CreateSoftwarePdvComponent,
    ProviderHistoryComponent,
    DialogFilterProviderHistoryComponent,
    CategoriesComponent,
    CategoryDialogComponent,
    StoreStatusProcessingComponent,
    PendingRefundsComponent,
    PendingRefundDetailComponent,
    StatusPageComponent,
    AverageComponent,
    MessageWithListComponent,
    PayerDocumentComponent,
    DialogStoreEditComponent,
    PspsComponent,
    EditPspComponent,
    ProviderDocumentComponent,
    EditPspComponent,
    RecipientsComponent,
    CreateRecipientComponent,
    DialogEditRecipientComponent,
    ConvertTimezonePipe,
    CreatePagarmeAccountFormComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    SidenavModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    MatRadioModule,
    FlexModule,
    ToolbarModule,
    FooterModule,
    ConfigPanelModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatStepperModule,
    SidebarModule,
    QuickpanelModule,
    MatDialogModule,
    MatTableModule,
    FilterCnpjDataModule,
    FormsModule,
    FilterCnpjsDataModule,
    CreateGroupModule,
    CreateBatchRegistrationModule,
    FilterStoreModule,
    CreateUserModule,
    CreateTicketModule,
    FinishTicketModule,
    MatButtonModule,
    VexModule,
    BreadcrumbsModule,
    PageLayoutModule,
    MatIconModule,
    IconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    RouterModule,
    MatCardModule,
    MatTabsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    TimelineModule,
    CardModule,
    NgxMaskModule,
    NgxQRCodeModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatMenuModule,
    UpdateGroupModule
  ],
  providers: [
    DataService,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
  ]
})
export class ClientLayoutModule {}
