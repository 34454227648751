import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { ProvidersStoreDto } from 'src/app/layouts/client-layout/models/ProviderDto'

@Component({
  selector: 'vex-provider-details',
  templateUrl: './provider-details.component.html',
  animations: [
    stagger40ms
  ]
})

export class ProviderDetailsDialog {
  dialogObj: {
    name: string;
    cnpj: string;
    details: Array<ProvidersStoreDto>;
  }
  dataSource: MatTableDataSource<ProvidersStoreDto>;

  displayedColumns: string[] = ['nickname', 'name', 'createdAtUtc', 'status']
  constructor (public dialogRef: MatDialogRef<ProviderDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {
      name: string;
      cnpj: string;
      details: Array<ProvidersStoreDto>;
    }) {
    this.dataSource = new MatTableDataSource()
    this.dialogObj = data
    this.dataSource.data = this.dialogObj.details
  }

  closeDialog (): void {
    this.dialogRef.close()
  }
}
