import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ClientLayoutComponent } from './layouts/client-layout/client-layout.component'
import { DashboardComponent } from './layouts/client-layout/pages/dashboard/dashboard.component'
import { OperationsProviderEditComponent } from './layouts/client-layout/pages/operations-provider-edit/operations-provider-edit.component'
import { StoreStatusProcessingComponent } from './layouts/client-layout/pages/store-status-processing/store-status-processing.component'
import { PointOfSaleComponent } from './layouts/client-layout/pages/point-of-sale/point-of-sale.component'
import { CreateProviderComponent } from './layouts/client-layout/pages/provider/create-provider/create-provider.component'
import { BrandsComponent } from './layouts/client-layout/pages/brands/brands.component'
import { ProviderComponent } from './layouts/client-layout/pages/provider/provider.component'
import { StoresComponent } from './layouts/client-layout/pages/stores/stores.component'
import { ProviderDetailsComponent } from './layouts/client-layout/pages/support-provider/provider-details/provider-details.component'
import { SupportProviderComponent } from './layouts/client-layout/pages/support-provider/support-provider.component'
import { AutomationsComponent } from './layouts/client-layout/pages/automations/automations.component'
import { ProviderHistoryComponent } from './layouts/client-layout/pages/provider-history/provider-history.component'
import { CategoriesComponent } from './layouts/client-layout/pages/categories/categories.component'
import { PendingRefundsComponent } from './layouts/client-layout/pages/pending-refunds/pending-refunds.component'
import { StatusPageComponent } from './layouts/client-layout/pages/status-page/status-page.component'
import { PspsComponent } from './layouts/client-layout/pages/psps/psps.component'
import { RecipientsComponent } from './layouts/client-layout/pages/recipients/recipients.component'
import { CreateRecipientComponent } from './layouts/client-layout/pages/recipients/create-recipient/create-recipient.component'
import { environment } from 'src/environments/environment'
import { AuthGuard } from './services/sso-auth/can-activate.service'

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: '',
    component: ClientLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent
      },
      {
        path: 'statuspage',
        canActivate: [AuthGuard],
        component: StatusPageComponent
      },
      {
        path: 'psps',
        canActivate: [AuthGuard],
        component: PspsComponent
      },
      {
        path: 'support-provider',
        canActivate: [AuthGuard],
        component: SupportProviderComponent
      },
      {
        path: 'support-provider/:id',
        canActivate: [AuthGuard],
        component: ProviderDetailsComponent
      },
      {
        path: 'provider',
        canActivate: [AuthGuard],
        component: ProviderComponent
      },
      {
        path: 'pos',
        canActivate: [AuthGuard],
        component: PointOfSaleComponent
      },
      {
        path: 'operations-provider-edit',
        canActivate: [AuthGuard],
        component: OperationsProviderEditComponent
      },
      {
        path: 'store-status-processing',
        canActivate: [AuthGuard],
        component: StoreStatusProcessingComponent
      },
      {
        path: 'brands',
        canActivate: [AuthGuard],
        component: BrandsComponent
      },
      {
        path: 'automations',
        canActivate: [AuthGuard],
        component: AutomationsComponent
      },
      {
        path: 'pending-refunds',
        canActivate: [AuthGuard],
        component: PendingRefundsComponent
      },
      {
        path: 'stores',
        canActivate: [AuthGuard],
        component: StoresComponent
      },
      {
        path: 'categories',
        canActivate: [AuthGuard],
        component: CategoriesComponent
      },
      {
        path: 'provider/create-provider',
        canActivate: [AuthGuard],
        component: CreateProviderComponent
      },
      {
        path: 'provider/edit-provider/:id',
        canActivate: [AuthGuard],
        component: CreateProviderComponent
      },
      {
        path: 'provider-history',
        canActivate: [AuthGuard],
        component: ProviderHistoryComponent
      },
      {
        path: 'recipients',
        canActivate: [AuthGuard],
        component: RecipientsComponent
      },
      {
        path: 'recipients/create',
        canActivate: [AuthGuard],
        component: CreateRecipientComponent
      },
      {
        path: 'recipients/edit-recipient/:id',
        canActivate: [AuthGuard],
        component: CreateRecipientComponent
      },
      {
        path: 'transactions',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './layouts/client-layout/pages/transaction/transaction.module'
          ).then((m) => m.TransactionModule)
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./layouts/client-layout/pages/reports/reports.module').then(
            (m) => m.ReportsModule
          )
      },
      {
        path: 'config',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./layouts/client-layout/pages/config/config.module').then(
            (m) => m.ConfigModule
          )
      }
    ]
  }
]

if (environment.showLoginPage) {
  routes.push({
    path: 'login',
    loadChildren: () =>
      import('./pages/qr-auth/qr-auth.module').then((m) => m.QrAuthModule)
  })
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
