import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { DefaultReturnObjDto } from '../../../models/DefaultReturnObjDto'
import { FuncionalityDto } from '../../../models/FuncionalityDto'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { StoreDto } from '../../../models/StoreDto'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import icInfo from '@iconify/icons-ic/outline-info'

@Component({
  selector: 'vex-dialog-store-edit',
  templateUrl: './dialog-store-edit.component.html',
  styleUrls: ['./dialog-store-edit.component.scss']
})
export class DialogStoreEditComponent implements OnInit {
  icInfo = icInfo
  allFunctionalies: FuncionalityDto[]
  loading = false
  store: StoreDto
  storeForm: FormGroup

  constructor (
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DialogStoreEditComponent>,

    @Inject(MAT_DIALOG_DATA) public data: { store: StoreDto }
  ) {
    this.store = data.store
    this.storeForm = new FormGroup({
      cnpj: new FormControl(this.store.cnpj),
      name: new FormControl(this.store.name ?? '', Validators.required),
      tradingName: new FormControl(
        this.store.tradingName ?? '',
        Validators.required
      ),
      status: new FormControl(this.store.status ?? '', Validators.required)
    })
  }
  ngOnInit (): void {
    this.fetchData()
  }

  fetchData (): void {
    this.accreditationService.getAllFuncionalies().subscribe(
      (res: DefaultReturnObjDto<FuncionalityDto[]>) => {
        this.allFunctionalies = res.data
        if (
          this.store.storeFunctionalities != null &&
          this.store.storeFunctionalities.length > 0
        ) {
          this.allFunctionalies.forEach((element) => {
            const storeFunctionalies = this.store.storeFunctionalities.find(
              (item) => item.functionalityId == element.id
            )
            element.status =
              storeFunctionalies != null ? storeFunctionalies.status : false
          })
        } else {
          this.allFunctionalies.forEach((element) => {
            element.status = false
          })
        }
      },
      () => {
        this.matSnackBar.open('Nenhuma funcionalidade encontrada!', '', {
          duration: 5000
        })
      }
    )
  }

  save () {
    if (!this.storeForm.valid) return

    this.loading = true
    const data = this.storeForm.value

    data.storeFunctionalities = this.allFunctionalies.map((e) => {
      return { functionalityId: e.id, status: e.status }
    })

    this.accreditationService.UpdateStore(data).subscribe(
      (res: DefaultReturnObjDto<StoreDto>) => {
        if (res.data) {
          this.matSnackBar.open('Loja atualizada!', '', {
            duration: 5000
          })
          this.loading = false
          this.dialogRef.close({ event: 'Success' })
        }
      },
      () => {
        this.matSnackBar.open('Erro ao atualizar loja', '', {
          duration: 5000
        })
        this.loading = false
        this.dialogRef.close({ event: 'Error' })
      }
    )
  }

  get f (): any {
    return this.storeForm.controls
  }

  close () {
    this.dialogRef.close()
  }
}
