<vex-page-layout>
  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="mt-0 mb-1"><b>Atualizar Lojas em Lote</b></h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">
    <div class="card overflow-auto -mt-16">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none" fxHide.xs>
          <span>Atualizar Lojas em Lote</span>
        </h2>
        <span fxFlex></span>

        <button type="button" [disabled]="!disableProcess" (click)="file.click()" class="ml-4" color="primary"
          fxFlex="none" mat-raised-button matTooltip="Carregar Arquivo" type="button">
          {{ fileName }}
          <mat-icon [icIcon]="icFileUpload"></mat-icon>
        </button>
        <input #inputFile hidden type="file" accept=".csv" #file (change)="onFileChange(file.files)" />
        <button type="button" *ngIf="!disablePostFile" [disabled]="!disableProcess" (click)="clearFile()" class="ml-4"
          color="secondary" fxFlex="none" mat-mini-fab matTooltip="Filtro" type="button">
          <mat-icon [icIcon]="icTrash"></mat-icon>
        </button>

        <button type="button" *ngIf="!disablePostFile" [disabled]="!disableProcess"
          (click)="confirmUploadFile(file.files)" class="ml-4" color="primary" fxFlex="none" mat-raised-button
          matTooltip="Enviar arquivos" type="button">
          Processar
          <mat-icon [icIcon]="checkSmall"></mat-icon>
        </button>

        <button type="button" (click)="clickFilters()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
          matTooltip="Filtro" type="button">
          <mat-icon *ngIf="showFilters" [icIcon]="icFilterAltOff"></mat-icon>
          <mat-icon *ngIf="!showFilters" [icIcon]="icFilterAlt"></mat-icon>
        </button>

        <button *ngIf="canList" (click)="fetchData()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
          matTooltip="Add" type="button">
          <mat-icon [icIcon]="icSync"></mat-icon>
        </button>
      </div>
      <form [formGroup]="form" fxLayout="column">
        <div *ngIf="showFilters" class="bg-app-bar px-6 p-3 left-0" fxLayout="row" fxLayoutGap="16px"
          fxLayoutAlign="start center">
          <mat-form-field class="w-full" appearance="fill">
            <mat-label>Nome do usuário operador</mat-label>
            <input matInput formControlName="operatorUserName" type="text" />
            <mat-error *ngIf="form.get('operatorUserName').hasError('invalidUUID')">Identificador inválido</mat-error>
          </mat-form-field>

          <mat-form-field class="w-full" appearance="fill">
            <mat-label>Período de busca</mat-label>
            <mat-date-range-input [max]="maxPeriodDate" [rangePicker]="picker">
              <input matStartDate formControlName="initialDate" placeholder="Data inicial" />
              <input matEndDate formControlName="finalDate" placeholder="Data final" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="form.controls.initialDate.hasError('matStartDateInvalid')">
              Data inicial inválida
            </mat-error>
            <mat-error *ngIf="form.controls.initialDate.hasError('matDatepickerParse')">
              Formato da data inicial inválida
            </mat-error>
            <mat-error *ngIf="form.controls.finalDate.hasError('matEndDateInvalid')">
              Data final inválida
            </mat-error>
            <mat-error *ngIf="form.controls.finalDate.hasError('matDatepickerParse')">
              Formato da data final inválida
            </mat-error>
            <mat-error *ngIf="form.controls.finalDate.hasError('required')">Você deve preencher a data final
            </mat-error>
            <mat-error *ngIf="form.controls.initialDate.hasError('required')">Você deve preencher a data inicial
            </mat-error>
          </mat-form-field>

          <mat-form-field class="w-full" appearance="fill">
            <mat-label>Situação de processamento</mat-label>
            <mat-select (selectionChange)="fetchData()" formControlName="selectedStatus">
              <mat-option value="">Todos</mat-option>
              <mat-option value="Aguardando">Aguardando</mat-option>
              <mat-option value="Falha">Falha</mat-option>
              <mat-option value="Concluido">Concluido</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('selectedStatus').hasError('required')">Sistema/API é obrigatório</mat-error>
          </mat-form-field>
        </div>
      </form>

      <table @stagger [dataSource]="dataSource" *ngIf="canList" class="w-full" mat-table matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Usuário</th>
          <td mat-cell *matCellDef="let element">
            {{ element.user.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Data e Hora da Solicitação</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date : 'dd/MM/yyyy HH:mm:ss' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="batchProcessingHistoryStatus">
          <th mat-header-cell *matHeaderCellDef>Processamento do Arquivo</th>
          <td mat-cell *matCellDef="let element">
            {{ element.batchProcessingHistoryStatus }}
          </td>
        </ng-container>
        <ng-container matColumnDef="quantityRows">
          <th mat-header-cell *matHeaderCellDef>Quantidade de Registros</th>
          <td mat-cell *matCellDef="let element">
            {{ element.quantityRows }}
          </td>
        </ng-container>
        <ng-container matColumnDef="quantitySuccess">
          <th mat-header-cell *matHeaderCellDef>Quantidade de Sucessos</th>
          <td mat-cell *matCellDef="let element">
            {{ element.quantitySuccess }}
          </td>
        </ng-container>
        <ng-container matColumnDef="quantityError">
          <th mat-header-cell *matHeaderCellDef>Quantidade de Erros</th>
          <td mat-cell *matCellDef="let element">
            {{ element.quantityError }}
          </td>
        </ng-container>

        <ng-container matColumnDef="inputUrl">
          <th mat-header-cell class="text-center" *matHeaderCellDef>
            Arquivo Carregado
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <div *ngIf="element.inputUrl">
              <button (click)="downloadFile('input', element)" mat-button color="primary">
                <mat-spinner *ngIf="element.isLoadingInputUrl" diameter="22"></mat-spinner>
                <mat-icon *ngIf="!element.isLoadingInputUrl" [icIcon]="icDownload"></mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="outputUrl">
          <th mat-header-cell class="text-center" *matHeaderCellDef>
            Arquivo Retornado
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <div>
              <button [disabled]="element.batchProcessingHistoryStatus !== 'Concluido'"
                (click)="downloadFile('output', element)" mat-button color="primary">
                <mat-spinner *ngIf="element.isLoadingOutputUrl" diameter="22"></mat-spinner>
                <mat-icon *ngIf="!element.isLoadingOutputUrl" [icIcon]="icDownload"></mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <div *ngIf="loading" class="m-6" fxLayoutAlign="center">
        <mat-spinner diameter="36"></mat-spinner>
      </div>

      <div class="m-6" fxLayoutAlign="center" *ngIf="!canList">
        USUÁRIO NÃO TEM PERMISSÃO DE VISUALIZAÇÃO!
      </div>

      <div class="m-6" fxLayoutAlign="center" *ngIf="
          (!dataSource.data || dataSource.data.length === 0) &&
          !loading &&
          canList
        ">
        NENHUM REGISTRO ENCONTRADO!
      </div>

      <mat-paginator #paginator class="paginator-text" [length]="paging.length" [pageSize]="paging.pageSize"
        (page)="changePage($event)" [showFirstLastButtons]="true" [pageSizeOptions]="[10, 50, 100]"
        aria-label="Select page">
      </mat-paginator>
    </div>
  </vex-page-layout-content>
</vex-page-layout>