import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import icSearch from '@iconify/icons-ic/sharp-search'
import { OperationTypeDto } from '../../../models/OperationTypeDto'
import { ProviderHistoryFilter } from '../../../models/ProviderHistoryFilter'
import { DialogFilterStoresComponent } from '../../stores/dialog-filter-stores/dialog-filter-stores.component'
import { DateHelper } from 'src/app/helpers/date.helper'

@Component({
  selector: 'vex-dialog-filter-provider-history',
  templateUrl: './dialog-filter-provider-history.component.html',
  styleUrls: ['./dialog-filter-provider-history.component.scss']
})
export class DialogFilterProviderHistoryComponent implements OnInit {
  icSearch = icSearch;
  form!: FormGroup;
  searchCtrl: FormControl = new FormControl('');
  filter: ProviderHistoryFilter;
  operationTypes: Array<OperationTypeDto> = [
    { id: 'Added', name: 'Inclusão' },
    { id: 'Modified', name: 'Alteração' },
    { id: 'Deleted', name: 'Exclusão' }
  ];

  constructor (
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogFilterStoresComponent>,
    private readonly matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: ProviderHistoryFilter
  ) {
    this.filter = data
  }

  ngOnInit (): void {
    this.form = this.fb.group({
      cnpj: ['', [Validators.maxLength(14), Validators.minLength(14)]],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      operationType: ['']
    })

    this.form.get('cnpj').setValue(this.filter.cnpj ?? '')
    this.form.get('startDate').setValue(this.filter.startDate ?? '')
    this.form.get('endDate').setValue(this.filter.endDate ?? '')
    this.form.get('operationType').setValue(this.filter.operationType ?? 0)
  }

  getToday (): Date {
    return DateHelper.getToday()
  }

  public filterData () {
    this._validateDates()

    if (this.form.valid) {
      this.filter.cnpj = this.form.get('cnpj').value
      this.filter.startDate = this.form.get('startDate').value
      this.filter.endDate = this.form.get('endDate').value
      this.filter.operationType = this.form.get('operationType').value
      this.filter.pageNumber = 0
      
      this.dialogRef.close({ event: 'FILTER', filter: this.filter })
    }
  }

  _validateDates () {
    const startDate = this.form.get('startDate')
    const endDate = this.form.get('endDate')

    const days = DateHelper.daysBetweenDates(startDate.value, endDate.value)

    if (days > 7) {
      startDate.setErrors({ 'incorrect': true })
    } 
    else {
      startDate.setErrors(null)
    }

    startDate.markAsTouched()
  }

  public clearFilter () {
    this._cleanField(this.form.get('cnpj'))
    this._cleanField(this.form.get('startDate'), DateHelper.getTodayAddDays(-7))
    this._cleanField(this.form.get('endDate'), this.getToday())
    this._cleanField(this.form.get('operationType'))
  }

  _cleanField (field: AbstractControl, value: any = '') {
    field.setValue(value)
    field.setErrors(null)
    field.markAsUntouched()
  }

  _showMessage (message: string) {
    this.matSnackBar.open(message, '', {
      duration: 5000
    })
  }
}
