import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import icFilter from '@iconify/icons-ic/sharp-filter-alt'
import icExport from '@iconify/icons-ic/sharp-download'
import icSearch from '@iconify/icons-ic/twotone-search'
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz'
import icAdd from '@iconify/icons-ic/sharp-plus'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { Crumb } from '../../models/crumb'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { PagedListDto } from '../../models/PagedListDto'
import { MatDialog } from '@angular/material/dialog'
import { FormControl } from '@angular/forms'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'
import { FilterCategoriesSearch } from '../../models/FilterCategoriesSearch'
import { CategoryDto } from '../../models/StoreDto'
import { CategoryDialogComponent } from './category-dialog/category-dialog.component'

@Component({
  selector: 'vex-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  animations: [stagger40ms]
})
export class CategoriesComponent implements OnInit {
  icFilter = icFilter
  icExport = icExport
  icSearch = icSearch
  icMoreHoriz = icMoreHoriz
  icAdd = icAdd
  loading = true

  dataSource: MatTableDataSource<CategoryDto>
  displayedColumns: string[] = ['name', 'actions']
  canList: boolean
  canExportCsv: boolean

  breadcrumbs: Crumb[] = [{ name: 'Categorias', path: [''] }]
  @ViewChild(MatPaginator) paginator: MatPaginator

  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }

  filter: FilterCategoriesSearch = {
    pageNumber: this.paging.actualPage,
    pageSize: this.paging.pageSize
  }

  disableBtnExport = false

  searchCtrl: FormControl

  constructor (
    private readonly utilsService: UtilsService,
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.canList = this.utilsService.canShowElementByFunctionality('marca')
    this.canExportCsv = this.utilsService.canShowElementByFunctionality('marca')
    this.dataSource = new MatTableDataSource()
    this.searchCtrl = new FormControl()
  }

  ngOnInit (): void {
    this.fetchData()

    this.searchCtrl.valueChanges
      .pipe(debounceTime(500), startWith(''), distinctUntilChanged())
      .subscribe((val) => {
        if (!val.length || val.length >= 3) {
          this.filter.name = val
          this.filter.pageNumber = 0
          this.paging.actualPage = 0
          this.fetchData()
        }
      })
  }

  fetchData (): void {
    this.loading = true
    this.dataSource.data = []
    this.accreditationService.searchCategories(this.filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<CategoryDto>>) => {
        this.loading = false

        if (!res.data.pageItems.length) {
          this.matSnackBar.open('Nenhuma categoria encontrada!', '', {
            duration: 5000
          })
          this.dataSource.data = []
          return
        }

        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Nenhuma categoria encontrada!', '', {
          duration: 5000
        })
      }
    )
  }

  updateFilterObjectWithThePaging (): void {
    this.filter.pageNumber = this.paging.actualPage
    this.filter.pageSize = this.paging.pageSize
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.updateFilterObjectWithThePaging()
    this.fetchData()
  }

  public ExportCategoriesToCSV (): void {
    this.disableBtnExport = true
    this.matSnackBar.open(
      'O download do arquivo de exportação irá iniciar em breve.',
      '',
      {
        duration: 5000
      }
    )
    this.accreditationService
      .ExportCategoriesCsvFile(this.filter)
      .subscribe((response: Blob) => {
        this.utilsService.downloadFile(
          response,
          `RelatorioDeCategorias_${new Date().getTime()}`,
          '.csv',
          'text/csv'
        )
        this.disableBtnExport = false
      },
      (response) => {
        this.matSnackBar.open(response.error, '', {
          duration: 5000
        })
        this.disableBtnExport = false
      })
  }

  openCreateCategoryDialog () {
    this.dialog.open(CategoryDialogComponent, {
      width: '590px'
    })
  }
  openUpdateCategoryDialog (category: CategoryDto): void {
    this.dialog
      .open(CategoryDialogComponent, {
        data: category,
        width: '590px'
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) this.fetchData()
      })
  }
}
