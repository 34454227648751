<vex-page-layout>
  <vex-page-layout-header
    class="pb-16"
    fxLayout="column"
    fxLayoutAlign="center start"
  >
    <div
      [class.px-gutter]="true"
      class="w-full flex flex-col sm:flex-row justify-between"
    >
      <div>
        <h1 class="mt-0 mb-1"><b>Pontos de Venda</b></h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">
    <div class="card overflow-auto -mt-16">
      <div
        class="bg-app-bar px-6 h-16 border-b sticky left-0"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <h2
          class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l"
          fxFlex="none"
          fxHide.xs
        >
          Pontos de Venda
        </h2>

        <div
          class="bg-card rounded-full border px-4"
          fxFlex="550px"
          fxFlex.lt-md="auto"
          fxHide.xs
          style="margin-top: -0.085vh"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
          <input
            [formControl]="searchCtrl"
            class="px-4 py-3 border-0 outline-none w-full bg-transparent"
            placeholder="Procurar por CNPJ, descrição, código externo"
            type="text"
          />
        </div>

        <span fxFlex></span>
        <button
          *ngIf="canList"
          (click)="fetchDataPointOfSalePaged()"
          class="ml-4"
          color="primary"
          fxFlex="none"
          mat-mini-fab
          matTooltip="Buscar"
          type="button"
        >
          <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>

        <button
          [disabled]="btnBlockExportToCSV"
          (click)="ExportToCSV()"
          class="ml-4"
          color="primary"
          fxFlex="none"
          mat-mini-fab
          matTooltip="Exportar pontos de venda"
          type="button"
        >
          <mat-icon [icIcon]="icExport"></mat-icon>
        </button>
      </div>

      <div>
        <div id="tbPointOfSale">
          <table
            @stagger
            [dataSource]="dataSource"
            *ngIf="canList"
            class="w-full"
            mat-table
            matSort
          >
            <ng-container matColumnDef="brandName">
              <th mat-header-cell *matHeaderCellDef>Marca</th>
              <td mat-cell *matCellDef="let element">
                {{ element.store.brand.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="cnpj">
              <th mat-header-cell *matHeaderCellDef>CNPJ</th>
              <td mat-cell *matCellDef="let element">
                {{ element.store.cnpj | mask:'00.000.000/0000-00' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Descrição</th>
              <td mat-cell *matCellDef="let element">
                {{ element.description ?? '---' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="externalCode">
              <th mat-header-cell *matHeaderCellDef>Código Externo</th>
              <td mat-cell *matCellDef="let element">
                {{ element.external_code ?? '---' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Situação</th>
              <td mat-cell *matCellDef="let element">{{ element.status }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>

      <div
        fxLayout.lt-md="column"
        fxLayout.md="column"
        fxLayout="column"
        fxLayoutAlign="end"
      >
        <div class="center" *ngIf="!canList">
          USUÁRIO NÃO TEM PERMISSÃO DE VISUALIZAÇÃO!
        </div>

        <div
          class="center"
          fxLayoutAlign="center center"
          *ngIf="(!dataSource.data || dataSource.data.length === 0) && !loading"
        >
          NENHUM PONTO DE VENDA ENCONTRADO!
        </div>

        <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
          <mat-spinner diameter="36"></mat-spinner>
        </div>

        <mat-paginator
          [pageSize]="paging.pageSize"
          (page)="changePage($event)"
          [length]="paging.length"
          [pageSizeOptions]="[10, 50, 100]"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </vex-page-layout-content>
</vex-page-layout>
