import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DashboardService } from 'src/app/services/dashboard/dashboard.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import ApexCharts from 'apexcharts'

@Component({
  selector: 'vex-payments-by-wallets',
  templateUrl: './payments-by-wallets.component.html',
  styleUrls: ['./payments-by-wallets.component.scss']
})
export class PaymentsByWalletsComponent implements OnInit {
  loading = true
  @ViewChild('chart', { static: true }) private chartElement: ElementRef
  constructor (
    private readonly dashboardService: DashboardService,
    private readonly utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      walletId: string,
      walletName: string,
      cnpj: string[],
      initialDate: string,
      finalDate: string,
    }
  ) { }

  ngOnInit (): void {
    this.loading = true
    this.dashboardService.getPaymentsStatusByWallets({
      CnpjLoja: this.data.cnpj,
      DataInicio: this.data.initialDate + 'T00:00:00',
      DataFim: this.data.finalDate + 'T23:59:59',
      IdCarteira: this.data.walletId
    }).subscribe(res => {
      this.loading = false
      new ApexCharts(
        this.chartElement.nativeElement,
        {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true
                  },
                  value: {
                    show: true
                  },
                  total: {
                    show: true
                  }
                }
              }
            }
          },
          title: {
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#616161'
            },
            align: 'center',
            text: 'Status de Transação Aprovadas com ' + this.data.walletName
          },
          colors: ['#6E4B87', '#F56F23', '#280A3C', '#FEAD2E', '#411E5A'],
          fill: {
            colors: ['#6E4B87', '#F56F23', '#280A3C', '#FEAD2E', '#411E5A']
          },
          labels: res.dados.map(e => this.utilsService.convertPaymentStatus(e.situacaoCobranca)),
          series: res.dados.map(e => e.quantidade),
          chart: {
            width: '100%',
            type: 'donut'
          },
          legend: {
            position: 'bottom'
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return Math.round(val) + '%'
            }
          }
        }
      ).render()
    })
  }

}
