import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { Crumb } from '../../models/crumb'
import icAdd from '@iconify/icons-ic/twotone-add'
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz'
import icSearch from '@iconify/icons-ic/twotone-search'
import icDownload from '@iconify/icons-ic/file-download'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { DefaultFilter } from '../../models/DefaultFilter'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { PagedListDto } from '../../models/PagedListDto'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'
import { SoftwarePdvDto } from '../../models/StoreDto'
import { MatSnackBar } from '@angular/material/snack-bar'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { MatDialog } from '@angular/material/dialog'
import { CreateSoftwarePdvComponent } from './create-software-pdv/create-software-pdv.component'
import { UtilsService } from 'src/app/services/utils/utils.service'

@Component({
  selector: 'vex-automations',
  templateUrl: './automations.component.html',
  styleUrls: ['./automations.component.scss'],
  animations: [stagger40ms]
})
export class AutomationsComponent implements OnInit {
  loadingAutomation = false
  loadingAutomationWithFilter = false
  dataSource: MatTableDataSource<SoftwarePdvDto>
  breadcrumbs: Crumb[] = [{ name: 'Automações', path: ['Automations'] }]

  @ViewChild(MatPaginator) paginator: MatPaginator

  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }

  form: FormGroup
  icSearch = icSearch
  icAdd = icAdd
  icMoreHoriz = icMoreHoriz
  icDownload = icDownload
  searchCtrl: FormControl = new FormControl('')
  displayedColumns: string[] = ['softwarePdvName', 'actions']
  canCreate: boolean
  canList: boolean

  constructor (
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private readonly utilsService: UtilsService
  ) {
    this.dataSource = new MatTableDataSource()
    this.form = this.formBuilder.group({})
  }

  ngOnInit (): void {
    this.loadingAutomation = true
    this.searchCtrl.valueChanges
      .pipe(debounceTime(500), startWith(''), distinctUntilChanged())
      .subscribe((val) => {
        if (!val.length || val.length >= 3) {
          this.paging.actualPage = 1
          this.loadingAutomationWithFilter = true
          this.fetchData()
        }
      })
  }

  fetchData (): void {
    this._getSoftwarePdvPaged({
      pageNumber: this.paging.actualPage,
      pageSize: this.paging.pageSize,
      searchBy: this.searchCtrl.value
    })
  }

  private _getSoftwarePdvPaged (filter: DefaultFilter): void {
    this.accreditationService.getSoftwarePdvPaged(filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<SoftwarePdvDto>>) => {
        this.loadingAutomation = false
        this.loadingAutomationWithFilter = false

        if (!res.data.pageItems.length) {
          this.matSnackBar.open('Nenhuma automação encontrada!', '', {
            duration: 5000
          })
          this.dataSource.data = res.data.pageItems
          return
        }
        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
      },
      () => {
        this.loadingAutomationWithFilter = false
        this.loadingAutomation = false
        this.matSnackBar.open('Nenhuma automação encontrada!', '', {
          duration: 5000
        })
      }
    )
  }

  openCreateSoftwarePdvDialog (): void {
    this.dialog.open(CreateSoftwarePdvComponent, {})
  }

  openUpdateSoftwarePdvDialog (softwarePdvDto: SoftwarePdvDto): void {
    this.dialog.open(CreateSoftwarePdvComponent, {
      data: {
        softwarePdvDto
      }
    })
  }

  public ExportTOExcel (): void {
    this.matSnackBar.open(
      'O download do arquivo de exportação irá iniciar em breve.',
      '',
      {
        duration: 5000
      }
    )

    const filter = this.searchCtrl.value
    this.accreditationService
      .getSoftwarePdvsByFilter(filter)
      .subscribe((response: Blob) => {
        this.utilsService.downloadFile(
          response,
          `RelatorioAutomacao_${new Date().toLocaleDateString()}${new Date().getHours()}${new Date().getMinutes()}`,
          'csv',
          'text/csv'
        )
      }, (err) => {
        this.matSnackBar.open(err.error, '', {
          duration: 5000
        })
      })
  }

  changePage (event: PageEvent): void {
    this.loadingAutomation = true
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.fetchData()
  }
}
