<h1 mat-dialog-title>Finalizar Ticket</h1>
<div mat-dialog-content>
  <form autocomplete="off" (submit)="save()" [formGroup]="form" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Título</mat-label>
        <input type="text" formControlName="title" matInput>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Sistema/API</mat-label>
        <input type="text" formControlName="system" matInput>
      </mat-form-field>

      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Data de início do evento</mat-label>

        <input disabled matInput [value]="dateTimeBegin">

        <mat-error *ngIf="form.get('dateTimeBegin').hasError('required')">Data é obrigatório</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Data de termino do evento</mat-label>

        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Data de termino do evento" [max]="maxDate"
          formControlName="dateTimeEnd" [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker>
        </ngx-mat-datetime-picker>

        <mat-error *ngIf="form.get('dateTimeEnd').hasError('required')">Data é obrigatório</mat-error>

      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Descrição</mat-label>
        <textarea type="text" formControlName="description" matInput></textarea>
      </mat-form-field>
    </div>

    <div *ngIf="!loading" class="mt-3 mb-1" mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
      <button mat-button mat-dialog-close>FECHAR</button>
      <button [disabled]="isReadOnly" mat-raised-button type="submit" color="primary">ENCERRAR</button>
    </div>

    <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
      <mat-spinner diameter="36"></mat-spinner>
    </div>
  </form>

</div>