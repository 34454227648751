import { Component, OnInit, Inject } from '@angular/core'
import icSearch from '@iconify/icons-ic/sharp-search'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { RecipientType } from '../../../models/RecipientType'
import { RecipientDto } from '../../../models/RecipientDto'
import { DefaultReturnObjDto } from '../../../models/DefaultReturnObjDto'

@Component({
  selector: 'vex-dialog-edit-recipient',
  templateUrl: './dialog-edit-recipient.component.html',
  styleUrls: ['./dialog-edit-recipient.component.scss']
})
export class DialogEditRecipientComponent implements OnInit {
  icSearch = icSearch

  form!: FormGroup
  searchCtrl: FormControl = new FormControl('')
  recipient: RecipientDto
  recipientTypes: Array<RecipientType> = [
    { name: 'Individual', id: 'Individual' },
    { name: 'Empresa', id: 'Company' }
  ]
  loading = false

  constructor (
    private fb: FormBuilder,
    private readonly accreditationService: AccreditationService,
    public dialogRef: MatDialogRef<DialogEditRecipientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecipientDto
  ) {
    this.recipient = data
  }

  ngOnInit (): void {
    this.form = this.fb.group({
      email: ['', [Validators.email]],
      description: ['', Validators.maxLength(256)],
      type: ['', Validators.required]
    })
    this.form.get('email').setValue(this.recipient.email ?? '')
    this.form.get('description').setValue(this.recipient.description ?? '')
    this.form.get('type').setValue(this.recipient.type ?? '')
  }

  public editRecipient (): void {
    this.recipient.email = this.form.get('email').value
    this.recipient.description = this.form.get('description').value
    this.recipient.type = this.form.get('type').value

    this.loading = true
    this.accreditationService.putRecipient(this.recipient).subscribe(
      (res: DefaultReturnObjDto<RecipientDto>) => {
        this.loading = false
        if (res.success) {
          this.dialogRef.close({
            event: 'SUCCESS',
            recipient: this.recipient
          })
        }
      },
      () => {
        this.loading = false
        this.dialogRef.close({ event: 'ERROR', recipient: this.recipient })
      }
    )
  }
}
