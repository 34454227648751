<vex-page-layout>
    <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
        <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class=" mt-0 mb-1"><b>Histórico de Provedores</b></h1>
                <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
            </div>
        </div>
    </vex-page-layout-header>
    <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">
        <div class="card overflow-auto -mt-16">
            <div class="bg-app-bar px-6 h-16 border-b sticky left-0" fxLayout="row" fxLayoutAlign="start center">
                <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l" fxFlex="none"
                    fxHide.xs>
                    <span>Histórico de Provedores</span>
                </h2>

                <div class="bg-card rounded-full border px-4" fxFlex="460px" fxFlex.lt-md="auto" fxHide.xs
                    fxLayout="row" fxLayoutAlign="start center">
                    <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
                    <input [formControl]="searchCtrl" class="px-4 py-3 border-0 outline-none w-full bg-transparent"
                        placeholder="Procurar provedor, nome do provedor ou nome da chave" type="text">
                </div>

                <span fxFlex></span>

                <button *ngIf="canList" (click)="fetchData()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
                    matTooltip="Procurar Provedor de Pagamento" type="button">
                    <mat-icon [icIcon]="icSearch"></mat-icon>
                </button>

                <button *ngIf="canList" (click)="filterData()" class="ml-4" color="primary" fxFlex="none" mat-mini-fab
                    matTooltip="Filtrar" type="button">
                    <mat-icon [icIcon]="icFilter"></mat-icon>
                </button>
                
                <button *ngIf="canExportCsv" (click)="exportToCsv()" [disabled]="disableBtnExport" class="ml-4" color="primary"
                    fxFlex="none" mat-mini-fab matTooltip="Exportar CSV" type="button">
                    <mat-icon [icIcon]="icExport"></mat-icon>
                </button>
            </div>
            <table @stagger [dataSource]="dataSource" *ngIf="canList" class="w-full" mat-table matSort>
                <ng-container matColumnDef="datetime">
                    <th mat-header-cell *matHeaderCellDef> Data e Hora </th>
                    <td mat-cell *matCellDef="let element"> {{ element.createdAtUtc + 'z' | date: 'dd/MM/yyyy HH:mm:ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="storecnpj">
                    <th mat-header-cell *matHeaderCellDef> CNPJ da Loja </th>
                    <td mat-cell *matCellDef="let element"> {{ element.storeCnpj | mask: 'CPF_CNPJ' }} </td>
                </ng-container>

                <ng-container matColumnDef="useremail">
                    <th mat-header-cell *matHeaderCellDef> E-mail do Usuário </th>
                    <td mat-cell *matCellDef="let element"> {{ element.viaPushKey ? "(Via Empurra Chave)" : element.userEmail }} </td>
                </ng-container>

                <ng-container matColumnDef="operationtype">
                    <th mat-header-cell *matHeaderCellDef> Tipo de Operação </th>
                    <td mat-cell *matCellDef="let element"> {{ getOperationTypeDescription(element.operationType) }} </td>
                </ng-container>

                <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef> Provedor </th>
                    <td mat-cell *matCellDef="let element"> {{ element.walletName == 'Pix MundiPagg' ? 'Pix Pagar.me' : element.walletName }} </td>
                </ng-container>

                <ng-container matColumnDef="providername">
                    <th mat-header-cell *matHeaderCellDef> Nome Provedor </th>
                    <td mat-cell *matCellDef="let element"> {{ element.providerName }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div fxLayout.lt-md="column" fxLayout.md="column" fxLayout="column" fxLayoutAlign="space-around center">
                <div class="center" *ngIf="!canList">
                    USUÁRIO NÃO TEM PERMISSÃO DE VISUALIZAÇÃO!
                </div>

                <div class="center" *ngIf="(!dataSource?.data || dataSource?.data.length === 0) && !loading">
                    NENHUM HISTÓRICO ENCONTRADO!
                </div>

                <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
                    <mat-spinner diameter="36"></mat-spinner>
                </div>
            </div>

            <div fxLayout.lt-md="column" fxLayout.md="column" fxLayout="column" fxLayoutAlign="space-around rigth">
                <mat-paginator [pageSize]="paging.pageSize" (page)="changePage($event)" [length]="paging.length"
                    [pageSizeOptions]="[10, 50, 100]" aria-label="Selecione a página">
                </mat-paginator>
            </div>
        </div>
    </vex-page-layout-content>
</vex-page-layout>