<mat-card-content>
  <div
    fxLayoutGap="25px"
    fxLayout="column"
    fxLayoutAlign="space-around none"
    [formGroup]="form"
  >
    <mat-form-field>
      <mat-label>CNPJ da Loja</mat-label>
      <input
        matInput
        formControlName="cnpj"
        placeholder="Informe o cnpj da loja"
        maxlength="14"
        minlength="14"
      />
      <mat-error *ngIf="form.controls.cnpj.hasError('maxlength') || form.controls.cnpj.hasError('minlength')">CNPJ inválido</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Tipo de Operação</mat-label>
      <mat-select formControlName="operationType">
        <mat-option value="">Selecione</mat-option>
        <mat-option *ngFor="let operationType of operationTypes" [value]="operationType.id">
          {{ operationType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Período de busca</mat-label>
        <mat-date-range-input [max]="getToday()" [rangePicker]="picker">
            <input matStartDate formControlName="startDate" placeholder="Data inicial">
            <input matEndDate formControlName="endDate" placeholder="Data final">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="form.controls.startDate.hasError('matStartDateInvalid')">Data inicial inválida</mat-error>
        <mat-error *ngIf="form.controls.startDate.hasError('matDatepickerParse')">Formato da data inicial inválida </mat-error>
        <mat-error *ngIf="form.controls.endDate.hasError('matEndDateInvalid')">Data final inválida</mat-error>
        <mat-error *ngIf="form.controls.endDate.hasError('matDatepickerParse')">Formato da data final inválida</mat-error>
        <mat-error *ngIf="form.controls.startDate.hasError('required')">Você deve preencher a data inicial</mat-error>
        <mat-error *ngIf="form.controls.endDate.hasError('required')">Você deve preencher a data final</mat-error>
        <mat-error *ngIf="form.controls.startDate.hasError('incorrect')">O período deve ser de no máximo 7 dias</mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <button
      (click)="clearFilter()"
      class="ml-4"
      color="primary"
      fxFlex="none"
      matTooltip="Limpar"
      type="button"
    >
      Limpar
    </button>

    <button
      (click)="filterData()"
      class="ml-4"
      color="primary"
      mat-raised-button
      fxFlex="none"
      matTooltip="Filtrar"
      type="button"
    >
      Filtrar
    </button>
  </div>
</mat-card-content>