import { BrowserModule } from '@angular/platform-browser'
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { VexModule } from '../@vex/vex.module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ClientLayoutModule } from './layouts/client-layout/client-layout.module'
import { HttpErrorInterceptor } from './services/http-interceptor'
import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
import { FilterCnpjDataModule } from './layouts/client-layout/components/filter-cnpj-data/filter-cnpj-data.module'
import { FilterCnpjsDataModule } from './layouts/client-layout/components/filter-cnpjs-data/filter-cnpjs-data.module'
import { MatChipsModule } from '@angular/material/chips'
import { MatPaginatorIntl } from '@angular/material/paginator'
import { PaginatorIntlPtBr } from './utils/paginatorIntlPtBr'
import { AuthGuard } from './services/sso-auth/can-activate.service'
import { GlobalService } from './services/Global.service'
import { UtilsService } from './services/utils/utils.service'
import { LocalStorangeService } from './services/local-storage.service'
import { AuthService } from './services/sso-auth/Auth.service'
import { MessageService } from 'primeng/api'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'

registerLocaleData(localePt, 'pt-BR')

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FilterCnpjDataModule,
    FilterCnpjsDataModule,
    // Vex
    VexModule,
    ClientLayoutModule,
    MatChipsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        duration: 2500,
        horizontalPosition: 'right',
        verticalPosition: 'top'
      }
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    { provide: MatPaginatorIntl, useClass: PaginatorIntlPtBr },
    AuthGuard,
    GlobalService,
    UtilsService,
    LocalStorangeService,
    AuthService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
