import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class LocalStorangeService {
  keyUserLog = 'userLog'

  getTokenUser (): string {
    const userLog = this.get(this.keyUserLog)
    return userLog != '' ? userLog.data.token.value : userLog
  }

  getUserLog (): string {
    return this.get(this.keyUserLog)
  }

  clearUserLog (): void {
    localStorage.removeItem(this.keyUserLog)
  }

  setUserLog (userLog: any): void {
    localStorage.setItem(this.keyUserLog, JSON.stringify(userLog))
  }

  get (chave: string) {
    const userLog = localStorage.getItem(chave)

    if (userLog == 'undefined' || userLog == null || userLog == '') {
      return ''
    }

    return JSON.parse(userLog)
  }

  getUserAuthenticatedtUserType () {  
    return JSON.parse(localStorage.getItem('userLog') || '').data.type
  }

  delete (chave: string) {
    localStorage.removeItem(this.get(chave))
  }

  Set (chave: string, valor: string) {
    localStorage.setItem(chave, JSON.stringify(valor))
  }
}
