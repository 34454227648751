import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz'
import icFilter from '@iconify/icons-ic/sharp-filter-alt'
import icExport from '@iconify/icons-ic/sharp-download'
import icSearch from '@iconify/icons-ic/twotone-search'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { Crumb } from '../../models/crumb'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { PagedListDto } from '../../models/PagedListDto'
import { PointOfSaleDto, StoreDto } from '../../models/StoreDto'
import { FilterStoresSearch } from '../../models/FilterStoresSearch'
import { MatDialog } from '@angular/material/dialog'
import { DialogFilterStoresComponent } from './dialog-filter-stores/dialog-filter-stores.component'
import { FormControl } from '@angular/forms'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'
import { DialogStoreEditComponent } from './dialog-store-edit/dialog-store-edit.component'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'vex-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss'],
  animations: [stagger40ms]
})
export class StoresComponent implements OnInit {
  icFilter = icFilter
  icExport = icExport
  icSearch = icSearch
  icMoreHoriz = icMoreHoriz
  loading = false

  dataSource: MatTableDataSource<StoreDto>
  displayedColumns: string[] = [    
    'name',
    'cnpj',
    'lastTimeHere',
    'scheduled'
    // 'limitPdv',
    // 'softwarePdvName',
    // 'categoryName',
    // 'createdAtUtc'
  ]
  canList: boolean
  canEditStore: boolean
  canExportCsv: boolean

  breadcrumbs: Crumb[] = [{ name: 'Clientes', path: [''] }]
  @ViewChild(MatPaginator) paginator: MatPaginator

  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }

  filter: FilterStoresSearch = {
    // removeProviders: false,
    pageNumber: this.paging.actualPage,
    pageSize: this.paging.pageSize
  }

  isLinxUser = false

  disableBtnExport = false

  searchCtrl: FormControl

  constructor (
    private readonly utilsService: UtilsService,
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.isLinxUser = this.utilsService.isUserFromLinx()
    this.canList = this.utilsService.canShowElementByFunctionality('ADMIN')
    this.canEditStore = this.utilsService.canShowElementByFunctionality('store-edit')
    this.canExportCsv =
      this.utilsService.canShowElementByFunctionality('lojaexportar')
    this.dataSource = new MatTableDataSource()
    this.searchCtrl = new FormControl()
  }

  ngOnInit (): void {
    this.fetchData()

    this.searchCtrl.valueChanges
      .pipe(debounceTime(500), startWith(''), distinctUntilChanged())
      .subscribe((val) => {
        if (!val.length || val.length >= 3) {
          this.dataSource.data = []
          // this.filter.cnpj = val
          this.filter.pageNumber = 0
          this.paging.actualPage = 0

          if (this.loading) return

          this.fetchData()
        }
      })
  }

  fetchData (): void {
    if (this.loading) return

    // if (this.isLinxUser && this.canEditStore &&
    //   this.displayedColumns.indexOf('actions') == -1) {
    //   this.displayedColumns.push('actions')
    // }

    // if (this.isLinxUser && !this.filter.cnpj) {
    //   return
    // }

    // if (this.filter.cnpj) {
    //   this.filter.cnpj = this.filter.cnpj.replace(/[^0-9]+/g, '')
    // }

    this.loading = true
    this.dataSource.data = []
    // this.filter.removeProviders = false
    this.accreditationService.searchStore(this.filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<StoreDto>>) => {
        this.loading = false
        
        if (!res.data.pageItems.length) {
          this.dataSource.data = []
          return
        }
        console.log(res.data.pageItems)
        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
      },
      (e) => {
        console.log(e.status == 401)

        if (e.status == 401) {
          this.redirectToLogin()
          throw new Error('Authentication failed')
        }

        this.loading = false
        this.matSnackBar.open('Ocorreu um erro ao filtrar os clientes!', '', {
          duration: 4000
        })
      }
    )
  }

  redirectToLogin (): void {
    location.href = environment.loginUrl
  }

  filterData (): void {
    this.dialog
      .open(DialogFilterStoresComponent, {
        disableClose: false,
        data: this.filter,
        width: '590px'
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && res.event === 'FILTER' && res.filter) {
          this.filter = res.filter
          // this.searchCtrl.setValue(this.filter.cnpj ?? '')
          this.fetchData()
        }
      })
  }

  updateFilterObjectWithThePaging (): void {
    this.filter.pageNumber = this.paging.actualPage
    this.filter.pageSize = this.paging.pageSize
  }

  openDialogEditStore (store: StoreDto) {
    this.dialog.open(DialogStoreEditComponent, {
      width: '600px',
      data: {
        store: store
      }
    }).afterClosed()
      .subscribe((res) => {
        if (res && res.event === 'Success') {
          this.fetchData()
        }
      })
  }
  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.updateFilterObjectWithThePaging()
    this.fetchData()
  }

  // getAmountOfActivePdvs (pointOfSales: Array<PointOfSaleDto>): number {
  //   return pointOfSales.map((f) => f.status === 'Ativo').length
  // }

  public ExportStoresToCSV (): void {
    this.disableBtnExport = true
    this.matSnackBar.open(
      'Aguarde, em instantes o download do arquivo de exportação será iniciado.',
      '',
      {
        duration: 5000
      }
    )

    this.accreditationService.ExportStoresCsvFile(this.filter).subscribe(
      (response: Blob) => {
        this.utilsService.downloadFile(
          response,
          `RelatorioDeLojas_${new Date().getTime()}`,
          'csv',
          'text/csv'
        )
        this.disableBtnExport = false
      },
      () => {
        this.matSnackBar.open(
          'Não foi possível gerar o relatório. Tente novamente mais tarde!',
          '',
          {
            duration: 5000
          }
        )
        this.disableBtnExport = false
      })
  }
}
