import { PixDto } from '../../../models/PixDto'
import { ProviderTypeDto } from '../../../models/ProviderTypeDto'
import { SegmentDto } from '../../../models/SegmentDto'

const getPspList = (): Array<PixDto> => [
  { name: 'Pefisa', integrationType: 2, exclusiveStone: false },
  { name: 'Bradesco', integrationType: 3, exclusiveStone: false },
  { name: 'Itaú', integrationType: 4, exclusiveStone: false },
  { name: 'Santander', integrationType: 5, exclusiveStone: false },
  { name: 'Banco do Brasil', integrationType: 6, exclusiveStone: false },
  { name: 'Pagar.me', integrationType: 7, exclusiveStone: true },
  { name: 'Hiper', integrationType: 8, exclusiveStone: false },
  { name: 'SaqPay', integrationType: 9, exclusiveStone: false },
  { name: 'Realize', integrationType: 10, exclusiveStone: false },
  { name: 'Cielo', integrationType: 11, exclusiveStone: false },
  { name: 'Stone', integrationType: 12, exclusiveStone: true },
  { name: 'Paga Leve', integrationType: 13, exclusiveStone: false },
  { name: 'A27', integrationType: 14, exclusiveStone: false },
  { name: 'Sicoob', integrationType: 15, exclusiveStone: false },
  { name: 'Sicredi', integrationType: 16, exclusiveStone: false }
]

const getProviderPriority = (): Array<ProviderTypeDto> => [
  { id: 0, name: 'Primary', description: 'Primário' },
  { id: 1, name: 'Secondary', description: 'Secundário' },
  { id: 2, name: 'Tertiary', description: 'Terceário' }
]

const getProviderType = (): Array<{ type: string; name: string }> => [
  { type: '0', name: 'Carteira' },
  { type: '1', name: 'Pix' }
  // { type: '2', name: 'Cartão' }
]

const getSegmentList = (): Array<SegmentDto> => [
  {
    name: 'Alimentação e Bebidas',
    category: [
      'Alimentação Rápida',
      'Bares e Restaurantes',
      'Comércio de Alimentos',
      'Comércio de Bebidas',
      'Suplementos'
    ]
  },
  {
    name: 'Autopeças e Serviços Automotivos',
    category: [
      'Autopeças e Acessórios',
      'Autopeças e Serviços Automotivos - Outros',
      'Estacionamentos e Lava-rápidos',
      'Oficinas Automotivas'
    ]
  },
  {
    name: 'Casa e Decoração',
    category: [
      'Armarinhos e Tecido',
      'Artigos de Decoração',
      'Cama, Mesa e Banho',
      'Casa e Decoração - Outros',
      'Jardinagem',
      'Móveis'
    ]
  },
  {
    name: 'Cias Aéreas',
    category: ['Cias Aéreas']
  },
  {
    name: 'Comércio de Veículos',
    category: ['Comércio de Veículos']
  },
  {
    name: 'Consertos, Obras e Manutenção',
    category: [
      'Conserto de Produtos e Reparos de Peças',
      'Dedetização e Desinfecção',
      'Paisagismo e Jardinagem',
      'Reformas e Obras em Geral'
    ]
  },
  {
    name: 'Crowdfunding',
    category: ['Crowdfunding']
  },
  {
    name: 'Doação',
    category: [
      'ONGs e Similares',
      'Outros Doação - Outros',
      'Política',
      'Religião'
    ]
  },
  {
    name: 'Educação',
    category: [
      'Cursos Preparatórios',
      'EAD - Cursos Preparatórios',
      'EAD - Extracurriculares e Outros',
      'EAD - Superior e Técnico',
      'Ensino Básico',
      'Ensino Superior e Técnico',
      'Extracurriculares, Autoescola e Outros'
    ]
  },
  {
    name: 'Eletroeletrônicos',
    category: [
      'Celulares e Computadores',
      'Eletrodomésticos',
      'Eletrônicos',
      'Softwares e Eletrônica Integrada'
    ]
  },
  {
    name: 'Entretenimento e Turismo',
    category: ['Entretenimento e Turismo']
  },
  {
    name: 'Erótica',
    category: ['Conteúdo Erótico e Outros', 'Sex Shop']
  },
  {
    name: 'Esporte e Fitness',
    category: [
      'Academias e Clubes',
      'Artigos Esportivos',
      'Esporte e Fitness - Outros'
    ]
  },
  {
    name: 'Farmácias, Produtos de Saúde e Cosmética',
    category: [
      'Cosméticos e Perfumaria',
      'Drogarias e Farmácias',
      'Outros Produtos de Saúde e Beleza'
    ]
  },
  {
    name: 'Imóveis e Hospedagem',
    category: [
      'Hospedagem - Outros',
      'Hotéis / Resorts / Pousadas / Motéis',
      'Imóveis - Outros',
      'Serviços Imobiliários'
    ]
  },
  {
    name: 'Ingressos, Eventos e Festas',
    category: ['Festas e Eventos', 'Ingressos']
  },
  {
    name: 'Jogos e Brinquedos',
    category: [
      'Jogos de Videogame',
      'Jogos Digitais',
      'Jogos e Brinquedos Físicos'
    ]
  },
  {
    name: 'Livrarias, Papelarias, Gráficas e Bancas',
    category: [
      'Gráfica, Impressão e Xerox',
      'Jornais e Revistas - Conteúdo Digital',
      'Jornais e Revistas - Conteúdo Físico',
      'Livrarias e Papelarias'
    ]
  },
  {
    name: 'Logística e Mobilidade',
    category: [
      'Apps de Mobilidade Urbana',
      'Delivery e Entrega',
      'Locação de Veículos',
      'Logística e Mobilidade - Outros',
      'Táxi/Carona'
    ]
  },
  {
    name: 'Lojas de Departamento',
    category: ['Lojas de Departamento']
  },
  {
    name: 'Materiais de Construção',
    category: ['Materiais de Construção']
  },
  {
    name: 'Moda e Acessórios',
    category: [
      'Acessórios, Bolsas e Bijuterias',
      'Calçados',
      'Joalherias, Relojoarias e Pratarias',
      'Moda e Acessórios - Outros',
      'Óticas e Óculos',
      'Vestuário'
    ]
  },
  {
    name: 'Musica e Video',
    category: [
      'Estúdios e Gravadoras',
      'Instrumentos Musicais, CDs, DVDs e Outros',
      'Música e Vídeo - Conteúdo Digital'
    ]
  },
  {
    name: 'Outros Comércios',
    category: [
      'Artigos Religiosos e Antiguidades',
      'Equipamentos de Uso Comercial e Industrial',
      'Floricultura',
      'Loja de Presentes',
      'Tabacaria'
    ]
  },
  {
    name: 'Outros Serviços',
    category: [
      'Anúncios',
      'Apostas',
      'Associação',
      'Certificadores',
      'Jurídico',
      'Outros Serviços - Outros',
      'Serviços Financeiros'
    ]
  },
  {
    name: 'Petshops e Veterinários',
    category: ['Petshops', 'Veterinários']
  },
  {
    name: 'Plataformas e Serviços Digitais',
    category: [
      'Agência de E-commerce / Plataforma',
      'Anúncios Online',
      'Cupons e Descontos',
      'Delivery e Entrega',
      'ERP/SaaS',
      'Ingressos',
      'Programa de Fidelidade',
      'Serviços Digitais - Outros',
      'Software'
    ]
  },
  {
    name: 'Postos de Gasolina e Similares',
    category: ['Gás GLP, Lubrificantes e Outros', 'Postos de Gasolina']
  },
  {
    name: 'Serviços',
    category: ['Serviços']
  },
  {
    name: 'Serviços Corporativos',
    category: [
      'Consultorias',
      'Marketing',
      'Segurança',
      'Serviços Corporativos - Outros'
    ]
  },
  {
    name: 'Serviços de Beleza e Estética',
    category: [
      'Clinicas de Estética e Massagem',
      'Hospitais e Laboratórios',
      'Medicina',
      'Odontologia',
      'Outras Clínicas',
      'Outros Serviços de Saúde',
      'Salão de Beleza'
    ]
  },
  {
    name: 'Serviços de Saúde e Clínicas',
    category: [
      'Hospitais e Laboratórios',
      'Medicina',
      'Odontologia',
      'Outras Clínicas',
      'Outros Serviços de Saúde'
    ]
  },
  {
    name: 'Serviços Financeiros',
    category: ['Serviços Financeiros']
  },
  { name: 'Subadquirente', category: ['Subadquirente'] },
  {
    name: 'Supermercados',
    category: ['Supermercados']
  },
  {
    name: 'Telecomunicações',
    category: ['Telecomunicações']
  },
  { name: 'Varejo e Comércio', category: ['Varejo e Comércio'] }
]

const getCategoriesList = (): Array<string> => ['Varejo e Comércio']

const getTypesList = (): Array<string> => ['Administrative']

export {
  getPspList,
  getProviderPriority,
  getProviderType,
  getSegmentList,
  getCategoriesList,
  getTypesList
}
