import { Component, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { RefundDto } from '../../models/RefundDto'
import { Crumb } from '../../models/crumb'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { FilterConsolidateDto } from '../../models/FilterConsolidateDto'
import { PagedListDto } from '../../models/PagedListDto'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { MatSnackBar } from '@angular/material/snack-bar'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { ContingencyService } from 'src/app/services/Contigency/contingency.service'
import icMoreHoriz from '@iconify/icons-ic/outline-remove-red-eye'
import { MatDialog } from '@angular/material/dialog'
import { PendingRefundDetailComponent } from './dialog/refund-detail/pending-refund-detail.component'


@Component({
  selector: 'vex-pending-refunds',
  templateUrl: './pending-refunds.component.html',
  styleUrls: ['./pending-refunds.component.scss'],
  animations: [stagger40ms]
})
export class PendingRefundsComponent implements OnInit {
  loadingRefunds = false
  emptyRefunds = false
  icMoreHoriz = icMoreHoriz
  cnpj: any = []
  dataSource: MatTableDataSource<RefundDto>
  NotSearchWhenIncludingCnpjValue = true;
  displayedColumns: string[] = [
    'cnpj',
    'status',
    'createdAt',
    'brand',
    'dateTimeCharge',
    'dateTimePayment',
    'amount',
    'attempts',
    'actions'
  ]
  savedData: { cnpjs: string[], initialDate: string, finalDate: string }

  breadcrumbs: Crumb[] = [{ name: 'Estornos Pendentes', path: [''] }]
  @ViewChild(MatPaginator) paginator: MatPaginator

  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }
  constructor (
    private readonly contingencyService: ContingencyService,
    private readonly matSnackBar: MatSnackBar,
    public dialog: MatDialog   
  ) {
    this.dataSource = new MatTableDataSource()
  }
  ngOnInit (): void {
    this.loadingRefunds = false
  }

  getRefundsByFilter (data: { cnpjs: string[], initialDate: string, finalDate: string }): void {
    this.savedData = data
    if (!data.cnpjs) {
      return
    }

    this._getRefundPaged(
      {storeCnpjs: data.cnpjs,
        startDate: data.initialDate + 'T00:00:00',
        endDate: data.finalDate + 'T23:59:59'}
    )
  }

  private _getRefundPaged (filter: FilterConsolidateDto): void {
    this.loadingRefunds = true
    this.emptyRefunds = false
    this.contingencyService.getPendingRefunds(filter)
      .subscribe(
        (res: DefaultReturnObjDto<PagedListDto<RefundDto>>) => {
          if (!res.data.pageItems.length) {
            this.matSnackBar.open('Nenhum estorno pendente encontrado!', '', {
              duration: 5000
            })
            this.dataSource.data = res.data.pageItems
            this.loadingRefunds = false
            this.emptyRefunds = true
            return
          }
          this.dataSource.data = res.data.pageItems
          this.paging.actualPage = res.data.pageNumber
          this.paging.pageSize = res.data.pageSize
          this.paging.length = res.data.totalItems
          this.loadingRefunds = false
          this.emptyRefunds = false
        },
        () => {
          this.matSnackBar.open('Nenhum estorno encontrado!', '', {
            duration: 5000
          })
          this.loadingRefunds = false
        }
      )
  }

  openDetailsDialog (data: RefundDto): void {
    this.dialog.open(PendingRefundDetailComponent, {data})
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
  }
}
