import { AbstractControl, FormControl, Validators } from '@angular/forms'

const cnpjBlackList: Array<string> = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999'
]

const strip = (number: string, strict?: boolean): string => {
  const regex: RegExp = strict ? /[-\\/.]/g : /[^\d]/g
  return (number || '').replace(regex, '')
}

const verifierDigit = (digits: string): number => {
  let index = 2
  const reverse: Array<number> = digits.split('').reduce((buffer, number) => {
    return [parseInt(number, 10)].concat(buffer)
  }, [])

  const sum: number = reverse.reduce((buffer, number) => {
    buffer += number * index
    index = index === 9 ? 2 : index + 1
    return buffer
  }, 0)

  const mod: number = sum % 11
  return mod < 2 ? 0 : 11 - mod
}

export class GenericValidator {
  static isEighteenYearsOld () {
    return (control: AbstractControl): Validators => {
      const birthday = control.value
      if (!birthday) return null

      const [ano, mes, dia] = birthday.split('-')
      const today = new Date()
      const birthdayDate = new Date(ano, mes, dia, 0, 0, 0)
      const eighteenYears = 1000 * 60 * 60 * 24 * 365 * 18

      if (today.getTime() - birthdayDate.getTime() >= eighteenYears) return null

      return { menorDeIdade: true }
    }
  }

  static isValidCnpj () {
    return (control: AbstractControl): Validators => {
      if (!control.value) return null

      const stripped: string = strip(control.value)

      if (!stripped) {
        return { cnpjNotValid: true }
      }

      if (stripped.length !== 14) {
        return { cnpjNotValid: true }
      }

      if (cnpjBlackList.includes(stripped)) {
        return { cnpjNotValid: true }
      }

      let numbers: string = stripped.substr(0, 12)
      numbers += verifierDigit(numbers)
      numbers += verifierDigit(numbers)

      if (numbers.substr(-2) !== stripped.substr(-2)) {
        return {
          cnpjNotValid: true
        }
      }

      return null
    }
  }

  static isValidCpf () {
    return (control: AbstractControl): Validators => {
      const cpf = control.value
      if (!cpf) return null

      let numbers, digits, sum, i, result, equalDigits
      equalDigits = 1
      if (cpf.length < 11) {
        return null
      }

      for (i = 0; i < cpf.length - 1; i++) {
        if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
          equalDigits = 0
          break
        }
      }

      if (!equalDigits) {
        numbers = cpf.substring(0, 9)
        digits = cpf.substring(9)
        sum = 0
        for (i = 10; i > 1; i--) {
          sum += numbers.charAt(10 - i) * i
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

        if (result !== Number(digits.charAt(0))) {
          return { cpfNotValid: true }
        }
        numbers = cpf.substring(0, 10)
        sum = 0

        for (i = 11; i > 1; i--) {
          sum += numbers.charAt(11 - i) * i
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

        if (result !== Number(digits.charAt(1))) {
          return { cpfNotValid: true }
        }
        return null
      } else {
        return { cpfNotValid: true }
      }
    }
  }

  static nameValidators = Validators.pattern('^[A-Za-z ]+$')
  static emailValidator = Validators.pattern(
    '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
  )

  static positiveNumberValidator (control: FormControl) {
    const value = control.value

    if (value === null || value === undefined) {
      return null
    }

    const isPositive = value >= 0

    return isPositive ? null : { positiveNumber: true }
  }
}
