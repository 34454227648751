import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { UtilsService } from '../utils/utils.service'
import { NotificationDto } from 'src/app/layouts/client-layout/models/notification'
import { DefaultReturnObjConciliadorDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjConciliadorDto'
import { saveAs } from 'file-saver'
import { map } from 'rxjs/operators'
import { DefaultReturnConciliadorDto } from 'src/app/layouts/client-layout/models/DefaultReturnConciliadorDto'
import { LocalStorangeService } from '../local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class ConciliationService {
  private baseUrl = environment.baseUrl

  constructor (
    private readonly httpClient: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly localStorangeService: LocalStorangeService
  ) { }

  listAll (params: { linesPerPage: number; actualPage: number; createdAt?: Date; expireDate?: Date; id?: string }): Observable<DefaultReturnConciliadorDto<any>> {
    const queryString = this.utilsService.encodeQueryData(params)
    return this.httpClient.get<DefaultReturnConciliadorDto<any>>(
      `${this.baseUrl}/wallet-qrlinx-nova-conciliacao/v1/report?${queryString}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }


  public createReport (data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.baseUrl}/wallet-qrlinx-nova-conciliacao/v1/report`,
      data,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  public getNotifications (): Observable<DefaultReturnObjConciliadorDto<NotificationDto>> {
    return this.httpClient.get<DefaultReturnObjConciliadorDto<NotificationDto>>(
      `${this.baseUrl}/wallet-qrlinx-nova-conciliacao/v1/report/notification`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
  }

  download (id: any) {
    return this.httpClient.get(
      `${this.baseUrl}/wallet-qrlinx-nova-conciliacao/v1/report/download?id=${id}`,
      {
        responseType: 'blob',
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
      .pipe(
        map(res => {
          return saveAs(res, `relatorio-${new Date().getTime()}.csv`)
        })
      )
  }

}
