<vex-page-layout>
  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="mt-0 mb-1"><b>Estornos Pendentes</b></h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>

  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">
    <div class="card overflow-auto -mt-16">
      <div class="bg-app-bar px-6 p-3 left-0" fxLayout="row" fxLayoutAlign="start center">
        <div class="w-full" fxLayout="column" fxLayoutGap="10px">
          <vex-filter-cnpjs-data [NotSearchWhenIncludingCnpj]="NotSearchWhenIncludingCnpjValue"
            (onSearch)="getRefundsByFilter($event)"></vex-filter-cnpjs-data>
        </div>
      </div>
      <div *ngIf="loadingRefunds" class="m-6" fxLayoutAlign="center center">
        <mat-spinner diameter="36"></mat-spinner>
      </div>
      <div *ngIf="!(dataSource.data.length > 0) && !emptyRefunds " class="mt-10 card" fxLayout="column"
        fxLayoutAlign="center center">
        <div class="w-1/4 md:w-2/12">
          <img alt="No Data" class="m-auto " src="../../../../../assets/img/data-analysis.png" alt="Nenhum gráfico disponível">
        </div>
        <p class="text-center center text-cnpj">Selecione a data e o CNPJ para ter acesso aos estornos pendentes</p>
      </div>

      <div style="overflow-y: auto;" [class.hidden]="!(dataSource.data.length > 0)">
        <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
          <ng-container matColumnDef="cnpj">
            <th mat-header-cell *matHeaderCellDef>Cnpj</th>
            <td mat-cell *matCellDef="let element">{{ element.storeCnpj | mask:'00.000.000/0000-00' }}</td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Data do estorno</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date : 'dd/MM/yyyy HH:mm' : 'UTC-3' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dateTimeCharge">
            <th mat-header-cell *matHeaderCellDef>Data criação da cobrança</th>
            <td mat-cell *matCellDef="let element">
              {{ element.dateTimeCharge | date : 'dd/MM/yyyy HH:mm' : 'UTC-3' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dateTimePayment">
            <th mat-header-cell *matHeaderCellDef>Data do pagamento</th>
            <td mat-cell *matCellDef="let element">
              {{ element.dateTimePayment | date : 'dd/MM/yyyy HH:mm' : 'UTC-3' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="brand">
            <th mat-header-cell *matHeaderCellDef>Marca da loja</th>
            <td mat-cell *matCellDef="let element">
              {{ element.brand}}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status do estorno</th>
            <td mat-cell *matCellDef="let element">
              {{ element.refundStatus}}
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Valor</th>
            <td mat-cell *matCellDef="let element">
              {{ element.amount}}
            </td>
          </ng-container>

          <ng-container matColumnDef="attempts">
            <th mat-header-cell *matHeaderCellDef>Quantidade de tentativas</th>
            <td class="text-center" mat-cell *matCellDef="let element">
              {{ element.attempts}}/48
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Detalhes </th>
            <td mat-cell *matCellDef="let element">
              <button (click)="openDetailsDialog(element)">
                <mat-icon [icIcon]="icMoreHoriz"></mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator fxLayoutAlign="end" [pageSize]="paging.pageSize" (page)="changePage($event)"
          [length]="paging.length" [pageSizeOptions]="[10, 50, 100]" aria-label="Select page">
        </mat-paginator>
      </div>
      <div fxLayoutGap="25px" fxLayout.lt-md="column" fxLayout.md="column" fxLayout="column"
        fxLayoutAlign="space-around center">

        <div class="center" *ngIf="emptyRefunds">
          NENHUM ESTORNO PENDENTE ENCONTRADO!
        </div>
      </div>
    </div>
  </vex-page-layout-content>
</vex-page-layout>