import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import icFilter from '@iconify/icons-ic/sharp-filter-alt'
import icExport from '@iconify/icons-ic/sharp-download'
import icSearch from '@iconify/icons-ic/twotone-search'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { Crumb } from '../../models/crumb'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { PagedListDto } from '../../models/PagedListDto'
import { ProviderHistoryDto } from '../../models/ProviderDto'
import { ProviderHistoryFilter } from '../../models/ProviderHistoryFilter'
import { DialogFilterProviderHistoryComponent } from './dialog-filter-provider-history/dialog-filter-provider-history.component'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { OperationType } from '../../models/OperationType'
import { DateHelper } from 'src/app/helpers/date.helper'

@Component({
  selector: 'vex-provider-history',
  templateUrl: './provider-history.component.html',
  styleUrls: ['./provider-history.component.scss'],
  animations: [stagger40ms]
})
export class ProviderHistoryComponent implements OnInit {
  icSearch = icSearch
  icFilter = icFilter
  icExport = icExport

  breadcrumbs: Crumb[] = [{ name: 'Histórico de Provedores', path: [''] }]

  canList = true
  disableBtnExport = true
  loading = true
  canExportCsv = false

  searchCtrl: FormControl = new FormControl('')

  dataSource: MatTableDataSource<ProviderHistoryDto>
  displayedColumns: string[] = [
    'datetime',
    'storecnpj',
    'useremail',
    'operationtype',
    'provider',
    'providername'
  ]

  @ViewChild(MatPaginator) paginator: MatPaginator
  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 1
  }

  filter: ProviderHistoryFilter = {
    pageNumber: this.paging.actualPage,
    pageSize: this.paging.pageSize,
    searchBy: '',
    startDate: DateHelper.getTodayAddDays(-7),
    endDate: DateHelper.getToday(),
    operationType: '',
    cnpj: ''
  }

  constructor (
    private readonly utilsService: UtilsService,
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.canList = this.utilsService.canShowElementByFunctionality('loja')
    this.canExportCsv =
      this.utilsService.canShowElementByFunctionality('lojaexportar')
    this.dataSource = new MatTableDataSource()
  }

  ngOnInit (): void {
    this.fetchData()
  }

  _getProviderHistoryPaged (filter: ProviderHistoryFilter): void {
    this.loading = true
    this.dataSource.data = null
    this.accreditationService.getProviderHistoryPaged(filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<ProviderHistoryDto>>) => {
        this.loading = false

        if (!res.data.pageItems.length) {
          this.disableBtnExport = true

          return
        }

        this.disableBtnExport = false

        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Ocorreu um erro ao listar o histórico!', '', {
          duration: 5000
        })
      }
    )
  }

  fetchData (): void {
    this.filter.searchBy = this.searchCtrl.value
    this._getProviderHistoryPaged(this.filter)
  }

  _filterIsValid (): boolean {
    return true
  }

  filterData (): void {
    this.dialog
      .open(DialogFilterProviderHistoryComponent, {
        disableClose: false,
        data: this.filter,
        width: '590px'
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && res.event === 'FILTER' && res.filter) {
          this.filter = res.filter
          this.fetchData()
        }
      })
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.updateFilterObjectWithThePaging()
    this.fetchData()
  }

  updateFilterObjectWithThePaging (): void {
    this.filter.pageNumber = this.paging.actualPage
    this.filter.pageSize = this.paging.pageSize
  }

  exportToCsv () {
    this.disableBtnExport = true
    this.loading = true
    this.matSnackBar.open(
      'O download do arquivo de exportação irá iniciar em breve.',
      '',
      {
        duration: 5000
      }
    )
    this.accreditationService
      .exportProviderHistoryCsvFile(this.filter)
      .subscribe(
        (response: Blob) => {
          this.utilsService.downloadFile(
            response,
            `RelatorioDeHistoricoDeProvedores_${new Date().getTime()}`,
            '.csv',
            'text/csv'
          )
          this.disableBtnExport = false
          this.loading = false
        },
        () => {
          this.matSnackBar.open('Não foi possível gerar a exportação!', '', {
            duration: 5000
          })
          this.disableBtnExport = false
          this.loading = false
        }
      )
  }

  getOperationTypeDescription (operationType: OperationType) {
    if (operationType == null) return ''

    const index = Object.keys(OperationType).indexOf(operationType)
    return Object.values(OperationType)[index]
  }
}
