import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatTableDataSource } from '@angular/material/table'
import icAdd from '@iconify/icons-ic/twotone-add'
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz'
import icSearch from '@iconify/icons-ic/twotone-search'
import icExport from '@iconify/icons-ic/sharp-download'
import { stagger40ms } from 'src/@vex/animations/stagger.animation'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { DefaultReturnObjDto } from '../../models/DefaultReturnObjDto'
import { PagedListDto } from '../../models/PagedListDto'
import { PointOfSaleFilter } from '../../models/PointOfSaleFIlter'
import { Observable } from 'rxjs'
import { Crumb } from '../../models/crumb'
import { PointOfSaleListDto } from '../../models/PointOfSaleListDto'
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators'

@Component({
  selector: 'vex-point-of-sale',
  templateUrl: './point-of-sale.component.html',
  styleUrls: ['./point-of-sale.component.scss'],
  animations: [stagger40ms]
})
export class PointOfSaleComponent implements OnInit {
  @Output() onSearch: EventEmitter<any> = new EventEmitter()

  icSearch = icSearch
  icAdd = icAdd
  icMoreHoriz = icMoreHoriz
  icExport = icExport

  searchCtrl: FormControl = new FormControl('')
  dataSource: MatTableDataSource<PointOfSaleListDto>
  displayedColumns: string[] = [
    'brandName',
    'cnpj',
    'description',
    'externalCode',
    'status'
  ]
  canList: boolean
  breadcrumbs: Crumb[] = [{ name: 'Pontos de Venda', path: ['pos'] }]

  btnBlockExportToCSV = false
  @ViewChild(MatPaginator) paginator: MatPaginator

  paging = {
    pageSize: 10,
    length: 0,
    actualPage: 0
  }

  form!: FormGroup
  CnpjCtrl = new FormControl()
  filteredCnpj: Observable<any[]>
  loading = false

  @ViewChild('cnpjInput') cnpjInput: ElementRef<HTMLInputElement>

  constructor (
    private readonly utilsService: UtilsService,
    private readonly accreditationService: AccreditationService,
    private readonly matSnackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) {
    this.canList =
      this.utilsService.canShowElementByFunctionality('pontodevenda')
    this.dataSource = new MatTableDataSource()
    this.form = this.formBuilder.group({})
  }

  ngOnInit (): void {
    this.fetchDataPointOfSalePaged()
    this.subscribeSearchInput()
  }

  private subscribeSearchInput (): void {
    this.searchCtrl.valueChanges
      .pipe(debounceTime(500), startWith(''), distinctUntilChanged())
      .subscribe((val) => {
        if ((!val.length || val.length >= 3) && !this.loading) {
          this.paging.actualPage = 0
          this.fetchDataPointOfSalePaged()
        }
      })
  }

  private _getPointOfSalePaged (filter: PointOfSaleFilter): void {
    if (this.loading) return

    this.dataSource.data = []
    this.loading = true

    this.accreditationService.getPointOfSalePaged(filter).subscribe(
      (res: DefaultReturnObjDto<PagedListDto<PointOfSaleListDto>>) => {
        this.loading = false

        if (!res.data.pageItems.length) {
          this.matSnackBar.open('Nenhum ponto de venda encontrado!', '', {
            duration: 5000
          })
        }

        this.dataSource.data = res.data.pageItems
        this.paging.actualPage = res.data.pageNumber
        this.paging.pageSize = res.data.pageSize
        this.paging.length = res.data.totalItems
      },
      () => {
        this.loading = false
        this.matSnackBar.open('Nenhum ponto de venda encontrado!', '', {
          duration: 5000
        })
      }
    )
  }

  fetchDataPointOfSalePaged (): void {
    this._getPointOfSalePaged({
      pageNumber: this.paging.actualPage,
      pageSize: this.paging.pageSize,
      searchBy: this.searchCtrl.value
    })
  }

  changePage (event: PageEvent): void {
    this.paging.pageSize = event.pageSize
    this.paging.actualPage = event.pageIndex + 1
    this.fetchDataPointOfSalePaged()
  }

  public ExportToCSV (): void {
    this.btnBlockExportToCSV = true
    this.matSnackBar.open(
      'O download do arquivo de exportação irá iniciar em breve.',
      '',
      {
        duration: 5000
      }
    )
    this.accreditationService
      .ExportCsvFile(this.searchCtrl.value)
      .subscribe((response: Blob) => {
        this.utilsService.downloadFile(
          response,
          `RelatorioDePontosDeVendas_${new Date().getTime()}`,
          '.csv',
          'text/csv'
        )
        this.btnBlockExportToCSV = false
      },
      (response) => {
        this.matSnackBar.open(response.error, '', {
          duration: 5000
        })
        this.btnBlockExportToCSV = false
      })
  }
}
