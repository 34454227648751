<h1 mat-dialog-title>Importação de Usuários e Grupos em Lote</h1>
<div mat-dialog-content>
    <form autocomplete="off" (submit)="uploadFile()" [formGroup]="form" fxLayout="column">
        <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
            <mat-form-field class="w-full" appearance="fill">
                <ngx-mat-file-input [formControl]="form.get('file')" placeholder="Escolha o arquivo" [multiple]="multiple" [accept]="accept" color="primary">
                    <mat-icon style="font-size: 20px;"  [icIcon]="icAttachFile" ngxMatFileInputIcon></mat-icon>
                </ngx-mat-file-input>
                
                <mat-error *ngIf="form.get('file').hasError('maxSize')">O tamanho do arquivo excede 30mb.</mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="!loading" class="mt-3 mb-1" mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
            <button mat-button mat-dialog-close>FECHAR</button>
            <button [disabled]="form.invalid" mat-raised-button type="submit" color="primary">ENVIAR</button>
        </div>

        <div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
        </div>
    </form>
</div>