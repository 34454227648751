<h2 mat-dialog-title>Validação de Provedor de Pagamento</h2>
<mat-dialog-content>
	<mat-label class="text-center" fxLayout="row" fxLayoutAlign="center center">
		Para ativar o provedor, realize o pagamento do QR Code abaixo<br /> e siga as instruções das próximas
		etapas.
	</mat-label>

	<div fxLayout="column" fxLayoutAlign="center center">
		<div fxLayout="column">
			<div fxLayout="row">
				<mat-label style="font-weight: bold; font-size: 15px;" [hidden]="!paymentStates[4]">Provedor principal
					testado com sucesso!</mat-label><ic-icon class="mt-0" [icon]="iconDone" size="15px"
					[hidden]="!paymentStates[4]"></ic-icon>
			</div>
			<div fxLayout="row">
				<label style="font-weight: bold; font-size: 15px;" [hidden]="!paymentStates[0]">Cobrança Criada
				</label><ic-icon class="mt-0" [icon]="iconDone" size="15px" [hidden]="!paymentStates[0]"></ic-icon>
			</div>
			<div fxLayout="row">
				<mat-label style="font-weight: bold; font-size: 15px;" [hidden]="!paymentStates[1]">Pagamento recebido
				</mat-label><ic-icon class="mt-0" [icon]="iconDone" size="15px" [hidden]="!paymentStates[1]"></ic-icon>
			</div>
			<div fxLayout="row">
				<mat-label style="font-weight: bold; font-size: 15px;" [hidden]="!paymentStates[2]">Confirmação manual
					realizada </mat-label><ic-icon class="mt-0" [icon]="iconDone" size="15px"
					[hidden]="!paymentStates[2]"></ic-icon>
			</div>
			<div fxLayout="row">
				<mat-label style="font-weight: bold; font-size: 15px;" [hidden]="!paymentStates[3]">Estorno realizado
					com sucesso </mat-label><ic-icon class="mt-0" [icon]="iconDone" size="15px"
					[hidden]="!paymentStates[3]"></ic-icon>
			</div>
		</div>
		<div fxLayout="column">
			<p style="font-weight: bold; font-size: 20px;" class="mt-3">{{this.stageMessage}}</p>
		</div>

		<div *ngIf="showQrCode" fxLayout="column">
			<ngx-qrcode [elementType]="elementType" [value]="qrCodeString" cssClass="aclass" errorCorrectionLevel="L">
			</ngx-qrcode>
			<mat-label>Valor da cobrança: {{testAmount | currency}}</mat-label>
		</div>

		<div *ngIf="showConfirmationButton" class="mt-3" fxLayout="column" fxLayoutAlign="center center">
			<p class="text-center">Por favor, confira se o valor pago entrou corretamente na conta do provedor. <br>
				Em caso positivo, clique no botão confirmar abaixo. Em caso negativo, feche esta janela e tente
				novamente!
			</p>
			<div fxLayout="row" class="mt-3 mb-3">
				<button mat-raised-button color="primary" [disabled]="loading"
					(click)="manualPaymentConfirmation()">Confirmar
					Pagamento</button>
			</div>
		</div>

		<div class="mt-3 mb-3" fxLayoutAlign="center center" *ngIf="testEndStatus" fxLayout="column">
			<button mat-raised-button color="primary" (click)="clickCloseSuccess()">Finalizar teste</button>
		</div>
	</div>

	<div *ngIf="loading" class="m-6" fxLayoutAlign="center center">
		<mat-spinner diameter="36"></mat-spinner>
	</div>

	<p><b>Obs: O Valor pago será estornado no final do processo.</b></p>
	<div *ngIf="!showConfirmationButton && !testEndStatus" fxLayout="column" fxLayoutAlign="center center">
		<div fxLayout="column">
			<button mat-raised-button color="primary" class="mt-3" (click)="closeModal()">
				Sair
			</button>
		</div>
	</div>
</mat-dialog-content>