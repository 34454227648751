<form [formGroup]="form" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <mat-form-field appearance="fill">
            <mat-label>Período de busca</mat-label>
            <mat-date-range-input [max]="getToday()" [rangePicker]="picker">
                <input matStartDate formControlName="initialDate" placeholder="Data inicial">
                <input matEndDate formControlName="finalDate" placeholder="Data final">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="form.controls.initialDate.hasError('matStartDateInvalid')">Data inicial
                inválida
            </mat-error>
            <mat-error *ngIf="form.controls.initialDate.hasError('matDatepickerParse')">Formato da data
                inicial
                inválida
            </mat-error>
            <mat-error *ngIf="form.controls.finalDate.hasError('matEndDateInvalid')">Data final inválida
            </mat-error>
            <mat-error *ngIf="form.controls.finalDate.hasError('matDatepickerParse')">Formato da data final
                inválida
            </mat-error>
            <mat-error *ngIf="form.controls.finalDate.hasError('required')">Você deve preencher a data final
            </mat-error>
            <mat-error *ngIf="form.controls.initialDate.hasError('required')">Você deve preencher a data
                inicial
            </mat-error>
        </mat-form-field>

        <mat-form-field class="flex-auto">
            <mat-label>CNPJ</mat-label>
            <input required [formControl]="storeFilter" [matAutocomplete]="auto" matInput>
            <mat-autocomplete [displayWith]="getOptionText" #auto="matAutocomplete"
                (optionSelected)='setCnpjValue($event.option.value)'>
                <mat-option *ngFor="let option of cnpjListOptions | async" [value]="option">
                    {{option.trading_name}} - {{option.cnpj | mask:
                    '00.000.000/0000-00'}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.controls.cnpj.hasError('required')">Você deve preencher o cnpj
            </mat-error>
        </mat-form-field>
        <mat-spinner diameter="20" *ngIf="loading" class="mb-4"></mat-spinner>

        <button (click)="search()" class="ml-2 -mt-5" color="primary" fxFlex="none" mat-mini-fab matTooltip="Pesquisar"
            type="submit">
            <mat-icon [icIcon]="icSearch"></mat-icon>
        </button>
    </div>
</form>