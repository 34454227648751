import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { GenericValidator } from 'src/app/utils/genericValidator'

@Component({
  selector: 'vex-provider-document',
  templateUrl: './provider-document.component.html',
  styleUrls: ['./provider-document.component.scss']
})
export class ProviderDocumentComponent implements OnInit {
  form: FormGroup
  providerName: string

  constructor (
    private readonly fb: FormBuilder,
    public matSnackBar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<ProviderDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string }
  ) {
    this.providerName = data.name
  }

  ngOnInit (): void {
    this.form = this.fb.group({
      document: ['', [Validators.required, GenericValidator.isValidCnpj()]]
    })
  }

  save (): void {
    if (!this.form.valid) return

    this.dialogRef.close(this.form.getRawValue())
  }
}
