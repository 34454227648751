import { v4 as uuidv4 } from 'uuid'

export const fieldsStone = {
  companyName: 'Linx',
  corporateName: 'Linx',
  accountName: `QrLinx_${uuidv4()}`,
  segment: 'Varejo e Comércio',
  subSegment: 'Varejo e Comércio',
  type: 'Administrative',
  name: 'Linx',
  email: 'suporte-wallet@linx.com.br',
  phone: '1121032400',
  birthday: '01/01/1990',
  mothersName: 'Maria da Silva',
  zipcode: '05425-902',
  state: 'SP',
  city: 'São Paulo',
  neighborhood: 'Pinheiros',
  street: 'Avenida Doutora Ruth Cardoso',
  number: '7221',
  complement: 'Edifício Birmann 21'
}
