<h2 mat-dialog-title>Autorização no provedor de pagamento</h2>
<mat-dialog-content>
	<div>
		<mat-label class="text-center" fxLayout="row" fxLayoutAlign="center center">
			Para ativar este provedor, é necessário realizar a autorização no Mercado Pago, <br>
			clique no botão abaixo para continuar.
		</mat-label>

		<div fxLayout="column" fxLayoutAlign="center center">
			<div fxLayout="column">
				<button mat-raised-button color="primary" class="mt-3" (click)="openDialogWindow()">
					Continuar
				</button>
			</div>
		</div>
	</div>
</mat-dialog-content>