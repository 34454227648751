<h1 mat-dialog-title>Detalhes do Provedor</h1>
<div mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <h2>{{dialogObj.name}}</h2>
    <h2>({{dialogObj.cnpj |  mask:'00.000.000/0000-00'}})</h2>
  </div>
  <table @stagger [dataSource]="dataSource" *ngIf="true" class="w-full" mat-table matSort>  
    <ng-container matColumnDef="nickname">
        <th mat-header-cell *matHeaderCellDef> Apelido </th>
        <td mat-cell *matCellDef="let element"> {{ element.nickname }} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Provedor </th>
        <td mat-cell *matCellDef="let element"> {{ element.wallet?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="createdAtUtc">
        <th mat-header-cell *matHeaderCellDef> Data de Cadastro </th>
        <td mat-cell *matCellDef="let element"> {{ element.createdAtUtc | date: 'dd/MM/yyyy HH:mm:ss' }} </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-raised-button color="primary" (click)="closeDialog()">Fechar</button>
</div>