import { Component, Input, TemplateRef } from '@angular/core'
import icShoppingBasket from '@iconify/icons-ic/twotone-shopping-basket'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  year: number = new Date().getFullYear()
  @Input() customTemplate: TemplateRef<any>;
  icShoppingBasket = icShoppingBasket;

  env = environment.envName ? ' | Ambiente de ' + environment.envName : ''
}
