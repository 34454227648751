/* eslint-disable indent */
import { I } from '@angular/cdk/keycodes'
import { BreakpointObserver } from '@angular/cdk/layout'
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter, map, startWith, takeUntil } from 'rxjs/operators'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { SidebarComponent } from '../../../@vex/components/sidebar/sidebar.component'
import { ConfigService } from '../../../@vex/services/config.service'
import { LayoutService } from '../../../@vex/services/layout.service'
import { checkRouterChildsData } from '../../../@vex/utils/check-router-childs-data'
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component'
import { DefaultReturnObjDto } from './models/DefaultReturnObjDto'
import { BannerDialogComponent } from './dialogs/banner-dialog/banner-dialog.component'
import { CookieService } from 'ngx-cookie-service'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { Subject } from 'rxjs'
import { DataService } from 'src/app/services/data/data.service'

@UntilDestroy()
@Component({
  selector: 'client-layout',
  templateUrl: './client-layout.component.html',
  styleUrls: ['./client-layout.component.scss']
})
export class ClientLayoutComponent implements OnInit {
  readonly newIntegrationCookieName = 'NOVA_INTEGRACAO'
  
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;
  private lastBasePath: string;

  constructor (
    private layoutService: LayoutService,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog,
    private readonly accreditationService: AccreditationService,
    private readonly cookieService: CookieService,
    private readonly utilsService: UtilsService,
    private readonly dataService: DataService
  ) { }

  ngOnInit (): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (this.lastBasePath != this.extractBasePath(event.url)) {
          if (this.dataService.hasData()) {
            this.dataService.clean()
          }
        }
        this.lastBasePath = this.extractBasePath(event.url)
      })
    
    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close())

    if (this.utilsService.canShowElementByFunctionality('RegisterProvider')) {
      this._getStoresWithoutProviders()
    }

    // setTimeout(() => this.showNewIntegrationBanner(), 3000)
  }

  private extractBasePath (url: string): string {
    const segments = url.split('/')
    return segments.length > 1 ? '/' + segments[1] : '/'
  }

  private showNewIntegrationBanner (): void {
    if (!this.cookieService.check(this.newIntegrationCookieName)) {
      this.dialog.open(BannerDialogComponent, {
        data: {
          cookieName: this.newIntegrationCookieName
        }
      })
        .afterClosed()
        .subscribe(() => {
          const expires = new Date()
          expires.setDate(expires.getDate() + 4)
          expires.setHours(0, 0, 0, 0)

          this.cookieService.set(this.newIntegrationCookieName, '', { expires })
        })
    }
  }

  private _getStoresWithoutProviders (): void {
    if (!this.utilsService.isUserFromLinx()) {
      this.accreditationService._getStoresWithoutProviders()
        .subscribe(
          (res: DefaultReturnObjDto<boolean>) => {
            if (res.data) {
              this.openConfirmDialog()
            }
          })
    }

  }

  openConfirmDialog (): void {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        dialogTitle: 'Aviso de cadastro de provedor de pagamento',
        dialogText: 'Existem estabelecimentos, vinculados ao seu acesso, sem provedor de pagamento cadastrado. Esse cadastro é fundamental para utilização do QRLinx.\
          \n\nAcesse o menu "Provedores de Pagamentos" para cadastrar as opções de pagamento desejadas.',
        dialogCloseText: 'Depois',
        dialogConfirmText: 'Cadastrar'
      }
    })

    dialog.afterClosed().subscribe(result => {
      if (result.event == 'CONFIRM') {
        return this.router.navigate(['provider'])
      }
    })
  }
}
