import { Component, OnInit } from '@angular/core'
import dateFormat from 'dateformat'
import { trackById } from '../../../../utils/track-by'
import icSettings from '@iconify/icons-ic/twotone-settings'
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right'
import icDownload from '@iconify/icons-ic/twotone-download-done'
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off'
import icClearAll from '@iconify/icons-ic/twotone-clear-all'
import { ConciliationService } from 'src/app/services/conciliation/conciliation.service'
import { ReportsNotificationsListVm } from 'src/app/layouts/client-layout/models/notification'
import { Router } from '@angular/router'
import { UtilsService } from 'src/app/services/utils/utils.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  notifications: ReportsNotificationsListVm[] = []
  notificationsQtd = 0
  canDownloadReports = false
  canListNotification = false
  constructor (
    private readonly conciliationService: ConciliationService,
    private readonly utils: UtilsService,
    private readonly snackbar: MatSnackBar,
    private readonly router: Router
  ) {
    this.canDownloadReports = utils.canShowElementByFunctionality('portal-report-download')
    this.canListNotification = utils.canShowElementByFunctionality('portal-report-notification')
  }

  loading = true

  icDownload = icDownload
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  trackById = trackById;

  ngOnInit (): void {
    if (!this.canListNotification) return
    this.conciliationService.getNotifications().subscribe(res => {
      this.loading = false
      this.notificationsQtd = res.dados.notificationsQtd
      this.notifications = res.dados.notifications.map(e => {
        e.initialPeriod = dateFormat(e.initialPeriod, 'dd/mm/yy')
        e.finalPeriod = dateFormat(e.finalPeriod, 'dd/mm/yy')
        return e
      })
    }, () => {
      this.loading = false
    })
  }

  downloadReport (id: string): void {
    if (!this.canDownloadReports) {
      this.snackbar.open('Você não tem a permissão (portal-report-download) para baixar o relatório!', '', {
        duration: 5000
      })
      return
    }
    if (this.loading) return
    this.loading = true

    this.conciliationService.download(id).subscribe(() => {
      this.loading = false
    }, () => {
      this.loading = false
    })
  }

  openReportPage (): Promise<boolean> {
    return this.router.navigate(['reports'])
  }

}
