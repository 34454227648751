import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CreateUserDto } from 'src/app/layouts/client-layout/models/CreateUserDto'
import { DefaultReturnDto } from 'src/app/layouts/client-layout/models/DefaultReturnDto'
import { DefaultReturnObjDto } from 'src/app/layouts/client-layout/models/DefaultReturnObjDto'
import { Users } from 'src/app/layouts/client-layout/models/users'
import { environment } from 'src/environments/environment'
import { UtilsService } from '../utils/utils.service'
import { LocalStorangeService } from '../local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = environment.baseUrl
 
  constructor (
    private readonly httpClient: HttpClient,
    private readonly utilsService: UtilsService,
    private readonly localStorangeService: LocalStorangeService
  ) { }

  public getUsers (params: {
    limit: number
    currentPage: number
    nameOrEmail?: string
  }): Observable<DefaultReturnDto<Users>> {
    const queryString = this.utilsService.encodeQueryData(params)
    return this.httpClient.get<DefaultReturnDto<Users>>(
      `${this.baseUrl}/v1/user?${queryString}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public deleteUser (userId: string): Observable<DefaultReturnDto<Users>> {
    return this.httpClient.delete<DefaultReturnDto<Users>>(
      `${this.baseUrl}/v1/user/${userId}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public getUser (userId: string): Observable<DefaultReturnObjDto<Users>> {
    return this.httpClient.get<DefaultReturnObjDto<Users>>(
      `${this.baseUrl}/v1/user/${userId}`,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public liberateSso (userId: string): Observable<DefaultReturnDto<Users>> {
    return this.httpClient.post<DefaultReturnDto<any>>(
      `${this.baseUrl}/v1/user/liberate`,
      { user_id: userId },
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public updateUser (
    createUserDto: CreateUserDto
  ): Observable<DefaultReturnDto<Users>> {
    return this.httpClient.put<DefaultReturnDto<any>>(
      `${this.baseUrl}/v1/user`,
      createUserDto,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }

  public createUser (
    createUserDto: CreateUserDto
  ): Observable<DefaultReturnDto<Users>> {
    return this.httpClient.post<DefaultReturnDto<any>>(
      `${this.baseUrl}/v1/user`,
      createUserDto,
      {
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      }
    )
  }
}
