import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { PopoverService } from '../../../components/popover/popover.service'
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component'
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active'
import { ConciliationService } from 'src/app/services/conciliation/conciliation.service'
import { UtilsService } from 'src/app/services/utils/utils.service'

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {
  countValue = 0
  hiddenBadge = true
  loading = false
  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;
  icNotificationsActive = icNotificationsActive;
  canListNotification = false

  constructor (
    private popover: PopoverService,
    private utils: UtilsService,
    private cd: ChangeDetectorRef,
    private readonly conciliationService: ConciliationService) {
    this.canListNotification = utils.canShowElementByFunctionality('portal-report-notification')
  }

  ngOnInit (): void {
    if (!this.canListNotification) return
    this.loading = true
    this.hiddenBadge = true
    this.conciliationService.getNotifications().subscribe(res => {
      this.loading = false
      this.hiddenBadge = false
      this.countValue = res.dados.notificationsQtd
      if (!res.dados.notificationsQtd) {
        this.hiddenBadge = true
      }
      this.cd.markForCheck()
    })
  }

  showPopover (): void {
    this.dropdownOpen = true
    this.cd.markForCheck()

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    })

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false
      if (!this.canListNotification) return
      if (!this.loading) {
        this.conciliationService.getNotifications().subscribe(res => {
          this.loading = false
          if (!res.dados.notificationsQtd) {
            this.hiddenBadge = true
          }
          this.countValue = res.dados.notificationsQtd
          this.cd.markForCheck()
        })
      }
      this.cd.markForCheck()
    })
  }
}
