import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Message, MessageService } from 'primeng/api'
import { Observable, throwError } from 'rxjs'

import { LocalStorangeService } from './local-storage.service'
import { UtilsService } from './utils/utils.service'
import { catchError, map, retry } from 'rxjs/operators'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor (
    private httpClient: HttpClient,
    private localStorangeService: LocalStorangeService,
    private router: Router,
    private messageService: MessageService,
    private utilsService: UtilsService,
    private readonly snackbar: MatSnackBar
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public get (endPoint: string, carregarLoading: boolean = true): Promise<any> {

    if (carregarLoading) {
      this.lockscreen()
    }

    return this.httpClient.get(endPoint, {
      headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
    })
      .pipe(map(objectRetorno => {

        if (carregarLoading) {
          this.unlockScreen()
        }
        return objectRetorno

      }))
      .toPromise()
      .catch(error => {
        this.errorHandler(error)
      })
  }
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public post (objectSend: any, endPoint: string, carregarLoading: boolean = true): Promise<any> {
    if (carregarLoading) {
      this.lockscreen()
    }

    return this.httpClient.post(
      endPoint,
      objectSend,
      {
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
      .pipe(
        map(objectRetorno => {
          if (carregarLoading) {
            this.unlockScreen()
          }
          return objectRetorno
        })
      ).toPromise()
      .catch((error: HttpErrorResponse) => {
        this.errorHandler(error)
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public put (objectSend: any, endPoint: string, carregarLoading: boolean = true): Promise<any> {

    if (carregarLoading) {
      this.lockscreen()
    }

    return this.httpClient.put(
      endPoint,
      objectSend,
      {
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
      .pipe(
        map(objectRetorno => {
          if (carregarLoading) {
            this.unlockScreen()
          }

          return objectRetorno
        })
      )
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        this.errorHandler(error)
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public patch (objectSend: any, endPoint: string, carregarLoading: boolean = true): Promise<any> {

    if (carregarLoading) {
      this.lockscreen()
    }

    return this.httpClient.patch(
      endPoint,
      objectSend,
      {
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
      .pipe(
        map(objectRetorno => {
          if (carregarLoading) {
            this.unlockScreen()
          }

          return objectRetorno
        })
      )
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        this.errorHandler(error)
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public delete (endPoint: string, carregarLoading: boolean = true): Promise<any> {

    if (carregarLoading) {
      this.lockscreen()
    }

    return this.httpClient.delete(
      endPoint,
      {
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
      .pipe(map(objectRetorno => {
        if (carregarLoading) {
          this.unlockScreen()
        }
        return objectRetorno
      })
      ).toPromise()
      .catch((error: HttpErrorResponse) => {
        this.errorHandler(error)
      })
  }
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public uploadFile (file: File, endPoint: string, runLoading: boolean = true) {
    const formData = new FormData()
    formData.append('formFile', file)

    if (runLoading) {
      this.lockscreen()
    }

    return this.httpClient.post(
      endPoint,
      formData,
      {
        reportProgress: true,
        responseType: 'arraybuffer',
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentialsFormFile(this.localStorangeService.getTokenUser())
      }
    ).pipe(
      map(objectRetorno => {

        if (runLoading) {
          this.unlockScreen()
        }

        return objectRetorno
      }),
      retry(1),
      catchError(this.errorHandlerUpload)
    )
      .toPromise()
  }

  public downloadFile (fileName: string, endPoint: string): Observable<any> {
    return this.httpClient.get(
      endPoint,
      {
        responseType: 'blob',
        withCredentials: true,
        headers: this.utilsService.getHeadersCredentials(this.localStorangeService.getTokenUser())
      })
      .pipe(
        map((res) => {
          // return saveAs(res, fileName)
        })
      )
  }

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  private errorHandler (error: any): void {
    // document.getElementById('myNav')!.style.width = '0%'

    const messages = new Array<Message>()
    console.log(error.status == 400)

    if (error.status == 401) {
      this.router.navigate(['/login'])
      this.snackbar.open('Usuário sem permissão!', 'Fechar')
      // messages.push({ key: 'toastMensagem', severity: 'warn', summary: 'Permissão', detail: 'Usuário sem permissão!', life: 4000 })
    }

    if (error.status != 401 && error.status >= 400 && error.status <= 499) {
      console.log('errrorrr 4000000')
      console.log(error?.error?.erros)
      const keys = Object.keys(error?.error?.erros) as (keyof typeof error.error.erros)[]
      const objErrors = error.error.erros
      keys.forEach((key) => {
        this.snackbar.open(objErrors[key].description, 'Fechar')
        // messages.push({ key: 'toastMensagem', severity: 'warn', summary: 'Informação', detail: objErrors[key].description, life: 4000 })
      })
    }

    if (error.status >= 500) {
      this.snackbar.open('Erro entre em contato com o suporte', 'Fechar')
      // messages.push({ key: 'toastMensagem', severity: 'error', summary: 'Erro', detail: 'Erro entre em contato com o suporte', life: 4000 })
    }

    this.messageService.addAll(messages)
  }

  private errorHandlerUpload (error: HttpErrorResponse) {
    return throwError(error.message || 'server error.')
  }

  private lockscreen (): void {
    // document.getElementById('myNav').style.width = '100%'
  }

  private unlockScreen (): void {
    // document.getElementById('myNav').style.width = '0%'
  }
}
