<vex-page-layout>
  <vex-page-layout-header class="pb-16" fxLayout="column" fxLayoutAlign="center start">
    <div [class.px-gutter]="true" class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="mt-0 mb-1">
          <b>{{ eventName }}</b>
        </h1>
        <vex-breadcrumbs [crumbs]="breadcrumbs"></vex-breadcrumbs>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.px-gutter]="true" class="-mt-6">
    <mat-vertical-stepper class="-mt-16" [linear]="true" #stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon [icIcon]="icDoneAll"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="edit">
        <mat-icon [icIcon]="icDoneAll"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon [icIcon]="icDoneAll"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon [icIcon]="icDoneAll"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon [icIcon]="icDoneAll"></mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon [icIcon]="icDoneAll"></mat-icon>
      </ng-template>

      <mat-step [stepControl]="providersForm">
        <ng-template matStepLabel>Selecione os provedores</ng-template>
        <form class="w-full pt-4" autocomplete="off" [formGroup]="providersForm" *ngIf="!loadingProviders"
          fxLayoutGap="25px">
          <div class="w-full">
            <div class="bg-card rounded-full border my-4 px-4 w-50" fxLayoutAlign="start center">
              <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
              <input formControlName="searchBy"
                class="px-4 py-3 border-0 outline-none w-full bg-transparent flex-grow-1"
                placeholder="Pesquise os provedores pelo apelido ou pelo CNPJ da loja " type="text" />
            </div>
          </div>
        </form>

        <div class="card overflow-auto">
          <table @stagger [dataSource]="providersDataSource" class="w-full" mat-table matSort>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="providersSelection.hasValue() && allSelected" color="primary">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="selectProvider($event, row)"
                  [checked]="providersSelection.isSelected(row)" color="primary">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="pixName">
              <th mat-header-cell *matHeaderCellDef>Pix</th>
              <td mat-cell *matCellDef="let element">
                {{ element.pixName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="nickname">
              <th mat-header-cell *matHeaderCellDef>Apelido</th>
              <td mat-cell *matCellDef="let element">
                {{ element.nickname }}
              </td>
            </ng-container>
            <ng-container matColumnDef="storeQuantity">
              <th mat-header-cell *matHeaderCellDef>Quantidade de lojas</th>
              <td mat-cell *matCellDef="let element">
                {{ element.storeQuantity }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <div fxLayout.lt-md="column" fxLayout.md="column" fxLayout="column" fxLayoutAlign="space-around center">
            <div class="center" *ngIf="
                (!providersDataSource.data ||
                  providersDataSource.data.length === 0) &&
                !loadingProviders
              ">
              NENHUM PROVEDOR ENCONTRADO!
            </div>
            <div *ngIf="loadingProviders" class="m-6" fxLayoutAlign="center center">
              <mat-spinner diameter="36"></mat-spinner>
            </div>
            <mat-paginator showFirstLastButtons [disabled]="loadingProviders" [pageSize]="paging.pageSize"
              fxLayoutAlign="center" (page)="changePage($event)" [length]="paging.length"
              [pageSizeOptions]="[10, 50, 100, 1000]" aria-label="Select page">
            </mat-paginator>
          </div>
        </div>

        <div *ngIf="!loadingProviders" fxLayout="row" class="pt-6" fxLayoutAlign="end center">
          <button mat-raised-button color="primary" (click)="goToNextStep(providersForm)" [disabled]="
              (selectedProviderIds.length == 0 && !allSelected) ||
              loadingProviders
            ">
            PRÓXIMO
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="identificationForm">
        <ng-template class="w-full" matStepLabel>
          <div class="w-full" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="flex-grow">Identificação</div>
          </div>
        </ng-template>
        <form class="flex pt-4 flex-col" autocomplete="off" [formGroup]="identificationForm">
          <div *ngIf="loading" class="mt-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
          </div>
          <section *ngIf="!loading">
            <div class="gap-5 flex flex-row w-full">
              <mat-form-field class="flex-grow">
                <mat-label>Selecione o tipo de documento</mat-label>
                <mat-select formControlName="documentType">
                  <mat-option value="CPF" selected> CPF </mat-option>
                  <mat-option value="CNPJ"> CNPJ </mat-option>
                </mat-select>
                <mat-error *ngIf="identificationFields.documentType.invalid">Tipo de documento é obrigatório</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Documento
                  {{
                  identificationFields.documentType.value
                  ? ' - ' + identificationFields.documentType.value
                  : ''
                  }}</mat-label>
                <input matInput formControlName="document" type="text" [mask]="getDocumentMask()" [placeholder]="
                    identificationFields.documentType.value
                      ? 'Informe o ' +
                        identificationFields.documentType.value +
                        ' do recebedor'
                      : 'Informe o documento de identificação do recebedor'
                  " />
                <mat-error *ngIf="
                    identificationFields.document.invalid &&
                    identificationFields.document.hasError('required')
                  ">O documento é obrigatório</mat-error>

                <mat-error *ngIf="
                    identificationFields.document.invalid &&
                    identificationFields.document.hasError('cpfInvalid')
                  ">CPF inválido</mat-error>

                <mat-error *ngIf="
                    identificationFields.document.invalid &&
                    identificationFields.document.hasError('cnpjInvalid')
                  ">CNPJ inválido</mat-error>
              </mat-form-field>
            </div>
          </section>
        </form>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button [disabled]="!editRecipient && !identificationForm.valid || loading" (click)="
              validReceiverAlreadyExistsAndGoToNextStep(identificationForm)
            " mat-raised-button color="primary">
            PRÓXIMO
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="recipientForm">
        <ng-template class="w-full" matStepLabel>
          <div class="w-full" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="flex-grow">Informações do recebedor</div>
          </div>
        </ng-template>
        <form class="flex pt-4 flex-col" autocomplete="off" [formGroup]="recipientForm">
          <div *ngIf="loading" class="mt-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
          </div>
          <section *ngIf="!loading">
            <div class="gap-5 flex flex-row w-full">
              <mat-form-field class="flex-grow">
                <mat-label>Nome do recebedor</mat-label>
                <input matInput formControlName="name" [readonly]="editRecipient"
                  placeholder="Informe o nome do recebedor" />
                <mat-error *ngIf="
                    recipientFields.name.invalid &&
                    recipientFields.name.hasError('required')
                  ">O nome do recebedor é obrigatório</mat-error>
                <mat-error *ngIf="
                    recipientFields.name.invalid &&
                    recipientFields.name.hasError('pattern')
                  ">O nome do recebedor não pode conter caracteres
                  especiais</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="email" placeholder="Informe o email do recebedor" />
                <mat-error *ngIf="
                    recipientFields.email.invalid &&
                    recipientFields.email.hasError('required')
                  ">O e-mail é obrigatório</mat-error>
                <mat-error *ngIf="
                    recipientFields.email.invalid &&
                    recipientFields.email.hasError('pattern')
                  ">O e-mail é inválido</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Descrição</mat-label>
                <input matInput formControlName="description" placeholder="Informe a descrição do recebedor" />
                <mat-error *ngIf="recipientFields.description.invalid">A descrição é obrigatória</mat-error>
              </mat-form-field>
            </div>
          </section>
        </form>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button [disabled]="!recipientForm.valid || loading" (click)="goToNextStep(recipientForm)" mat-raised-button
            color="primary">
            PRÓXIMO
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="bankAccountForm">
        <ng-template class="w-full" matStepLabel>
          <div class="w-full" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="flex-grow">Informações bancárias</div>
          </div>
        </ng-template>
        <form class="flex pt-4 flex-col" autocomplete="off" [formGroup]="bankAccountForm">
          <div *ngIf="loading" class="mt-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
          </div>
          <section *ngIf="!loading">
            <div class="gap-5 flex flex-row w-full">
              <mat-form-field class="flex-grow">
                <mat-label>Banco</mat-label>
                <mat-select formControlName="bankName" #bankSelect (opened)="Openedselect()">
                  <mat-option>
                    <ngx-mat-select-search placeholderLabel="Digite o nome do seu banco" [noEntriesFoundLabel]="
                        bankFilterCtrl.value && bankFilterCtrl.value.length < 3
                          ? 'Digite pelo menos 3 caracteres'
                          : isSearchBankings
                          ? 'Pesquisando ...'
                          : 'Nenhum banco encontrado'
                      " [formControl]="bankFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank.name">
                    {{ bank.code }} - {{ bank.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="
                    bankAccountFields.bankName.invalid &&
                    bankAccountFields.bankName.hasError('required')
                  ">Selecione um banco</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Selecione o tipo de Conta</mat-label>
                <mat-select formControlName="accountType">
                  <mat-option value="SavingsAccount">Conta Poupança</mat-option>
                  <mat-option value="CheckingAccount">Conta Corrente</mat-option>
                </mat-select>
                <mat-error *ngIf="bankAccountFields.accountType.invalid">Tipo de conta é obrigatório</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Nome do responsável da conta bancária</mat-label>
                <input matInput formControlName="bankResponsableName"
                  placeholder="Informe o nome do responsável pela conta" />
                <mat-error *ngIf="
                    bankAccountFields.bankResponsableName.invalid &&
                    bankAccountFields.bankResponsableName.hasError('required')
                  ">O nome é obrigatório</mat-error>
              </mat-form-field>
            </div>

            <div class="gap-5 flex flex-row w-full">
              <mat-form-field class="flex-grow">
                <mat-label>Agência</mat-label>
                <input matInput type="number" formControlName="branch" placeholder="Informe a conta do recebedor" />
                <mat-error *ngIf="
                    bankAccountFields.branch.invalid &&
                    bankAccountFields.branch.hasError('required')
                  ">A conta é obrigatória</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Dígito</mat-label>
                <input matInput type="number" maxlength="1" formControlName="branchDigit"
                  placeholder="Informe o digíto da conta" />
                <mat-error *ngIf="bankAccountFields.branchDigit.invalid">O dígito da agência pode ter no máximo 1
                  caracter</mat-error>
              </mat-form-field>
            </div>
            <div class="gap-5 flex flex-row w-full">
              <mat-form-field class="flex-grow">
                <mat-label>Conta</mat-label>
                <input matInput type="number" formControlName="account" placeholder="Informe a conta do recebedor" />
                <mat-error *ngIf="
                    bankAccountFields.branch.invalid &&
                    bankAccountFields.branch.hasError('required')
                  ">A conta é obrigatória</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow">
                <mat-label>Dígito</mat-label>
                <input matInput type="number" maxlength="2" formControlName="accountDigit"
                  placeholder="Informe o digito da conta" />
                <mat-error *ngIf="
                    bankAccountFields.accountDigit.invalid &&
                    bankAccountFields.accountDigit.hasError('required')
                  ">O dígito da conta é obrigatório</mat-error>
                <mat-error *ngIf="
                    bankAccountFields.accountDigit.invalid &&
                    (bankAccountFields.accountDigit.hasError('min') ||
                      bankAccountFields.accountDigit.hasError('max'))
                  ">O dígito da conta pode ter no máximo 2 caracteres</mat-error>
              </mat-form-field>
            </div>
          </section>
        </form>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button [disabled]="loading" (click)="goToNextStep(bankAccountForm)" mat-raised-button color="primary">
            PRÓXIMO
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="automaticAnticipationForm">
        <ng-template class="w-full" matStepLabel>
          <div class="w-full" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="flex-grow">Configurações de antecipação</div>
          </div>
        </ng-template>
        <form class="flex pt-4 flex-col" autocomplete="off" [formGroup]="automaticAnticipationForm">
          <div *ngIf="loading" class="mt-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
          </div>

          <section *ngIf="!loading">
            <div *ngIf="editRecipient" class="gap-5 flex flex-row w-full mt-3">
              <small class="text-muted">
                Não é permitido alteração da configuração de antecipação, isso
                somente poderá ser alterado pelo lojista solicitando via canal
                da pagarMe</small>
            </div>
            <div class="gap-5 flex flex-row w-full">
              <mat-slide-toggle formControlName="automaticAnticipationIsEnabled" [disabled]="loading" color="primary">
                {{
                automaticAnticipationFields.automaticAnticipationIsEnabled
                .value
                ? 'Ativada'
                : 'Desativada'
                }}
              </mat-slide-toggle>
            </div>
            <div class="p-3 bg-alert border-alert-light border text-alert-dark items-center rounded flex my-3" *ngIf="
                automaticAnticipationFields.automaticAnticipationIsEnabled.value
              ">
              <ic-icon [icIcon]="icWarning" size="30px"></ic-icon>
              <span class="ml-3">
                Para utilizar configurações de antecipação automática é
                necessário realizar a liberação junto a Pagar.me.
              </span>
            </div>
            <div class="gap-5 flex flex-row w-full mt-3">
              <mat-form-field class="flex-grow" *ngIf="
                  automaticAnticipationFields.automaticAnticipationIsEnabled
                    .value
                ">
                <mat-label>Modelo de antecipação</mat-label>
                <mat-select formControlName="automaticAnticipationType">
                  <mat-option value="Volume">Volume</mat-option>
                  <mat-option value="Days">Recebimento em D+X</mat-option>
                </mat-select>
                <mat-error *ngIf="
                    automaticAnticipationFields.automaticAnticipationType
                      .invalid
                  ">O modelo de antecipação é obrigatório</mat-error>
              </mat-form-field>
            </div>
            <div class="gap-5 flex flex-row w-full">
              <mat-form-field class="flex-grow" *ngIf="
                  automaticAnticipationFields.automaticAnticipationType
                    .value === 'Volume' &&
                  automaticAnticipationFields.automaticAnticipationIsEnabled
                    .value
                ">
                <mat-label>Volume antecipável (%)</mat-label>
                <input matInput type="number" formControlName="automaticAnticipationVolumePercentage"
                  placeholder="Volume antecipável (%)" />
                <mat-error *ngIf="
                    automaticAnticipationFields
                      .automaticAnticipationVolumePercentage.invalid &&
                    automaticAnticipationFields.automaticAnticipationVolumePercentage.hasError(
                      'required'
                    )
                  ">O volume antecipável é obrigatório</mat-error>
                <mat-error *ngIf="
                    automaticAnticipationFields
                      .automaticAnticipationVolumePercentage.invalid &&
                    automaticAnticipationFields.automaticAnticipationVolumePercentage.hasError(
                      'positiveNumber'
                    )
                  ">O valor da porcentagem deve ser um número valido</mat-error>
              </mat-form-field>

              <mat-form-field class="flex-grow" *ngIf="
                  automaticAnticipationFields.automaticAnticipationType
                    .value === 'Days' &&
                  automaticAnticipationFields.automaticAnticipationIsEnabled
                    .value
                ">
                <mat-label>Delay em dias</mat-label>
                <input matInput type="number" formControlName="automaticAnticipationDelay"
                  placeholder="Delay em dias" />
                <mat-error *ngIf="
                    automaticAnticipationFields.automaticAnticipationDelay
                      .invalid &&
                    automaticAnticipationFields.automaticAnticipationDelay.hasError(
                      'required'
                    )
                  ">O delay em dias é obrigatório</mat-error>

                <mat-error *ngIf="
                    automaticAnticipationFields.automaticAnticipationDelay
                      .invalid &&
                    automaticAnticipationFields.automaticAnticipationDelay.hasError(
                      'positiveNumber'
                    )
                  ">O delay em dias deve ser um numero valido</mat-error>
              </mat-form-field>
            </div>
          </section>
        </form>

        <div fxLayout="row" fxLayoutAlign="end center">
          <button (click)="goToNextStep(automaticAnticipationForm)" mat-raised-button color="primary">
            PRÓXIMO
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="transferForm">
        <ng-template class="w-full" matStepLabel>
          <div class="w-full" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="flex-grow">Configurações de transferências</div>
          </div>
        </ng-template>
        <form class="flex pt-4 flex-col" autocomplete="off" [formGroup]="transferForm">
          <div *ngIf="loading" class="mt-6" fxLayoutAlign="center center">
            <mat-spinner diameter="36"></mat-spinner>
          </div>
          <section *ngIf="!loading">
            <div class="gap-5 flex flex-row w-full">
              <mat-label>Transferência automática</mat-label>
              <mat-slide-toggle formControlName="receivePaymentsAutomatically" [disabled]="loading" color="primary">
                {{
                transferFields.receivePaymentsAutomatically.value
                ? 'Ativada'
                : 'Desativada'
                }}
              </mat-slide-toggle>
            </div>
            <div class="gap-5 flex flex-row w-full mt-3">
              <mat-form-field class="flex-grow" *ngIf="transferFields.receivePaymentsAutomatically.value">
                <mat-label>Periodicidade da transferência</mat-label>
                <mat-select formControlName="transferInterval">
                  <mat-option value="daily">Diária</mat-option>
                  <mat-option value="weekly">Semanal</mat-option>
                  <mat-option value="monthly">Mensal</mat-option>
                </mat-select>
                <mat-error *ngIf="transferFields.transferInterval.invalid">A peridiocidade é obrigatória</mat-error>
              </mat-form-field>
            </div>
            <div class="gap-5 flex flex-row w-full" *ngIf="transferFields.transferInterval.value == 'weekly'">
              <mat-form-field class="flex-grow" *ngIf="transferFields.receivePaymentsAutomatically.value">
                <mat-label>Dia da transafêrencia</mat-label>
                <mat-select formControlName="transferDay">
                  <mat-option value="1">Segunda-feira</mat-option>
                  <mat-option value="2">Terça-feira</mat-option>
                  <mat-option value="3">Quarta-feira</mat-option>
                  <mat-option value="4">Quinta-feira</mat-option>
                  <mat-option value="5">Sexta-feira</mat-option>
                </mat-select>
                <mat-error *ngIf="
                    transferFields.transferDay.invalid &&
                    transferFields.transferDay.hasError('required')
                  ">O dia da transferência é obrigatório</mat-error>
                <mat-error *ngIf="
                    (transferFields.transferDay.invalid &&
                      transferFields.transferDay.hasError('min')) ||
                    transferFields.transferDay.hasError('max')
                  ">O dia da transaferência deve ser entre 1 e 31</mat-error>
              </mat-form-field>
            </div>
            <div class="gap-5 flex flex-row w-full" *ngIf="transferFields.transferInterval.value == 'monthly'">
              <mat-form-field class="flex-grow" *ngIf="transferFields.receivePaymentsAutomatically.value">
                <mat-label>Dia da transafêrencia</mat-label>
                <input matInput type="number" formControlName="transferDay" placeholder="Dia da transferência" />
                <mat-error *ngIf="
                    transferFields.transferDay.invalid &&
                    transferFields.transferDay.hasError('required')
                  ">O dia da transferência é obrigatório</mat-error>
                <mat-error *ngIf="
                    (transferFields.transferDay.invalid &&
                      transferFields.transferDay.hasError('min')) ||
                    transferFields.transferDay.hasError('max')
                  ">O dia da transaferência deve ser entre 1 e 31</mat-error>
              </mat-form-field>
            </div>
          </section>
        </form>

        <mat-spinner diameter="10" *ngIf="loading"></mat-spinner>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button (click)="finish()" mat-raised-button color="primary">
            {{ !editRecipient ? 'Criar Recebedor' : 'Editar Recebedor' }}
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </vex-page-layout-content>
</vex-page-layout>