import { Component, OnInit, Inject } from '@angular/core'
import icSearch from '@iconify/icons-ic/sharp-search'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { FilterStoresSearch } from '../../../models/FilterStoresSearch'
import { WalletDto } from '../../../models/WalletDto'
import { BrandDto } from '../../../models/BrandDto'
import { PixDto } from '../../../models/PixDto'
import { AccreditationService } from 'src/app/services/accreditation/accreditation.service'
import { DefaultReturnObjDto } from '../../../models/DefaultReturnObjDto'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { getPspList } from '../../provider/create-provider/info-data'

@Component({
  selector: 'vex-dialog-filter-stores',
  templateUrl: './dialog-filter-stores.component.html',
  styleUrls: ['./dialog-filter-stores.component.scss']
})
export class DialogFilterStoresComponent implements OnInit {
  icSearch = icSearch

  form!: FormGroup
  searchCtrl: FormControl = new FormControl('')
  filter: FilterStoresSearch
  wallets: Array<WalletDto>
  brands: Array<BrandDto>
  pixPsp: Array<PixDto> = getPspList()

  constructor (
    private fb: FormBuilder,
    private readonly accreditationService: AccreditationService,
    public dialogRef: MatDialogRef<DialogFilterStoresComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilterStoresSearch
  ) {
    this.filter = data
  }

  ngOnInit (): void {
    this.form = this.fb.group({
      cnpj: [''],
      walletName: [''],
      brandName: [''],
      integrationTypePix: ['']
    })

    // this.form.get('cnpj').setValue(this.filter.cnpj ?? '')
    // this.form.get('walletName').setValue(this.filter.walletName ?? '')
    // this.form.get('brandName').setValue(this.filter.brandName ?? '')
    // this.form
    //   .get('integrationTypePix')
    //   .setValue(this.filter.integrationTypePix ?? '')

    this._getWalletData()
    this._getBrandData()
  }

  private _getWalletData (): Promise<void> {
    return new Promise((resolve) => {
      this.accreditationService
        .getWallets()
        .subscribe((res: DefaultReturnObjDto<Array<WalletDto>>) => {
          this.wallets = res.data
          resolve()
        })
    })
  }

  private _getBrandData (): Promise<void> {
    return new Promise((resolve) => {
      this.accreditationService
        .getBrands()
        .subscribe((res: DefaultReturnObjDto<Array<BrandDto>>) => {
          this.brands = res.data
          resolve()
        })
    })
  }

  public filterData (): void {
    // this.filter.cnpj = this.form.get('cnpj').value
    // this.filter.walletName = this.form.get('walletName').value
    // this.filter.brandName = this.form.get('brandName').value

    // if (!isNaN(parseInt(this.form.get('integrationTypePix').value)))
    //   this.filter.integrationTypePix = parseInt(
    //     this.form.get('integrationTypePix').value
    //   )
    // else this.filter.integrationTypePix = this.form.get('integrationTypePix').value ?? ''
    this.filter.pageNumber = 0

    this.dialogRef.close({ event: 'FILTER', filter: this.filter })
  }

  public clearFilter (): void {
    this.form.get('cnpj').setValue('')
    this.form.get('walletName').setValue('')
    this.form.get('brandName').setValue('')
    this.form.get('integrationTypePix').setValue('')
  }
}
